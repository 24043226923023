
import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from 'react-bootstrap';
import { AdjustmentsIcon, CheckIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";

import swal from 'sweetalert';
import { Routing } from "routes";
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as authAction from "actions/authActions";

import './style.css';
import { indexOf } from "underscore";
import {ADMIN_ERROR, ADMIN_INPROGRESS, IS_AUTHENTICATED} from "../../constants/actionsTypes";
import axios from "axios";
import ENVIRONMENT_VARIABLES from "../../config";

const AddRole = (props) => {

    const history = useHistory();
    const { state } = props.location;
    const [roleId, setRoleID] = useState('');
    const [role, setRole] = useState("");
    const [editRole, setEditRole] = useState(false);
    const [description, setDescription] = useState("");
    const [landingPage, setLandingPage] = useState("");
    const [roleStatus, setRoleStatus ] = useState("false");
    
    const adminAuthReducer = useSelector((state) => ({
        error_msg: state.adminAuthReducer.error_msg,
        isLoading: state.adminAuthReducer.loading,
        isUpdated: state.adminAuthReducer.isUpdated,
        isCreated: state.adminAuthReducer.isCreated
    }));

    function handleSubmit(event) {
        event.preventDefault();
        if(editRole) {
            const updateRole = { roleId, role, description, landingPage }
            props.actions.auth.updateRole(updateRole);

        } else {
            const addRole = { role, description, landingPage }
            props.actions.auth.createRole(addRole);
        }
    }

    useEffect(() => {
        if(state){
            setEditRole(true);
            setRole(state.name);
            setRoleID(state._id);
            setDescription(state.description);
            setLandingPage(state.landingPage); 
            setRoleStatus(state.isActive);
        }

    }, []);

    useEffect(() => {
        if(adminAuthReducer.isCreated) {
            history.push(Routing.Roles.path);
        }
    }, [adminAuthReducer.isCreated]);

    useEffect(() => {
        if(adminAuthReducer.isUpdated) {
            history.push(Routing.Roles.path);
        }
    }, [adminAuthReducer.isUpdated]);

    useEffect(() => {
        if(adminAuthReducer.error_msg !== null) {
            swal('Oops...', adminAuthReducer.error_msg.toString(), 'error');
        }
    }, adminAuthReducer.error_msg);
    
    const redirectUrl = (url) => {
        history.push(url);
    };

    return (
        <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                    <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => redirectUrl(Routing.Roles.path)}>Roles</Breadcrumb.Item>
                    <Breadcrumb.Item active>Add Role Management</Breadcrumb.Item>
                </Breadcrumb>
                {!editRole ? <h4>Add Role Management</h4> : <h4>Edit Role Management</h4> }
            </div>
        </div>
        
        <Card border="0" className="shadow p-3 pb-4 mb-4">
            <Card.Body className="p-0 p-md-4">
                <Form className="mt-4" onSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        
                        {   
                            editRole ?
                                <>
                                <Col xs={12} lg={6}>
                                    <Form.Group id="roles" className="mb-4">
                                        <Form.Label> Title: </Form.Label>
                                        <Form.Control
                                            autoFocus 
                                            required
                                            type="text" value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                            placeholder="Roles" />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Group id="description" className="mb-4">
                                        <Form.Label> Description: </Form.Label>
                                        <Form.Control
                                            autoFocus 
                                            required 
                                            type="text" value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            placeholder="Description" />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Group id="landingPage" className="mb-4">
                                        <Form.Label> Default Landing Page: </Form.Label>
                                        <Form.Select value={landingPage}
                                            onChange={(e) => setLandingPage(e.target.value)} 
                                            className="mb-4" id="landingPage">
                                            <option defaultChecked>Choose...</option>
                                            <option value="1">Main Page</option>
                                            <option value="2">Clinic Page</option>
                                            <option value="3">User Page</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} lg={6}>
                                    {/*<Form.Label key="roleStatus" htmlFor="roleStatus">Status</Form.Label>*/}
                                    {/*<Form.Select*/}
                                    {/*    value={String(roleStatus)}*/}
                                    {/*    onChange={(e) => setRoleStatus(e.target.value)}*/}
                                    {/*    className="mb-4" id="roleStatus">*/}
                                    {/*    <option value="true">Active</option>*/}
                                    {/*    <option value="false">In-active</option>*/}
                                    {/*</Form.Select>*/}
                                </Col>
                                </>
                            :
                            <>
                            <Col xs={12} lg={6}>
                                <Form.Group id="roles" className="mb-4">
                                    <Form.Label> Role Name: </Form.Label>
                                    <Form.Control
                                        autoFocus 
                                        required
                                        type="text" value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        placeholder="Roles" />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group id="description" className="mb-4">
                                    <Form.Label> Description: </Form.Label>
                                    <Form.Control
                                        autoFocus 
                                        required 
                                        type="text" value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder="Description" />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group id="landingPage" className="mb-4">
                                    <Form.Label> Default Landing Page: </Form.Label>
                                    <Form.Select value={landingPage}
                                        onChange={(e) => setLandingPage(e.target.value)} 
                                        className="mb-4" id="landingPage">
                                        <option defaultChecked>Choose...</option>
                                        <option value="1">Main Page</option>
                                        <option value="2">Clinic Page</option>
                                        <option value="3">User Page</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}></Col>
                            </>
                        }
                        <Col xs={12} className="button-color">
                            <Button variant="gray-800" className="animate-up-2 mt-2" size="lg" type="submit"> Confirm </Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: {
        auth: bindActionCreators(authAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(AddRole)