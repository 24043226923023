import React, { useState, useEffect} from "react";
import { Form, Card, Button, Row, Col, Table } from "react-bootstrap";

import { Routing } from "routes";
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as authAction from "actions/authActions";

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import './style.css';

const SelectClinic = ({ nextStep, handleFormData, values, clinicData }) => {

    const history = useHistory();
    const [ error, setError ] = useState(false);

    const onChangeClinic = (value) => {
        if(value) {
            nextStep(value);
        }
    };

    return (
        <>
        <Card border="0" className="table-wrapper table-responsive shadow">
            <Card.Body>
                <Row className="justify-content-center kpi-planner">
                    <Col xs={12} lg={3}></Col>
                    <Col xs={12} lg={6} className="kpi-planner-type">
                       {
                            clinicData &&
                            <>
                            <Form.Label key="userClinic" htmlFor="userClinic">Clinics: </Form.Label>
                            <Form.Select
                                onChange={(e) => onChangeClinic(e.target.value)}
                                className="mb-4" id="userClinic">
                                    <option value="">Choose...</option>
                                    { clinicData && clinicData.map((row, index) => {
                                        return ( <> <option key={index} value={row._id}>{row.clinicId}</option> </>)
                                    })}

                            </Form.Select>
                           </>
                       } 
                    </Col>
                    <Col xs={12} lg={3}></Col>
                </Row>
            </Card.Body>
        </Card>
        </>
    )

}

export default SelectClinic