import { useEffect, useState } from "react"
import _ from "underscore"
import PerformanceSummary from "./PerformanceSummary"
import { format, minTime, parse } from "date-fns"
import { SYSTEM_DATE_FORMAT_DF } from "constants/common"

const MonthlySummary = ({monthlySummaryData, currentDate, isEmbedded}) => {
  const [summary, setSummary] = useState(undefined)

  useEffect(async () => {
    setSummary(await formatData(monthlySummaryData))
    return () => {
      setSummary(formatData(undefined))
    }
  }, [monthlySummaryData])

  useEffect(() => {
    if(!isEmbedded) {
      window.history.replaceState(null, "Medtrik", "/dr-performance-report/monthly-summary");
      window.scrollTo(0, 0);
    }
  }, []);

  return !_.isEmpty(summary) ? (
    <PerformanceSummary
      isEmbedded={isEmbedded}
      title={!isEmbedded ? `Monthly Summary ${ currentDate ? ' - '.concat(format(parse(currentDate, SYSTEM_DATE_FORMAT_DF, minTime), 'LLL')) : ''}` : ''}
      {...summary}
    />
  ) : null
}

let formatData = async (data) => {
  // return format
  if(_.isEmpty(data)) return {
    month: 0,
    totalRevenue: 0,
    targetRevenue: 0,
    variance: 0,
    totalVisits: 0,
    totalNewPatients: 0,
    totalHoursWorked: 0,
    totalDaysWorked: 0,
    hoursWorkedPerDay: 0,
  }
  return {
    month: data?.thisMonth,
    totalRevenue: data?.totalRevenue,
    targetRevenue: data?.targetRevenue,
    variance: data?.variance,
    totalVisits: data?.totalVisits,
    totalNewPatients: data?.totalNewPatients,
    totalHoursWorked: data?.hoursWorkedTime,
    totalDaysWorked: data?.daysWorked,
    hoursWorkedPerDay: data?.hoursPerDay,
  }
}

export default MonthlySummary
