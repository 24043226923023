import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Chart from "react-apexcharts"
import _ from "lodash"
import { formatAmount, formatNumber } from "utils/parser"
import { Routing } from "routes"
import { useChainId } from "hooks/useChainId";

const TimeLine = (props) => {
  const chainId = useChainId();
  const { actions, clinicId, startDate, endDate} = props
  useEffect(() => {
    !_.isEmpty(clinicId) && actions?.clinicActions?.getTimeLine(
      chainId,
      clinicId,
      startDate,
      endDate,
    )
  }, [clinicId, startDate, endDate])

  const timelineData = useSelector((state) => state.clinicReducer.timelineData)
  const [series, setSeries] = useState([])
  const [uncapturedRevenue, setUncapturedRevenue] = useState(0)


  const [options, setOptions] = useState({
    plotOptions: {},
    xaxis: {
      categories: [],
      labels: {
        show: true,
        formatter: (date) => {
          return date
        },
      },
      title: {
        text: "Date",
      },
    },
    yaxis: [
      {
        seriesName: "Average Chair Usage",
        opposite: true,
        labels: {
          show: true,
          formatter: (val) => `${formatNumber(val)}`,
        },
        title: {
          text: "Average Chair Usage",
        },
      },
      {
        seriesName: "Average Utilisation",
        labels: {
          show: true,
          formatter: (val) => `${formatNumber(val)}%`,
        },
        title: {
          text: "Average Utilisation",
        },
      },
    ],
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "butt",
      width: 2,
      dashArray: 0,
    },
    colors: ["#F26158", "#79ED66"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: !props.isEmbedded,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      offsetY: 0,
    },
  });

  useEffect(() => {
    if (_.isEmpty(timelineData)) return

    setUncapturedRevenue(timelineData?.uncapturedRevenue)
    let update = formatData(timelineData?.avgDailyUtilAndChairUsage)
    let updatedOptions = {...options, xaxis: {
      categories: update.categories,
      labels: {
        show: true,
        formatter: (date) => {
          return date
        },
      }}
    };

    if(update?.series?.length) {
      updatedOptions = {...updatedOptions, yaxis: [
        {
          ...options.yaxis[0],
          min: 0,
          max: _.max(update.series[0].data),
          forceNiceScale: true,
        },
        {
          ...options.yaxis[1],
          min: 0,
          max: _.max(update.series[0].data),
          forceNiceScale: true,
        }
      ]}
    }

    setSeries(update.series)
    setOptions(updatedOptions)
  }, [timelineData])

  useEffect(() => {
    if (!props.isEmbedded) {
      window.history.replaceState(null, null, `/clinic-report${Routing.ClinicReport.TimeLine.relativePath}`);
      window.scrollTo(0, 0);
    }
  }, []);
  
  return (
    <article className='postion-relative w-100'>
      {/* chart title */}
      {!props.isEmbedded &&
        <header className='app-font-class-0 mb-4'>
          Timeline view of Utilisation and Chair Usage
        </header>
      }
      <main className='position-relative'>
        {/* count display */}
        <section className='position-relative px-4'>
          <div
            className='d-inline-block'
            style={{ width: "100%", height: "2rem" }}
          >
            <span className='d-inline-block w-100 text-center app-font-class-0'>
              You have {formatAmount(uncapturedRevenue) ?? ""} in Uncaptured Revenue
            </span>
          </div>
        </section>

        {/* sub title */}
        <section className='position-relative d-flex flex-row mt-4 px-4'>
          <div
            className='d-inline-block'
            style={{ width: "100%", height: "2rem" }}
          >
            <span
              className='d-inline-block w-100 text-center'
              style={{ backgroundColor: "grey" }}
            >
              Average Daily Utilisation and Chair Usage
            </span>
          </div>
        </section>

        {/* chart */}
        <section className='mt-1'>
          <Chart options={options} series={series} type='line' />
        </section>
      </main>
    </article>
  )
}

let formatData = (data) => {
  // return format
  if (!data?.length)
    return {
      series: [],
      categories: [],
    }


  return {
    series: [
      {
        name: "Average Chair Usage",
        data: data?.map(each => each.avgChairUsage),
      },
      {
        name: "Average Daily Utilisation",
        data: data?.map(each => each.avgDailyUtilization),
      },
    ],
    categories: data?.map(each => each.date),
  }
}

export default TimeLine
