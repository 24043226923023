import React, { useEffect, useState, useMemo } from "react";
import { Form, Card, Button, Row, Col, Table } from "react-bootstrap";

import { Routing } from "routes";
import { connect, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as kpiPlannerAction from "actions/kpiPlannerAction";
import Box from '@mui/material/Box';
import { Slider, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getChainKpiData } from "actions/kpiPlannerAction";
import Loader from "components/Loader"
import swal from 'sweetalert';
import {numberWithCommas, roundToTwo} from "utils/parser.js"
import decode from "jwt-decode";
import _ from "lodash";
import {
    KPI_STATUS_CONFIRMED,
    KPI_STATUS_NOT_STARTED,
    KPI_STATUS_PROPOSED,
    KPI_STATUS_TO_CONFIRM
} from "../../constants/actionsTypes";
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
    root: {
        "& .Mui-active .MuiStepIcon-root": { color: "white" },
        "& .Mui-completed .MuiStepIcon-root": { color: "#2f4858" },
        "& .Mui-disabled .MuiStepIcon-root": { color: "#dfe4ed" },
        "& .MuiStepLabel-root .MuiStepLabel-label": { fontFamily: 'Poppins', fontSize: '1em', paddingBottom: '1em', fontWeight: '500', color: '#2f4858' }
    },
    cols: {
        paddingLeft: '2em',
        paddingRight: '2em'
    },
    textField: {
        marginLeft: '1em',
        marginRight: 'auto',
        paddingBottom: 0,
        backgroundColor: 'white',
        marginBottom: "20%",
        maxHeight: '10%',
        borderRadius: '10%'
    },
    itemBar: {
        listStyleType: 'none',
        float: 'left',
        width: '15%',
        position: 'relative',
        textAlign: 'center',
    }
}));
const ClinicLevel = ({ kpiProgress, step, nextStep, setName, prevStep, handleFormData, values, clinics, singleProcess = false, clinicPropose, getClinicKPIPlanningData, getClinicReference, getAllocatesByClinic, viewMode, year }) => {
    const classes = useStyles();
    const [ranges, setRanges] = useState({});
    const [chainKpiData, setChainKpiData] = useState(null)
    const allocate = useSelector((state) => state.kpiPlannerReducer.allocatedByClinic);
    const clinicReference = useSelector((state) => state.kpiPlannerReducer.referencesClinic);
    const clinicKPIData = useSelector((state) => state.kpiPlannerReducer.clinicKPIData);
    const [optionReference, setOptionReference] = useState(clinicKPIData?.reference?._id);
    const [allocateBy, setAllocateBy] = useState(clinicKPIData?.allocate?._id);
    const [loading, setLoading] = useState(true)
    const [inputTarget, setInputTarget] = useState(clinicKPIData?.target);
    const [doctorsData, setDoctorsData] = useState([])

    const queryParams = new URLSearchParams(window.location.search);
    const confirmMode = queryParams.get('confirmMode');

    const [errors, setErrors] = useState({
        inputAmount: null
    });

    const history = useHistory();

    const steps = [
        'Propose Chain Target',
        'Propose Clinic Target',
        'Propose Doctor Target',
        'Confirm Doctor Target',
        'Confirm Clinic Target',
        'Confirm Chain Target'
    ];
    const marks = [
        {
            label: '-100%',
            value: 0
        },

        {
            value: 100,
            label: 'Max',
        },
    ];

    const clinicId = useMemo(() => {
        const accessToken = localStorage.getItem("accessToken")
        if (accessToken !== "undefined" && accessToken !== null) {
            const userProfile = decode(accessToken)
            const user = userProfile.user;
            if (user.clinic) {
                return user.clinic.clinicId;
            }
        }
    }, [])


    const submitFormData = (e, data) => {
        e.preventDefault();
        clinicPropose(data)
        setTimeout(() => { prevStep1() }, 4000);


    }
    const prevStep1 = () => {
        history.push(Routing.KpiPlanner.path);
    }
    const handleChangeRanges = (e) => {
        let { name:index, value } = e.target;
        if (!inputTarget) {
            return
        }
        let shallowListDoctors = _.cloneDeep(doctorsData)
        let targetAmount = 0

        if (value >= 0) {
            targetAmount = inputTarget * value / 100
        }
        shallowListDoctors[index].targetAmount = targetAmount
        shallowListDoctors[index].percentage = value
        setDoctorsData(shallowListDoctors)
        setAllocateBy(4)
    }

    // #299: add onChange function for new handleChangeTargetAmount
    const handleChangeTargetAmount = (index, value) => {
        if (!inputTarget) {
            return
        }

        let shallowListDoctors = _.cloneDeep(doctorsData);

        let targetAmount = Number(value) || 0;

        let percentage;
        if (targetAmount >= 0) {
            percentage = +(((targetAmount * 100) / inputTarget).toFixed(2))
        }

        shallowListDoctors[index].targetAmount = targetAmount;
        shallowListDoctors[index].percentage = percentage;
        setDoctorsData(shallowListDoctors);

        setAllocateBy(4);
    }

    const handleChangeOptionsReference = (e) => {
        const optionReference = e.target.value
        setLoading(true)
        setOptionReference(optionReference)
    }

    const handleInputTargetChange = (e) => {
        let { value } = e.target
        let shallowListDoctors = _.cloneDeep(doctorsData)
        shallowListDoctors = changeDataDoctorAcordingToAllocateId(shallowListDoctors, Number(value), Number(allocateBy))
        setDoctorsData(shallowListDoctors)
        setInputTarget(value)
    }

    const handleChangeAllocateInput = (e) => {
        setAllocateBy(e.target.value)
        if (inputTarget) {
            let shallowListDoctors = _.cloneDeep(doctorsData)
            shallowListDoctors = changeDataDoctorAcordingToAllocateId(shallowListDoctors, Number(inputTarget), Number(e.target.value))
            setDoctorsData(shallowListDoctors)
        }
    }

    const changeDataDoctorAcordingToAllocateId = (tempDoctorData, value, allocateId) => {
        let shallowListDoctors = _.cloneDeep(tempDoctorData)
        if (allocateId === 1) {
            const totalDoctors = doctorsData.length;
            let percentage = 100 / totalDoctors;

            return shallowListDoctors.map(el => {
                return {
                    ...el,
                    percentage: percentage,
                    targetAmount: roundToTwo(percentage * value / 100)
                }
            })
        } else if (allocateId === 2 && clinicKPIData?.referenceAmount) {
            const totalReferenceAmount = roundToTwo(_.sumBy(shallowListDoctors, 'referenceAmount'));
            return shallowListDoctors.map(el => {
                return {
                    ...el,
                    percentage: (el.referenceAmount / totalReferenceAmount * 100).toFixed(),
                    targetAmount: roundToTwo(el.referenceAmount / totalReferenceAmount * value)
                }
            })
        } else if (allocateId === 4) {
            return shallowListDoctors.map(el => {
                return {
                    ...el,
                    targetAmount: roundToTwo(el.percentage / 100 * value)
                }
            })
        }
        return shallowListDoctors
    }

    useEffect(() => {
        getAllocatesByClinic(0);
        getClinicReference(0);
    }, []);

    const handleFindTotalTarget = (data) => {
        if (data && data.length) {
            const total = data.reduce((previousValue, currentValue) => {
                return previousValue + Number(currentValue.targetAmount)
            }, 0)
            return '$' + numberWithCommas(roundToTwo(total))
        } else {
            return '$0'
        }
    }

    const validateFormData = (data) => {
        let errors = {};
        let formIsValid = true;
        // inputAmount

        if (!data?.inputAmount) {
            errors.inputAmount = "Please type target amount"
        }

        if (data?.inputAmount && typeof data?.inputAmount !== 'number') {
            errors.inputAmount = "target amount must be a number"
        }

        if (Object.keys(errors)?.length) {
            formIsValid = false;
            setErrors(errors)
        } else {
            setErrors({})
        }

        return formIsValid
    }

    const sendAPiClinicPlanningData = (e) => {
        const dataRequest = {
            "_id": clinicKPIData._id,
            "kpiStatusId": clinicKPIData.status._id + 1,
            "clinicId": clinicId,
            "referenceId": Number(optionReference),
            "allocateId": Number(allocateBy),
            "referenceAmount": clinicKPIData?.referenceAmount,
            "inputAmount": Number(inputTarget),
            "doctors": doctorsData,
            year
        }
        e.preventDefault();

        if (validateFormData(dataRequest)) {
            setLoading(true);
            kpiPlannerAction.postUpdateClinicKpiConfirm(dataRequest).then(response => {
                setLoading(false)
                if (response) {
                    swal(response.message, {
                        icon: "success",
                    }).then(() => {
                        prevStep1()
                    });
                }
            })
        }
    }

    const getButtonLabel = () => {
        switch (clinicKPIData.status._id) {
            case 1: return 'Propose';
            case 2: return 'Update';
            case 3: return 'Confirm';
            case 4: return 'Confirmed';
            default: return 'N/A';
        }
    }

    useEffect(() => {
        if (viewMode) {
            const queryParams = new URLSearchParams(window.location.search);
            const _id = queryParams.get('_id');
            getClinicKPIPlanningData(queryParams.get('clinicId'), null, Number(_id), year);
        }
    }, [viewMode])

    useEffect(() => {
        if (!_.isEmpty(clinicKPIData)) {
            setLoading(false)
            const shallowDoctorsData = clinicKPIData?.doctors.map(el => {
                return {
                    doctorId: el.doctorId,
                    referenceAmount: el.reference,
                    targetAmount: el.target,
                    percentage: el.percentage
                }
            })
            setDoctorsData(shallowDoctorsData)
            setInputTarget(clinicKPIData?.target)
            setOptionReference(clinicKPIData?.reference._id)
            setAllocateBy(clinicKPIData?.allocate._id)

            if (clinicKPIData.status._id < KPI_STATUS_TO_CONFIRM && !viewMode) {
                let shallowListDoctors = changeDataDoctorAcordingToAllocateId(shallowDoctorsData, Number(clinicKPIData?.target), Number(clinicKPIData?.allocate?._id))
                setDoctorsData(shallowListDoctors)
            }
        }
    }, [clinicKPIData])

    const confirmKpi = (id) => {
        swal("Confirm this KPI?", {
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                setLoading(true);
                kpiPlannerAction.confirmKpi(id).then(response => {
                    setLoading(false)
                    if (response) {
                        swal(response.message, {
                            icon: "success",
                        }).then(() => {
                            history.push({
                                pathname: Routing.KpiPlanner.path
                            })
                        })
                    }
                })
            }
        });
    }

    if (loading) {
        return <Loader></Loader>
    }

    return (
        <>

            <Box sx={{ width: '100%' }}>
                <div class="row">
                    <div class="col-12 mb-3">
                        <div class="wrapper-progressBar">
                            <ul class="progressBar">
                                {steps.map((el, index) =>
                                    <li
                                    className={clsx({
                                        'finalize-progress': kpiProgress && kpiProgress[Number(index) + 1],
                                        'selected-progress':
                                            index === 1 && clinicKPIData?.status?._id === KPI_STATUS_NOT_STARTED
                                            || index === 4 && ([KPI_STATUS_PROPOSED, KPI_STATUS_TO_CONFIRM, KPI_STATUS_CONFIRMED].includes(clinicKPIData?.status?._id))
                                    })}>
                                        {el}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </Box>


            <Card className="table-wrapper table-responsive shadow">
                <Card.Body>
                    <Form onSubmit={submitFormData}>
                        <Row className="justify-content-center chain-level" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            <Col xs={12} lg={4} className={classes.cols}>
                                <Table className="align-items-center">
                                    <tbody>

                                    <tr className="table-active">
                                        <td>Reference:</td>
                                        <td>${numberWithCommas(roundToTwo(clinicKPIData?.referenceAmount))}</td>
                                        <td></td>
                                    </tr>
                                    <tr className="table-active">
                                        <td>Input Target:</td>
                                        <td>
                                            <Form.Group controlId="inputTarget">
                                                <Form.Control
                                                    type="number"
                                                    required={true}
                                                    disabled={viewMode || clinicKPIData.status._id >= KPI_STATUS_TO_CONFIRM}
                                                    value={inputTarget}
                                                    onChange={(e) => handleInputTargetChange(e)}
                                                />
                                            </Form.Group>
                                            {
                                                errors?.inputAmount && <span style={{ color: 'red', fontSize: '15px'}}>
                                                        {errors.inputAmount}
                                                    </span>
                                            }
                                        </td>
                                        <td> {`  >>>   ${handleFindTotalTarget(doctorsData)}`} </td>


                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={12} lg={4}></Col>
                            <Col xs={12} lg={4} className={classes.cols}>
                                <Form.Group id="Reference" className="mb-4">
                                    <Form.Label> Select reference: </Form.Label>
                                    <Form.Select
                                        className="mb-4" id="financialYears" value={optionReference}
                                        onChange={(e)=> handleChangeOptionsReference(e)}
                                        disabled={true}
                                    >
                                        {clinicReference?.references.map((item, index) => (
                                            <option key={index} value={item.referenceId}>{item.referenceName}</option>
                                        ))}


                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-center clinic-chain-level pt-5 pb-5" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            {doctorsData.map((item, index) => (

                                <Col key={index} xs={12} md={6} lg={6} xl={3} className={classes.cols}>

                                    <Form.Label>{item.doctorId}</Form.Label>
                                    <Table className="align-items-center table-responsive  ">
                                        <tbody style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                                        <tr className="table-active">
                                            <td>Reference:</td>
                                            <td>${numberWithCommas(roundToTwo(item.referenceAmount))}</td>
                                        </tr>

                                        <tr className="table-active">
                                            <td>Target:</td>
                                            {/* // #299: adding input field
                                            <td>${(doctorsData && doctorsData.length) && numberWithCommas(roundToTwo(doctorsData[index].targetAmount))}</td> */}
                                            <td>
                                                <Form.Control
                                                    required={true}
                                                    type="number"
                                                    value={doctorsData && doctorsData.length ? doctorsData[index].targetAmount : 0}
                                                    onChange={(e) => handleChangeTargetAmount(index, e.target.value)}
                                                    disabled={viewMode || clinicKPIData.status._id > KPI_STATUS_TO_CONFIRM}
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>

                                    <Slider
                                        defaultValue={item.inputTargetPercentage}
                                        value={doctorsData && doctorsData.length ? doctorsData[index].percentage : 0}
                                        name={index}
                                        onChange={handleChangeRanges}
                                        marks={marks}
                                        valueLabelDisplay="auto"
                                        min = {0} max = {100}
                                        width={"15%"}
                                        className={classes.cols}
                                        disabled={viewMode || clinicKPIData.status._id > KPI_STATUS_TO_CONFIRM}
                                    />


                                </Col>



                            ))}

                        </Row>
                        <Row className="justify-content-center clinic-chain-level pt-1 pb-1" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            <Col xs={12} lg={4} className={classes.cols}>
                                <Form.Group id="AllocatedBy" className="mb-4">
                                    <Form.Label> Allocate By: </Form.Label>
                                    <Form.Select value={allocateBy}
                                                 className="mb-4" id="financialYears"
                                                 onChange={handleChangeAllocateInput}
                                                 disabled={viewMode}
                                    >
                                        {allocate?.allocateBy.map((item, index) => (
                                            <option key={index} value={item.allocateById}>{item.allocateByName}</option>

                                        ))}
                                        {/* <option value="Simple-Average">Simple Average</option>
                                    <option value="Fixed-Percentage">Fixed Percentage</option>
                                    <option value="Historical-Contribution">Historical Contribution</option>
                                    <option value="Number-of-Bookings">Number of Bookings</option>
                                    <option value="Manual-Adjustment">Manual Adjustment</option> */}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={8}></Col>
                        </Row>
                        <div style={{ justifyContent: "space-around" }}>
                            <Row className="justify-content-center clinic-chain-level pt-2 pb-5" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                                <Col xs={12} lg={8}></Col>
                                <Col xs={12} lg={2}>
                                    <Table className="align-items-center">
                                        <tbody>
                                        <tr className="table-active">
                                            <td>Total:</td>
                                            <td>{`${handleFindTotalTarget(doctorsData)}`}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs={12} lg={2} className="button-color">
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                        {/* <Button variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" onClick={prevStep1} style={{ marginLeft: "1em"}}> Back </Button> */}
                                        {!viewMode
                                            ?
                                                <Button
                                                    variant="primary"
                                                    onClick={sendAPiClinicPlanningData}
                                                    className="animate-up-2 btn btn-gray-800 btn-lg"
                                                    type="submit"
                                                    style={{ marginLeft: "1em" }}
                                                    disabled={clinicKPIData.status._id > KPI_STATUS_TO_CONFIRM}
                                                >
                                                    {getButtonLabel()}
                                                </Button>
                                            :
                                            <>
                                                {confirmMode
                                                    ?
                                                        <Button onClick={() => confirmKpi(clinicKPIData?._id)}
                                                                variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" type="submit"
                                                        > Confirm </Button>
                                                    :
                                                        <Button
                                                            variant="primary"
                                                            onClick={() => {
                                                                history.push({
                                                                    pathname: Routing.KpiPlanner.path,
                                                                })
                                                            }}
                                                            className="animate-up-2 btn btn-gray-800 btn-lg"
                                                            type="submit"
                                                            style={{ marginLeft: "1em" }}
                                                        >
                                                            Back to Dashboard
                                                        </Button>
                                                }
                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        getAllocatesByClinic: (data) => dispatch(kpiPlannerAction.getAllocatedByClinic(data)),
        getClinicReference: (data) => dispatch(kpiPlannerAction.getReferenceByClinic(data)),
        getClinicKPIPlanningData: (clinicId, referenceId, _id, year) => dispatch(kpiPlannerAction.getClinicKPIPlanningData(clinicId, referenceId, _id, year)),
        clinicPropose: (data) => dispatch(kpiPlannerAction.updateClinicProposeKPI(data)),
    }
};

export default connect(null, mapDispatchToProps)(ClinicLevel)
