import React, { useState, useEffect } from "react";
import { Box, FormControlLabel, TextField, RadioGroup, Radio, Button } from "@mui/material";

let agegenderPref;

const AgegenderPreference = ({ agegenderFilled, setAgegenderFilled, agegenderSubmitRef }) => {
  const [age, setAge] = useState(0);
  const [gender, setGender] = useState("Male");

  const handleGenderChange = (e) => {
    setGender(e.currentTarget.value);
  };

  const changeHandler = (e) => {
    e.target.value > 120 ? setAge(120) : setAge(e.target.value);
  };

  const handleSubmitAgegender = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    agegenderPref = {
      age: data.get("select-age"),
      gender: gender,
    };
  };

  const validate = () => {
    let event = (age > 0) & (gender !== "");
    return event;
  };

  useEffect(() => {
    setAgegenderFilled(validate);
  }, [validate]);

  return (
    <Box component="form" noValidate onSubmit={handleSubmitAgegender}>
      <section>
        <label style={{ marginBottom: 0 }}>Gender</label>
        <RadioGroup row defaultValue={gender} onChange={(e) => handleGenderChange(e)}>
          <FormControlLabel value="Male" control={<Radio />} label="Male" />
          <FormControlLabel value="Female" control={<Radio />} label="Female" />
        </RadioGroup>
      </section>

      <section>
        <label className="mb-2" style={{ display: "grid" }}>
          Age
        </label>
        <TextField
          name="select-age"
          size="small"
          id="outlined-basic"
          type="number" //Still allow special characters ('e', '+', '-', '.')
          variant="outlined"
          InputProps={{ inputProps: { min: 1, max: 120 }, inputMode: "numeric", pattern: "[0-9]*" }}
          value={age}
          onChange={changeHandler}
        />
      </section>

      <Button
        disabled={!validate()}
        type="submit"
        variant="outlined"
        ref={agegenderSubmitRef}
        style={{ display: "none" }}
      >
        {" "}
        Confirm{" "}
      </Button>
    </Box>
  );
};

export default AgegenderPreference;
export { agegenderPref };
