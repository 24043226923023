import {
    ADMIN_INPROGRESS,
    ADMIN_ERROR,
    IS_AUTHENTICATED,
    PASSWORD_RECOVERED,
    LOGOUT,
    GET_USERS_DETAIL,
    GET_ROLES_DETAIL,
    CREATE_USERS,
    UPDATE_USER_DATA,
    GET_DOCTORS_DETAIL,
    GET_DOCTOR_CLINIC_DETAIL,
    GET_KPI_CLINIC,
    GET_CHAINS,
    GET_CLINICS,
    GET_USER_CLINICS_BY_ROLE,
    CREATE_ROLES,
    UPDATE_ROLES,
    USER_DELETED,
    ROLE_DELETED
} from '../constants/actionsTypes';
import initialState from './initialState'; 

export default function adminAuthReducer(state = initialState.adminAuthReducer, action) {
    
    switch (action.type) {

        case ADMIN_INPROGRESS:
            return Object.assign({}, state, {
                error_msg: null,
                success_msg: null,
                loading: true,
                isAuthenticatedFail: false
            });

        case ADMIN_ERROR:
            return Object.assign({}, state, {
                loading: false, 
                error_msg: action.data.error_msg
            });

        case IS_AUTHENTICATED:
            localStorage.setItem("accessToken", action.data.accessToken);
            return Object.assign({}, state, {
                isAuthenticated: true,
                error_msg: null,
                loading: false,
                token: action.data.accessToken
            }); 

        case PASSWORD_RECOVERED:
            return Object.assign({}, state, {
                isAuthenticated: false,
                error_msg: null,
                loading: false,
                passwordRecovered: true
            });

        case LOGOUT:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                orders: [],
                userDetail: []
            });

        case GET_DOCTORS_DETAIL:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                doctorsDetails: action.data
            });

        case GET_DOCTOR_CLINIC_DETAIL:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                doctorClinic: action.data.clinics
            });

        case GET_CHAINS:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                chains: action.data
            });

        case GET_CLINICS:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                clinics: action.data
            });

        case GET_USER_CLINICS_BY_ROLE:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                clinics: action.data
            });
            
        case GET_KPI_CLINIC:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                kpiClinic: action.data
            });

        case CREATE_USERS:
            return Object.assign({}, state, {
                loading: false,
                isUpdated: true,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                userDetail: action.data
            });

        case GET_USERS_DETAIL:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                userDetail: action.data
            });

        case GET_ROLES_DETAIL:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                roleDetail: action.data
            });

        case UPDATE_USER_DATA:
            return Object.assign({}, state, {
                loading: false,
                isUpdated: true,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                userDetail: action.data,
            });

        case USER_DELETED:
            return Object.assign({}, state, {
                loading: false,
                isDeleted: true,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                userDeletedID: action.data,
            });

        case CREATE_ROLES:
            return Object.assign({}, state, {
                loading: false,
                isCreated: true,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                roleDetail: action.data,
            });

        case UPDATE_ROLES:
            return Object.assign({}, state, {
                loading: false,
                isUpdated: true,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                roleDetail: action.data,
            });

        case ROLE_DELETED:
            return Object.assign({}, state, {
                loading: false,
                isDeleted: true,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allKeyWords: [],
                allPages: null,
                selectedPage: null,
                roleDeletedID: action.data,
            });

        default:
            return Object.assign({}, state, {
                loading: false
              });
    }
};