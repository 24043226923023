import {
  ADMIN_INPROGRESS,
  GET_DR_PR_COMPONENTS,
  GET_DR_PR_MONTHLY,
  GET_DR_PR_MTD,
  GET_DR_PR_MTD_CURR_VS_PREV_YEAR,
  GET_DR_PR_MTD_CURR_YEAR,
  GET_DR_PR_ROLLING,
  GET_DR_PR_TREATMENTMIX,
  GET_DR_PR_YEARLY,
  GET_DR_PR_YTD,
  SET_DR_PR_DATE_PERIOD,
} from "../constants/actionsTypes"
import initialState from "./initialState"

export default function drPerformanceReducer(
  state = initialState.drPerformanceReducer,
  action
) {
  switch (action.type) {
    case ADMIN_INPROGRESS:
      return Object.assign({}, state, {
        loading: true,
        error_msg: null,
        success_msg: null,
        components: action.data,
      })
    case GET_DR_PR_COMPONENTS:
      return Object.assign({}, state, {
        loading: false,
        error_msg: null,
        success_msg: null,
        components: action.data,
      })

    case GET_DR_PR_MONTHLY:
      return Object.assign({}, state, {
        loading: false,
        error_msg: null,
        success_msg: null,
        monthly: action.data,
      })

    case GET_DR_PR_YEARLY:
      return Object.assign({}, state, {
        loading: false,
        error_msg: null,
        success_msg: null,
        yearly: action.data,
      })
    case GET_DR_PR_MTD:
      return Object.assign({}, state, {
        loading: false,
        error_msg: null,
        success_msg: null,
        mtdPerformance: action.data,
      })
    case GET_DR_PR_MTD_CURR_YEAR:
      return Object.assign({}, state, {
        loading: false,
        error_msg: null,
        success_msg: null,
        mtdCurrYear: action.data,
      })
    case GET_DR_PR_MTD_CURR_VS_PREV_YEAR:
      return Object.assign({}, state, {
        loading: false,
        error_msg: null,
        success_msg: null,
        mtdCurrVsPrevYear: action.data,
      })
    case GET_DR_PR_ROLLING:
      return Object.assign({}, state, {
        loading: false,
        error_msg: null,
        success_msg: null,
        rolling: action.data,
      })
    case GET_DR_PR_TREATMENTMIX:
      return Object.assign({}, state, {
        loading: false,
        error_msg: null,
        success_msg: null,
        treatmentMix: action.data,
      })
    case GET_DR_PR_YTD:
      return Object.assign({}, state, {
        loading: false,
        error_msg: null,
        success_msg: null,
        ytd: action.data,
      })
    case SET_DR_PR_DATE_PERIOD:
      return Object.assign({}, state, {
        loading: false,
        datePeriod: Object.assign({}, state.datePeriod, action.datePeriod)
      })
    default:
      return Object.assign({}, state, {
        loading: false
      });
  }
}
