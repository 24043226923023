import React, { useState, useEffect } from "react";
import { Col } from 'react-bootstrap';
import Chart from "react-apexcharts";
import moment from 'moment-timezone';

import '../style.css';
import { formatAmount } from "utils/parser";

const DailyRevenueForMonth = ({isEmbedded, dailyRevenue}) => {
    const [ chartOptions, setChartOptions ] = useState({
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        grid: {
            row: {
                colors: ['transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        colors: ['#7444CD'],
        xaxis: {},
        yaxis: {
            labels: {
                formatter: (value) => formatAmount(value),
                style: {
                    fontSize: '14px'
                }
            },
            title: {
                text: "Revenue",
                offsetX: -5,
                offsetY: 0
            }
        }
    });

    // #378: shows full date in tooltip (Daily Revenue For Month Graph)
    // [2024-04-12] some adjustments to make tooltip looks better
    const customTooltip = ({ dataPointIndex }, days, monthYear, revenue) => {
        return (
            `<div>
                <div class="highlight">
                    <p class="adjusted-fontsize-tooltip"> ${days[dataPointIndex]} ${monthYear}</p>
                </div>
                <div>
                    <p class="adjusted-fontsize-tooltip"> Revenue: <b>${formatAmount(revenue[dataPointIndex])}</b></p>
                </div>
            </div>`
        );
    }

    const [ chartSeries, setChartSeries ] = useState([{
        name: "Revenue",
        data: []
    }]);

    useEffect(() => {
        if(!isEmbedded) {
            window.history.replaceState(null, "Medtrik", "/finance/dashboard/daily-revenue-for-month");
            window.scrollTo(0, 0);
        }
    }, []);

    useEffect(() => {
        if(dailyRevenue) {
            let days = Object.keys(dailyRevenue);
            days = days.map(day => day.match(/\d+/)[0]);
            // #378: shows full date in tooltip (Daily Revenue For Month Graph)
            const monthYear = moment().format('MMM YYYY');
            const revenue = Object.values(dailyRevenue);
            setChartOptions({...chartOptions, 
                // #378: shows full date in tooltip (Daily Revenue For Month Graph)
                tooltip: { custom: (args) => customTooltip(args, days, monthYear, revenue) },
                xaxis: {
                categories: days,
                tickAmount: 5,
                labels: {
                    style: {
                        fontSize: '14px'
                    }
                },
                title: !isEmbedded && {
                    text: 'Day',
                    offsetX: 0,
                    offsetY: 5,
                }
            }});
            setChartSeries([{...chartSeries[0], data: revenue}]);
        }
    }, [dailyRevenue]);

    return (
        <Col xs={12} lg={12} className={`${!isEmbedded ? 'px-8 ' : ''}`}>
            <Chart options={chartOptions} series={chartSeries} type="line" height={350} />
        </Col>
    )
}

export default DailyRevenueForMonth;
