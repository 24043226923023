import React, {Component, useEffect} from 'react'
import Chart from 'react-apexcharts'

function ConversionBarChart({seriesData, xaxisCategories, rowMaxWidths, maxWidth}) {
    const chartOptions = {
        chart: {
          type: 'bar',
          width: '100%'
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '15px',
            colors: ['#fff']
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        tooltip: {
          shared: true,
          intersect: false
        },
        xaxis: {
            categories: xaxisCategories,
            labels: {
                style: {
                fontSize: '15px',
                fontWeight: 500,
                },
            }
        },
        yaxis: {
            max: maxWidth + 0.2 * maxWidth,
            labels: {
                style: {
                  fontSize: '15px',
                  fontWeight: 500,
                },
            }
        },
        title: {
            text: 'Patient Conversion Graph',
            align: 'center',
            style: {
              fontSize:  '20px',
              fontWeight:  'bold',
              color:  '#263238'
            },
        },
        colors: ['#04AA6D', '#0468aa'],
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'center',
            offsetY: -15
        },
        // annotations: {
        //     points: [
        //         {
        //             x: maxWidth + 0.1 * maxWidth,
        //             y: xaxisCategories[0],
        //             marker: {
        //                 size: 5,
        //                 shape: 'square'
        //             },
        //             label: {
        //                 borderColor: '#000000',
        //                 text: 'Conversion Rate: ' + seriesData[2][0],
        //                 style: {
        //                     background: '#000000',
        //                     color: '#ffffff',
        //                     fontSize: '15px',
        //                     fontFamily: 'Poppins'
        //                 }
        //             }
        //         },
        //         {
        //             x: maxWidth + 0.1 * maxWidth,
        //             y: xaxisCategories[1],
        //             marker: {
        //                 size: 5,
        //                 shape: 'square'
        //             },
        //             label: {
        //                 borderColor: '#000000',
        //                 text: 'Conversion Rate: ' + seriesData[2][1],
        //                 style: {
        //                     background: '#000000',
        //                     color: '#ffffff',
        //                     fontSize: '15px',
        //                     fontFamily: 'Poppins'
        //                 }
        //             }
        //         },
        //         {
        //             x: maxWidth + 0.1 * maxWidth,
        //             y: xaxisCategories[2],
        //             marker: {
        //                 size: 5,
        //                 shape: 'square'
        //             },
        //             label: {
        //                 borderColor: '#000000',
        //                 text: 'Conversion Rate: ' + seriesData[2][2],
        //                 style: {
        //                     background: '#000000',
        //                     color: '#ffffff',
        //                     fontSize: '15px',
        //                     fontFamily: 'Poppins'
        //                 }
        //             }
        //         },
        //         {
        //             x: maxWidth + 0.1 * maxWidth,
        //             y: xaxisCategories[3],
        //             marker: {
        //                 size: 5,
        //                 shape: 'square'
        //             },
        //             label: {
        //                 borderColor: '#000000',
        //                 text: 'Conversion Rate: ' + seriesData[2][3],
        //                 style: {
        //                     background: '#000000',
        //                     color: '#ffffff',
        //                     fontSize: '15px',
        //                     fontFamily: 'Poppins'
        //                 }
        //             }
        //         },
        //         {
        //             x: maxWidth + 0.1 * maxWidth,
        //             y: xaxisCategories[4],
        //             marker: {
        //                 size: 5,
        //                 shape: 'square'
        //             },
        //             label: {
        //                 borderColor: '#000000',
        //                 text: 'Conversion Rate: ' + seriesData[2][4],
        //                 style: {
        //                     background: '#000000',
        //                     color: '#ffffff',
        //                     fontSize: '15px',
        //                     fontFamily: 'Poppins'
        //                 }
        //             }
        //         },
        //         {
        //             x: maxWidth + 0.1 * maxWidth,
        //             y: xaxisCategories[5],
        //             marker: {
        //                 size: 5,
        //                 shape: 'square'
        //             },
        //             label: {
        //                 borderColor: '#000000',
        //                 text: 'Conversion Rate: ' + seriesData[2][5],
        //                 style: {
        //                     background: '#000000',
        //                     color: '#ffffff',
        //                     fontSize: '15px',
        //                     fontFamily: 'Poppins'
        //                 }
        //             }
        //         },
        //         {
        //             x: maxWidth + 0.1 * maxWidth,
        //             y: xaxisCategories[6],
        //             marker: {
        //                 size: 5,
        //                 shape: 'square'
        //             },
        //             label: {
        //                 borderColor: '#000000',
        //                 text: 'Conversion Rate: ' + seriesData[2][6],
        //                 style: {
        //                     background: '#000000',
        //                     color: '#ffffff',
        //                     fontSize: '15px',
        //                     fontFamily: 'Poppins'
        //                 }
        //             }
        //         }
        //     ]
        // },
        responsive: [
            {
                breakpoint: 1200,
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: false
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        offsetY: 5,
                        offsetX: 0,
                        style: {
                          fontSize: '10px',
                          colors: ['#fff']
                        }
                    },
                    stroke: {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                    },
                    tooltip: {
                    shared: true,
                    intersect: false
                    }
                }
            }
        ]
    }

    const chartSeries = [
        {
            name: '1st-time Patient Count (Cumulative over 6-month period)',
            data: seriesData[0]
        },
        {
            name: 'Returned within the 6-month period',
            data: seriesData[1]
        }
    ]

    return (
        <div>
            <Chart options={chartOptions} series={chartSeries} type="bar" width="100%"/>
        </div>
    )
}

export default ConversionBarChart