import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Chart from "react-apexcharts"
import _ from "lodash"
import SingleSelect from "components/filters/SingleSelect"
import { formatAmount, formatNumber } from "utils/parser"
import { Routing } from "routes"
import { useChainId } from "hooks/useChainId";
import { formatWeekly } from "./FormatDataForBoundaries" // #275: import format data function

const MIN = 0
const MAX = 6
const MEDIUM = 3

const WeeklyCalendar = (props) => {
  const chainId = useChainId();
  const { actions, clinicId, startDate, endDate} = props

  const [min, setMin] = useState(MIN)
  const [max, setMax] = useState(MAX)
  const [medium, setMedium] = useState(MEDIUM)

  const [series, setSeries] = useState([])
  const [categories, setCategories] = useState([])
  const [criteriaOptions, setCriteriaOptions] = useState([])
  const [criteria, setCriteria] = useState("")

  const avgData = useSelector((state) => state.clinicReducer?.weeklyData?.weeklyChairUsage)
  // #275: not used due to using different approach
  // const chairUsageMin = useSelector((state) => state.clinicReducer?.weeklyData?.chairUsageMin)
  // const chairUsageMax = useSelector((state) => state.clinicReducer?.weeklyData?.chairUsageMax)
  // // #275: boundaries variables bound to app state
  // const underutilizationMin = useSelector((state) => state.clinicReducer?.weeklyUnderutilizationBoundaryData?.min)
  // const underutilizationMax = useSelector((state) => state.clinicReducer?.weeklyUnderutilizationBoundaryData?.max)
  const criteriaFilter = useSelector((state) => state.clinicReducer?.dashboard_filter)

  const [options, setOptions] = useState({
    plotOptions: {
      heatmap: {
        colorScale: {
          enableShades: true,
          shadeIntensity: 0.3,
          ranges: [],
          min: min,
          max: max,
        },
      },
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: '1rem'
      }
    },
    dataLabels: {},
    xaxis: {
      categories: categories,
      position: "top",
      labels: {
        show: true,
        formatter: (date) => {
          return date
          // return Intl.DateTimeFormat("en-us", {
          //   month: "long",
          //   year: "numeric",
          //   day: "2-digit",
          // }).format(date)
        },
        style: {
          fontSize: '1rem'
        }
      },
    },
    yaxis: {
      reversed: true,
      labels: {
        show: true,
        align: 'right',
        style: {
          fontSize: '1rem'
        }
      },
    },
    legend: {
      show: false,
    },
  });

  // api request to fetch criteria
  useEffect(() => {
    _.isEmpty(criteriaFilter) && actions?.clinicActions?.getDashboardFilter(
      0
    )
  }, [])

  // api request to fetch data
  useEffect(() => {
    !_.isEmpty(clinicId) && criteria && actions?.clinicActions?.getWeeklyCalendar(
      chainId,
      clinicId,
      startDate,
      endDate,
      criteria
    )
    // #275: not used due to using different approach
    // // #275: do new action to populate weeklyUnderutilizationBoundaryData state
    // !_.isEmpty(clinicId) && criteria && actions?.clinicActions?.getWeeklyUnderutilizationBoundaries(chainId, clinicId, startDate, endDate);
  }, [clinicId, startDate, endDate, criteria])

  // criteria into options
  useEffect(() => {
    if(_.isEmpty(criteriaFilter)) return
    let opts = criteriaFilter?.map(({clinicDashboardFilterName}) => ({label: clinicDashboardFilterName, value : clinicDashboardFilterName}))
    setCriteriaOptions(opts)
    if(_.isEmpty(criteria?.value) && !_.isEmpty(opts[0]?.value)) setCriteria(opts[0]?.value)
  }, [criteriaFilter])

  // handeling change
  const handleAvgCriteria = (change) => {
    setCriteria(change?.value)
  }

  // data into apex data
  useEffect(() => {
    let update = formatWeekly(avgData)
    setSeries(update.series)
    setCategories(update.categories)
    
    if(update.max > MAX) setMax(_.ceil(update.max))
    else setMax(MAX)

    if(update.medium > MEDIUM) setMedium(Math.round(update.medium))
    else setMedium(MEDIUM)
  }, [criteria, avgData])     // #275: add criteria so month headers load more smoothly

  // setState: categories
  useEffect(() => {
    setOptions({
      ...options,
      xaxis: {
        ...options.xaxis,
        categories: categories,
      },
    })
  }, [categories])

  useEffect(() => {
    if(criteria === "Down Time") {
      setOptions({
        ...options,
        plotOptions: {
          ...options.plotOptions,
          heatmap: {
            ...options.plotOptions.heatmap,
            colorScale: {
              ...options.plotOptions.heatmap.colorScale,
              ranges: [
                {
                  from: 0,
                  to: 0,
                  color: "#FFFFFF",
                  foreColor: "#FFFFFF",
                  name: "none",
                },
                {
                  from: min + 0.0000001,
                  to: medium / 2,
                  color: "#519985",
                  name: "lower_middle",
                },
                {
                  from: medium / 2 + 0.0000001,
                  to: medium,
                  color: "#D7CD48",
                  name: "upper_middle",
                },
                {
                  from: medium + 0.0000001,
                  to: max,
                  color: "#C36D50",
                  name: "high",
                },
              ],
            }
          }
        },
        dataLabels: {
          offset: 50,
          textAnchor: 'middle',
          formatter: (val) => `${criteria === "Revenue Booked" ? formatAmount(val) : formatNumber(val)}`,
          style: {
            fontSize: "1rem",
          }
        }
      })
    }
    else {
      setOptions({
        ...options,
        plotOptions: {
          ...options.plotOptions,
          heatmap: {
            ...options.plotOptions.heatmap,
            colorScale: {
              ...options.plotOptions.heatmap.colorScale,
              ranges: [
                {
                  from: 0,
                  to: 0,
                  color: "#FFFFFF",
                  foreColor: "#FFFFFF",
                  name: "none",
                },
                {
                  from: min + 0.0000001,
                  to: medium / 2,
                  color: "#C36D50",
                  name: "lower_middle",
                },
                {
                  from: medium / 2 + 0.0000001,
                  to: medium,
                  color: "#D7CD48",
                  name: "upper_middle",
                },
                {
                  from: medium + 0.0000001,
                  to: max,
                  color: "#519985",
                  name: "high",
                },
              ],
            }
          }
        },
        dataLabels: {
          offset: 50,
          textAnchor: 'middle',
          formatter: (val) => `${criteria === "Revenue Booked" ? formatAmount(val) : formatNumber(val)}`,
          style: {
            fontSize: "1rem",
          }
        }
      })
    }
  }, [criteria, medium])

  useEffect(() => {
    if (!props.isEmbedded) {
      window.history.replaceState(null, null, `/clinic-report${Routing.ClinicReport.WeeklyCalendar.relativePath}`);
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <article className='postion-relative w-100'>
      {/* chart header */}
      {!props.isEmbedded &&
        <header className='app-font-class-0'>
          Weekly Calendar View of Chair Usage, Underutilisation Rate and Revenue
          Booked
        </header>
      }
      <main className='position-relative mt-2'>
        {/* dropdown */}
        {!props.isEmbedded &&
          <section className='d-flex flex-row-reverse mt-1'>
            <SingleSelect placeholder={"Select criteria"} onChange={handleAvgCriteria} options={criteriaOptions} value={{label: criteria, value: criteria}}/>
          </section>
        }
        {/* color scale */}
        {/* #275: added conditional elements to show label and color scale according to selected criteria */}
        <section className='container-fluid mt-4 px-4'>
          <div className='row'>
            <div className='col'>
              {
                criteria==='Chair Usage' &&
                <label>Chair Usage (No. Of Chairs)</label>
              }
              {
                criteria==='Down Time' &&
                <label>Underutilisation Rate (%)</label>
              }
              {
                criteria==='Revenue Booked' &&
                <label>Revenue Booked ($)</label>
              }
              <div>
                <span className='d-inline-block text-end pe-1' style={{ minWidth: "5%" }}>
                  {criteria === "Revenue Booked" ? formatAmount(min) : (criteria === "Chair Usage" ? formatNumber(min) : formatNumber(min) + "%")}
                </span>
                {
                  criteria==='Down Time'
                  ?
                  <span
                      className='d-inline-block'
                      style={{
                      width: "90%",
                      backgroundImage: "linear-gradient(to right, #519985 0%, #D7CD48 40%, #DBCE47 61%, #C36D50 100%)",
                      color: "transparent",
                      }}
                  >
                      null
                  </span>
                  :          
                  <span
                    className='d-inline-block'
                    style={{
                      width: criteria==='Revenue Booked' ? "88%" : "90%",
                      backgroundImage: "linear-gradient(to left, #519985 0%, #D7CD48 40%, #DBCE47 61%, #C36D50 100%)",
                      color: "transparent",
                    }}
                  >
                    null
                  </span>
                }
                <span
                  className='d-inline-block ps-1'
                  style={{ minWidth: "5%" }}
                >
                  {criteria === "Revenue Booked" ? formatAmount(max) : (criteria === "Chair Usage" ? formatNumber(max) : formatNumber(max) + "%")}
                </span>
              </div>
            </div>
            {/* #275: not used due to using different approach */}
            {/* <div className='col'>
                <label>Underutilisation Rate</label>
                <div>
                <span className='d-inline-block text-end pe-1' style={{ minWidth: "10%" }}>
                    {underutilizationMin}
                </span>
                <span
                    className='d-inline-block'
                    style={{
                    width: "80%",
                    backgroundImage: "linear-gradient(to right, #519985 0%, #D7CD48 40%, #DBCE47 61%, #C36D50 100%)",
                    color: "transparent",
                    }}
                >
                    null
                </span>
                <span
                    className='d-inline-block ps-1'
                    style={{ minWidth: "10%" }}
                >
                    {underutilizationMax}
                </span>
                </div>
            </div> */}
          </div>
        </section>
        {/* chart */}
        <section className='mt-1'>
          <Chart
            options={options}
            series={series}
            type='heatmap'
          />
        </section>
      </main>
    </article>
  )
}

// #275: moved to FormatDataForBoundaries.js so it can be used in reducer
// let formatData = (data) => {
//   // return format
//   if (!data?.length > 0)
//     return {
//       series: [],
//       categories: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
//     }

//     let series = []
//     let mergedData = []
  
//     // find min, max, avg from mergedData
//     for(let row of data){
//       let res = row.data.map(datum => datum.usage)
//       mergedData = _.union(mergedData, res)
//       series.push({
//         name: row.day,
//         data: res
//       })
//     }
  
//     // calculating min, max, avg
//     let vmin = Number.POSITIVE_INFINITY
//     let vmax = Number.NEGATIVE_INFINITY
//     let vsum = 0
//     let vavg = 0
//     for(let i of mergedData){
//       if(i < vmin) vmin = i
//       if(i > vmax) vmax = i
//       vsum+=parseFloat(i)
//     }
//     if(vsum) vavg = vsum / mergedData.length

//   return {
//     series,
//     categories: data?.[0].data?.map(each => each.month),
//     max: _.ceil(vmax),
//     medium: Math.round(vavg),
//     min: _.floor(vmin)
//   }
// }

export default WeeklyCalendar
