import { useEffect, useState } from "react"
import PerformanceSummary from "./PerformanceSummary"
import _ from "underscore"
import moment from "moment-timezone"

const YearlySummary = ({doctorId, doctorName, selectedYear, yearlySummaryData, isEmbedded}) => {
  const [summary, setSummary] = useState(undefined)

  useEffect(() => {
    setSummary(formatData(yearlySummaryData))
    return () => {
      setSummary(formatData(undefined))
    }
  }, [yearlySummaryData])

  useEffect(() => {
    if(!isEmbedded) {
      window.history.replaceState(null, "Medtrik", "/dr-performance-report/yearly-summary");
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <PerformanceSummary
      isEmbedded={isEmbedded}
      title={!isEmbedded ? `${(doctorName ?? doctorId) ? (doctorName ?? "Doctor")?.concat("'s ") : ""}Report Card - ${selectedYear === moment().format('YYYY') ? 'YTD' : ''}${ selectedYear }` : ''}
      {...summary}
    />
  )
}

let formatData = (data) => {
  if(_.isEmpty(data)) return {    
    year: 0,
    doctorName: "",
    totalRevenue: 0,
    targetRevenue: 0,
    variance: 0,
    totalVisits: 0,
    totalNewPatients: 0,
    totalHoursWorked: 0,
    totalDaysWorked: 0,
    hoursWorkedPerDay: 0,
  }
  return {
    year: data?.thisMonth,
    doctorName: data?.doctorName,
    totalRevenue: data?.totalRevenue,
    targetRevenue: data?.targetRevenue,
    variance: data?.variance,
    totalVisits: data?.totalVisits,
    totalNewPatients: data?.totalNewPatients,
    totalHoursWorked: data?.hoursWorkedTime,
    totalDaysWorked: data?.daysWorked,
    hoursWorkedPerDay: data?.hoursPerDay,
  }
}

export default YearlySummary
