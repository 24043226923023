import { Slider, styled } from "@mui/material"
import { SYSTEM_DATE_DISPLAY_FORMAT2_DF, SYSTEM_DATE_FORMAT_DF } from "constants/common"
import { MONTH_SLIDER } from "constants/date"
import { add, format, isThisMonth, lastDayOfMonth, startOfMonth, sub } from "date-fns"
import _ from "lodash"
import { useEffect, useState } from "react"

/**
 * return { currentDate} or { startDate, endDate}
 */
export default function MonthSlider({ display, reverse, type, step, min, max, marks, startDate, base, handleMonthChange, staticThumbs=[]}) {
    const [config, setConfig] = useState({});
    const [monthValues, setMonthValues] = useState([]) //monthValues contains month values from slider
    const [monthValuesCommitted, setMonthValuesCommitted] = useState([]); // committed values that will be passed to parent element

    useEffect(() => {
      let redefined
      if(!marks) {
        redefined = ((min && _.isNumber(min)) || (max && _.isNumber(max)) ? _.range(min ?? MONTH_SLIDER.min, max ?? MONTH_SLIDER.max): undefined)
      }
      let initConfig = _.assignWith({}, MONTH_SLIDER, { display, type, step, min, max, marks: redefined, base, start: startDate || startOfMonth(sub(base, {months: max}))}, (o, n) => _.isUndefined(n) ? o : n);
      setConfig(initConfig)

      // #406: set initial month values so slider doesn't need defaultValues param and can be tied to monthValues state instead
      let initMonthValues = initConfig.type === 2 ? [initConfig?.min ?? 1, initConfig?.max ?? 12] : [initConfig.max];
      setMonthValues(initMonthValues)
      setMonthValuesCommitted(initMonthValues)
    }, []);


    //based on selected month
    // #406: executed on monthValuesCommitted change instead of monthValues change; use monthValuesCommitted values instead of monthValues values
    useEffect(() => {
      let date = {}
      if(config.type === 1) {
        date.currentDate = isThisMonth(add(config.start, {months: monthValuesCommitted[0] ?? config.max})) ? format(Date.now(), SYSTEM_DATE_FORMAT_DF) : format(lastDayOfMonth(add(config.start, {months: monthValuesCommitted[0] ?? config.max})), SYSTEM_DATE_FORMAT_DF)
      }
      if(config.type === 2) {
        date.startDate = format(add(config.start, {months: monthValuesCommitted[0] ?? config.min}), SYSTEM_DATE_FORMAT_DF) 
        date.endDate = isThisMonth(add(config.start, {months: monthValuesCommitted[1] ?? config.max})) ? format(Date.now(), SYSTEM_DATE_FORMAT_DF) : format(lastDayOfMonth(add(config.start, {months: monthValuesCommitted[1] ?? config.max})), SYSTEM_DATE_FORMAT_DF)
      }
      handleMonthChange(date)
    }, [monthValuesCommitted, config])

    // // #406: styling slider so disabled thumb will be grayed out
    // const styleObj = {};
    // if (staticThumbs.length>0) {
    //   staticThumbs.map(thumb => {
    //     styleObj[`& .MuiSlider-thumb[data-index="${thumb}"]`] = { color: 'lightgray' }
    //   })
    // }
    // const Slider = styled(MuiSlider)(styleObj);
    const conditionalProps = {};
    if (staticThumbs.length>0) {
      conditionalProps.sx = {};
      staticThumbs.map((thumb) => {
        conditionalProps.sx[`& .MuiSlider-thumb[data-index="${thumb}"]`] = { color: 'lightgray' }
      })
    }

    // #406: handle thumb change function -> only move if activeThumb is not static
    const handleThumbChange = (event, newValue, activeThumb) => {
      if (!staticThumbs.includes(activeThumb)) {
        setMonthValues(newValue)
      }
    }
    
    return (
        <>
          { 
            config?.display && 
            <div className="d-flex pt-3 px-5 w-100">
              <span
                style={{
                  width: "fit-content",
                  whiteSpace: "nowrap",
                  paddingRight: "1rem",
                }}
              >
                {format(add(config.start, {months: 1}), SYSTEM_DATE_DISPLAY_FORMAT2_DF)}
              </span>
              <Slider
                id='date-period-input'
                step={config?.step}
                // #406: only change monthValues if thumb is static 
                onChange={(event, newValue, activeThumb) => handleThumbChange(event, newValue, activeThumb)}
                // #406: when change is committed then commit monthValues to monthValuesCommitted then call useEffect
                onChangeCommitted={(event, newValue) => {setMonthValuesCommitted(monthValues)}}
                marks={config?.marks}
                min={config?.min}
                max={config?.max}
                value={monthValues}
                // value={_.isEmpty(monthValues) ? (config.type === 2 ? [config?.min ?? 1, config?.max ?? 12] : [config.max]): monthValues}
                // defaultValue={config.type === 2 ? [config?.min ?? 1, config?.max ?? 12] : [config.max]}
                disableSwap={config.type!==1}
                valueLabelDisplay="auto"
                valueLabelFormat={(val) => format(add(config.start, {months: val ?? config.min}), SYSTEM_DATE_DISPLAY_FORMAT2_DF)}
                {...conditionalProps}
              />
              <span
                style={{
                  width: "fit-content",
                  whiteSpace: "nowrap",
                  paddingLeft: "1rem",
                }}
              >
                {reverse ? format(add(config.start, {months: max}), SYSTEM_DATE_DISPLAY_FORMAT2_DF) : format(config.base, SYSTEM_DATE_DISPLAY_FORMAT2_DF) }
              </span>
            </div>
          }
        </>
    )
}

export function monthSlider(){
  let date = {}
  if(MONTH_SLIDER.type === 1) {
    date.currentDate = isThisMonth(add(MONTH_SLIDER.start, {months: MONTH_SLIDER.max})) ? format(Date.now(), SYSTEM_DATE_FORMAT_DF)  : format(lastDayOfMonth(add(MONTH_SLIDER.start, {months: MONTH_SLIDER.max})), SYSTEM_DATE_FORMAT_DF)
  }
  if(MONTH_SLIDER.type === 2) {
    date.startDate = format(add(MONTH_SLIDER.start, {months: MONTH_SLIDER.min}), SYSTEM_DATE_FORMAT_DF) 
    date.endDate = isThisMonth(add(MONTH_SLIDER.start, {months: MONTH_SLIDER.max})) ? format(Date.now(), SYSTEM_DATE_FORMAT_DF)  : format(lastDayOfMonth(add(MONTH_SLIDER.start, {months: MONTH_SLIDER.max})), SYSTEM_DATE_FORMAT_DF)
  }
  return date
}