import axios from "axios"
import {
  GET_CLINIC_DASHBOARD_FILTER,
  GET_CLINIC_HOURLY_AVERAGE_FILTER,
  GET_CLINIC_TREATMENT_TIME,
  GET_CLINIC_HOURLY_CALENDAR,
  GET_CLINIC_WEEKLY_CALENDAR,
  // GET_CLINIC_WEEKLY_UNDERUTILIZATION_BOUNDARIES, // #275: not used due to using different approach
  GET_CLINIC_DAILY_CALENDAR,
  // GET_CLINIC_DAILY_DOWNTIME_BOUNDARIES, // #275: not used due to using different approach
  GET_CLINIC_USAGE,
  GET_CLINIC_TIMELINE

} from "../constants/actionsTypes"
import ENVIRONMENT_VARIABLES from "../config"
import processRequest from "utils/processRequest"

export const getTreatmentTime = (chainId, clinicId, startDate, endDate) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Clinics/getClinicTreatmentTime",
    data: {
      chainId: chainId,
      clinicId: clinicId,
      filterStartDate: startDate,
      filterEndDate: endDate
    },
  }
  return processRequest(api, GET_CLINIC_TREATMENT_TIME)
}

export const getHourlyCalendar = (chainId, clinicId, startDate, endDate, criteria) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Clinics/getClinicDoctorRatio",
    data: {
      chainId: chainId,
      clinicId: clinicId,
      filterStartDate: startDate,
      filterEndDate: endDate,
      selectionFilter: criteria,
    },
  }
  return processRequest(api, GET_CLINIC_HOURLY_CALENDAR)
}

export const getWeeklyCalendar = (chainId, clinicId, startDate, endDate, criteria) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Clinics/getClinicUnderUtilization",
    data: {
      chainId: chainId,
      clinicId: clinicId,
      filterStartDate: startDate,
      filterEndDate: endDate,
      selectionFilter: criteria,
    },
  }
  return processRequest(api, GET_CLINIC_WEEKLY_CALENDAR)
}

// #275: not used due to using different approach
// // #275: call API with selectionFilter = "Down Time"
// export const getWeeklyUnderutilizationBoundaries = (chainId, clinicId, startDate, endDate) => {
//   const token = "Bearer " + localStorage.getItem("accessToken")
//   let api = {
//     method: "POST",
//     headers: { Authorization: token },
//     url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Clinics/getClinicUnderUtilization",
//     data: {
//       chainId: chainId,
//       clinicId: clinicId,
//       filterStartDate: startDate,
//       filterEndDate: endDate,
//       selectionFilter: "Down Time",
//     },
//   }
//   return processRequest(api, GET_CLINIC_WEEKLY_UNDERUTILIZATION_BOUNDARIES)
// }

export const getDailyCalendar = (chainId, clinicId, startDate, endDate, criteria) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Clinics/getClinicDowntimeRevenue",
    data: {
      chainId: chainId,
      clinicId: clinicId,
      filterStartDate: startDate,
      filterEndDate: endDate,
      selectionFilter: criteria,
    },
  }
  return processRequest(api, GET_CLINIC_DAILY_CALENDAR)
}

// #275: not used due to using different approach
// // #275: call API with selectionFilter = "Down Time"
// export const getDailyDowntimeBoundaries = (chainId, clinicId, startDate, endDate) => {
//   const token = "Bearer " + localStorage.getItem("accessToken")
//   let api = {
//     method: "POST",
//     headers: { Authorization: token },
//     url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Clinics/getClinicDowntimeRevenue",
//     data: {
//       chainId: chainId,
//       clinicId: clinicId,
//       filterStartDate: startDate,
//       filterEndDate: endDate,
//       selectionFilter: "Down Time",
//     },
//   }
//   return processRequest(api, GET_CLINIC_DAILY_DOWNTIME_BOUNDARIES)
// }

export const getUsage = (chainId, clinicId, startDate, endDate) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Clinics/getClinicUsageDowntime",
    data: {
      chainId: chainId,
      clinicId: clinicId,
      filterStartDate: startDate,
      filterEndDate: endDate
    },
  }
  return processRequest(api, GET_CLINIC_USAGE)
}

export const getTimeLine = (chainId, clinicId, startDate, endDate) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Clinics/getClinicUtilization",
    data: {
      chainId: chainId,
      clinicId: clinicId,
      filterStartDate: startDate,
      filterEndDate: endDate
    },
  }
  return processRequest(api, GET_CLINIC_TIMELINE)
}

export const getDashboardFilter = (clinicId) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Clinics/getClinicDashboardFilter",
    data: {
      clinicId: clinicId
    },
  }
  return processRequest(api, GET_CLINIC_DASHBOARD_FILTER)
}

export const getHourlyAverageFilter = (clinicId) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Clinics/getHourlyAverageFilter",
    data: {
      clinicId: clinicId
    },
  }
  return processRequest(api, GET_CLINIC_HOURLY_AVERAGE_FILTER)
}