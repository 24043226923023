import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"

import appReducer from "./appReducer"
import adminAuthReducer from "./adminAuthReducers"
import kpiPlannerReducer from "./kpiPlannerReducer"
import drPerformanceReducer from "./drPerformanceReducer"
import clinicReducer from "./clinicReducer"
import financeReducer from "./financeReducer"
import patientReducer from './patientReducer'
import reportReducer from "./reportReducer"
import patientSegmentationReducer from "./patientSegmentationReducer"

const rootReducer = combineReducers({
  appReducer,
  adminAuthReducer,
  kpiPlannerReducer,
  financeReducer,
  patientReducer,
  drPerformanceReducer,
  clinicReducer,
  reportReducer, 
  patientSegmentationReducer,     // #406: add patientsegmentation dashboard reducer
  routing: routerReducer,
})

export default rootReducer
