import React from "react"
import Select from "react-select"
import _ from "lodash"



export default function SingleSelect({onChange: handleChange, options, placeholder, value}) {
  return (
    <Select 
      className="me-2" 
      placeholder={placeholder} 
      components={{
        IndicatorSeparator: () => null
      }} 
      onChange={(selected) => {
          handleChange(selected)
      }}
      value={value?.value && value?.label && value}
      options={options}/>
  );
}
