import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable' //DO NOT REMOVE: FUNCTION IS REQUIRED FOR 'doc.autoTable'
import logoB64 from './logoB64'

export default function generatePDF(type, reportData) {
    const {month, year, branch, financeDashboard, clinicDashboard, doctorDashboard, 
        treatmentDashboard, patientDashboard, conversionDashboard, topTreatments, 
        branchDoctors, headlines, insights} = reportData

    const getMonthName = (monthNumber) => {
        const date = new Date()
        date.setDate(1)
        date.setMonth(monthNumber - 1)
        return date.toLocaleString('en-US', {month: 'short'})
    }
    const monthAndYear = `${getMonthName(month)} ${year}`

    const textDataArr = []

    const formatTextData = (textData) => {
        var newTextData = textData.map((t) => [t])
        textDataArr.push(newTextData)
    }

    formatTextData(headlines)
    formatTextData(insights)

    const dataArr = []

    const formatData = (dashboardData, colourHex) => {
        var data = dashboardData.slice(0, -1).map(a => ({...a}))

        var comments = [...dashboardData.at(-1)]
        comments.unshift(`Comments (as of ${monthAndYear})`)

        for (let i = 0; i < comments.length; i++) {
            if (comments[i] && comments[i].length > 0) {
                var commObj = [{
                    colSpan: 14,
                    content: comments[i],
                    styles: {
                        halign: 'left',
                        fontStyle: 'normal'
                    }
                }]

                if (i === 0) {
                    commObj[0]['styles'] = {
                            halign: 'center',
                            fillColor: colourHex,
                            textColor: '#ffffff',
                            fontStyle: 'bold'
                        }
                }

                data.push(commObj)
            }
        }

        dataArr.push(data)
    }

    formatData(financeDashboard, '#04AA6D')
    formatData(clinicDashboard, '#0468aa')
    formatData(doctorDashboard, '#ddc540')
    formatData(treatmentDashboard, '#f88335')
    formatData(patientDashboard, '#db2645')

    const tHeaders = {}
    tHeaders['grouped'] = 'Treatment Dashboard (for top 4 revenue-generating treatments of ' + (year - 1) + ')'
    tHeaders['top1'] = topTreatments['top1'].charAt(0).toUpperCase() + topTreatments['top1'].slice(1).toLowerCase(), 
    tHeaders['varTop1'] = 'MOM Var of ' + topTreatments['top1'].charAt(0).toUpperCase() + topTreatments['top1'].slice(1).toLowerCase()
    tHeaders['top2'] = topTreatments['top2'].charAt(0).toUpperCase() + topTreatments['top2'].slice(1).toLowerCase()
    tHeaders['varTop2'] = 'MOM Var of ' + topTreatments['top2'].charAt(0).toUpperCase() + topTreatments['top2'].slice(1).toLowerCase()
    tHeaders['top3'] = topTreatments['top3'].charAt(0).toUpperCase() + topTreatments['top3'].slice(1).toLowerCase()
    tHeaders['varTop3'] = 'MOM Var of ' + topTreatments['top3'].charAt(0).toUpperCase() + topTreatments['top3'].slice(1).toLowerCase()
    tHeaders['top4'] = topTreatments['top4'].charAt(0).toUpperCase() + topTreatments['top4'].slice(1).toLowerCase()
    tHeaders['varTop4'] = 'MOM Var of ' + topTreatments['top4'].charAt(0).toUpperCase() + topTreatments['top4'].slice(1).toLowerCase()

    const doc = new jsPDF({orientation: 'landscape'})

    const pageWidth = doc.internal.pageSize.width
    const pageHeight = doc.internal.pageSize.height

    //insert report header
    doc.setFontSize(25)
    if (type === 'chain') {
        doc.text(`Monthly Report (${monthAndYear})`, pageWidth / 2, 25, {align: 'center'})
    } else {
        doc.text(`${toTitleCase(branch)} Monthly Report (${monthAndYear})`, pageWidth / 2, 25, {align: 'center'})
    }

    doc.setFontSize(20)

    if (type === 'chain') {
        //insert headlines
        //doc.text('Headlines', 15, 40)
        doc.autoTable({
            startY: 40,
            margin: {top: 25},
            theme: 'grid',
            body: textDataArr[0],
            head: [
                [{content: 'Headlines', styles: {halign: 'center', fillColor: '#00c4ba', fontSize: 15, fontStyle: 'bold'}}]
            ],
            showHead: 'firstPage'
        })

        doc.addPage()

        //insert insights
        //doc.text('Insights', 15, doc.lastAutoTable.finalY + 20)
        doc.autoTable({
            startY: 25,
            margin: {top: 25},
            theme: 'grid',
            body: textDataArr[1],
            head: [
                [{content: 'Actionable Insights', styles: {halign: 'center', fillColor: '#600ee4', fontSize: 15, fontStyle: 'bold'}}]
            ],
            showHead: 'firstPage'
        })

        doc.addPage()
    }
    //insert finance dashboard
    //doc.text('Finance Dashboard', 15, doc.lastAutoTable.finalY + 20)
    doc.autoTable({
        startY: type === 'chain' ? 25 : 40,
        margin: {top: 25},
        theme: 'grid',
        body: dataArr[0],
        head: [
            [{content: 'Finance Dashboard', colSpan: 14, styles: {halign: 'center', fillColor: '#38c692', fontSize: 15, fontStyle: 'bold'}}],
            ['', 'Revenue ($)', 'MOM Var of Revenue', 'Cost ($)', 'MOM Var of Cost', 'Profit ($)', 'MOM Var of Profit', 'Profit Margin', 
        '1st (Per Invoice of Treatment)', '2nd (Per Invoice of Treatment)', '3rd (Per Invoice of Treatment)']
        ],
        columns: [
            {header: '', dataKey: 'label'},
            {header: 'Revenue ($)', dataKey: 'revenue'},
            {header: 'MOM Var of Revenue', dataKey: 'varRevenue'},
            {header: 'Cost ($)', dataKey: 'cost'},
            {header: 'MOM Var of Cost', dataKey: 'varCost'},
            {header: 'Profit ($)', dataKey: 'profit'},
            {header: 'MOM Var of Profit', dataKey: 'varProfit'},
            {header: 'Profit Margin', dataKey: 'profitMargin'},
            {header: '1st (Per Invoice of Treatment)', dataKey: 'firstInvoice'},
            {header: '2nd (Per Invoice of Treatment)', dataKey: 'secondInvoice'},
            {header: '3rd (Per Invoice of Treatment)', dataKey: 'thirdInvoice'}
        ],
        headStyles: {fillColor: '#04AA6D'},
        columnStyles: {
            label: {halign: 'center', fontStyle: 'bold'},
            revenue: {halign: 'right'},
            varRevenue: {halign: 'right'},
            cost: {halign: 'right'},
            varCost: {halign: 'right'},
            profit: {halign: 'right'},
            varProfit: {halign: 'right'},
            profitMargin: {halign: 'right'}
        },
        showHead: 'firstPage',
        // didParseCell(data) {
        //     var isVarCell = [month + 2, month + 5].includes(data.row.index) && [1].includes(data.column.index)
        //     if (isVarCell) {
        //         data.cell.styles.fontStyle = 'bold'
        //         let varSymbol = (data.cell.raw.split(' '))[1].charAt(1)
        //         if (varSymbol === '+') {
        //             data.cell.styles.textColor = '#008000'
        //         } else if (varSymbol === '-') {
        //             data.cell.styles.textColor = 'FF0000'
        //         }
        //     }
        // }
    })

    doc.addPage()

    //insert clinic dashboard
    //doc.text('Clinic Dashboard', 15, doc.lastAutoTable.finalY + 20)
    doc.autoTable({
        startY: 25,
        margin: {top: 25},
        theme: 'grid',
        body: dataArr[1],
        head: [
            [{content: 'Clinic Dashboard', colSpan: 6, styles: {halign: 'center', fillColor: '#3f96d0', fontSize: 15, fontStyle: 'bold'}}],
            ['', 'Uncaptured Revenue ($)', 'Average Utilisation', 'Peak Hours 1', 'Peak Hours 2', 'Peak Hours 3']
        ],
        columns: [
            {header: '', dataKey: 'label'},
            {header: 'Uncaptured Revenue ($)', dataKey: 'uncapturedRevenue'},
            {header: 'Average Utilisation', dataKey: 'avgUtilisation'},
            {header: 'Peak Hours 1', dataKey: 'peakHours1'},
            {header: 'Peak Hours 2', dataKey: 'peakHours2'},
            {header: 'Peak Hours 3', dataKey: 'peakHours3'}
        ],
        headStyles: {fillColor: '#0468aa'},
        columnStyles: {
            label: {halign: 'center', fontStyle: 'bold'},
            uncapturedRevenue: {halign: 'right'},
            avgUtilisation: {halign: 'right'}
        },
        showHead: 'firstPage'
    })

    doc.addPage()

    //insert doctor dashboard
    //doc.text('Doctor Dashboard', 15, doc.lastAutoTable.finalY + 20)
    doc.autoTable({
		startY: 25,
        margin: {top: 25},
        theme: 'grid',
        body: dataArr[2],
        head: [
            [{content: 'Doctor Dashboard', colSpan: 14, styles: {halign: 'center', fillColor: '#fce355', fontSize: 15, fontStyle: 'bold'}}],
            ['', 'Visits', 'MOM Var of Visits', 'New Patients', 'MOM Var of New Patients', 'Hours Worked', 'MOM Var of Hours Worked', 
        'Days Worked', 'MOM Var of Days Worked', 'Revenue per Hour ($)', 'MOM Var of Revenue per Hour', 'Dr with 1st highest rev per hr', 
        'Dr with 2nd highest rev per hr', 'Dr with 3rd highest rev per hr']
        ],
        columns: [
            {header: '', dataKey: 'label'},
            {header: 'Visits', dataKey: 'visits'},
            {header: 'MOM Var of Visits', dataKey: 'varVisits'},
            {header: 'New Patients', dataKey: 'newPatients'},
            {header: 'MOM Var of New Patients', dataKey: 'varNewPatients'},
            {header: 'Hours Worked', dataKey: 'hoursWorked'},
            {header: 'MOM Var of Hours Worked', dataKey: 'varHoursWorked'},
            {header: 'Days Worked', dataKey: 'daysWorked'},
            {header: 'MOM Var of Days Worked', dataKey: 'varDaysWorked'},
            {header: 'Revenue per Hour ($)', dataKey: 'revPerHour'},
            {header: 'MOM Var of Revenue per Hour', dataKey: 'varRevPerHour'},
            {header: 'Dr with 1st highest rev per hr', dataKey: 'firstDoctor'},
            {header: 'Dr with 2nd highest rev per hr', dataKey: 'secondDoctor'},
            {header: 'Dr with 3rd highest rev per hr', dataKey: 'thirdDoctor'}
        ],
        headStyles: {fillColor: '#ddc540'},
        columnStyles: {
            label: {halign: 'center', fontStyle: 'bold'},
            visits: {halign: 'right'},
            varVisits: {halign: 'right'},
            newPatients: {halign: 'right'},
            varNewPatients: {halign: 'right'},
            hoursWorked: {halign: 'right'},
            varHoursWorked: {halign: 'right'},
            daysWorked: {halign: 'right'},
            varDaysWorked: {halign: 'right'},
            revPerHour: {halign: 'right'},
            varRevPerHour: {halign: 'right'}
        },
        showHead: 'firstPage',
        // didParseCell(data) {
        //     var isVarCell = [month + 2, month + 5].includes(data.row.index) && [3].includes(data.column.index)
        //     if (isVarCell) {
        //         data.cell.styles.fontStyle = 'bold'
        //         let varSymbol = (data.cell.raw.split(' '))[1].charAt(1)
        //         if (varSymbol === '+') {
        //             data.cell.styles.textColor = '#008000'
        //         } else if (varSymbol === '-') {
        //             data.cell.styles.textColor = 'FF0000'
        //         }
        //     }
        // }
    })

    doc.addPage()

    //insert treatment dashboard
    //doc.text('Treatment Dashboard', 15, doc.lastAutoTable.finalY + 20)
    doc.autoTable({
		startY: 25,
        margin: {top: 25},
        theme: 'grid',
        body: dataArr[3],
        head: [
            [{content: tHeaders['grouped'], colSpan: 9, styles: {halign: 'center', fillColor: '#ed9e69', fontSize: 15, fontStyle: 'bold'}}],
            ['', tHeaders['top1'], tHeaders['varTop1'], tHeaders['top2'], tHeaders['varTop2'], tHeaders['top3'], tHeaders['varTop3'],
            tHeaders['top4'], tHeaders['varTop4']]
        ],
        columns: [
            {header: '', dataKey: 'label'},
            {header: tHeaders['top1'], dataKey: 'top1'},
            {header: tHeaders['varTop1'], dataKey: 'varTop1'},
            {header: tHeaders['top2'], dataKey: 'top2'},
            {header: tHeaders['varTop2'], dataKey: 'varTop2'},
            {header: tHeaders['top3'], dataKey: 'top3'},
            {header: tHeaders['varTop3'], dataKey: 'varTop3'},
            {header: tHeaders['top4'], dataKey: 'top4'},
            {header: tHeaders['varTop4'], dataKey: 'varTop4'}
        ],
        headStyles: {fillColor: '#f88335'},
        columnStyles: {
            label: {halign: 'center', fontStyle: 'bold'},
            top1: {halign: 'right'},
            varTop1: {halign: 'right'},
            top2: {halign: 'right'},
            varTop2: {halign: 'right'},
            top3: {halign: 'right'},
            varTop3: {halign: 'right'},
            top4: {halign: 'right'},
            varTop4: {halign: 'right'}
        },
        showHead: 'firstPage',
        // didParseCell(data) {
        //     var isVarCell = [month + 2, month + 5].includes(data.row.index) && [1, 3, 5, 7].includes(data.column.index)
        //     if (isVarCell) {
        //         data.cell.styles.fontStyle = 'bold'
        //         let varSymbol = (data.cell.raw.split(' '))[1].charAt(1)
        //         if (varSymbol === '+') {
        //             data.cell.styles.textColor = '#008000'
        //         } else if (varSymbol === '-') {
        //             data.cell.styles.textColor = 'FF0000'
        //         }
        //     }
        // }
    })

    doc.addPage()

    //insert patient dashboard
    //doc.text('Patient Dashboard', 15, doc.lastAutoTable.finalY + 20)
    doc.autoTable({
		startY: 25,
        margin: {top: 25},
        theme: 'grid',
        body: dataArr[4],
        head: [
            [{content: 'Patient Dashboard', colSpan: 11, styles: {halign: 'center', fillColor: '#f74d69', fontSize: 15, fontStyle: 'bold'}}],
            ['', 'All Segments 1', 'All Segments 2', 'All Segments 3', 'All Segments 4', 'First Treatment 1', 'First Treatment 2', 'First Treatment 3',
        'High Value Segment 1', 'High Value Segment 2', 'High Value Segment 3']
        ],
        columns: [
            {header: '', dataKey: 'label'},
            {header: 'All Segments 1', dataKey: 'allSeg1'},
            {header: 'All Segments 2', dataKey: 'allSeg2'},
            {header: 'All Segments 3', dataKey: 'allSeg3'},
            {header: 'All Segments 4', dataKey: 'allSeg4'},
            {header: 'First Treatment 1', dataKey: 'firstTreat1'},
            {header: 'First Treatment 2', dataKey: 'firstTreat2'},
            {header: 'First Treatment 3', dataKey: 'firstTreat3'},
            {header: 'High Value Segment 1', dataKey: 'highVal1'},
            {header: 'High Value Segment 2', dataKey: 'highVal2'},
            {header: 'High Value Segment 3', dataKey: 'highVal3'},
        ],
        headStyles: {fillColor: '#db2645'},
        columnStyles: {
            label: {halign: 'center', fontStyle: 'bold'}
        },
        showHead: 'firstPage'
    })

    doc.addPage()

    //insert conversion dashboard
    doc.autoTable({
		startY: 25,
        margin: {top: 25},
        theme: 'grid',
        body: conversionDashboard,
        head: [
            [{content: 'Patient Conversion Dashboard', colSpan: 4, styles: {halign: 'center', fillColor: '#525252', fontSize: 15, fontStyle: 'bold'}}],
            ['', '1st-time Patient Count (Cumulative over 6-month period)', 'Returned within the 6-month period', 'Conversion Rate']
        ],
        columns: [
            {header: '', dataKey: 'dateLabel'},
            {header: '1st-time Patient Count (Cumulative over 6-month period)', dataKey: 'firstPatients'},
            {header: 'Returned within the 6-month period', dataKey: 'returnedPatients'},
            {header: 'Conversion Rate', dataKey: 'conversionRate'}
        ],
        headStyles: {fillColor: '#2c2c2c'},
        columnStyles: {
            dateLabel: {halign: 'center', fontStyle: 'bold'},
            firstPatients: {halign: 'right'},
            returnedPatients: {halign: 'right'},
            conversionRate: {halign: 'right'}
        },
        showHead: 'firstPage'
    })

    //function to add custom header and footer to each page of pdf
    const addPageHeaderFooter = (doc, pageNo) => {
        //add page header
        doc.text('CONFIDENTIAL', pageWidth / 2, 10, {align: 'center'})
        doc.addImage(logoB64, 'png', pageWidth - 50, 5, 40, 11)
        //add page footer
        doc.text(pageNo.toString(), 10, pageHeight - 10, {align: 'center'})
        doc.text('CONFIDENTIAL', pageWidth / 2, pageHeight - 10, {align: 'center'})
    }

    //iterate through pdf pages to add header and footer
    doc.setFontSize(10)
    for (let p = 1; p <= doc.internal.getNumberOfPages(); p++) {
        doc.setPage(p)
        addPageHeaderFooter(doc, p)
    }

    doc.save(`${monthAndYear.split(' ')[0]}-${monthAndYear.split(' ')[1]}-monthly-report.pdf`)
}

function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
}