import React, { useState, useEffect } from "react";
import { HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, Breadcrumb, InputGroup, Table, Modal} from 'react-bootstrap';

import { Routing } from "routes";
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as authAction from "actions/authActions";

const VerticallyCenteredModal = (props) => {
    
    const { data, show, onHide,  
        role=false, 
        user=false, } = props;
    
    let permissionVal = ['cm', 'dm', 'fm', 'pm', 'ud']

    return (
        <>
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title> { user ? data.fullName : role ? data.name : data.name } </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table hover className="user-table table-striped align-items-center w-100">
                    <tbody className="border-0">
                        {
                            role &&
                            <>
                            <tr>
                                <td>Description</td>
                                <td>{ data.description }</td>
                            </tr>
                            </>
                        }
                        {
                            user && 
                            <>
                            <tr>
                                <td>User Name </td>
                                <td>{ data.userName }</td>
                            </tr>
                            <tr>
                                <td>Email </td>
                                <td>{ data.email }</td>
                            </tr>
                            <tr>
                                <td>Role </td>
                                {/*<td>{ data.Role?.title }</td>*/}
                            </tr>
                            {
                                data.doctor &&
                                <tr>
                                    <td> Doctor Name </td>
                                    <td>{ data.doctor.name }</td>
                                </tr>
                            }
                            {
                                data.clinic &&
                                <>
                                <tr>
                                    <td> Clinic Id </td>
                                    <td>{ data.clinic.clinicId }</td>
                                </tr>
                                <tr>
                                    <td> Clinic address </td>
                                    <td>{ data.clinic.address }</td>
                                </tr>
                                </>
                            }
                            </>
                        }
                        
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
        </>
    )

}

const mapDispatchToProps = dispatch => ({
    actions: {
        adminAction: bindActionCreators(authAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(VerticallyCenteredModal)