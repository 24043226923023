import React, { useState, useEffect, useMemo } from "react";
import { HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import { EyeIcon, PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { Col, Row, Form, Button, Breadcrumb, InputGroup, } from 'react-bootstrap';

import { Routing } from "routes";
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import * as authAction from "actions/authActions";
import swal from 'sweetalert';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import VerticallyCenteredModal from '../Modal';
import Loader from "../Loader";


const Clinics = (props) => {

    const history = useHistory();
    const [ clinics, setClinics ] = useState([]);
    const { SearchBar, ClearSearchButton } = Search;
    const [ modalShow, setModalShow ] = useState(false);
    const [ modalData, setModalData ] = useState([]);

    useEffect(() => {
        props.actions.adminAction.getClinics();
    }, []);

    const adminAuthReducer = useSelector((state) => ({
        clinics: state.adminAuthReducer.clinics,
        isLoading: state.adminAuthReducer.loading,
        loginUserID: state.adminAuthReducer.loginUserID
    }));

    const onClickView = (rowType) => {
        setModalShow(true);
        setModalData(rowType);
    }

    const columns = [
        { dataField: '_id', text: 'INDEX', sort: false, formatter(cell, row, rowIndex) { return ++rowIndex; } },
        { dataField: 'clinicId', text: 'CLINIC ID', sort: true },
        { dataField: 'address', text: 'FULL NAME', sort: true },
        { dataField: 'isActive', text: 'STATUS', sort: false, formatter(cell, row) { return row.isActive ? 'Activate' : 'Deactivate'} },
        { dataField: '', text: 'COMMAND', 
            attrs: { className: "command-icon" }, 
            sort: false,
            formatter: rankFormatter
        }
    ];

    function rankFormatter(cell, row, rowIndex, formatExtraData) { 
        return ( 
            <>
            <a href="#" onClick={() => onClickView(row)} >
                <EyeIcon className="text-gray-800 me-2" />
            </a>
            <PencilAltIcon className="text-gray-800 me-2" />
            <TrashIcon className="text-gray-800 me-2" />
            </>
    )};

    const pagination = paginationFactory({
        sizePerPage: 10,
        showTotal: true,
        nextPageText: 'Next',
        prePageText: 'Previous',
        alwaysShowAllBtns: true
    });

    useEffect(() => {
        if(adminAuthReducer.clinics) {
            setClinics(adminAuthReducer.clinics.map(u => ({ ...u })))
        }
    }, [adminAuthReducer.clinics]);
  
    const redirectUrl = (url) => {
        history.push(url);
    };

    return (
        <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                    <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                    <Breadcrumb.Item active>Cliinc Management</Breadcrumb.Item>
                </Breadcrumb>
                <h4>Clinic Management</h4>
            </div>
        </div>
        { clinics &&
            <>
            <ToolkitProvider
                bootstrap4
                keyField='_id'
                data={clinics}
                columns={columns}
                search
            >
                {
                    props => (
                        <div className="cs-data-tables">
                        <SearchBar {...props.searchProps} />
                        <ClearSearchButton {...props.searchProps} />
                        <BootstrapTable
                            classes="table-striped"
                            pagination={pagination}
                            {...props.baseProps}
                        />
                        </div>
                    )
                }
    
            </ToolkitProvider>

            <VerticallyCenteredModal 
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={modalData}
                clinic={true}
            />
            </>
        }

        { adminAuthReducer.isLoading && <Loader/> }
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: {
        adminAction: bindActionCreators(authAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(Clinics)