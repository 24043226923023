import React from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import moment from "moment";

const marks = [
    {
      value: 0,
      label: moment().subtract(24, 'Months').format('YYYY'),
    },
    {
      value: 1,
      label: moment().subtract(12, 'Months').format('YYYY'),
    },
    {
      value: 2,
      label: moment().format('YYYY'),
    },
];

const YearSlider = ({handleChange}) => {

    return (
        <Box className="pt-3 px-5 w-100">
            <Slider
                onChangeCommitted={(event, year) => handleChange(moment().subtract((2 - year), 'Years').format('YYYY'))}
                defaultValue={[2]}
                max={2}
                step={1}
                marks={marks}
                disableSwap
                valueLabelDisplay="auto"
                valueLabelFormat={(val) => {
                  return moment().subtract((2 - val), 'Years').format('YYYY');
                }}
            />
        </Box>
    )

}

export default YearSlider;
