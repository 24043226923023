import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import swal from 'sweetalert';
import { SalesValueWidget } from 'components/Widgets';
import { PageVisitsTable } from 'components/Tables';
import { useHistory, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import decode from 'jwt-decode';
import axios from 'axios';
import { Routing } from 'routes';
import './style.css'
import clinicDashboardImage from 'assets/img/clinic.png';
import doctorDashboardImage from 'assets/img/doctor.png';
import financeDashboardImage from 'assets/img/finance.png';
import patientDashboardImage from 'assets/img/patient.png';

export function isLoggedIn() {
  const accessToken = getAccessToken();
  return !!accessToken && !isTokenExpired(accessToken);
}

export function getAccessToken() {
  return localStorage.getItem('accessToken');
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  if (expirationDate < new Date()) {
    clearAccessToken();
  }
  return expirationDate < new Date();
}

const ClinicDashboard = () => {
  const openMenu = (link, title) => {
    window.open(link, '_self');
  };
  return (
    <div className="col-sm-6 mb-3 mb-sm-0 mt-3">
      <div
        className="card text-center"
        style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <h4 onClick={() => openMenu(Routing.ClinicReport.path)} className="btn-title card-title text-center mt-4">Clinic Dashboard</h4>
        <img src={clinicDashboardImage} onClick={() => openMenu(Routing.ClinicReport.path)} style={{ width: '50%', height: 'auto' }} className='darken' />
        {/* <div
          className="card-body justify-content-center text-center"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <a onClick={() => openMenu(Routing.ClinicReport.path)} className="btn btn-primary">
            Click Here
          </a>
        </div> */}
      </div>
    </div>
  );
};

const DoctorDashboard = () => {
  const openMenu = (link, title) => {
    window.open(link, '_self');
  };
  return (
    <div className="col-sm-6 mb-3 mb-sm-0 mt-3">
      <div
        className="card text-center"
        style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <h4 onClick={() => openMenu(Routing.DrPerformanceReport.path)} className="btn-title card-title text-center mt-4">Doctor Dashboard</h4>
        <img src={doctorDashboardImage} onClick={() => openMenu(Routing.DrPerformanceReport.path)} style={{ width: '50%', height: 'auto' }} className='darken' />
        {/* <div
          className="card-body justify-content-center text-center"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <a onClick={() => openMenu(Routing.DrPerformanceReport.path)} className="btn btn-primary">
            Click Here
          </a>
        </div> */}
      </div>
    </div>
  );
};

const FinanceDashboard = () => {
  const openMenu = (link, title) => {
    window.open(link, '_self');
  };
  return (
    <div className="col-sm-6 mb-3 mb-sm-0 mt-3">
      <div
        className="card text-center"
        style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <h4 onClick={() => openMenu(Routing.FinanceDashboard.path)} className="btn-title card-title text-center mt-4">Finance Dashboard</h4>
        <img src={financeDashboardImage} onClick={() => openMenu(Routing.FinanceDashboard.path)} style={{ width: '50%', height: 'auto' }} className='darken' />
        {/* <div
          className="card-body justify-content-center text-center"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          #371: Need to change routing from Dr Dashboard to Finance Dashboard
          <a onClick={() => openMenu(Routing.FinanceDashboard.path)} className="btn btn-primary">
            Click Here
          </a>
        </div> */}
      </div>
    </div>
  );
};

const PatientDashboard = () => {
  const openMenu = (link, title) => {
    window.open(link, '_self');
  };
  return (
    <div className="col-sm-6 mb-3 mb-sm-0 mt-3">
      <div
        className="card text-center"
        style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <h4 onClick={() => openMenu(Routing.PatientsDashboard.path)} className="btn-title card-title text-center mt-4">Patient Dashboard </h4>
        <img src={patientDashboardImage} onClick={() => openMenu(Routing.PatientsDashboard.path)} style={{ width: '50%', height: 'auto' }} className='darken' />
        {/* <div
          className="card-body justify-content-center text-center"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <a onClick={() => openMenu(Routing.PatientsDashboard.path)} className="btn btn-primary">
            Click Here
          </a>
        </div> */}
      </div>
    </div>
  );
};

function getTokenExpirationDate(encodedToken) {
  try {
    if (encodedToken) {
      const token = decode(encodedToken);
      if (!token.exp) {
        return null;
      }
      const date = new Date(0);
      date.setUTCSeconds(token.exp);
      return date;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export function clearAccessToken() {
  localStorage.removeItem('accessToken');
  localStorage.clear();
}

export default () => {
  const [switchOn, setSwitchOn] = useState(false);

  const handleSwitchChange = () => {
    setSwitchOn(!switchOn);
  };
  const history = useHistory();

  const redirectUrl = (url) => {
    history.push(url);
  };

  const adminAuthReducer = useSelector((state) => ({
    adminUser: state.adminAuthReducer.adminUser,
  }));

  return (
    <>
      <div className="py-4"></div>
      <h3>Welcome  <b>{(adminAuthReducer?.adminUser?.Role?.title && adminAuthReducer.adminUser.Role.title)}</b></h3>
      {/* {[2, 3, 5].includes(adminAuthReducer?.adminUser?.Role?.id) && (
        <Form.Check // prettier-ignore
          type="switch"
          id="custom-switch"
          label=""
          role="button"
          tabIndex={0}
          className="mb-2"
          checked={switchOn}
          onChange={handleSwitchChange}
        />
      )} */}

      <Row className="justify-content-lg-center">
        <Col xs={12} className="mb-4">
          {/* {switchOn || [1].includes(adminAuthReducer?.adminUser?.Role?.id) ? (
            <SalesValueWidget title="Medtrik" value="0" percentage={0} />
          ) */}
          {switchOn ? (
            <SalesValueWidget title="Medtrik" value="0" percentage={0} />
          )  : (
            (() => {
              const roleId = adminAuthReducer?.adminUser?.Role?.id;

              // #412: REMOVE MENU FOR SUPERADMIN USERS
              if (roleId === 1) {
                // Role 1 (super admin) => clinic
                return (
                  <div className="row" style={{ minHeight: 250 }}>
                    {/* <ClinicDashboard /> */}
                  </div>
                );
              } else if (roleId === 2) {
                // Role 2 (chain manager) => finance, patient, doctor, clinic
                return (
                  <div className="row">
                    <FinanceDashboard />
                    <PatientDashboard />
                    <DoctorDashboard />
                    <ClinicDashboard />
                  </div>
                );
              } else if (roleId === 3) {
                // Role 3 (clinic manager) => finance, patient, doctor, clinic
                return (
                  <div className="row">
                    <FinanceDashboard />
                    <PatientDashboard />
                    <DoctorDashboard />
                    <ClinicDashboard />
                  </div>
                );
              } else if (roleId === 4) {
                // Role 4 (doctor) => doctor
                return (
                  <div className="row">
                    <DoctorDashboard />
                  </div>
                );
              } else if (roleId === 5) {
                // Role 5 (admin executive) => clinic
                return (
                  <div className="row">
                    <ClinicDashboard />
                  </div>
                );
              } else {
                return null;
              }
            })()
          )}
        </Col>
      </Row>
    </>
  );
};
