// styles
let commonFontClass = {
  fontFamily: "Poppins",
  fontWeight: "500",
  color: "#000000",
  colors: ["#000000"],
}

let titleFontClass = {
  ...commonFontClass,
  fontSize: "32px",
  color: "#2F4858",
}
let subTitleFontClass = {
  ...commonFontClass,
  fontSize: "18px",
  color: "#2F4858",
}
let legendFontClass = {
  ...commonFontClass,
  fontSize: "18px",
}
let xaxisTitleFontClass = {
  ...commonFontClass,
  fontSize: "18px",
}
let yaxisTitleFontClass = {
  ...commonFontClass,
  fontSize: "18px",
}
let dataLabelFontClass = {
  ...commonFontClass,
  fontSize: "18px",
}
let labelFontClass = {
  ...commonFontClass,
  fontSize: "15px",
  fontWeight: "600",
}

// options
global.window.Apex = {
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: true,
      zoomedArea: {
        fill: {
          opacity: 0,
        },
        stroke: {
          opacity: 0,
        },
      },
    },
  },
  colors: ["#CECED0", "#E9EAEB", "transparent"],
  plotOptions: {},
  xaxis: {
    show: true,
    labels: {
      style: labelFontClass,
    },
    axisBorder: {
      show: true,
      color: "#000000",
      width: "100%",
      height: 2,
    },
    title: {
      text: "",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#000000",
        ...xaxisTitleFontClass,
      },
    },
  },
  yaxis: {
    show: true,
    axisBorder: {
      show: true,
      color: "#000000",
      width: 2,
    },
    title: {
      style: {
        color: "#000000",
        ...yaxisTitleFontClass,
      },
    },
  },
  title: {
    text: "",
    align: "left",
    offsetX: 20,
    offsetY: 10,
    floating: false,
    style: titleFontClass,
  },
  subtitle: {
    text: "",
    align: "left",
    offsetX: 40,
    offsetY: 50,
    floating: false,
    style: subTitleFontClass,
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: true,
    style: dataLabelFontClass,
  },
  legend: {
    ...legendFontClass,
    position: "right",
    horizontalAlign: "right",
    offsetY: 70,
  },
}
