import { SYSTEM_CURRENCY, SYSTEM_CURRENCY_PRECESION, SYSTEM_LOCALE } from "constants/common"

export const parseAmount = (
  amount = "",
  prefixDelimeter = "$",
  suffixDelimeter = "K"
) => {
  let prefixed = ""
  let suffixed = ""
  amount = amount + ""
  if (amount.startsWith(prefixDelimeter)) {
    amount = amount.slice(1)
    prefixed = prefixDelimeter
  }
  if (amount.endsWith(suffixDelimeter)) {
    amount = amount.slice(0, amount.length - 1)
    suffixed = suffixDelimeter
  }
  return { amount: trimAmount(parseFloat(amount)), prefixed, suffixed }
}

export const formatAmount = (amount) => {
  return new Intl.NumberFormat(SYSTEM_LOCALE, { style: 'currency', currency: SYSTEM_CURRENCY }).format(amount)
}

export const formatNumber = (amount) => {
  return new Intl.NumberFormat(SYSTEM_LOCALE).format(amount)
}

export const trimAmount = (amount, precision) => {
  let base = precision ?? SYSTEM_CURRENCY_PRECESION * 10
  return Math.round((amount + Number.EPSILON) * base) / base
}

export const parseDate = () => {
  return new Date()
}
export const parsePercentage = (percent = "", suffixDelimeter = "%") => {
  let suffixed = ""
  if (percent.endsWith(suffixDelimeter)) {
    percent = percent.slice(0, percent.length - 1)
    suffixed = suffixDelimeter
  }
  return { percent: parseFloat(percent), suffixed }
}

export const numberWithCommas = (string) => {
  if (string || string === 0) {
    var parts = string.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? parts[1].length > 1 ? `.${parts[1]}` : `.${parts[1]}0` : ".00");
  } else {
    return 0
  }
}

export const roundToTwo = (num) => {
  return num ? +(Math.round(num + "e+2")  + "e-2") : 0;
}
