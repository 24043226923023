import React, { useState, useSelector } from "react";
import { Route, Redirect } from "react-router-dom";
import decode from "jwt-decode";

// Widgets
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Navbar/AfterLogin";
import AccessRestricted from "pages/examples/AccessRestricted";
import { useAllowAccess } from "hooks/useAllowAccess";

export function isLoggedIn() {
  const accessToken = getAccessToken();
  return !!accessToken && !isTokenExpired(accessToken);
}

export function getAccessToken() {
  return localStorage.getItem("accessToken");
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  if (expirationDate < new Date()) {
    clearAccessToken();
  }
  return expirationDate < new Date();
}

function getTokenExpirationDate(encodedToken) {
  try {
    if (encodedToken) {
      const token = decode(encodedToken);
      if (!token.exp) {
        return null;
      }
      const date = new Date(0);
      date.setUTCSeconds(token.exp);
      return date;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export function clearAccessToken() {
  localStorage.removeItem("accessToken");
  localStorage.clear();
}

function PrivateRoute({ component: Component, roles, componentProps, showSidebar = true, ...rest }) {
  const resize = () => {
    var resize = setInterval(() => {
      window.dispatchEvent(new Event("resize"));
    }, 10);

    setTimeout(function () {
      clearInterval(resize);
    }, 301);
  };

  const localStorageIsContracted = () => {
    return localStorage.getItem("sidebarContracted") === "false" ? false : true;
  };

  const [contracted, setContracted] = useState(localStorageIsContracted());
  const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());

  const toggleMouseOver = () => {
    if (contracted) {
      setContractSidebar(!contractSidebar);
    }
    resize();
  };

  const toggleContracted = () => {
    setContracted(!contracted);
    setContractSidebar(!contracted);
    localStorage.setItem("sidebarContracted", !contracted);
    resize();
  };

  if (!useAllowAccess(roles) && isLoggedIn()) return <AccessRestricted />;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn()) {
          // getUserRole();
          // not logged in so redirect to login page with the return url
          return (
            <>
              {showSidebar === false ? (
                <main className="content">
                  <Component {...props} {...componentProps} />
                </main>
              ) : (
                <>
                  <Sidebar contracted={contractSidebar} onMouseEnter={toggleMouseOver} onMouseLeave={toggleMouseOver} />
                  <main className="content">
                    <Topbar toggleContracted={toggleContracted} />
                    <Component {...props} {...componentProps} />
                  </main>
                </>
              )}
            </>
          );
        }

        // authorized so return component
        return <Redirect push to={{ pathname: "/", state: { from: props.location } }} />;
      }}
    />
  );
}

export { PrivateRoute };
