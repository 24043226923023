import { useMemo } from "react"
import { Col, Row } from "react-bootstrap"
import { Link, useRouteMatch } from "react-router-dom"



const SlotConfirmed = () => {
    const routeMatch = useRouteMatch()
    const parentRoute = useMemo(() => routeMatch.path.split("/")[1], [routeMatch.path])

    return (
        <section>
            <div className="app-font-class-0" style={{fontWeight: "700"}}>Appointment Confirmed</div>
            <div style={{position: "relative", top: "4rem"}}>
                <div className="d-flex justify-content-center" >
                    <table className="btable table-borderless table-responsive">
                        <tr>
                            <td className="text-end p-1" style={{whiteSpace:"nowrap"}}>Date of Appointment:</td>
                            <td className="p-1">14/9/21</td>
                        </tr>
                        <tr>
                            <td className="text-end p-1">Timeslot:</td>
                            <td className="p-1">1000 - 1045</td>
                        </tr>
                        <tr>
                            <td className="text-end p-1">Doctor:</td>
                            <td className="p-1">Lee</td>
                        </tr>
                    </table>
                </div>
                <div className="d-flex flex-row-reverse" style={{position: "relative", top: "4rem"}}>
                    <Link to={`/${parentRoute}`}>
                        <button className="btn" style={{backgroundColor: "#FFFFFF", color: "#00657B", border: "1px solid #00657B"}}>New Appointment</button>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default SlotConfirmed