import {
  ADMIN_INPROGRESS,
  ADMIN_DONEPROGRESS,
  ADMIN_ERROR
} from "../constants/actionsTypes"
import initialState from "./initialState"

export default function appReducer(
  state = initialState.appReducer,
  action
) {
  switch (action.type) {
    case ADMIN_INPROGRESS:
      return Object.assign({}, state, {
        loading: true,
        error_msg: null,
        success_msg: null,
      })
    case ADMIN_DONEPROGRESS:
      return Object.assign({}, state, {
        loading: false,
        error_msg: null,
        success_msg: null
      })
    case ADMIN_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error_msg: null,
        success_msg: null
      })
    default:
      return Object.assign({}, state, {
        loading: false
      })
  }
}
