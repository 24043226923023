import React, { useState, useEffect } from "react";
import _ from "lodash";
import { HomeIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Breadcrumb } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { Routing } from "routes";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as PatientsActions from "actions/patientActions";
import * as AuthActions from "actions/authActions";
import { useChainId } from "hooks/useChainId";
import Select, { components } from "react-select";
import { Checkbox } from "@mui/material";
import {MONTH_SLIDER} from "constants/date"
import MonthSlider from "components/filters/MonthSlider";
import { format, parse, minTime} from "date-fns";
import { SYSTEM_DATE_DISPLAY_FORMAT2_DF, SYSTEM_DATE_FORMAT_DF } from "constants/common";
import Loader from "../../Loader";


//additional for new conversion graph
import * as ReportActions from "actions/reportActions";
import ConversionChart from "../ConversionChart";
//

import "../style.css";
import GeographicDistributionOfPatients from "./GeographicDistributionOfPatients";
import PatientDemographic from "./PatientDemographic";
import FirstTreatmentWithDentalClinic from "./FirstTreatmentWithDentalClinic";
import NationalityDistribution from "./NationalityDistribution";
import OccupationDistribution from "./OccupationDistribution";
import ReferralSource from "./ReferralSource";
import FrequentDoctors from "./FrequentDoctors";
import MaritalStatus from "./MaritalStatus";
//for filters
const Option = (props) => {
  return (
    <div>
      <components.Option {...props} className="react-select-option">
        <Checkbox
          checked={props.isSelected}
          color="default"
          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const PatientDashboard = (props) => {
  const [treatmentFilter, setTreatmentFilter] = useState([]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [treatmentIds, setTreatmentIds] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({});
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [doctorFilter, setDoctorFilter] = useState([]);
  const [doctorIds, setDoctorIds] = useState([]);
  const [segmentFilter, setSegmentFilter] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [segmentIds, setSegmentIds] = useState([]);
  const [clinicFilter, setClinicFilter] = useState([]);
  const [selectedClinics, setSelectedClinics] = useState([]);
  const [clinicIds, setClinicIds] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(
    props.selectedComponent
  );
  const chainId = useChainId();
  const history = useHistory();
  const redirectUrl = (url) => {
    history.push(url);
  };

  useEffect(() => {
    props.actions.PatientsActions.getPatientDashboard(chainId);
    props.actions.PatientsActions.getPatientTreatments(chainId);
    props.actions.PatientsActions.getPatientDoctors();
  }, []);
  
  useEffect(() => {
    if (chainId) {
      props.actions.AuthActions.getClinics(chainId);
      props.actions.PatientsActions.getPatientSegments(chainId);
    }
  }, [chainId]);

  useEffect(() => {
    let treatmentFilter = [
      { value: "All Treatments", label: "All Treatments" },
    ];
    props.treatments.forEach((treatment) => {
      treatmentFilter.push({
        value: treatment.treatmentId,
        label: treatment.treatmentName,
      });
    });
    setTreatmentFilter(treatmentFilter);
    handleTreatmentChange([
      { value: "All Treatments", label: "All Treatments" },
    ]);
  }, [props.treatments]);

  const handleTreatmentChange = (selectedTreatments) => {
    let selectedTreatmentIds = selectedTreatments.map(
      (selectedTreatment) => selectedTreatment.value
    );
    if (selectedTreatmentIds.includes("All Treatments")) {
      selectedTreatmentIds = props.treatments.map(
        (treatment) => treatment.treatmentId
      );
      setSelectedTreatments([
        { value: "All Treatments", label: "All Treatments" },
      ]);
      setTreatmentIds([]);
    } else {
      setSelectedTreatments(selectedTreatments);
      setTreatmentIds(selectedTreatmentIds);
    }
  };

  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  useEffect(() => {
    let doctorFilter = [{ value: "All Doctors", label: "All Doctors" }];
    props.doctors.forEach((doctor) => {
      doctorFilter.push({ value: doctor.doctorId, label: doctor.name });
    });
    setDoctorFilter(doctorFilter);
    handleDoctorChange([{ value: "All Doctors", label: "All Doctors" }]);
  }, [props.doctors]);

  const handleDoctorChange = (selectedDoctors) => {
    let selectedDoctorIds = selectedDoctors.map(
      (selectedDoctor) => selectedDoctor.value
    );
    if (selectedDoctorIds.includes("All Doctors")) {
      selectedDoctorIds = props.doctors.map((doctor) => doctor.doctorId);
      setSelectedDoctors([{ value: "All Doctors", label: "All Doctors" }]);
      setDoctorIds([]);
    } else {
      setSelectedDoctors(selectedDoctors);
      setDoctorIds(selectedDoctorIds);
    }
  };

  useEffect(() => {
    let segmentFilter = [{ value: "All Segments", label: "All Segments" }];
    props.segments.forEach((segment) => {
      segmentFilter.push({
        value: segment.segmentId,
        label: segment.segmentName,
      });
    });
    setSegmentFilter(segmentFilter);
    handleSegmentChange([{ value: "All Segments", label: "All Segments" }]);
  }, [props.segments]);

  const handleSegmentChange = (selectedSegments) => {
    let selectedSegmentIds = selectedSegments.map(
      (selectedSegment) => selectedSegment.label
    );
    if (selectedSegmentIds.includes("All Segments")) {
      selectedSegmentIds = props.segments.map((segment) => segment.segmentName);
      setSelectedSegments([{ value: "All Segments", label: "All Segments" }]);
      setSegmentIds([]);
    } else {
      setSelectedSegments(selectedSegments);
      setSegmentIds(selectedSegmentIds);
    }
  };

  useEffect(() => {
    let clinicArray = [];
    if (props.user.Role.id == 3 && props.user.clinic) {
      props.clinics.forEach((clinic) => {
        if (props.user.clinic.clinicId == clinic.clinicId)
          clinicArray.push({ value: clinic.clinicId, label: clinic.clinicId });
      });
    } else {
      clinicArray = [{ value: "All Clinics", label: "All Clinics" }];
      props.clinics.forEach((clinic) => {
        clinicArray.push({ value: clinic.clinicId, label: clinic.clinicId });
      });
      setClinicFilter(clinicArray);
    }
    handleClinicChange(clinicArray);
  }, [props.clinics]);

  const handleClinicChange = (selectedClinics) => {
    let selectedClinicIds = selectedClinics.map((selectedClinic) =>
      selectedClinic.value.toUpperCase()
    );
    if (selectedClinicIds.includes("ALL CLINICS")) {
      selectedClinicIds = props.clinics.map((clinic) =>
        clinic.clinicId.toUpperCase()
      );
      setSelectedClinics([{ value: "All Clinics", label: "All Clinics" }]);
    } else {
      setSelectedClinics(selectedClinics);
    }
    setClinicIds(selectedClinicIds);
  };

  const componentMapping = {
    "Geographic Distribution of Patients": GeographicDistributionOfPatients,
    "Patient Demographic": PatientDemographic,
    "First Treatment with Dental Clinic": FirstTreatmentWithDentalClinic,
    "Nationality Distribution": NationalityDistribution,
    "Occupation Distribution": OccupationDistribution,
    "Referral Source": ReferralSource,
    "Frequent Doctors": FrequentDoctors,
    "Marital Status": MaritalStatus,
    "Conversion Chart": ConversionChart
  };

  const getSelectedComponent = (componentName, isEmbedded = false) => {
    if (componentName=='Conversion Chart'){
      const SelectedComponent = componentMapping[componentName];
      return <SelectedComponent type='branch' dashboarddate={selectedDateRange} branch = {clinicIds} doctors = {doctorIds} isEmbedded={true}/>
    } else {
      const SelectedComponent = componentMapping[componentName];
      return <SelectedComponent {...props} isEmbedded={isEmbedded} />;
    }
  };

  const goBackToDashboard = () => {
    window.history.replaceState(null, "Medtrik", "/patient/dashboard");
    setSelectedComponent(null);
  };

  useEffect(() => {
    if(
      !_.size(clinicIds) ||
      !_.size(selectedDateRange) ||
      !_.size(selectedTreatments) ||
      !_.size(selectedDoctors) ||
      !_.size(selectedSegments) ||
      !props.demographicData ||
      !props.firstTreatmentData ||
      !props.frequentDoctors ||
      !props.geographicDistribution ||
      !props.maritalStatus ||
      !props.nationalityDistribution ||
      !props.occupationDistribution ||
      !props.referralSource
    ) return;
    callAPIs();
  }, [treatmentIds, segmentIds, clinicIds, doctorIds, selectedDateRange]);

  useEffect(() => {
    if(
      !_.size(clinicIds) ||
      !_.size(selectedDateRange) ||
      _.size(props.demographicData) ||
      _.size(props.firstTreatmentData) ||
      _.size(props.frequentDoctors) ||
      _.size(props.geographicDistribution) ||
      _.size(props.maritalStatus) ||
      _.size(props.nationalityDistribution) ||
      _.size(props.occupationDistribution) ||
      _.size(props.referralSource)
    ) return;
    callAPIs();
  }, [clinicIds]);

  const callAPIs = () => {
    props.actions.PatientsActions.getGeoGraphicDistributionOfPatient(
      chainId,
      treatmentIds,
      doctorIds,
      segmentIds,
      clinicIds,
      selectedDateRange.startDate,
      selectedDateRange.endDate,
    );
    props.actions.PatientsActions.getPatientDemographic(
      chainId,
      treatmentIds,
      doctorIds,
      segmentIds,
      clinicIds,
      selectedDateRange.startDate,
      selectedDateRange.endDate,
    );
    props.actions.PatientsActions.getFirstTreatment(
      chainId,
      treatmentIds,
      doctorIds,
      segmentIds,
      clinicIds,
      selectedDateRange.startDate,
      selectedDateRange.endDate,
    );
    props.actions.PatientsActions.getNationalityDistribution(
      chainId,
      treatmentIds,
      doctorIds,
      segmentIds,
      clinicIds,
      selectedDateRange.startDate,
      selectedDateRange.endDate,
    );
    props.actions.PatientsActions.getOccupationDistribution(
      chainId,
      treatmentIds,
      doctorIds,
      segmentIds,
      clinicIds,
      selectedDateRange.startDate,
      selectedDateRange.endDate,
    );
    props.actions.PatientsActions.getReferralSource(
      chainId,
      treatmentIds,
      doctorIds,
      segmentIds,
      clinicIds,
      selectedDateRange.startDate,
      selectedDateRange.endDate,
    );
    props.actions.PatientsActions.getFrequentDoctors(
      chainId,
      treatmentIds,
      segmentIds,
      clinicIds,
      selectedDateRange.startDate,
      selectedDateRange.endDate,
    );
    props.actions.PatientsActions.getMaritalStatus(
      chainId,
      treatmentIds,
      doctorIds,
      segmentIds,
      clinicIds,
      selectedDateRange.startDate,
      selectedDateRange.endDate,
    );
  };

  return (
    <>
      {(!_.size(props.demographicData) &&
      !_.size(props.firstTreatmentData) &&
      !_.size(props.frequentDoctors) &&
      !_.size(props.geographicDistribution) &&
      !_.size(props.maritalStatus) &&
      !_.size(props.nationalityDistribution) &&
      !_.size(props.occupationDistribution) &&
      !_.size(props.referralSource)) && <Loader/>}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item
              onClick={() => redirectUrl(Routing.Dashboard.path)}
            >
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            {selectedComponent ? (
              <>
                <Breadcrumb.Item onClick={goBackToDashboard}>
                  Patient Dashboard
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{selectedComponent}</Breadcrumb.Item>
              </>
            ) : (
              <Breadcrumb.Item active>Patient Dashboard</Breadcrumb.Item>
            )}
          </Breadcrumb>
          <h4>Patient Dashboard</h4>
        </div>
      </div>
      <Card border="0" className="table-wrapper table-responsive shadow">
        <Card.Body>
          <Row className="patient-dashboard">
            <Col xs={12}>
              <Row className="mb-3 pb-3">
                <Col xs={12} lg={6}></Col>
                <Col
                  xs={12}
                  lg={6}
                  className="justify-content-end text-end mb-3 px-4"
                >
                  <h6 className="m-0">Selected Month Range {selectedDateRange?.startDate && format(parse(selectedDateRange.startDate, SYSTEM_DATE_FORMAT_DF, minTime), SYSTEM_DATE_DISPLAY_FORMAT2_DF)} - {selectedDateRange?.endDate && format(parse(selectedDateRange.endDate, SYSTEM_DATE_FORMAT_DF, minTime), SYSTEM_DATE_DISPLAY_FORMAT2_DF)}</h6>
                  <MonthSlider {...Object.assign({}, MONTH_SLIDER, {display: true, type: 2, max: 24})} handleMonthChange={handleDateRangeChange} />
                </Col>
                <Col xs={12} lg={3}>
                  <Select
                    value={selectedTreatments}
                    className="medtrik-dropdown"
                    placeholder="Select Treatment Type(s)..."
                    isMulti
                    components={{ Option }}
                    onChange={handleTreatmentChange}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={treatmentFilter}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                  />
                </Col>
                <Col xs={12} lg={3}>
                  <Select
                    value={selectedDoctors}
                    className="medtrik-dropdown"
                    placeholder="Select Doctor(s)..."
                    isMulti
                    components={{ Option }}
                    onChange={handleDoctorChange}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={doctorFilter}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                  />
                </Col>
                <Col xs={12} lg={3}>
                  <Select
                    value={selectedSegments}
                    className="medtrik-dropdown"
                    placeholder="Select Segment(s)..."
                    isMulti
                    components={{ Option }}
                    onChange={handleSegmentChange}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={segmentFilter}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                  />
                </Col>
                <Col xs={12} lg={3}>
                  {props.user.Role.id != 3 && (
                    <Select
                      value={selectedClinics}
                      className="medtrik-dropdown"
                      placeholder="Select Clinic(s)..."
                      isMulti
                      components={{ Option }}
                      onChange={handleClinicChange}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      options={clinicFilter}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            {selectedComponent && getSelectedComponent(selectedComponent)}
            {!selectedComponent &&
              props.dashboardData &&
              props.dashboardData.map((patientCategory, index) => {
                return (
                  <Col
                    xs={12}
                    lg={
                      patientCategory === "Geographic Distribution of Patients" ||
                      patientCategory === "First Treatment with Dental Clinic"
                        ? 12
                        : 6
                    }
                    key={index}
                  >
                  {/* <p>{patientCategory}{index}</p> */}
                    <div
                      className="mb-4"
                      onClick={() => setSelectedComponent(patientCategory)}
                    >
                      <div className="pill-container px-4 pt-3">
                        <div className="pill-text mb-3">{patientCategory}</div>
                        {getSelectedComponent(patientCategory, true)}
                      </div>
                    </div>
                  </Col>
                );
              })}
              {selectedComponent?null:
              <div className="mb-4" onClick={() => setSelectedComponent('Conversion Chart')}>
                <ConversionChart type = 'branch' dashboarddate={selectedDateRange} doctors={doctorIds} branch={clinicIds} isEmbedded={false}></ConversionChart>
              </div>
              }
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
const mapStateToProps = (state) => {
  const { adminAuthReducer, patientReducer } = state;
  return {
    user: adminAuthReducer.adminUser,
    dashboardData: patientReducer.dashboardData,
    demographicData: patientReducer.demographicData,
    doctors: patientReducer.doctors,
    firstTreatmentData: patientReducer.firstTreatmentData,
    frequentDoctors: patientReducer.frequentDoctors,
    geographicDistribution: patientReducer.geographicDistribution,
    maritalStatus: patientReducer.maritalStatus,
    nationalityDistribution: patientReducer.nationalityDistribution,
    occupationDistribution: patientReducer.occupationDistribution,
    referralSource: patientReducer.referralSource,
    segments: patientReducer.segments,
    treatments: patientReducer.treatments,
    clinics: adminAuthReducer.clinics,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    PatientsActions: bindActionCreators(PatientsActions, dispatch),
    AuthActions: bindActionCreators(AuthActions, dispatch),

    //
    ReportActions: bindActionCreators(ReportActions, dispatch),
    //
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(PatientDashboard);
