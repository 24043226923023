import React, { useEffect, useState } from "react";
import { Form, Card, Button, Row, Col, Table } from "react-bootstrap";

import { Routing } from "routes";
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import * as kpiPlannerAction from "actions/kpiPlannerAction";

import jwt_decode from "jwt-decode";
import Box from '@mui/material/Box';
import { Grid, Slider, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles"
import { SettingsEthernetSharp, SettingsInputAntenna } from "@mui/icons-material";
import ENVIRONMENT_VARIABLES from "config";
import axios from "axios";
import { sub } from "date-fns";
import Loader from "components/Loader"
import _ from "lodash";
import swal from 'sweetalert';
import { numberWithCommas, roundToTwo } from "utils/parser.js"
import {
    KPI_STATUS_CONFIRMED,
    KPI_STATUS_NOT_STARTED,
    KPI_STATUS_PROPOSED,
    KPI_STATUS_TO_CONFIRM
} from "../../constants/actionsTypes";
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
    root: {
        "& .Mui-active .MuiStepIcon-root": { color: "white" },
        "& .Mui-completed .MuiStepIcon-root": { color: "#2f4858" },
        "& .Mui-disabled .MuiStepIcon-root": { color: "#dfe4ed" },
        "& .MuiStepLabel-root .MuiStepLabel-label": { fontFamily: 'Poppins', fontSize: '1em', paddingBottom: '1em', fontWeight: '500', color: '#2f4858' }
    },
    cols: {
        paddingLeft: '1em',
        paddingRight: '2em'
    },
    label: {
        fontFamily: 'Poppins',
        fontSize: "1em",
        fontWeight: "500"
    },
    tryial: {
        color: "green"
    },
    stepper: {

        iconColor: "yellow",
        hoveredIconColor: "grey",
        inactiveIconColor: "black",
        iconTextColor: "red",
        hoveredIconTextColor: "gold",
        inactiveIconTextColor: "pink",

    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        backgroundColor: 'white',


    },

}));

const ChainLevel = ({ kpiProgress, step, setName, nextStep, handleFormData, values, chainPropose, getReferenceChain, getAllocateChain, getChainKpiPlanningData, viewMode, chainId, year }) => {
    const classes = useStyles();
    const history = useHistory();
    const steps = [
        'Propose Chain Target',
        'Propose Clinic Target',
        'Propose Doctor Target',
        'Confirm Doctor Target',
        'Confirm Clinic Target',
        'Confirm Chain Target'
    ];

    const initClinicData = (clinics) => {
        const clinicInputs = {}

        if (!clinics?.length) {
            return clinicInputs
        }

        clinics.forEach(each => {
            clinicInputs[each.clinicId] = {
                clinicId: each.clinicId,
                referenceAmount: each.reference,
                targetAmount: +(each.target).toFixed(2),
                percentage: each.percentage,
            };
        })
        return clinicInputs
    }

    const reference = useSelector(state => state.kpiPlannerReducer.referencesChain);
    const allocate = useSelector(state => state.kpiPlannerReducer.allocatedByChain.allocateBy);
    const chainKPIData = useSelector(state => state.kpiPlannerReducer.chainKPIData);
    const [ranges, setRanges] = useState(chainKPIData ? chainKPIData.clinics.map(item => { return { [item.clinicName]: 0 } }) : {});
    const initClinicInputData = initClinicData(chainKPIData?.clinics);
    const [clinicInputData, setClinicInputData] = useState(initClinicInputData);
    const [inputTarget, setInputTarget] = useState(chainKPIData?.target);
    const [optionReference, setOptionReference] = useState(chainKPIData?.reference?._id);
    const [allocateBy, setAllocateBy] = useState(chainKPIData?.allocate?._id);
    const [errors, setErrors] = useState({
        inputAmount: null,
        referenceId: null,
        allocateId: null
    });
    const disable = false;

    const queryParams = new URLSearchParams(window.location.search);
    const confirmMode = queryParams.get('confirmMode');

    const [loading, setLoading] = useState(true)

    const handleRangeChanges = (e) => {
        let { name, value } = e.target;

        if (!inputTarget) {
            return
        }

        let tempClinicInputData = clinicInputData

        if (!clinicInputData || !Object.keys(clinicInputData)?.length) {
            tempClinicInputData = initClinicInputData
        }

        let targetAmount

        if (value >= 0) {
            targetAmount = +(inputTarget * value / 100).toFixed(2)
        }

        setRanges({
            ...ranges,
            [name]: value
        })

        setClinicInputData({
            ...tempClinicInputData,
            [name]: {
                ...tempClinicInputData[name],
                percentage: value,
                targetAmount,
            }
        })
    }

    // #299: add onChange function for new form control
    const handleTargetAmountChanges = (name, value) => {
        if (!inputTarget) {
          return;
        }
    
        let tempClinicInputData = clinicInputData;
    
        if (!clinicInputData || !Object.keys(clinicInputData)?.length) {
          tempClinicInputData = initClinicInputData;
        }

        let targetAmount = +(Number(value) || 0).toFixed(2);
    
        let percentage;
        if (targetAmount >= 0) {
          percentage = +(((targetAmount * 100) / inputTarget).toFixed())
        }
    
        setRanges({
          ...ranges,
          [name]: percentage,
        });
    
        setClinicInputData({
          ...tempClinicInputData,
          [name]: {
            ...tempClinicInputData[name],
            targetAmount: targetAmount,
            percentage: percentage,
          },
        });
    
        setAllocateBy("4");
      };

    const handleInputTargetChange = (e) => {
        let { value } = e.target

        if (!clinicInputData || !Object.keys(clinicInputData)?.length) {
            setClinicInputData(initClinicInputData)
        }

        let tempClinicInputData = clinicInputData


        if (!clinicInputData || !Object.keys(clinicInputData)?.length) {
            tempClinicInputData = initClinicInputData
        }

        for (const clinicId in tempClinicInputData) {
            const { percentage } = tempClinicInputData[clinicId]

            tempClinicInputData[clinicId].targetAmount = +(percentage * value / 100).toFixed(2)
        }

        setClinicInputData(tempClinicInputData)
        setInputTarget(value)
    }

    const marks = [
        {
            label: '-100%',
            value: 0
        },

        {
            value: 100,
            label: 'Max',
        },
    ];

    const datas = {
        "chainId": chainKPIData?.chainId,
        "referenceId": Number(optionReference),
        "allocateId": Number(allocateBy),
        "referenceAmount": chainKPIData?.referenceAmount,
        "inputAmount": Number(inputTarget),
        "clinics": [],
        "kpiStatusId": KPI_STATUS_CONFIRMED,
        "_id": chainKPIData?._id,
        year
    }

    const validateFormData = (data) => {
        let errors = {};
        let formIsValid = true;
        // inputAmount

        if (!data?.inputAmount) {
            errors.inputAmount = "Please type target amount"
        }

        if (data?.inputAmount && typeof data?.inputAmount !== 'number') {
            errors.inputAmount = "target amount must be a number"
        }

        // referenceId

        if (!data?.referenceId) {
            errors.referenceId = "Please select reference"
        }

        // allocateId

        if (!data?.allocateId) {
            errors.allocateId = "Please select allocation"
        }

        if (Object.keys(errors)?.length) {
            formIsValid = false;
            setErrors(errors)
        }

        return formIsValid
    }

    const prevStep1=()=>{
        history.push(Routing.KpiPlanner.path);
        // nextStep();
    }

    const submitFormData = (e) => {
        const clinicFormData = []

        for (const name in clinicInputData) {
            clinicFormData.push(clinicInputData[name])
        }

        datas.clinics = clinicFormData

        e.preventDefault();

        if (validateFormData(datas)) {
            setLoading(true);
            kpiPlannerAction.postUpdateChainKpiConfirm(datas).then(response => {
                setLoading(false)
                if (response) {
                    swal("Confirm Chain KPI is successfully!", {
                        icon: "success",
                    }).then(() => {
                        prevStep1()
                    });
                }
            })
        }
    }


    const handleChangeOptionsReference = (e) => {
        const optionReference = e.target.value
        setLoading(true)
        setOptionReference(optionReference)
    }

    const handleFindTotalTarget = (clinicInputData) => {
        if (!_.isEmpty(clinicInputData)) {
            let total = 0
            for (const key in clinicInputData) {
                total = total + clinicInputData[key].targetAmount
            }
            return '$' + numberWithCommas(roundToTwo(total))
        } else {
            return '$0'
        }
    }

    useEffect(() => {
        if (optionReference) {
            const queryParams = new URLSearchParams(window.location.search);
            const _id = queryParams.get('_id');
            getChainKpiPlanningData(chainId, optionReference, Number(_id), year);
        }
    }, [optionReference])

    useEffect(() => {
        setName(chainKPIData?.chainId);

        getAllocateChain(chainId, 0);
        getReferenceChain(chainId, 0);
    }, []);

    useEffect(() => {
        if (viewMode) {
            const queryParams = new URLSearchParams(window.location.search);
            const _id = queryParams.get('_id');
            getChainKpiPlanningData(chainId, null, Number(_id), year);
        }
    }, [viewMode])

    useEffect(() => {
        if (!_.isEmpty(chainKPIData)) {
            setLoading(false)
            setInputTarget(chainKPIData?.target)
            setOptionReference(chainKPIData?.reference?._id)
            setAllocateBy(chainKPIData?.allocate?._id)
            setClinicInputData(initClinicData(chainKPIData?.clinics))
        }
    }, [chainKPIData])

    const confirmKpi = (id) => {
        swal("Confirm this KPI?", {
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                setLoading(true);
                kpiPlannerAction.confirmKpi(id).then(response => {
                    setLoading(false)
                    if (response) {
                        swal(response.message, {
                            icon: "success",
                        }).then(() => {
                            history.push({
                                pathname: Routing.KpiPlanner.path
                            })
                        })
                    }
                })
            }
        });
    }

    if (loading) {
        return <Loader></Loader>
    }

    return (
        <>

            <Box sx={{ width: '100%' }}>
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="wrapper-progressBar">
                            <ul className="progressBar">
                                {steps.map((el, index) =>
                                    <li
                                        key={index}
                                        className={clsx({
                                            'finalize-progress': kpiProgress && kpiProgress[Number(index) + 1],
                                            'selected-progress':
                                                index === 0 && chainKPIData?.status?._id === KPI_STATUS_NOT_STARTED
                                                || index === 5 && chainKPIData?.status?._id === KPI_STATUS_PROPOSED
                                        })}>
                                        {el}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </Box>

            <Card className="table-wrapper table-responsive shadow">
                <Card.Body>
                    <Form>
                        <Row className="justify-content-center chain-level">
                            <Col xs={12} lg={4}>
                                <p> Chain {chainKPIData?.chainId}</p>
                                <Table className="align-items-center">
                                    <tbody key={indexedDB} style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                                    <tr className="table-active">
                                        <td style={{ textAlign: 'left' }}>Reference:</td>
                                        <td>${chainKPIData?.referenceAmount && numberWithCommas(roundToTwo(chainKPIData?.referenceAmount))}</td>
                                        <td></td>
                                    </tr>
                                    <tr className="table-active">
                                        <td style={{ textAlign: 'left' }}>Input Target:</td>
                                        <td >
                                            <Form.Group controlId="inputTarget">

                                                <Form.Control
                                                    type="number"
                                                    required={true}
                                                    value={inputTarget}
                                                    disabled={viewMode || chainKPIData.status._id >= KPI_STATUS_TO_CONFIRM}
                                                    readOnly={true}
                                                    onChange={handleInputTargetChange}
                                                />
                                            </Form.Group>
                                            {
                                                errors?.inputAmount && <span style={{ color: 'red', fontSize: '15px' }}>
                                                        {errors.inputAmount}
                                                    </span>
                                            }

                                        </td>
                                        <td>
                                            {`  >>>   ${handleFindTotalTarget(clinicInputData)}`}
                                        </td>

                                    </tr>
                                    </tbody>

                                </Table>
                            </Col>
                            <Col xs={12} lg={4}></Col>
                            <Col xs={12} lg={4}>
                                <Form.Group id="Reference" className="mb-4">
                                    <Form.Label> Select reference: </Form.Label>
                                    <Form.Select required={true} value={optionReference} onChange={(e) => handleChangeOptionsReference(e)}
                                                 disabled={true}
                                                 className="mb-4" id="financialYears" style={{ fontFamily: "Poppins", fontWeight: '500' }}

                                    >
                                        {disable ? (<option defaultChecked>{reference && reference.references[0].referenceName}</option>) : (
                                            <>
                                                {reference && reference.references.map((item, index) => (

                                                    <option key={index} value={item.referenceId}>{item.referenceName}</option>


                                                ))}
                                            </>

                                        )
                                        }

                                    </Form.Select>
                                    {
                                        errors?.referenceId && <span style={{ color: 'red' }}>
                                            {errors.referenceId}
                                        </span>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row className="justify-content-left clinic-chain-level pt-5 pb-5" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            {chainKPIData && _.sortBy(chainKPIData?.clinics, 'clinicId').map((item, index) => (
                                <Col key={index} xs={12} md={6} lg={6} xl={4} className={classes.cols}>
                                    <Form.Label>{item.clinicId}</Form.Label>
                                    <Table className="align-items-center table-responsive  ">
                                        <tbody style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                                        <tr className="table-active">
                                            <td style={{ textAlign: 'left' }}>Reference:</td>
                                            <td>${numberWithCommas(roundToTwo(item.reference))}</td>
                                        </tr>

                                        <tr className="table-active">
                                            <td style={{ textAlign: 'left' }}>Target:</td>
                                            {/* // #299: adding input field
                                            <td>${numberWithCommas(roundToTwo(clinicInputData[item.clinicId]?.targetAmount))}</td> */}
                                            <td>
                                                <Form.Control
                                                    required={true}
                                                    type="number"
                                                    defaultValue={item.target}
                                                    value={clinicInputData && clinicInputData[item.clinicId] ? clinicInputData[item.clinicId].targetAmount : 0}
                                                    onChange={(e) => handleTargetAmountChanges(item.clinicId, e.target.value)}
                                                    disabled={Number(allocateBy) != 4 || viewMode || chainKPIData.status._id >= KPI_STATUS_TO_CONFIRM}
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>

                                    <Slider
                                        defaultValue={item.inputTargetPercentage}
                                        value={clinicInputData && clinicInputData[item.clinicId] ? clinicInputData[item.clinicId].percentage : 0}
                                        name={item.clinicId}
                                        onChange={handleRangeChanges}
                                        marks={marks}
                                        valueLabelDisplay="auto"
                                        min={0} max={100}
                                        width={"10%"}
                                        className={classes.cols}
                                        disabled={Number(allocateBy) !== 4 || viewMode || chainKPIData.status._id >= KPI_STATUS_TO_CONFIRM}
                                    />
                                </Col>
                            ))}
                        </Row>

                        <Row className="justify-content-center clinic-chain-level pt-1 pb-1" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            <Col xs={12} lg={4}>

                                <Form.Group id="AllocatedBy" className="mb-4">
                                    <Form.Label> Allocate By: </Form.Label>
                                    <Form.Select required={true} value={allocateBy} onChange={(e) => setAllocateBy(e.target.value)}
                                                 disabled={true}
                                                 className="mb-4" id="financialYears">
                                        {disable ? (<option defaultChecked>{allocate && allocate[0].allocateByName}</option>) : (
                                            <>
                                                {allocate && allocate.map((item, index) => (

                                                    <option key={index} value={item.allocateById}>{item.allocateByName}</option>

                                                ))}
                                            </>
                                        )
                                        }


                                    </Form.Select>
                                    {
                                        errors?.allocateId && <span style={{ color: 'red' }}>
                                            {errors.allocateId}
                                        </span>
                                    }
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={8}></Col>
                        </Row>
                        <div style={{ justifyContent: "space-around" }}>
                            <Row className="justify-content-center clinic-chain-level pt-5 pb-5" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                                <Col xs={12} lg={8}></Col>
                                <Col xs={12} lg={2}>
                                    <Table className="align-items-center">
                                        <tbody>
                                        <tr className="table-active">
                                            <td>Total:</td>
                                            <td>{handleFindTotalTarget(clinicInputData)}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs={12} lg={2} className="button-color">
                                    {!viewMode
                                        ?
                                            <Button onClick={submitFormData}
                                                    variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" type="submit"
                                                    disabled={chainKPIData.status._id > KPI_STATUS_TO_CONFIRM}
                                            > Propose </Button>
                                        :
                                            <>
                                                {confirmMode
                                                    ?
                                                        <Button onClick={() => confirmKpi(chainKPIData?._id)}
                                                                variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg"
                                                        > Confirm </Button>
                                                    :
                                                        <Button
                                                            variant="primary"
                                                            onClick={() => {
                                                                history.push({
                                                                    pathname: Routing.KpiPlanner.path,
                                                                })
                                                            }}
                                                            className="animate-up-2 btn btn-gray-800 btn-lg"
                                                            type="submit"
                                                            style={{ marginLeft: "1em" }}
                                                        >
                                                            Back to Dashboard
                                                        </Button>
                                                }
                                            </>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        getReferenceChain: (chainID, clinicID) => dispatch(kpiPlannerAction.getReferenceByChain(chainID, clinicID)),
        getAllocateChain: (chainID, clinicID) => dispatch(kpiPlannerAction.getAllocatedByChain(chainID, clinicID)),
        getChainKpiPlanningData: (chainId, referenceId, _id, year) => dispatch(kpiPlannerAction.getChainKPIPlanningData(chainId, referenceId, _id, year)),
        chainPropose: (data) => dispatch(kpiPlannerAction.postChainProposeKPI(data)),
    }
};

export default connect(null, mapDispatchToProps)(ChainLevel)
