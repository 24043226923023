import axios from "axios"
import {
  ADMIN_INPROGRESS,
  ADMIN_DONEPROGRESS,
  ADMIN_ERROR,
  IS_AUTHENTICATED,
  GET_DR_PR_MONTHLY,
  GET_DR_PR_YEARLY,
  GET_DR_PR_MTD,
  GET_DR_PR_MTD_CURR_YEAR,
  GET_DR_PR_MTD_CURR_VS_PREV_YEAR,
  GET_DR_PR_ROLLING,
  GET_DR_PR_TREATMENTMIX,
  GET_DR_PR_YTD,
  SET_DR_PR_DATE_PERIOD
} from "../constants/actionsTypes"
import ENVIRONMENT_VARIABLES from "../config"
import processRequest from "utils/processRequest"


export const getMonthly = (chainId, doctorId, currentDate, clinicId) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url:
      ENVIRONMENT_VARIABLES.Base_API_URL + "/Doctors/getDoctorMTDSummary",
    data: {
      chainId: chainId,
      doctorId: doctorId,
      todayDate: currentDate,
      clinicId: clinicId
    },
  }
  return processRequest(api,GET_DR_PR_MONTHLY)
}

export const getYearly = (chainId, doctorId, selectedYear, clinicId) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url:
      ENVIRONMENT_VARIABLES.Base_API_URL + "/Doctors/getDoctorYTDSummary",
    data: {
      chainId,
      doctorId,
      selectedYear,
      clinicId
    },
  }
  return processRequest(api, GET_DR_PR_YEARLY)
}

export const getMTDPerformance = (chainId, doctorId, clinicId) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Doctors/getDoctorMTDPerf",
    data: {
      chainId,
      doctorId: doctorId,
      clinicId
    },
  }
  return processRequest(api,GET_DR_PR_MTD)
}

export const getMTDPerformanceCurrentYear = (
  chainId,
  doctorId,
  startDate,
  endDate,
  clinicId
) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url:
      ENVIRONMENT_VARIABLES.Base_API_URL + "/Doctors/getDoctorMTDCurrentYr",
    data: {
      chainId,
      doctorId,
      startDate,
      endDate,
      clinicId
    },
  }
  return processRequest(api, GET_DR_PR_MTD_CURR_YEAR)
}

export const getMTDPerformanceCurrVsPrev = (chainId, doctorId, clinicId) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url:
      ENVIRONMENT_VARIABLES.Base_API_URL +
      "/Doctors/getDoctorMTDCurrentVSPrevYr",
    data: {
      chainId: chainId,
      doctorId: doctorId,
      clinicId: clinicId
    },
  }
  return processRequest(api, GET_DR_PR_MTD_CURR_VS_PREV_YEAR)
}

export const getRolling = (chainId, doctorId, startDate, clinicId) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url:
      ENVIRONMENT_VARIABLES.Base_API_URL +
      "/Doctors/getDoctorLast12MthsRolling",
    data: {
      chainId: chainId,
      doctorId: doctorId,
      startDate: startDate,
      clinicId: clinicId
    },
  }
  return processRequest(api, GET_DR_PR_ROLLING)
}

export const getTreatmentMix = (chainId, doctorId, selectedYear, clinicId) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url:
      ENVIRONMENT_VARIABLES.Base_API_URL +
      "/Doctors/getAllDoctorTreatmentSummary",
    data: {
      chainId,
      doctorId,
      selectedYear,
      clinicId
    },
  }
  return processRequest(api, GET_DR_PR_TREATMENTMIX)
}

export const getYTD = (chainId, doctorId, selectedYear, clinicId) => {
  const token = "Bearer " + localStorage.getItem("accessToken")
  let api = {
    method: "POST",
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Doctors/getDoctorYTDMetric",
    data: {
      chainId,
      doctorId,
      selectedYear,
      clinicId
    },
  }
  return processRequest(api, GET_DR_PR_YTD)
}

export const setDatePeriod = ({type, min, max, step, marks}) => {
  try {
    return (dispatch) => {
      dispatch({
        type: SET_DR_PR_DATE_PERIOD,
        datePeriod: {
          type, min, max, step, marks
        },
      })
    }
  } catch (error) {
    alert(error.message.toString())
  }
}
