// #429: change format to ISO standard to ensure correct calculation at all times
// as per date-fns lib
export const SYSTEM_DATE_FORMAT_DF = "yyyy-MM-dd"
export const SYSTEM_DATE_DISPLAY_FORMAT_DF = "yyyy-MM-dd"
export const SYSTEM_DATE_DISPLAY_FORMAT2_DF = "MMM yyyy"
export const SYSTEM_TIME_FORMAT_DF = "HH/mm/ss"


// currency locale
export const SYSTEM_LOCALE = "en-US"
export const SYSTEM_CURRENCY = "USD"
export const SYSTEM_CURRENCY_PRECESION = 2