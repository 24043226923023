export const Routing = {
  // pages
  Login: { path: '/' },
  userManual: { path: '/user-manual' },
  Account: { path: '/account' },
  CheckTwoFactor: { path: '/check2fa' },
  Dashboard: { path: '/dashboard' },
  Doctors: { path: '/doctors' },
  AddDoctor: { path: '/doctor/add' },
  EditDoctor: { path: '/doctor/edit/:id' },
  KpiClinic: { path: '/kpiclinic' },
  Roles: { path: '/roles' },
  AddRole: { path: '/role/add' },
  EditRole: { path: '/role/edit/:id' },
  Users: { path: '/users' },
  AddUser: { path: '/user/add' },
  EditUser: { path: '/user/edit/:id' },
  Clinic: { path: '/clinics' },
  TwoFactor: { path: '/user/twofactor' },
  PerformanceSummaryReport: { path: '/PerformanceSummaryReport' },
  PasswordRecovery: { path: '/:userId/:uid' },

  // finances
  FinanceDashboard: { path: '/finance/dashboard' },
  RevenueGraph: { path: '/finance/dashboard/revenue-graph' },
  DailyRevenueForMonth: { path: '/finance/dashboard/daily-revenue-for-month' },
  KPIProgressBar: { path: '/finance/dashboard/kpi-progress-bar' },
  PerInstanceOfTreatmentByIC: {
    path: '/finance/dashboard/per-instance-of-treatment-invoice-count',
  },
  PerInstanceOfTreatmentByDTC: {
    path: '/finance/dashboard/per-instance-of-treatment-cycle',
  },
  PerHourOfTreatment: { path: '/finance/dashboard/per-hour-of-treatment' },
  FinanceForecast: { path: '/finance/forecast' },
  FinanceDoctorChairRatioScatterGraph: {
    path: '/finance/doctor-chair-ratio-scatter-graph',
  },

  // patients
  PatientsDashboard: { path: '/patient/dashboard' },
  GeographicDistributionOfPatients: { path: '/patient/dashboard/geographic-distribution-of-patients' },
  PatientDemographic: { path: '/patient/dashboard/patient-demographic' },
  FirstTreatmentWithDentalClinic: { path: '/patient/dashboard/first-treatment-with-dental-clinic' },
  NationalityDistribution: { path: '/patient/dashboard/nationality-distribution' },
  OccupationDistribution: { path: '/patient/dashboard/occupation-distribution' },
  ReferralSource: { path: '/patient/dashboard/referral-source' },
  FrequentDoctors: { path: '/patient/dashboard/frequent-doctors' },
  MaritalStatus: { path: '/patient/dashboard/marital-status' },

  ConversionGraph: { path: '/patient/dashboard/conversion-graph'},

  KpiPlanner: { path: '/kpi-planner' },
  TotalRevenueBarChart: { path: '/TotalRevenueBarChart' },
  TotalRevenuePieChart: { path: '/TotalRevenuePieChart' },
  TreatmentTarget: { path: '/TreatmentTarget' },
  TreatmentAverage: { path: '/TreatmentAverage' },
  DrPerformanceReport: {
    path: '/dr-performance-report',
    MonthlySummary: { relativePath: '/monthly-summary' },
    YearlySummary: { relativePath: '/yearly-summary' },
    MTDPerformance: { relativePath: '/mtd-performance' },
    MoMRevenue: { relativePath: '/mom-revenue' },
    MTD: { relativePath: '/ytd-current-yr-vs-previous-yr' },
    MonthlyPerformance: { relativePath: '/monthly-performance' },
    RollingPerformance: { relativePath: '/rolling-performance' },
    TreatmentMix: { relativePath: '/treatment-mix' },
    YTD: { relativePath: '/ytd' },
  },

  //clinic
  MultiClinic: { path: '/multi-clinic' },
  SingleClinic: { path: '/single-clinic' },
  ClinicReport: {
    path: '/clinic-report',
    TimeLine: { relativePath: '/timeline' },
    Usage: { relativePath: '/usage' },
    DailyCalendar: { relativePath: '/daily-calendar' },
    WeeklyCalendar: { relativePath: '/weekly-calendar' },
    HourlyCalendar: { relativePath: '/hourly-calendar' },
    TreatmentTime: { relativePath: '/treatment-time' },
  },

  //clinic shedule remainder
  ClinicScheduleRecommender: {
    path: '/schedule-recommender',
    RecommendedSlots: { relativePath: '/recommended-slots' },
    SlotConfirmed: { relativePath: '/slot-confirmed' },
  },

  //show monthly report
  MonthlyReport: { path: '/monthly-report' },
  MonthlyBranchReport: {path: '/monthly-report/branch'},

  DashboardOverview: { path: '/dashboard/overview' },
  DashboardTraffic: { path: '/dashboard/traffic-and-engagement' },
  DashboardProductAnalysis: { path: '/dashboard/product-analysis' },
  Kanban: { path: '/kanban' },
  Messages: { path: '/messages' },
  SingleMessage: { path: '/message' },
  Transactions: { path: '/transactions' },
  Tasks: { path: '/tasks' },
  // Settings: { path: "/settings" },
  Calendar: { path: '/calendar' },
  Map: { path: '/map' },
  Datatables: { path: '/tables/datatables' },
  BootstrapTables: { path: '/tables/bootstrap-tables' },
  Pricing: { path: '/examples/pricing' },
  Billing: { path: '/examples/billing' },
  Invoice: { path: '/examples/invoice' },
  Signin: { path: '/examples/sign-in' },
  Signup: { path: '/examples/sign-up' },
  ForgotPassword: { path: '/examples/forgot-password' },
  ResetPassword: { path: '/examples/reset-password' },
  Lock: { path: '/examples/lock' },
  NotFound: { path: '/examples/404' },
  ServerError: { path: '/examples/500' },
  Presentation: { path: '/login' },

  // TODO: MORE SUB-ROUTE PER GRAPH
  // #406: patient segmentation
  PatientSegmentationDashboard: { path: '/patient-segmentation' },
  SegmentCategoryHorizontalBarChart: { path: '/patient-segmentation/segment-category-bar-chart' },
  RFMScatterPlot: { path: '/patient-segmentation/rfm-scatter-plot' },
  RFMBubbleChart: { path: '/patient-segmentation/rfm-bubble-chart' },

  // docs
  DocsOverview: { path: '/documentation/overview' },
  DocsDownload: { path: '/documentation/download' },
  DocsQuickStart: { path: '/documentation/quick-start' },
  DocsLicense: { path: '/documentation/license' },
  DocsFolderStructure: { path: '/documentation/folder-structure' },
  DocsBuild: { path: '/documentation/build-tools' },
  DocsChangelog: { path: '/documentation/changelog' },

  // plugins
  PluginCharts: { path: '/plugins/charts' },
  PluginCalendar: { path: '/plugins/calendar' },
  PluginDatatable: { path: '/plugins/datatable' },
  PluginMap: { path: '/plugins/map' },
  PluginDropzone: { path: '/plugins/dropzone' },
  PluginSweetAlert: { path: '/plugins/sweet-alert' },

  // components
  Accordions: { path: '/components/accordions' },
  Alerts: { path: '/components/alerts' },
  Badges: { path: '/components/badges' },
  Widgets: { path: '/widgets' },
  Breadcrumbs: { path: '/components/breadcrumbs' },
  Buttons: { path: '/components/buttons' },
  Forms: { path: '/components/forms' },
  Modals: { path: '/components/modals' },
  Navs: { path: '/components/navs' },
  Navbars: { path: '/components/navbars' },
  Pagination: { path: '/components/pagination' },
  Popovers: { path: '/components/popovers' },
  Progress: { path: '/components/progress' },
  Tables: { path: '/components/tables' },
  Tabs: { path: '/components/tabs' },
  Tooltips: { path: '/components/tooltips' },
  Toasts: { path: '/components/toasts' },
  WidgetsComponent: { path: '/components/widgets' },
};
