/**
 * Reducers to update global state
 */

import { stubFalse } from 'lodash';
import {
    PATIENT_SEGMENTATION_ERROR,
    GET_SEGMENT_CATEGORY_HORIZONTAL_BAR_CHART,
    GET_SEGMENT_PATIENT_EXPORT,
    GET_RFM_SCATTER_PLOT,
    GET_RFM_BUBBLE_CHART,
    PATIENT_EXPORT_START,
    PATIENT_BAR_START,
    PATIENT_BUBBLE_START,
    PATIENT_SCATTER_START
} from '../constants/actionsTypes'
import initialState from "./initialState";



export default function patientSegmentationReducer(state = initialState.patientSegmentationReducer, action) {
    switch (action.type) {
        case PATIENT_SEGMENTATION_ERROR:
            return Object.assign({}, state, {
                errorMessage: action.data.errorMessage
            });
        case PATIENT_BAR_START:
            return Object.assign({}, state, {
                isBarLoading: true
            });
        case PATIENT_EXPORT_START:
            return Object.assign({}, state, {
                isExportLoading: true
            });
        case GET_SEGMENT_CATEGORY_HORIZONTAL_BAR_CHART:
            return Object.assign({}, state, {
                segmentCategoryHorizontalBarChart: action.data,
                isBarLoading: false
            });
        case GET_SEGMENT_PATIENT_EXPORT:
            return Object.assign({}, state, {
                segmentPatientExport: action.data,
                isExportLoading: false 
            });
        case GET_RFM_SCATTER_PLOT:
            return Object.assign({}, state, {
                rfmScatterPlot: action.data,
            });
        case GET_RFM_BUBBLE_CHART:
            return Object.assign({}, state, {
                rfmBubbleChart: action.data,
            });
        default:
            return state;
    }
}