/**
 * Info modal data and render config
 * - Will show explanations for each patient segment
 */

import { useEffect, useState, useRef } from 'react';

import moment from "moment-timezone"
import segmentOrderConfig from "./PatientSegmentationOrderConfig";

import swal from "sweetalert";

import Loader from "components/Loader";
import MultiSelect from "components/filters/MultiSelect";
import "./style.css";



const PatientSegmentInfoModal = (props) => {

    
    // 
    // RENDER UI
    // 

    return (
        <>
            <div className="info-modal" style={{ display: props.infoToggle ? 'block' : 'none', bottom: props.isEmbedded ? "45px" : "35px" }}>
                <table className="info-table" cellSpacing={0} cellPadding={0}>
                    <tbody className="info-table-tbody">
                        {Object.keys(segmentOrderConfig).map((segment) => {
                            return (
                                <tr className="info-table-tr" key={`${segment}-segmentRow`}>
                                    <td className="info-table-td circle-td" key={`${segment}-segmentColorKey`}>
                                        <div className="circle circle-downsize" style={{ backgroundColor: segmentOrderConfig[segment].color }}></div>
                                    </td>
                                    <td className="info-table-td info-bold" key={`${segment}-segmentName`}>{segment.split(" ").join("\n")}:</td>
                                    <td className="info-table-td info-desc" key={`${segment}-segmentDesc`}>{segmentOrderConfig[segment].description}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
};

export default PatientSegmentInfoModal;