import {
  ADMIN_INPROGRESS,
  GET_CLINIC_COMPONENTS,
  GET_CLINIC_DASHBOARD_FILTER,
  GET_CLINIC_HOURLY_AVERAGE_FILTER,
  GET_CLINIC_TREATMENT_TIME,
  GET_CLINIC_HOURLY_CALENDAR,
  GET_CLINIC_WEEKLY_CALENDAR,
  // GET_CLINIC_WEEKLY_UNDERUTILIZATION_BOUNDARIES, // #275: not used due to using different approach
  GET_CLINIC_DAILY_CALENDAR,
  // GET_CLINIC_DAILY_DOWNTIME_BOUNDARIES, // #275: not used due to using different approach
  GET_CLINIC_USAGE,
  GET_CLINIC_TIMELINE

} from "../constants/actionsTypes"
import initialState from "./initialState"
// #275: not used due to using different approach
// import { formatDaily, formatWeekly } from "../components/ClinicReport/FormatDataForBoundaries" // #275: import function to calculate min max

export default function clinicReducer(
  state = initialState.clinicReducer,
  action
) {
  switch (action.type) {
    case ADMIN_INPROGRESS:
      return Object.assign({}, state, {
        loading: true,
        error_msg: null,
        success_msg: null,
        components: action.data,
      })
    case GET_CLINIC_TREATMENT_TIME:
      return Object.assign({}, state, {
        loading: false,
        error_msg: null,
        success_msg: null,
        treatmentData: action.data,
      })
      case GET_CLINIC_HOURLY_CALENDAR:
        return Object.assign({}, state, {
          loading: false,
          error_msg: null,
          success_msg: null,
          hourlyData: action.data,
        })
      case GET_CLINIC_WEEKLY_CALENDAR:
        return Object.assign({}, state, {
          loading: false,
          error_msg: null,
          success_msg: null,
          weeklyData: action.data,
        })
      // #275: not used due to using different approach
      // // #275: add new reducer to assign boundary data state 
      // case GET_CLINIC_WEEKLY_UNDERUTILIZATION_BOUNDARIES:
      //   return Object.assign({}, state, {
      //     loading: false,
      //     error_msg: null,
      //     success_msg: null,
      //     weeklyUnderutilizationBoundaryData: formatWeekly(action.data.weeklyChairUsage),
      //   })
      case GET_CLINIC_DAILY_CALENDAR:
        return Object.assign({}, state, {
          loading: false,
          error_msg: null,
          success_msg: null,
          dailyData: action.data,
        })
      // #275: not used due to using different approach
      // // #275: add new reducer to assign boundary data state
      // case GET_CLINIC_DAILY_DOWNTIME_BOUNDARIES:
      //   return Object.assign({}, state, {
      //     loading: false,
      //     error_msg: null,
      //     success_msg: null,
      //     dailyDowntimeBoundaryData: formatDaily(action.data.dailyChairUsage),
      //   })
      case GET_CLINIC_USAGE:
        return Object.assign({}, state, {
          loading: false,
          error_msg: null,
          success_msg: null,
          usageData: action.data,
        })
      case GET_CLINIC_TIMELINE:
        return Object.assign({}, state, {
          loading: false,
          error_msg: null,
          success_msg: null,
          timelineData: action.data,
        })
      case GET_CLINIC_DASHBOARD_FILTER:
        return Object.assign({}, state, {
          loading: false,
          error_msg: null,
          success_msg: null,
          dashboard_filter: action.data,
        })
      case GET_CLINIC_HOURLY_AVERAGE_FILTER:
        return Object.assign({}, state, {
          loading: false,
          error_msg: null,
          success_msg: null,
          hourly_average_filter: action.data,
        })
    default:
      return Object.assign({}, state, {
        loading: false
      });
  }
}
