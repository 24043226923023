import React from 'react'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import './style.css'

function Dashboard({columns, data, comments, type, monthYear}) {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
      })

    const monthAndYear = `${getMonthName(monthYear[0])} ${monthYear[1]}`
    
    return (
        <div className="tableWrapper">
          <table className={`${type}Dashboard`}>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
              {comments && comments.length > 0 ? 
                <tr>
                  <th colSpan={14}>{`Comments (as of ${monthAndYear})`}</th>
                </tr> : null
              }
              {comments && comments.length > 0 ? 
                comments.map((comm, i) => (
                  comm && comm.length > 0 ?
                    <tr key={i}>
                      <td colSpan={14}>{comm}</td>
                    </tr> : null
                )) : null
              }
            </tbody>
          </table>
        </div>
    )
}

function getMonthName(monthNumber) {
  const date = new Date()
  date.setDate(1)
  date.setMonth(monthNumber - 1)
  return date.toLocaleString('en-US', {month: 'short'})
}

export default Dashboard