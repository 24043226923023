import _ from "lodash" 

// taken from DailyCalendar.js
export function formatDaily(data) {
    // return format
    if (!data?.length > 0)
        return {
            series: [],
            categories: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        }

    let series = []
    let mergedData = []

    // find min, max, avg from mergedData
    for(let row of data){
        let res = row.data.map(datum => datum.usage)
        mergedData = _.union(mergedData, res)
        series.push({
            name: row.day,
            data: res
        })
    }

    // #275: casts values to Number()
    // calculating min, max, avg
    let vmin = Number.POSITIVE_INFINITY
    let vmax = Number.NEGATIVE_INFINITY
    let vsum = 0
    let vavg = 0
    for(let i of mergedData){
        if(Number(i) < Number(vmin)) vmin = Number(i)
        if(Number(i) > Number(vmax)) vmax = Number(i)
        vsum+=Number(i)
    }
    if(vsum) vavg = Number(Number(vsum)/ Number(mergedData.length))

    return {
        series,
        categories: data?.[0].data?.map(each => each.month),
        max: Number(vmax),
        medium: Number(vavg),
        min: Number(vmin)
    }
}

// taken from WeeklyCalendar.js
export function formatWeekly(data) {
    // return format
    if (!data?.length > 0)
    return {
        series: [],
        categories: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    }

    let series = []
    let mergedData = []

    // find min, max, avg from mergedData
    for(let row of data){
        let res = row.data.map(datum => datum.usage)
        mergedData = _.union(mergedData, res)
        series.push({
            name: row.day,
            data: res
        })
    }

    // #275: casts values to Number()
    // calculating min, max, avg
    let vmin = Number.POSITIVE_INFINITY
    let vmax = Number.NEGATIVE_INFINITY
    let vsum = 0
    let vavg = 0
    for(let i of mergedData){
        if(Number(i) < Number(vmin)) vmin = Number(i)
        if(Number(i) > Number(vmax)) vmax = Number(i)
        vsum+=parseFloat(Number(i))
    }
    if(vsum) vavg = Number(Number(vsum) / Number(mergedData.length))

    return {
        series,
        categories: data?.[0].data?.map(each => each.month),
        max: Number(vmax),
        medium: Number(vavg),
        min: Number(vmin)
    }
}