import React, { useState, useEffect } from "react";
import { Col, Row} from 'react-bootstrap';
import _ from "lodash";
import Chart from "react-apexcharts";

import '../style.css';


const PatientDemographic = (props) => {

    const baseOptions = {
        chart: {
            type: 'bar',
            height: 440,
            stacked: true
        },
        colors: ['#70C1DF', '#F4D0F1'],
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '100%',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 1,
            colors: ["#fff"]
        },
        grid: {
            xaxis: {
                lines: {
                    show: false
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '14px'
                }
            },
            title: {
                text: 'Age Band',
                offsetX: 8,
            }
        },
        tooltip: {
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                const ageRanges = w.config.xaxis.categories;
                const total = _.sum(series[0]) - _.sum(series[1])
                return (
                    `<div>
                        <p> Age group: <b>${ageRanges[dataPointIndex]}</b> </p>
                        <p> Gender: <b>${seriesIndex === 0 ? 'Male' : 'Female'}</b> </p>
                        <p> % of all patients: <b>${(Math.abs(series[seriesIndex][dataPointIndex]) / total * 100).toFixed(2)}%</b> </p>
                        <p> Number of visits: <b>${Math.abs(series[seriesIndex][dataPointIndex])}</b> </p>
                    </div>`
                );
            },
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: [],
            labels: {
                formatter: function (val) {
                    const valFin = Math.abs(val)<10 ? Math.abs(val) : Math.abs(+(val.toFixed(0)))
                    return valFin
                },
                style: {
                    fontSize: '14px'
                }
            }
        }
    };
    const [ chartOptions, setChartOptions ] = useState(baseOptions);
    const [ chartSeries, setChartSeries ] = useState([
        {
            name: 'Males',
            data: []
        },
        {
            name: 'Females',
            data: []
        }
    ]);


    const demographicData = props.demographicData;

    useEffect(() => {
        if(!demographicData) return;

        /**
         * CHART SERIES
         */

        const ageRanges = ['0-9', '10-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80-89', '90-99'].reverse();

        let maleVisits = [];
        let femaleVisits = [];
        demographicData.forEach(demographicObj => {
            if(demographicObj.gender === 'Male') {
                ageRanges.forEach(ageRange => maleVisits.push(+demographicObj[ageRange] || 0));
            }
            else {
                ageRanges.forEach(ageRange => femaleVisits.push((+demographicObj[ageRange] || 0) * -1));
            }
        });
        setChartSeries([{...chartSeries[0], data: maleVisits}, {...chartSeries[1], data: femaleVisits}]);

        /**
         * CHART OPTIONS
         */

        let xAxis = { categories: ageRanges, tickAmount: undefined };

        // precision value to round up/down the min-max values
        const round = (value, precision) => {
            const multiplier = Math.pow(10, precision || 0);
            return Math.round(value * multiplier) / multiplier;
        }

        // default -> if both are empty
        xAxis.min = -10;
        xAxis.max = 10;

        // in case femaleVisits series is not empty
        if ((femaleVisits.length!==0) && (_.sum(femaleVisits)<0)) {
            xAxis.min = (round((_.min(femaleVisits)*-1), -1)) * -1;                  // round to the nearest 10
            if (xAxis.min > _.min(femaleVisits)) xAxis.min = xAxis.min - 10;         // make sure bars won't be getting clipped
        }

        // in case maleVisits series is not empty
        if ((maleVisits.length!==0) && (_.sum(maleVisits)>0)) {
            xAxis.max = round(_.max(maleVisits), -1);                              // round to the nearest 10
            if (xAxis.max < _.max(maleVisits)) xAxis.max = xAxis.max + 10;         // make sure bars won't be getting clipped
        }

        setChartOptions({...baseOptions, xaxis: xAxis});
    }, [demographicData]);


    useEffect(() => {
        if (!props.isEmbedded) {
            window.history.replaceState(
                null,
                "Medtrik",
                "/patient/dashboard/patient-demographic"
            );
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <Row className={!props.isEmbedded ? "pb-5" : "pb-4"}>
            <Col xs={12} lg={12} className={!props.isEmbedded ? "px-8 mt-4" : ""}>
                {!props.isEmbedded &&
                    <Row className="mt-2">
                        <Col className="text-center" xs={12} lg={12} style={{fontSize: "32px", fontWeight: "500", color: "#2f4858"}}>Patient Demographic</Col>
                    </Row>
                }
                <Row className="mt-2">
                    <Col className="text-center" xs={6} lg={6} style={{fontSize: "24px", fontWeight: "500", color: "#2f4858"}}>Females</Col>
                    <Col className="text-center" xs={6} lg={6} style={{fontSize: "24px", fontWeight: "500", color: "#2f4858"}}>Males</Col>
                </Row>
                <Chart options={chartOptions} series={chartSeries} type="bar" height={555} />
            </Col>
        </Row>
    )
}

export default PatientDemographic;
