import React, { useEffect, useState } from 'react'
import './style.css'

function EditTextTable({column, data, type}) {
    const[textData, setTextData] = useState(data)

    useEffect(() => {
        setTextData(data)
    }, [data])

    return (
        <div className="tableWrapper">
          <table className={type}>
            <thead>
                <tr>
                    <th>{column}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <textarea
                            className='editableTextInput'
                            name={type}
                            value={textData}
                            onChange={(e) => setTextData(e.target.value)}
                        />
                    </td>
              </tr>
            </tbody>
          </table>
        </div>
    )
}

export default EditTextTable