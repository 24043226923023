import { useState, useEffect, useRef } from "react"
import Chart from "react-apexcharts"
import _ from "lodash"

import { formatAmount, parseAmount } from "utils/parser"

const RollingPerformance = ({rollingData, isEmbedded}) => {
  const [series, setSeries] = useState([])
  const [categories, setCategories] = useState([])
  const [kpiTarget, setKpiTarget] = useState(Number.NEGATIVE_INFINITY)

  let options = {
    chart: {
      stacked: true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "center",
          hideOverflowingLabels: false,
        },
      },
    },
    title: {
      text: !isEmbedded ? "Rolling Performance Last 12 Months" : '',
    },
    dataLabels: {
      formatter: (val) => `${formatAmount(val)}`,
      style: {
        fontSize: ".5rem",
      }
    },
    xaxis: {
      categories: categories,
      labels: {
        rotate: -90,
        minHeight: 0,
        maxHeight: 200
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    legend: {
      show: !isEmbedded,
    },
    tooltip: {
      y: {
        formatter: (val) => formatAmount(val)
      },
    }
  }

  useEffect(() => {
    if (_.isEmpty(rollingData)) return
    let update = formatData(rollingData)
    setSeries(update.series)
    setCategories(update.categories)
    setKpiTarget(update.kpiTarget)
  }, [rollingData])

  // setState: categories
  useEffect(() => {
    _.assign(options, {
      xaxis: {
        categories: categories,
      },
    })
  }, [categories])

  useEffect(() => {
    if(!isEmbedded) {
      window.history.replaceState(null, "Medtrik", "/dr-performance-report/rolling-performance");
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <Chart options={options} series={series} type='bar' height={isEmbedded ? 424 : '100%'} />
  )
}

let formatData = (data) => {
  // return format
  if (!data?.months.length)
    return {
      series: [],
      categories: [],
      kpiTarget: Number.NEGATIVE_INFINITY,
    }

  const rollingData = _.cloneDeep(data);
  rollingData.months = rollingData.months.reverse();
  return {
    series: [
      {
        name: "Cost",
        data: rollingData.months.map((m) => parseAmount(m.drMnthTtCost).amount),
      },
      {
        name: "Profit",
        data: rollingData.months.map((m) => parseAmount(m.drMnthTtProfit).amount),
      },
    ],
    categories: rollingData.months.map((m) => m.monthName),
    kpiTarget: rollingData?.drKPITarget ?? Number.NEGATIVE_INFINITY,
    amountPrefix:
      parseAmount(_.get(rollingData?.months[0], "drMnthTtCost")).prefixed ??
      Number.NEGATIVE_INFINITY,
    amountSuffix:
      parseAmount(_.get(rollingData?.months[0], "drMnthTtCost")).suffixed ??
      Number.NEGATIVE_INFINITY,
  }
}

export default RollingPerformance
