import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import { Routing } from "routes"
import { useChainId } from "hooks/useChainId";

const TreatmentTime = (props) => {
  useEffect(() => {
    if (!props.isEmbedded) {
      window.history.replaceState(null, null, `/clinic-report${Routing.ClinicReport.TreatmentTime.relativePath}`);
      window.scrollTo(0, 0);
    }
  }, []);
  
  // states
  const chainId = useChainId();
  const { actions, clinicId, startDate, endDate } = props
  // #331: treatment options state for the drop down selector
  const [treatmentFilter, setTreatmentFilter] = useState([]);
  // #331: selected treatment state
  const [selectedTreatmentValue, setSelectedTreatmentValue] = useState();
  const avgTimeTreatment = useSelector((state) => state.clinicReducer?.treatmentData?.avgTimeTreatment)
  const [treatment, setTreatment] = useState({
    dataLabel: "",
    avgTime: 0,
  })

  // call api to assign response values to clinicReducer.treatmentData.avgTimeTreatment -> accessible via avgTimeTreatment
  useEffect(() => {
    !_.isEmpty(clinicId) && actions.clinicActions.getTreatmentTime(
      chainId,
      clinicId, 
      startDate,
      endDate
    ) // get from props
  }, [clinicId, startDate, endDate])

  // handle when treatment filter changes
  const handleTreatment = (event) => {
    // #331: replace with filtering function
    let filteredTreatmentData = filterData(avgTimeTreatment, event.target.value);
    filteredTreatmentData?.length && setTreatment(filteredTreatmentData[0]);

    // #331: set event target as selectedTreatmentValue
    setSelectedTreatmentValue(event.target.value);

  }

  // handle when avgTimeTreatment data changes (after API is called)
  useEffect(() => {
    // #331: set treatment filter option if state is empty
    // (exclude string empty label)
    !treatmentFilter?.length && setTreatmentFilter(avgTimeTreatment?.filter(timeTreatmentData => timeTreatmentData.dataLabel != "").map(timeTreatmentData => timeTreatmentData));

    if(avgTimeTreatment && !!avgTimeTreatment.length) {
      // #331: exclude data with empty label
      let tempAvgTimeTreatment = avgTimeTreatment.filter(timeTreatmentData => timeTreatmentData.dataLabel != "");

      // #331: if a treatment is selected -> get filtered data
      if (selectedTreatmentValue) {
        let filteredTreatmentData = filterData(tempAvgTimeTreatment, selectedTreatmentValue);
        filteredTreatmentData?.length && setTreatment(filteredTreatmentData[0]);
      } 
      // #331: else if no treatment is selected -> use first data
      else {
        setTreatment({
          dataLabel: tempAvgTimeTreatment?.[0]?.dataLabel,
          avgTime: tempAvgTimeTreatment?.[0]?.avgTime
        });
        setSelectedTreatmentValue(tempAvgTimeTreatment?.[0]?.dataLabel);
      }
    }

  }, [avgTimeTreatment]);

  return (
    <article className='postion-relative w-100'>
      {/* chart header */}
      {!props.isEmbedded &&
        <header className='app-font-class-0'>Average Time of Treatment</header>
      }
      <main className='position-relative mt-2'>
        {/* sub titles */}
        {!props.isEmbedded &&
          <section className='cotainer-fluid mt-4 mb-1 px-4'>
            <div className='row g-2'>
              <div className='col'>
                <div
                  className='text-center'
                  style={{ backgroundColor: "#F6F8FB" }}
                >
                  Average Time for Treatment
                </div>
                <div
                  className='text-center'
                  style={{ backgroundColor: "#D7D8DA" }}
                >
                  {/* #331: use treatmentFilter for drop down option */}
                  <select className='form-select' onChange={handleTreatment}>
                    {treatmentFilter?.map((each, index) => (
                      <option key={each.dataLabel} value={each.dataLabel} selected={index === 0}>
                        {each.dataLabel}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </section>
        }
        {/* chart */}
        <section className='container'>
          <div className='row'>
            <div
              className='col position-relative'
              style={{
                height: !props.isEmbedded ? "15rem" : '2rem',
              }}
            >
              <h4
                style={{
                  top: !props.isEmbedded ? "50%" : '0%',
                }}
                className='position-relative text-center'
              >
                {treatment.dataLabel && (
                  <span>Average Time for <span style={{ color: '#468195' }}>{treatment.dataLabel}</span> is <span style={{ color: '#468195' }}>{treatment.avgTime}</span></span>
                )}
              </h4>
            </div>
          </div>
        </section>
      </main>
    </article>
  )
}

// #331: function to filter out data so it can both be used in handleTreatment and useEffect on avgTimeTreatment change
let filterData = (avgTimeData, filterValue) => {
  let filtered = avgTimeData.filter(
    (each) => each.dataLabel === filterValue
  ).map(each => each)

  // #331: if there is no data with that label, then set avgTime to 0
  if (!filtered.length) {
    filtered = [
      {
        avgTime: '0 minutes',
        dataLabel: filterValue
      }
    ]
  }

  return (filtered)
}

export default TreatmentTime
