import React from 'react';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import './style.css';

const Loader = () => {

    return (
        <div className="loadingPanel h-100 d-flex align-items-center">
            <lottie-player
                src="https://assets10.lottiefiles.com/packages/lf20_earcnwm3.json"
                background="transparent"
                speed="1"
                style={{width:'100%', height: '300px'}}
                loop 
                autoplay
                ></lottie-player>
        </div>
    )
}

export default Loader