import { SYSTEM_DATE_FORMAT_DF } from "constants/common"
import { format, startOfMonth, sub } from "date-fns"
import decode from "jwt-decode"
import _ from "underscore"

const accessToken = localStorage.getItem("accessToken")
let token = null
let adminUser = null
let loginUserID = null

if (accessToken !== "undefined" && accessToken !== null) {
  token = accessToken
  const userProfile = decode(token)
  loginUserID = userProfile.user._id
  adminUser = userProfile.user
}

export default {
  appReducer: {
    loading: false,
    error_msg: null,
    success_msg: null,
  },
  adminAuthReducer: {
    loading: false,
    error_msg: null,
    success_msg: null,
    isAuthenticated: false,
    passwordRecovered: false,
    token: token,
    isAuthenticatedFail: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    allKeyWords: [],
    allPages: null,
    selectedPage: null,
    adminUser: adminUser,
    orders: [],
    userDeletedID: null,
    roleDeletedID: null,
    loginUserID: loginUserID,
    userDetail: [],
    roleDetail: [],
    doctorsDetails: [],
    doctorClinic: [],
    kpiClinic: [],
    chains: [],
    clinics: [],
    coupons: [],
    checkOutOrder: null,
    importFailOrders: null,
    outOfStockOrder: null,
    searchOrderId: null,
    couponDetail: null,
    orderSearch: null,
  },
  drPerformanceReducer: {
    monthly: undefined,
    yearly: undefined,
    mtdPerformance: undefined,
    mtdCurrYear: undefined,
    mtdCurrVsPrevYear: undefined,
    rolling: undefined,
    treatmentMix: undefined,
    ytd: undefined,
  },
  clinicReducer: {},
  kpiPlannerReducer: {
    userKPISummary: [],
    allocatedByChain: [],
  },
  financeReducer: {
    dashboardData: {},
    clinics: [],
    revenueFigures: {},
    revenueGraph: {},
    dailyRevenue: {},
    kpiProgress: {},
    treatmentByInvoiceCount: {},
    treatmentByDiscreteTreatmentCycle: {},
    perHourTreatmentByInvoiceCount: {},
    revenueForecastData: {}
  },
  patientReducer: {
    dashboardData: [],
    treatments: [],
    doctors: [],
    segments: [],
    demographicData: undefined,
    geographicDistribution: undefined,
    firstTreatmentData: undefined,
    nationalityDistribution: undefined,
    occupationDistribution: undefined,
    referralSource: undefined,
    frequentDoctors: undefined,
    maritalStatus: undefined,
    optimalDoctorChairRatio: undefined
  },
  reportReducer: {
    isLoading: false,
    getReportDataSuccess: false,
    updateHeadlinesInsightsSuccess: false,
    publishHeadlinesInsightsSuccess: false,
    getConversionDataSuccess: false,
    errorMessage: {},
    reportData: {
        month: null,
        year: null,
        financeDashboard: [],
        clinicDashboard: [],
        doctorDashboard: [],
        treatmentDashboard: [],
        patientDashboard: [],
        conversionDashboard: [],
        topTreatments: {},
        branchDoctors: [],
        headlines: [],
        insights: [],
        headlinesInsightsPublished: ''
    }
  },
  // #406: add patient segmentation dashboard
  patientSegmentationReducer: {
    errorMessage: null,
    isBarLoading: false,
    isExportLoading: false,
    segmentCategoryHorizontalBarChart: [],
    segmentPatientExport: [],
    rfmScatterPlot: [],
    rfmBubbleChart: []
  }
}

