import { useState, useEffect } from "react"
import { useSelector } from "react-redux"


import useFirstRender from "./useFirstRender"
import Loader from "components/Loader"

const useLoading = (init) => {
  const [loading, setLoading] = useState(init ?? true)
  const reloading = useSelector((state => state.appReducer.loading))
  const firstRender = useFirstRender()
  // console.debug({firstRender, reloading})

  useEffect(() => {
    !firstRender && setLoading(reloading)
    return () => {
      setLoading(false)
    }
  }, [reloading])

  return [loading, (value) => {
    !firstRender && setLoading(value)
  }, Loader]
}

export default useLoading
