import React, { useState, useEffect } from "react";
import { HomeIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Breadcrumb } from 'react-bootstrap';
import { Checkbox } from "@mui/material";
import { useChainId } from "hooks/useChainId";
import { Routing } from "routes";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as financeActions from "actions/financeActions";
import {MONTH_SLIDER} from "constants/date"
import MonthSlider from "components/filters/MonthSlider";
import Select, { components } from 'react-select';
import useLoading from "hooks/useLoading";
import RevenueGraph from "./RevenueGraph";
import DailyRevenueForMonth from "./DailyRevenueForMonth";
import KPIProgressBar from "./KPIProgressBar";
import PerInstanceOfTreatmentByIC from "./PerInstanceOfTreatmentByIC";
import PerInstanceOfTreatmentByDTC from "./PerInstanceOfTreatmentByDTC";
import PerHourOfTreatment from "./PerHourOfTreatment";

import '../style.css';
import { format, parse, minTime} from "date-fns";
import { SYSTEM_DATE_DISPLAY_FORMAT2_DF, SYSTEM_DATE_FORMAT_DF } from "constants/common";

const Option = (props) => {
    return (
      <div>
        <components.Option {...props} className="react-select-option">
            <Checkbox
                checked={props.isSelected}
                color="default"
                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />
            <label>{props.label}</label>
        </components.Option>
      </div>
    );
};

const FinanceDashboard = (props) => {
    const [ loading, setLoading, Loader ] = useLoading();
    const chainId = useChainId();
    const history = useHistory();
    const redirectUrl = (url) => {
        history.push(url);
    };

    const [ selectedDateRange, setSelectedDateRange ] = useState({});
    const [ selectedDate, setSelectedDate ] = useState({});
    const [ clinics, setClinics ] = useState([]);
    const [ selectedClinics, setSelectedClinics ] = useState([]);
    const [ clinicIds, setClinicIds ] = useState([]);
    const [ selectedComponent, setSelectedComponent ] = useState(props.selectedComponent);

    const handleClinicChange = (selectedClinics) => {
        let selectedClinicIds = selectedClinics.map(selectedClinic => selectedClinic.value.toUpperCase());
        if(selectedClinicIds.includes('ALL CLINICS')) {
            selectedClinicIds = props.clinics.map(clinic => clinic.clinicId.toUpperCase());
            setSelectedClinics([{ value: 'All Clinics', label: 'All Clinics' }]);
        }
        else {
            setSelectedClinics(selectedClinics);
        }
        setClinicIds(selectedClinicIds);
    };

    const handleDateRangeChange = (dateRange) => {
        setSelectedDateRange(dateRange);
    };
    
    const handleMonthChange = (dateRange) => {
        setSelectedDate(dateRange);
    };

    const goBackToDashboard = () => {
        window.history.replaceState(null, "Medtrik", "/finance/dashboard");
        setSelectedComponent(null);
    }

    useEffect(() => {
        props.actions.financeActions.getFinanceComponents(props.user._id, props.user.Role.id);
        if(clinicIds.length) {
            props.actions.financeActions.getFinanceRevenueFigures(chainId, clinicIds, selectedDateRange.startDate, selectedDateRange.endDate);
            props.actions.financeActions.getFinanceRevenueGraph(chainId, clinicIds, selectedDateRange.startDate, selectedDateRange.endDate);
            props.actions.financeActions.getFinanceDailyRevenue(chainId, clinicIds, selectedDate.currentDate);
            // props.actions.financeActions.getFinanceKPIProgress(chainId, clinicIds, selectedDateRange.startDate, selectedDateRange.endDate);
            props.actions.financeActions.getFinanceKPIProgress(chainId, clinicIds);
            props.actions.financeActions.getFinancePerInstanceOfTreatmentByInvoiceCount(chainId, clinicIds, selectedDateRange.startDate, selectedDateRange.endDate);
            props.actions.financeActions.getFinancePerInstanceOfTreatmentByDiscreteTreatmentCycle(chainId, clinicIds, selectedDateRange.startDate, selectedDateRange.endDate);
            props.actions.financeActions.getFinancePerHourOfTreatmentByInvoiceCount(chainId, clinicIds, selectedDateRange.startDate, selectedDateRange.endDate);
        }
    }, [chainId, clinicIds, selectedDateRange, selectedDate]);

    useEffect(() => {
        if(chainId)
            props.actions.financeActions.getClinics(chainId);
    }, [chainId]);

    useEffect(() => {
        let clinics = [];
        if(props.user.Role.id == 3 && props.user.clinic) {
            props.clinics.forEach(clinic => {
                if(props.user.clinic.clinicId == clinic.clinicId)
                    clinics.push({ value: clinic.clinicId, label: clinic.clinicId })
            });
        }
        else {
            clinics = [{ value: 'All Clinics', label: 'All Clinics' }];
            props.clinics.forEach(clinic => {
                clinics.push({ value: clinic.clinicId, label: clinic.clinicId })
            });
            setClinics(clinics);
        }
        handleClinicChange(clinics);
    }, [props.clinics]);

    // #271: Change component name
    const componentMapping = {
        'Revenue Graphs': RevenueGraph,
        'Daily Revenue For Month': DailyRevenueForMonth,
        'KPI Progress Bar': KPIProgressBar,
        'Profitability Per Treatment Instance': PerInstanceOfTreatmentByIC,
        'Profitability Per Treatment Cycle': PerInstanceOfTreatmentByDTC,
        'Profitability Per Hour': PerHourOfTreatment
    }

    const getSelectedComponent = (componentName, isEmbedded=false) => {
        const SelectedComponent = componentMapping[componentName]
        return <SelectedComponent {...props} isEmbedded={isEmbedded} />
    }

    return (
        <>
        {loading && <Loader/>}
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                    <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                    {
                        selectedComponent ?
                        <>
                            <Breadcrumb.Item onClick={goBackToDashboard}>Finance Dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item active>{selectedComponent}</Breadcrumb.Item>
                        </> :
                        <Breadcrumb.Item active>Finance Dashboard</Breadcrumb.Item>
                    }
                </Breadcrumb>
                <h4>Finance Dashboard</h4>
            </div>
        </div>
        <Card border="0" className="table-wrapper table-responsive shadow px-4">
            <Card.Body>
                <Row className="finance-dashboard">
                    <Col xs={12} lg={4}>
                        {props.user.Role.id != 3 &&
                            <Select
                                value={selectedClinics}
                                className="medtrik-dropdown"
                                placeholder="Select Clinic(s)..."
                                isMulti
                                components={{Option}}
                                onChange={handleClinicChange}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                options={clinics} />
                        }
                    </Col>
                    <Col xs={12} lg={2}></Col>
                    {/* #346: add class d-none for components Daily Revenue and KPI Progress*/}
                    <Col xs={12} lg={6} className={`justify-content-end text-end mb-${selectedComponent ? '8' : '6'} px-4${((selectedComponent === 'Daily Revenue For Month') || (selectedComponent === 'KPI Progress Bar')) ? ' d-none' : ''}`}>
                        <h6 className="m-0">Selected Month Range {selectedDateRange?.startDate && format(parse(selectedDateRange.startDate, SYSTEM_DATE_FORMAT_DF, minTime), SYSTEM_DATE_DISPLAY_FORMAT2_DF)} - {selectedDateRange?.endDate && format(parse(selectedDateRange.endDate, SYSTEM_DATE_FORMAT_DF, minTime), SYSTEM_DATE_DISPLAY_FORMAT2_DF)}</h6>
                        <MonthSlider {...Object.assign({}, MONTH_SLIDER, {display: true, type: 2, max: 24})} handleMonthChange={handleDateRangeChange} />
                    </Col>
                    <Col xs={12} lg={6} className={`justify-content-end text-end mb-${selectedComponent ? '8' : '6'} px-4${selectedComponent !== 'Daily Revenue For Month' ? ' d-none' : ''}`}>
                        <h6 className="m-0">Selected Month {selectedDate?.currentDate && format(parse(selectedDate.currentDate, SYSTEM_DATE_FORMAT_DF, minTime), SYSTEM_DATE_DISPLAY_FORMAT2_DF)}</h6>
                        <MonthSlider {...Object.assign({}, MONTH_SLIDER, {display: true, max: 24})} handleMonthChange={handleMonthChange} />
                    </Col>
                    {/* #346: add empty col and h6 component if component = KPI Progress so the spacing doesn't change even if the time filter is not shown */}
                    {selectedComponent === 'KPI Progress Bar' && 
                        <Col xs={12} lg={6} className={`justify-content-end text-end mb-8 px-4`}>
                            <h6 className="m-0"> </h6>
                        </Col>}
                    {selectedComponent && getSelectedComponent(selectedComponent)}
                    {!selectedComponent && props.dashboardData.financeComponents && props.dashboardData.financeComponents.map((financeComponent) => {
                        return (
                            <Col xs={12} lg={financeComponent.financeComponentName === 'KPI Progress Bar' ? 4 : financeComponent.financeComponentName === 'Revenue Graphs' ? 8 : 6 } key={financeComponent.financeComponentId}>
                                <div className="mb-4" onClick={() => setSelectedComponent(financeComponent.financeComponentName)}>
                                    <div className="pill-container px-4 pt-3">
                                        <div className="pill-text mb-3">{financeComponent.financeComponentName}</div>
                                        {getSelectedComponent(financeComponent.financeComponentName, true)}
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Card.Body>
        </Card>
        </>
    )

}

const mapStateToProps = (state) => {
    const {adminAuthReducer, financeReducer} = state;
    return {
        user: adminAuthReducer.adminUser,
        dashboardData: financeReducer.dashboardData,
        clinics: financeReducer.clinics,
        revenueGraph: financeReducer.revenueGraph,
        revenueFigures: financeReducer.revenueFigures,
        dailyRevenue: financeReducer.dailyRevenue,
        kpiProgress: financeReducer.kpiProgress,
        treatmentByInvoiceCount: financeReducer.treatmentByInvoiceCount,
        treatmentByDiscreteTreatmentCycle: financeReducer.treatmentByDiscreteTreatmentCycle,
        perHourTreatmentByInvoiceCount: financeReducer.perHourTreatmentByInvoiceCount,
    }
};

const mapDispatchToProps = dispatch => ({
    actions: {
        financeActions: bindActionCreators(financeActions, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(FinanceDashboard);
