import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Chart from "react-apexcharts"
import _ from "underscore"
import { Routing } from "routes"
import { useChainId } from "hooks/useChainId";

const Usage = (props) => {
  const chainId = useChainId();
  const { actions, clinicId, startDate, endDate} = props
  useEffect(() => {
    !_.isEmpty(clinicId) && actions?.clinicActions?.getUsage(
      chainId,
      clinicId,
      startDate,
      endDate
    )
  }, [clinicId, startDate, endDate])

  const usageData = useSelector((state) => state.clinicReducer.usageData)
  const [series1, setSeries1] = useState([])
  const [series2, setSeries2] = useState([])
  const [categories, setCategories] = useState([])

  let options = {
    plotOptions: {},
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        formatter: (date) => {
          return date
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  }

  useEffect(() => {
    if (_.isEmpty(usageData)) return
    let update = formatData(usageData?.dailyChairUsage, "Chair Usage")

    setSeries1(update.series)
    update = formatData(usageData?.avgChairDowntimeHourPerDay, "Chair Downtime")

    setSeries2(update.series)
    setCategories(update.categories)
  }, [usageData])

  // setState: categories
  useEffect(() => {
    _.assign(options, {
      xaxis: {
        categories: categories,
      },
    })
  }, [categories])

  useEffect(() => {
    if (!props.isEmbedded) {
      window.history.replaceState(null, null, `/clinic-report${Routing.ClinicReport.Usage.relativePath}`);
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <article className='postion-relative w-100'>
      {/* chart header */}
      {!props.isEmbedded &&
        <header className='app-font-class-0'>
          Average Chair Usage & Downtime per Day
        </header>
      }
      <main className='position-relative mt-2'>
        {/* sub titles */}
        <section className='cotainer-fluid mt-4 px-4'>
          <div
            className='row g-2'
            // style={{
            //   width: "48%",
            //   height: "4rem",
            // }}
          >
            <div
              className='col me-2 text-center'
              style={{ backgroundColor: "grey" }}
            >
              Average Chair Usage per Day
            </div>
            <div
              className='col text-center'
              style={{ backgroundColor: "grey" }}
            >
              Average Chair Downtime (hours) per Day
            </div>
          </div>
        </section>
        {/* chart */}
        <section className='mt-1 container'>
          <div className='row'>
            <div className='col'>
              <Chart
                options={{ ...options, colors: ["#F26158"], yaxis: {
                  labels: {
                    show: true,
                  },
                  title: {
                    text: "Average Chair Usage",
                  },
                }}}
                series={series1}
                type='bar'
              />
            </div>
            <div className='col'>
              <Chart
                options={{ ...options, colors: ["#79ED66"], yaxis: {
                  labels: {
                    show: true,
                  },
                  title: {
                    text: "Chair Downtime (Hrs)",
                  },
                }}}
                series={series2}
                type='bar'
              />
            </div>
          </div>
        </section>
      </main>
    </article>
  )
}

let formatData = (data, name) => {
  // return format
  if (!data?.length > 0)
    return {
      series: [],
      categories: [],
    }

  return {
    series: [{
      name: name,
      data: data?.map(each => each.usage)
    }],
    categories: data?.map(each => each.day.toUpperCase())
  }
}
export default Usage
