/**
 * Export modal data and render config
 * - Will show segment option checkboxes and export button (to download)
 */

import { useEffect, useState, useRef } from 'react';

import moment from "moment-timezone"
import segmentOrderConfig from "./PatientSegmentationOrderConfig";

import swal from "sweetalert";

import Loader from "components/Loader";
import MultiSelect from "components/filters/MultiSelect";
import "./style.css";



const ExportModal = (props) => {

    // 
    // STATES
    // 

    const [isExportClicked, setIsExportClicked] = useState(false);

    const [selectedSegmentsExport, setSelectedSegmentsExport] = useState([{ value: "all", label: "All Segments" }]);
    let segmentOptionExport = Object.keys(segmentOrderConfig).map(segment => { return { value: segment, label: segment } });
    segmentOptionExport.unshift({ value: "all", label: "All Segments" })

    // 
    // HANDLE EVENTS
    // 

    // on segment checkbox change
    const handleSegmentExportChange = (segments=[]) => {
        setSelectedSegmentsExport(segments);
    };

    // on export button clicked
    const handleExportClick = () => {
        // if at least one checkbox is selected -> call API and set isExportClicked to true
        if (selectedSegmentsExport && (Array.isArray(selectedSegmentsExport) && selectedSegmentsExport.length>0) ) {
            props.actions.PatientSegmentationActions.getSegmentPatientExport(props.selectedDateRange.startDate, props.selectedDateRange.endDate, selectedSegmentsExport.map(segment => { return segment.value }));
            setIsExportClicked(true);
        } 
        // otherwise show a warning
        else {
            swal({
                title: "Invalid Selected Segments",
                text: "Please choose at least 1 patient segment to export data",
                icon: "error"
            })
        }
    };

    // after export button is clicked, after export action is done loading
    useEffect(() => {
        if (isExportClicked && !props.isExportLoading && props.segmentPatientExportData) {
            // setup csv download
            let csvTitle = `segment_${moment().format('DDMMYY')}.csv`;
            let csvContent = `data:text/csv;charset=utf-8,Category,Patient Id,Most Recent Visit,Frequency,Monetary Value\n${props.segmentPatientExportData
                .map((c) => `${c.segmentCategory},${c.cmsPatientId},${c.latestVisit},${c.numVisits},${c.totalAmount}`)
                .join('\n')}`;
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', csvTitle);
            document.body.appendChild(link);

            // start download
            link.click();

            // close modal and set isExportClicked to false
            props.handleCancelClick();
            setIsExportClicked(false);
        }
    }, [isExportClicked, props.isExportLoading])


    
    // 
    // RENDER UI
    // 

    return (
        <>
            {/* IF EXPORT ACTION IS LOADING -> SHOW LOADER */}
            { isExportClicked && props.isExportLoading && <Loader/>}
            <div className="export-modal" style={{ display: props.exportToggle ? 'block' : 'none' }} onClick={(e) => e.stopPropagation()}>
                {/* SEGMENT CHECKBOX CONTROL */}
                <MultiSelect 
                    placeholder={"Select Segments"} 
                    onChange={handleSegmentExportChange} 
                    options={segmentOptionExport} 
                    value={selectedSegmentsExport} 
                    valueForAll={"all"} 
                    labelForAll={"All Segments"} 
                    menuIsOpen={true}
                    styleMenu = {{
                        fontSize: "16px",
                        width: "300px",
                        maxHeight: "1000px",
                        boxShadow: "none",
                        border: "1px solid lightgray",
                        borderRadius: 0
                    }}
                    styleOption = {{
                        height: '40px',
                        padding: '0px'
                    }}
                    styleControl = {{
                        display: "none"
                    }}
                />
                {/* BUTTONS */}
                <div className="export-control">
                    {/* CANCEL */}
                    <div className="control-wrap cancel-wrap" onClick={props.handleCancelClick}>
                        <p className="text-control cancel-text">Cancel</p>
                    </div>
                    {/* EXPORT */}
                    <div className="control-wrap" onClick={handleExportClick}>
                        <div className="export-btn">
                            <p className="text-control export-text">Export</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ExportModal;