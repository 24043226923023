import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, Container } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {bindActionCreators} from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import * as authAction from "actions/authActions";
import Logo from "assets/img/brand/Medtrik-Logo.png";
import Loader from "../Loader";

import "./PasswordRecovery.css";


const PasswordRecovery = (props) => {
    let { userId, uid } = useParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const history = useHistory();

    const adminAuthReducer = useSelector((state) => ({
        isLoading: state.adminAuthReducer.loading,
        passwordRecovered: state.adminAuthReducer.passwordRecovered,
        error_msg: state.adminAuthReducer.error_msg
    }));

    useEffect(() => {
        if(adminAuthReducer.error_msg !== null) {
            toast.error( adminAuthReducer.error_msg , {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    }, [adminAuthReducer.error_msg]);

    useEffect(() => {
        if(!!adminAuthReducer.passwordRecovered) {
            toast.success( "Password updated successfully, proceed to login!" , {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                onClose: () => history.push("/")
            });
        }
    }, [adminAuthReducer.passwordRecovered]);

    function validateForm() {
        return password.length > 0 && confirmPassword.length > 0 && password === confirmPassword;
    }

    function handlePasswordChange() {
        props.actions.auth.changePassword(userId, uid, password);
    }

    return (
        <>
        <main>
            <ToastContainer />
            <section className="d-flex vh-100 bg-white">
                <Container className="mt-6">
                    <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${Logo})` }}>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="bg-white border-0 rounded border-light p-4 w-100 fmxw-800">
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <img src={Logo} alt="Medtrik Logo" />
                                </div>
                                <div>
                                    <div className="auth-banner text-center mb-5">
                                        Please key in your new password.
                                    </div>
                                    <Form.Group id="password" className="d-flex align-items-center mb-3">
                                        <Col xs={4}>
                                            <Form.Label className="me-2">Password:</Form.Label>
                                        </Col>
                                        <Col xs={8}>
                                            <Form.Control 
                                                autoFocus 
                                                required 
                                                type="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group id="confirmPassword" className="d-flex align-items-center mb-3">
                                        <Col xs={4}>
                                            <Form.Label className="me-2">Confirm Password:</Form.Label>
                                        </Col>
                                        <Col xs={8}>
                                            <Form.Control 
                                                autoFocus 
                                                required 
                                                type="password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <div className="text-end text-danger mb-5">
                                    {
                                        password.length > 0 && confirmPassword.length > 0 && password !== confirmPassword &&
                                        "Password and Confirm Password do not match!"
                                    }
                                    </div>
                                    <div className="text-end">
                                        <Button variant="gray-800" className="login-button px-5" onClick={handlePasswordChange} disabled={!validateForm()}>
                                            Reset Password
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            { adminAuthReducer.isLoading ? <Loader/> : ''}  
        </main>
        </>      
    );
}

const mapDispatchToProps = dispatch => ({
    actions: {
        auth: bindActionCreators(authAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(PasswordRecovery);
