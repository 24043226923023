import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Routing } from 'routes';
import { useSelector, useDispatch } from 'react-redux';

// Routings
import { PrivateRoute } from 'actions/privateRoutes';

// Pages
import Login from './Login/Login';
import PasswordRecovery from './PasswordRecovery/PasswordRecovery';
import Dashboard from './Dashboard';

// User Admin Pages
import Doctors from './Doctor';
import Roles from './Roles';
import AddRole from './Roles/AddRole';
import Users from './Users';
import AddUser from './Users/add';
import Clinic from './Clinic';
import NotFound from 'pages/examples/NotFound';
import ClinicConfiguration from './ClinicConfiguration';
import FinanceDashboard from './Finance/Dashboard';
import FinanceForecast from './Finance/Forecast';
import FinanceDoctorChairRatioScatterGraph from './Finance/DoctorChairRatioScatterGraph';
import KpiPlanner from './KpiPlanner';
import MultiClinic from './KpiPlanner/MultiClinic';
import SingleClinic from './KpiPlanner/SingleClinic';
import DrPerformanceReport from './DrPerformanceReport';
import PerformanceSummaryReport from './PerformanceSummaryReport';
import PatientsDashboard from './Patients/Dashboard';
import GeographicDistributionOfPatients from './Patients/Dashboard/GeographicDistributionOfPatients';
import PatientDemographic from './Patients/Dashboard/PatientDemographic';
import FirstTreatmentWithDentalClinic from './Patients/Dashboard/FirstTreatmentWithDentalClinic';
import NationalityDistribution from './Patients/Dashboard/NationalityDistribution';
import OccupationDistribution from './Patients/Dashboard/OccupationDistribution';
import ReferralSource from './Patients/Dashboard/ReferralSource';
import FrequentDoctors from './Patients/Dashboard/FrequentDoctors';
import MaritalStatus from './Patients/Dashboard/MaritalStatus';
import ConversionChart from './Patients/ConversionChart';
import ClinicReport from './ClinicReport';
import ScheduleRecommender from './ScheduleRecommender';
import MonthlyReport from './MonthlyReport/Report';
import PatientSegmentationDashboard from './PatientSegmentation'
import twofactor from './Users/twofactor';
import TwoFactorLogin from './Login/TwoFactorLogin';
import PDFPreview from './Navbar/PDFPreview';

import editprofile from './Users/editprofile';
import { AvatarProvider } from 'actions/avatarContext';
import SegmentCategoryHorizontalBarChart from './PatientSegmentation/SegmentCategoryHorizontalBarChart';
import RFMBubbleChart from './PatientSegmentation/RFMBubbleChart';
import RFMScatterPlot from './PatientSegmentation/RFMScatterPlot';

const Mainpage = () => {
  const [avatarUrl, setAvatarUrl] = useState('');

  return (
    <AvatarProvider value={{ avatarUrl, setAvatarUrl }}>
      <Switch>
        <Route exact={true} path="/" component={Login} />
        <Route exact={true} path={Routing.CheckTwoFactor.path} component={TwoFactorLogin} />
        <Route exact={true} path={Routing.userManual.path} component={PDFPreview} />
        <PrivateRoute exact={true} path={Routing.Dashboard.path} component={Dashboard} />
        <PrivateRoute exact={true} path={Routing.Account.path} component={editprofile} />
        <PrivateRoute exact={true} path={Routing.Doctors.path} component={Doctors} />
        <PrivateRoute exact={true} path={Routing.KpiClinic.path} component={ClinicConfiguration} />
        <PrivateRoute exact={true} path={Routing.Roles.path} component={Roles} />
        <PrivateRoute exact={true} path={Routing.AddRole.path} component={AddRole} />
        <PrivateRoute exact={true} path={Routing.EditRole.path} component={AddRole} />
        <PrivateRoute exact={true} path={Routing.Users.path} component={Users} />
        <PrivateRoute exact={true} path={Routing.AddUser.path} component={AddUser} />
        <PrivateRoute exact={true} path={Routing.TwoFactor.path} component={twofactor} />
        <PrivateRoute exact={true} path={Routing.EditUser.path} component={AddUser} />
        <PrivateRoute exact={true} path={Routing.Clinic.path} component={Clinic} />

        <PrivateRoute exact={true} path={Routing.FinanceDashboard.path} component={FinanceDashboard} roles={[2, 3]} />
        <PrivateRoute
          exact={true}
          path={Routing.RevenueGraph.path}
          component={FinanceDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Revenue Graphs' }}
        />
        <PrivateRoute
          exact={true}
          path={Routing.DailyRevenueForMonth.path}
          component={FinanceDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Daily Revenue For Month' }}
        />
        <PrivateRoute
          exact={true}
          path={Routing.KPIProgressBar.path}
          component={FinanceDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'KPI Progress Bar' }}
        />
        {/* #271: Change component name */}
        <PrivateRoute
          exact={true}
          path={Routing.PerInstanceOfTreatmentByIC.path}
          component={FinanceDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Profitablity Per Treatment Instance' }}
        />
        {/* #271: Change component name */}
        <PrivateRoute
          exact={true}
          path={Routing.PerInstanceOfTreatmentByDTC.path}
          component={FinanceDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Profitability Per Treatment Cycle' }}
        />
        {/* #271: Change component name */}
        <PrivateRoute
          exact={true}
          path={Routing.PerHourOfTreatment.path}
          component={FinanceDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Profitability Per Hour' }}
        />
        <PrivateRoute exact={true} path={Routing.FinanceForecast.path} component={FinanceForecast} roles={[2, 3]} />
        <PrivateRoute
          exact={true}
          path={Routing.FinanceDoctorChairRatioScatterGraph.path}
          component={FinanceDoctorChairRatioScatterGraph}
          roles={[2, 3]}
        />

        <PrivateRoute exact={true} path={`${Routing.ClinicReport.path}`} component={ClinicReport} roles={[2, 3]} />
        <PrivateRoute
          exact={true}
          path={`${Routing.ClinicReport.path}${Routing.ClinicReport.TimeLine.relativePath}`}
          component={ClinicReport}
          roles={[2, 3]}
          componentProps={{ preselectedComponent: 'Ave. Utilisation & Chair Usage' }}
        />
        <PrivateRoute
          exact={true}
          path={`${Routing.ClinicReport.path}${Routing.ClinicReport.Usage.relativePath}`}
          component={ClinicReport}
          roles={[2, 3]}
          componentProps={{ preselectedComponent: 'Ave. Chair Usage & Downtime per Day' }}
        />
        <PrivateRoute
          exact={true}
          path={`${Routing.ClinicReport.path}${Routing.ClinicReport.DailyCalendar.relativePath}`}
          component={ClinicReport}
          roles={[2, 3]}
          componentProps={{ preselectedComponent: 'Calendar View' }}
        />
        <PrivateRoute
          exact={true}
          path={`${Routing.ClinicReport.path}${Routing.ClinicReport.WeeklyCalendar.relativePath}`}
          component={ClinicReport}
          roles={[2, 3]}
          componentProps={{ preselectedComponent: 'Weekly View' }}
        />
        <PrivateRoute
          exact={true}
          path={`${Routing.ClinicReport.path}${Routing.ClinicReport.HourlyCalendar.relativePath}`}
          component={ClinicReport}
          roles={[2, 3]}
          componentProps={{ preselectedComponent: 'Hourly View' }}
        />
        <PrivateRoute
          exact={true}
          path={`${Routing.ClinicReport.path}${Routing.ClinicReport.TreatmentTime.relativePath}`}
          component={ClinicReport}
          roles={[2, 3]}
          componentProps={{ preselectedComponent: 'Average Time of Treatment' }}
        />

        <PrivateRoute exact={true} path={Routing.PatientsDashboard.path} component={PatientsDashboard} roles={[2, 3]} />
        <PrivateRoute
          exact={true}
          path={Routing.ConversionGraph.path}
          component={PatientsDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Conversion Chart' }}
        />
        <PrivateRoute
          exact={true}
          path={Routing.GeographicDistributionOfPatients.path}
          component={PatientsDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Geographic Distribution of Patients' }}
        />
        <PrivateRoute
          exact={true}
          path={Routing.PatientDemographic.path}
          component={PatientsDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Patient Demographic' }}
        />
        <PrivateRoute
          exact={true}
          path={Routing.FirstTreatmentWithDentalClinic.path}
          component={PatientsDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'First Treatment with Dental Clinic' }}
        />
        <PrivateRoute
          exact={true}
          path={Routing.NationalityDistribution.path}
          component={PatientsDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Nationality Distribution' }}
        />
        <PrivateRoute
          exact={true}
          path={Routing.OccupationDistribution.path}
          component={PatientsDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Occupation Distribution' }}
        />
        <PrivateRoute
          exact={true}
          path={Routing.ReferralSource.path}
          component={PatientsDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Referral Source' }}
        />
        <PrivateRoute
          exact={true}
          path={Routing.FrequentDoctors.path}
          component={PatientsDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Frequent Doctors' }}
        />
        <PrivateRoute
          exact={true}
          path={Routing.MaritalStatus.path}
          component={PatientsDashboard}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Marital Status' }}
        />

        <PrivateRoute exact={true} path={Routing.KpiPlanner.path} component={KpiPlanner} />
        <PrivateRoute path={`${Routing.DrPerformanceReport.path}`} component={DrPerformanceReport} roles={[2, 3, 4]} />
        <PrivateRoute
          path={`${Routing.ClinicScheduleRecommender.path}`}
          component={ScheduleRecommender}
          roles={[2, 3, 5]}
          showSidebar={false}
        />
        <PrivateRoute exact={true} path={Routing.MultiClinic.path} component={MultiClinic} />
        <PrivateRoute exact={true} path={Routing.SingleClinic.path} component={SingleClinic} />
        <PrivateRoute
          exact={true}
          path={Routing.PerformanceSummaryReport.path}
          component={PerformanceSummaryReport}
          roles={[2, 3]}
        />
        <PrivateRoute
          exact={true}
          path={Routing.TotalRevenueBarChart.path}
          component={PerformanceSummaryReport}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Total Revenue Bar Graph' }}
        />
        <PrivateRoute
          exact={true}
          path={Routing.TotalRevenuePieChart.path}
          component={PerformanceSummaryReport}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Total Revenue Pie Chart' }}
        />
        {/* // #265: Change 'for' to 'of' for revenue per hour and average time */}
        <PrivateRoute
          exact={true}
          path={Routing.TreatmentTarget.path}
          component={PerformanceSummaryReport}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Revenue per Hour of Treatment' }}
        />
        <PrivateRoute
          exact={true}
          path={Routing.TreatmentAverage.path}
          component={PerformanceSummaryReport}
          roles={[2, 3]}
          componentProps={{ selectedComponent: 'Average Time of Treatment' }}
        />
        <PrivateRoute 
          exact={true} 
          path={Routing.MonthlyReport.path}
          component={MonthlyReport} 
          roles={[1, 2]}
          componentProps={{ type: 'chain' }}
        />
        <PrivateRoute 
          exact={true} 
          path={Routing.MonthlyBranchReport.path} 
          component={MonthlyReport} 
          roles={[1, 2, 3]} 
          componentProps={{ type: 'branch' }}
        />
        {/* --- #406 START: PATIENT SEGMENTATION DASHBOARD --- */}
        <PrivateRoute
          exact={true}
          path={Routing.PatientSegmentationDashboard.path}
          component={PatientSegmentationDashboard}
          roles={[2]}
        />
        <PrivateRoute
          exact={true}
          path={Routing.SegmentCategoryHorizontalBarChart.path}
          component={SegmentCategoryHorizontalBarChart}
          roles={[2]}
        />
        <PrivateRoute
          exact={true}
          path={Routing.RFMBubbleChart.path}
          component={RFMBubbleChart}
          roles={[2]}
        />
        <PrivateRoute
          exact={true}
          path={Routing.RFMScatterPlot.path}
          component={RFMScatterPlot}
          roles={[2]}
        />
        {/* --- #406 END --- */}
        <Route exact={true} path={Routing.PasswordRecovery.path} component={PasswordRecovery} />
        <Route path="*" component={NotFound} exact={true} />
      </Switch>
    </AvatarProvider>
  );
};

export default Mainpage;

