import { useState, useEffect } from "react"
import _ from "underscore"
import { formatAmount, formatNumber } from "utils/parser"
import moment from "moment-timezone"

const YTD = ({selectedYear, ytdData, isEmbedded}) => {
  const [ytd, setYtd] = useState({
    months: [],
    data: {
      prod: [],
      day: [],
      prodPerDay: [],
      timeAvailWorked: [],
      prodPerHr: [],
    },
  })

  let title = !isEmbedded ? `Tabulated Metrics Report (${selectedYear === moment().format('YYYY') ? 'YTD by' : 'By'} Month)` : ''

  useEffect(() => {
    !_.isEmpty(formatData(ytdData?.metrics)) &&
      setYtd(formatData(ytdData?.metrics))
  }, [ytdData?.metrics])

  useEffect(() => {
    if(!isEmbedded) {
      window.history.replaceState(null, "Medtrik", "/dr-performance-report/ytd");
      window.scrollTo(0, 0);
    }
  }, []);

  // #271: add `(hrs)` to timeAvailWorked
  const descs = {
    prod: `Dentist Production`,
    day: `Dentist Days`,
    prodPerDay: `Dentist Production/ Day`,
    timeAvailWorked: `Time Available (hrs)
    +Time Worked (hrs)
    `,
    prodPerHr: `Dentist Production/ Hr`,
  }

  return !_.isEmpty(ytd) ? (
    <div className='position-relative pt-1 pb-5'>
      <div className='page-tittle'>{title}</div>
      <section className={`position-relative${!isEmbedded ? ' px-1 mt-5' : ''}`}>
        <table className='table rounded mb-0 app-font-class-0'>
          <thead className='thead-light'>
            <tr>
              <th colSpan={4} className='text-start'>
                metrics
              </th>
              {ytd.months.map((mon, index) => (
                <th className='p-1' key={index}>{mon}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(ytd.data).map(([mName, each], index) => {
              let title = _.get(descs, mName)
              switch (mName) {
                case "prodPerHr":
                  return (
                    <SpannedRow
                      key={index}
                      title={title}
                      subTitle={"Actual"}
                      data={_.pluck(each, "actual")}
                      rowSpan={1}
                      colSpan={3}
                    />
                  )
                case "timeAvailWorked":
                  return <GroupedType1 key={index} title={<div style={{marginBottom: 0}}><div>{title.split("+")[0]}</div><br /><div>{title.split("+")[1]}</div></div>} data={each} />

                default:
                  return <GroupedType1 key={index} title={title} data={each} />
              }
            })}
          </tbody>
        </table>
      </section>
    </div>
  ) : null
}

// data : [{target:any, actual: any, variance: any}]
let GroupedType1 = ({ title, data }) => {
  return (
    <>
      {/* target */}
      <SpannedRow
        title={title}
        subTitle={"Target"}
        data={_.pluck(data, "target")}
        rowSpan={3}
        colSpan={3}
      />
      {/* actual */}
      <SpannedRow subTitle={"Actual"} data={_.pluck(data, "actual")} />
      {/* variance */}
      <SpannedRow subTitle={"Variance"} data={_.pluck(data, "variance")} />
    </>
  )
}

let GroupedType2 = ({ titles = [], data }) => {
  return (
    <>
      {/* target */}
      <SpannedRow
        title={titles[0]}
        subTitle={"Target"}
        data={_.pluck(data, "target")}
        rowSpan={1}
        colSpan={3}
      />
      {/* actual */}
      <SpannedRow
        rowSpan={2}
        colSpan={3}
        title={titles[1]}
        subTitle={"Actual"}
        data={_.pluck(data, "actual")}
      />
      {/* variance */}
      <SpannedRow subTitle={"Variance"} data={_.pluck(data, "variance")} />
    </>
  )
}

let SpannedRow = ({ title, subTitle, data = [], rowSpan = 1, colSpan = 1 }) => {
  return (
    <tr>
      {title && (
        <td
          className={`text-start p-1 text-wrap`}
          rowSpan={rowSpan}
          colSpan={colSpan}
        >
          {title}
        </td>
      )}
      {subTitle && <td className='p-1'>{subTitle}</td>}
      {data.map((e, i) => (
        <td key={i}>{e}</td>
      ))}
    </tr>
  )
}

const formatData = (ytd) => {
  let data = {
    prod: [],
    day: [],
    prodPerDay: [],
    timeAvailWorked: [],
    prodPerHr: [],
    // ttlNewPatient: [],
    // ttlExistPatient: [],
  }
  let months = []

  ytd?.forEach((metric) => {
    let {
      month,
      prodTarget,
      prodActual,
      prodVariance,
      dayTarget,
      dayActual,
      dayVariance,
      prodPerDayTarget,
      prodPerDayActual,
      prodPerDayVariance,
      timeAvailWorkedTarget,
      timeAvailWorkedActual,
      timeAvailWorkedVariance,
      prodPerHrActual,
      // ttlNewPatientTarget,
      // ttlNewPatientActual,
      // ttlNewPatientVariance,
      // ttlExistPatientTarget,
      // ttlExistPatientActual,
      // ttlExistPatientVariance,
    } = metric

    months.push(month)
    data.prod.push({
      month,
      target: formatAmount(prodTarget ?? 0),
      actual: formatAmount(prodActual ?? 0),
      variance: prodVariance ?? 0,
    })
    data.day.push({
      month,
      target: formatNumber(dayTarget ?? 0),
      actual: formatNumber(dayActual ?? 0),
      variance: dayVariance ?? 0,
    })
    data.prodPerDay.push({
      month,
      target: formatAmount(prodPerDayTarget ?? 0),
      actual: formatAmount(prodPerDayActual ?? 0),
      variance: prodPerDayVariance ?? 0,
    })
    data.timeAvailWorked.push({
      month,
      target: formatNumber(timeAvailWorkedTarget ?? 0),
      actual: formatNumber(timeAvailWorkedActual ?? 0),
      variance: timeAvailWorkedVariance ?? 0,
    })
    data.prodPerHr.push({
      month,
      actual: formatAmount(prodPerHrActual ?? 0),
    })
    // data.ttlNewPatient.push({
    //   month,
    //   target: ttlNewPatientTarget,
    //   actual: ttlNewPatientActual,
    //   variance: ttlNewPatientVariance,
    // })
    // data.ttlExistPatient.push({
    //   month,
    //   target: ttlExistPatientTarget,
    //   actual: ttlExistPatientActual,
    //   variance: ttlExistPatientVariance,
    // })
  })
  return { months, data }
}

export default YTD
