import React, { useState, useEffect } from "react";
import { Col, Row} from 'react-bootstrap';
import _ from "lodash";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import '../style.css';

const ReferralSource = (props) => {
    const [order, setOrder] = React.useState('asc');
    const handleRequestSort = () => {
        const isAsc = order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setDistributionData(order === 'asc' ? _.sortBy(distributionData, 'referralSource') : _.sortBy(distributionData, 'referralSource').reverse());
    };

    const [ distributionData, setDistributionData ] = useState([]);
    const columns = [
        { id: 'referralSource', label: 'Referral Source', minWidth: 170 },
        { id: 'values', label: '', minWidth: 200 }
    ];
    const rowColors = ['#2C5A84', '#55ABBD', '#69B6C4', '#A0D4D1', '#ACDCD5', '#B5E0D7'];

    const referralSource = props.referralSource

    useEffect(() => {
        let distributionData = [];
        if(referralSource) {
            distributionData = _.sortBy(referralSource, [function(n) { return parseInt(n.refSourceCount); }]).reverse();
            distributionData = _.map(distributionData, (d, i) => {
                return {
                    id: i,
                    referralSource: _.startCase(d.refSourceName),
                    values: `${d.refSourcePercentage}\n${d.refSourceCount}`,
                    bcolor: i < rowColors.length ? rowColors[i] : '#B6E1D7',
                    color: i === 0 ? '#ffffff' : '#000000'
                };
            })
            setDistributionData(distributionData);
        }
    }, [referralSource]);

    useEffect(() => {
        if (!props.isEmbedded) {
            window.history.replaceState(
                null,
                "Medtrik",
                "/patient/dashboard/referral-source"
            );
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <Row className={!props.isEmbedded ? "pb-5" : "pb-4"}>
            <Col xs={12} lg={12} className={!props.isEmbedded ? "px-8 mt-4" : ""}>
                <Paper sx={{ width: '100%' }}>
                    <TableContainer sx={{ maxHeight: 432 }}>
                        <Table stickyHeader aria-label="sticky table" sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
                            {!props.isEmbedded &&
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" colSpan={2} sx={{ fontSize: '24px', backgroundColor: '#E6E6E6', color: '#656565', padding: '17px' }}>
                                            Referral Source
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ top: 0, minWidth: column.minWidth, fontSize: '18px' }}
                                            sortDirection={column.id === 'referralSource' ? order : false}>
                                            <TableSortLabel
                                                active={column.id === 'referralSource'}
                                                direction={column.id === 'referralSource' ? order : 'asc'}
                                                onClick={handleRequestSort}>
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                            }
                            <TableBody>
                                {distributionData.map((row) => {
                                    return (
                                        <TableRow key={row.id}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        sx={{
                                                            backgroundColor: column.id === 'values' ? row.bcolor : '#ffffff',
                                                            color: column.id === 'values' && row.color ? row.color : '#000000'
                                                        }}>
                                                        {value ? value.split('\n')[0] : 'Not Specified'}<br />{value ? value.split('\n')[1] : ''}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Col>
        </Row>
    )
}

export default ReferralSource;
