import React, { useEffect } from "react"
import Select, { components } from "react-select"
import _ from "lodash"
import { Checkbox } from "@mui/material";


const CustomOption = (props) => {
  return (
    <div>
      <components.Option {...props} className ="react-select-option">
          <Checkbox
              checked={props.isSelected}
              color="default"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          />
          <label style={{whiteSpace: "nowrap"}}>{props.label}</label>
      </components.Option>
    </div>
  );
};


// #406: added additional style parameters to make multiselect control more customisable
export default function MultiSelect({onChange: handleChange, options=[], valueForAll, labelForAll, placeholder, value, menuIsOpen=false, styleControl={}, styleMenu={}, styleOption={}}) {
  // unless specified, width of drop down menu will follow max content length
  let styleMenuFixed;
  if (Object.keys(styleMenu).length===0) {
    styleMenuFixed = {
      width: "max-content"
    } 
  } else {
    styleMenuFixed = Object.assign({}, styleMenu);
  }

  // unless menuIsOpen=true, then don't include menuIsOpen in the props
  let conditionalProps = {}
  if (menuIsOpen) {
    conditionalProps.menuIsOpen = true;
  }

  useEffect(() => {
    valueForAll && !options.some(e => e.value === valueForAll) && options.unshift({value: valueForAll, label: labelForAll ?? "Select All"})
  }, [options])
  return (
    <Select 
      className="me-2" 
      placeholder={placeholder} 
      styles = {
          {
            // include all new style params
            menu: (provided, state) => (Object.assign({}, provided, styleMenuFixed)),
            option: (provided, state) => (Object.assign({}, provided, styleOption)),
            control: (provided, state) => (Object.assign({}, provided, styleControl))
          }        
      }
      components={{
        IndicatorSeparator: () => null,
        Option: CustomOption,
      }} 
      onChange={(selected) => {
        if(Array.isArray(selected) && valueForAll && selected.map(e => e.value).includes(valueForAll)) {
          handleChange(selected.filter(e => e.value === valueForAll))
          return
        }
        if(Array.isArray(selected)) {
          handleChange(selected) // for multi select
          return
        }
      }}
      value={!_.isEmpty(value) && value}
      options={options}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      // include conditional props
      {...conditionalProps}/>
  );
}
