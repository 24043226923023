import React from 'react'
import './style.css'

function TextTable({column, data, type}) {
    return (
        <div className="tableWrapper">
          <table className={type}>
            <thead>
                <tr>
                    <th>{column}</th>
                </tr>
            </thead>
            <tbody>
              {data.map((row, i) => (
                <tr key={i}>
                    <td>{row}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    )
}

export default TextTable