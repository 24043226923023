import { useState, useEffect } from "react"
import Chart from "react-apexcharts"
import _ from "lodash"

import { formatAmount } from "utils/parser"
import { format } from "date-fns"
import { SYSTEM_DATE_DISPLAY_FORMAT_DF } from "constants/common"

const MTDPerformance = ({mtdPerformanceData, isEmbedded}) => {
  const [series, setSeries] = useState([])
  const [categories, setCategories] = useState([])
  const [kpiTarget, setKpiTarget] = useState(Number.NEGATIVE_INFINITY)

  let options = {
    chart: {
      stacked: true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "bottom",
          hideOverflowingLabels: false,
        },
      },
    },
    title: {
      text: !isEmbedded ? "MTD Performance" : '',
    },
    dataLabels: {
      formatter: (val) => !Number.isNaN(val) ? `${formatAmount(val)}` : "",
    },
    xaxis: {
      type: "categories",
      categories: categories,
      labels: {
        show: true,
        formatter: (val) => {
          return _.isDate(val)
            ? `As at ${format(val, SYSTEM_DATE_DISPLAY_FORMAT_DF)}`
            : val
        },
      },
    },
    yaxis: {
      min: 0,
      max: _.max([series[2]?.data[0], series[2]?.data[1], parseFloat(kpiTarget)]),
      labels: {
        show: false,
      },
    },
    annotations: {
      yaxis: [
        {
          y: kpiTarget,
          strokeDashArray: 5,
          borderColor: "#DB2F2F",
          width: "80%",
          offsetY: 0,
          label: {
            borderColor: "transparent",
            text: `KPI Target: ${formatAmount(kpiTarget)}`,
            textAnchor: "end",
            position: "right",
            offsetX: -70,
            offsetY: 0,
            style: {
              background: "transparent",
              color: "#000000",
              fontSize: "15px",
              fontWeight: "400",
              fontFamily: "Poppins",
              cssClass: "apexcharts-yaxis-annotation-label",
            },
          },
        },
      ],
    },
    legend: {
      show: !isEmbedded,
      inverseOrder: false
    },
    tooltip: {
      y: {
        formatter: (val) => formatAmount(val)
      }
    }
  }

  useEffect(() => {
    let update = formatData(mtdPerformanceData)
    setSeries(update.series)
    setCategories(update.categories)
    setKpiTarget(update.kpiTarget)
  }, [mtdPerformanceData])

  // setState: categories
  useEffect(() => {
    _.assign(options, {
      xaxis: {
        categories: categories,
      },
    })
  }, [categories])

  useEffect(() => {
    if(!isEmbedded) {
      window.history.replaceState(null, "Medtrik", "/dr-performance-report/mtd-performance")
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <Chart options={options} series={series} type='bar' height={isEmbedded ? 424 : '100%'} />
  )
}

let formatData = (data) => {
  // return format
  if (!data?.thisMonth)
    return {
      series: [],
      categories: [],
      kpiTarget: Number.NEGATIVE_INFINITY,
    }

  return {
    series: [
      {
        name: "Cost",
        data: [
          parseFloat(data?.drTtlCost ?? "0"),
          parseFloat(data?.drRunrateCost ?? "0"),
          ""
        ],
      },
      {
        name: "Profit",
        data: [
          parseFloat(data?.drTtlProfit ?? "0"),
          parseFloat(data?.drRunrateProfit ?? "0"),
          ""
        ],
      },
      {
        name: "Revenue",
        data: [
          parseFloat(data?.drTtlCost ?? "0") + parseFloat(data?.drTtlProfit ?? "0"),
          parseFloat(data?.drRunrateCost ?? "0") + parseFloat(data?.drRunrateProfit ?? "0"),
          ""
        ],
      },
    ],
    categories: [new Date(), "Run Rate", ""],
    kpiTarget: data?.kpiTargetValue ?? Number.NEGATIVE_INFINITY,
  }
}

export default MTDPerformance
