// =========================================================
// * Volt Pro React Dashboard
// =========================================================

// * Product Page: https://themesberg.com/product/dashboard/volt-pro-react
// * Copyright 2021 Themesberg (https://www.themesberg.com)
// * License Information (https://themesberg.com/licensing)

// * Designed and coded by https://themesberg.com

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. Please contact us to request a removal.

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import promise from 'redux-promise';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createBrowserHistory } from 'history';

import rootReducer from 'reducers';
import initialState from 'reducers/initialState';

// core styles
import './scss/volt.scss';

// vendor styles
import 'leaflet/dist/leaflet.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-datetime/css/react-datetime.css';

import Mainpage from 'components/Mainpage';

import 'assets/style.css';
import 'apex';
import { AvatarProvider } from 'actions/avatarContext';

// #409: Scroll to top everytime we load a new route included in Mainpage.js
import ScrollToTop from 'components/ScrollToTop';

const composeEnhancers = composeWithDevTools({});
const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk, promise, logger)));
// const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk, promise)));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter forceRefresh={true}>
      <ScrollToTop/>
      <Mainpage />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
