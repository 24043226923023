import React, { useState, useEffect } from "react";
import { Col } from 'react-bootstrap';
import Chart from "react-apexcharts";
import _ from "lodash";

import '../style.css';
import { formatAmount, trimAmount } from "utils/parser";

const KPIProgressBar = ({isEmbedded, kpiProgress}) => {
    const [ chartOptions, setChartOptions ] = useState({
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 0
            },
        },
        xaxis: {
            categories: ['']
        },
        yaxis: {},
        annotations: {},
        legend: {
            fontSize:'14px',
            position: 'right',
            offsetY: 40,
            offsetX:0
        },
        fill: {
            opacity: 1,
        },
        colors: ['#C7C7C7', '#E7E7E7', '#53C1DE'],
        tooltip: {}
    });

    const customTooltip = () => {
        if(kpiProgress?.kpiProgressBarGraph) {
            return (
                `<div>
                    <p> Revenue (To-date): ${formatAmount(kpiProgress.kpiProgressBarGraph.kpiRevenue)}</p>
                    <p> Profit (To-date): ${formatAmount(kpiProgress.kpiProgressBarGraph.kpiProfit)}</p>
                    <p> Margin (To-date): ${trimAmount(kpiProgress.kpiProgressBarGraph.kpiPrcntMargin)}%</p>
                    <p> Run Rate (Revenue): ${formatAmount(kpiProgress.kpiProgressBarGraph.kpiRunRate)}</p>
                    <p> Cost (To-date): ${formatAmount(kpiProgress.kpiProgressBarGraph.kpiTotalCost)}</p>
                </div>`
            );
        }
    }

    const [ chartSeries, setChartSeries ] = useState([
        {
            name: 'Cost (To-date)',
            data: []
        },
        {
            name: 'Profit (To-date)',
            data: []
        },
        {
            name: isEmbedded ? 'Run Rate (Revenue)' : 'Run Rate (Revenue) - Revenue (To-date)',
            data: []
        }
    ]);

    useEffect(() => {
        if(!isEmbedded) {
            window.history.replaceState(null, "Medtrik", "/finance/dashboard/kpi-progress-bar");
            window.scrollTo(0, 0);
        }
    }, []);

    useEffect(() => {
        if(kpiProgress.kpiProgressBarGraph) {
            setChartSeries([
                {
                    ...chartSeries[0],
                    data: [kpiProgress.kpiProgressBarGraph.kpiTotalCost]
                },
                {
                    ...chartSeries[1],
                    data: [kpiProgress.kpiProgressBarGraph.kpiProfit]
                },
                {
                    ...chartSeries[3],
                    data: [kpiProgress.kpiProgressBarGraph.kpiRunRateDiff]
                }
            ]);
            const sumOfSeries = _.sum(
                _.compact([
                    parseFloat(kpiProgress.kpiProgressBarGraph.kpiTotalCost),
                    parseFloat(kpiProgress.kpiProgressBarGraph.kpiProfit),
                    parseFloat(kpiProgress.kpiProgressBarGraph.kpiRunRateDiff),
                ])
            );
            setChartOptions({...chartOptions, annotations: {
                position: "front",
                yaxis: [
                    {
                        y: kpiProgress.kpiProgressBarGraph.kpiTarget,
                        borderColor: "#DB2F2F",
                        width: "100%",
                        label: {
                            borderColor: 'none',
                            style: {
                              color: "#DB2F2F",
                              background: 'none',
                              fontSize: '14px',
                            },
                            text: `KPI Target: ${formatAmount(kpiProgress.kpiProgressBarGraph.kpiTarget)}`,
                        }
                    },
                ]
            }, tooltip: {
                custom: customTooltip,
                fixed: {
                    enabled: true,
                    position: 'right',
                    offsetX: 0,
                    offsetY: 0,
                },
            }, yaxis: {
                min: 0,
                max: (max) => {
                    if(sumOfSeries < parseFloat(kpiProgress.kpiProgressBarGraph.kpiTarget)) {
                        return parseFloat(kpiProgress.kpiProgressBarGraph.kpiTarget)
                    }
                    return max;
                },
                forceNiceScale: true,
                labels: {
                    formatter: (value) => formatAmount(value),
                    style: {
                        fontSize: '12px'
                    }
                },
            }});
        }
    }, [kpiProgress]);

    return (
        <>
        <Col xs={12} lg={2}></Col>
        <Col xs={12} lg={12}>
            <Chart options={chartOptions} series={chartSeries} type="bar" height={isEmbedded ? 420 : 350} width={isEmbedded ? 420 : 750} style={{ paddingBottom:0 }} />
            <div style={{ fontWeight: "normal", marginBottom:"15px", justifyContent:'center', alignItems:'center', marginTop:"-20px", marginRight:"0px" }}>
                    <b>Note:</b> The Run Rate for this graph reflects the financial projection for the full current year. 
            </div>
        </Col>
        </>
    )
}

export default KPIProgressBar;
