// PDFPreview.js
import React from 'react';
// Import the main component
import ENVIRONMENT_VARIABLES from 'config';

const PDFPreview = () => {
  const userManual = ENVIRONMENT_VARIABLES.USER_MANUAL;
  return (
    <div
      style={{
        border: '1px solid rgba(0, 0, 0, 0.3)',
        height: '750px',
      }}
    >
      {/* Version Automation */}
      <object data="/pdf/User-Manual.pdf#toolbar=0" type="application/pdf" width="100%" height="1200px">
        <div id="toolbarViewer" style={{ display: 'none' }}></div>
      </object>
    </div>
  );
};

export default PDFPreview;
