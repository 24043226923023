import React, { useState, useEffect } from "react";
import { Col } from 'react-bootstrap';
import _ from "lodash";
import Chart from "react-apexcharts";

import '../style.css';
import { formatAmount } from "utils/parser";

const PerInstanceOfTreatmentByDTC = ({isEmbedded, treatmentByDiscreteTreatmentCycle}) => {
    const [ chartOptions, setChartOptions ] = useState({
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 0
            },
        },
        xaxis: {
            labels: {
                show: !isEmbedded,
                rotate: -90,
                minHeight: 0,
                maxHeight: 400
            },
            categories: [],
        },
        yaxis: {
            labels: {
                formatter: (value) => formatAmount(value),
                style: {
                    fontSize: '14px'
                }
            },
        },
        legend: {
            show: !isEmbedded,
            position: 'bottom',
            horizontalAlign: 'center',
            offsetY: 0
        },
        tooltip: {},
        fill: {
            opacity: 1,
        },
        colors: ['#59A4B5', '#99D9EA', '#B5E61D', '#CBB64A', '#9BA1A2', '#857BA9', '#A349A4'],
    });

    // #272: add html/jsx element in tooltip to display other costs
    // #271: add profit to tooltip
    const customTooltip = ({ dataPointIndex }, treatmentNames, revenue, profit, dentistCommission, materialCost, miscCost, overheadCost, paymentModeDiscount) => {
        return (
            `<div>
                <p> Treatment Name: <b>${treatmentNames[dataPointIndex]}</b> </p>
                <p> Average Revenue: <b>${formatAmount(revenue[dataPointIndex])}</b> </p>
                <p> Average Profit: <b>${formatAmount(profit[dataPointIndex])}</b> </p>
                <p> Dentist Commission: <b>${formatAmount(dentistCommission[dataPointIndex])}</b> </p>
                <p> Material Cost: <b>${formatAmount(materialCost[dataPointIndex])}</b> </p>
                <p> Misc. Cost: <b>${formatAmount(miscCost[dataPointIndex])}</b> </p>
                <p> Overhead Cost: <b>${formatAmount(overheadCost[dataPointIndex])}</b> </p>
                <p> Payment Mode Disc.: <b>${formatAmount(paymentModeDiscount[dataPointIndex])}</b> </p>
            </div>`
        );
    }

    // #272: added sorted arrays of other costs to chart data
    const [ chartSeries, setChartSeries ] = useState([
        {
            name: 'Avg. Dentist Commission',
            data: []
        },
        {
            name: 'Avg. Material Cost',
            data: []
        },
        {
            name: 'Avg. Misc Cost',
            data: []
        },
        {
            name: 'Avg. Overhead Cost',
            data: []
        },
        {
            name: 'Avg. Payment Mode Disc.',
            data: []
        },
        {
            name: 'Avg. Profit',
            data: []
        }
    ]);

    useEffect(() => {
        if(!isEmbedded) {
            window.history.replaceState(null, "Medtrik", "/finance/dashboard/per-instance-of-treatment-cycle");
            window.scrollTo(0, 0);
        }
    }, []);

    useEffect(() => {
        if(treatmentByDiscreteTreatmentCycle.pitByDiscreteTrtmtCycleGraph) {
            const treatmentNames = treatmentByDiscreteTreatmentCycle.pitByDiscreteTrtmtCycleGraph.map(graph => graph.treatmentName);
            // #272: read response data from API
            const dentistCommission = treatmentByDiscreteTreatmentCycle.pitByDiscreteTrtmtCycleGraph.map(graph => graph.avgDentistCommission ? parseFloat(graph.avgDentistCommission) : 0);
            const materialCost = treatmentByDiscreteTreatmentCycle.pitByDiscreteTrtmtCycleGraph.map(graph => graph.avgMaterialCost ? parseFloat(graph.avgMaterialCost) : 0);
            const miscCost = treatmentByDiscreteTreatmentCycle.pitByDiscreteTrtmtCycleGraph.map(graph => graph.avgMiscCost ? parseFloat(graph.avgMiscCost) : 0);
            const overheadCost = treatmentByDiscreteTreatmentCycle.pitByDiscreteTrtmtCycleGraph.map(graph => graph.avgOverheadCost ? parseFloat(graph.avgOverheadCost) : 0);
            const paymentModeDiscount = treatmentByDiscreteTreatmentCycle.pitByDiscreteTrtmtCycleGraph.map(graph => graph.avgPaymentModeDiscount ? parseFloat(graph.avgPaymentModeDiscount) : 0)
            const profit = treatmentByDiscreteTreatmentCycle.pitByDiscreteTrtmtCycleGraph.map(graph => graph.avgProfit ? parseFloat(graph.avgProfit) : 0);
            const revenue = treatmentByDiscreteTreatmentCycle.pitByDiscreteTrtmtCycleGraph.map(graph => graph.avgRevenue ? parseFloat(graph.avgRevenue) : 0);
            const labFree = treatmentByDiscreteTreatmentCycle.pitByDiscreteTrtmtCycleGraph.map(graph => graph.labFree);

            // #272: add payment and material
            const seriesSum = miscCost.map((value, index) => value + overheadCost[index] + dentistCommission[index] + profit[index] + materialCost[index] + paymentModeDiscount[index]);
            const sortedSeriesSum = [...seriesSum];
            sortedSeriesSum.sort((a, b) => b - a);

            let sortedTreatmentNames = [];
            let sortedDentistCommission = [];
            let sortedMaterialCost = [];
            let sortedMiscCost = [];
            let sortedOverheadCost = [];
            let sortedPaymentModeDisc = [];
            let sortedProfit = [];
            let sortedRevenue = [];
            let sortedLabFree = [];
            miscCost.forEach((value, index) => {
                // #272: add payment and material
                const sum = value + overheadCost[index] + dentistCommission[index] + profit[index] + materialCost[index] + paymentModeDiscount[index];
                sortedTreatmentNames[sortedSeriesSum.indexOf(sum)] && sortedTreatmentNames[sortedSeriesSum.indexOf(sum)].length ? sortedTreatmentNames[sortedSeriesSum.indexOf(sum)].push(treatmentNames[index]) : sortedTreatmentNames[sortedSeriesSum.indexOf(sum)] = [treatmentNames[index]];
                // #272: sort all costs
                sortedDentistCommission[sortedSeriesSum.indexOf(sum)] && sortedDentistCommission[sortedSeriesSum.indexOf(sum)].length ? sortedDentistCommission[sortedSeriesSum.indexOf(sum)].push(dentistCommission[index]) : sortedDentistCommission[sortedSeriesSum.indexOf(sum)] = [dentistCommission[index]];
                sortedMaterialCost[sortedSeriesSum.indexOf(sum)] && sortedMaterialCost[sortedSeriesSum.indexOf(sum)].length ? sortedMaterialCost[sortedSeriesSum.indexOf(sum)].push(materialCost[index]) : sortedMaterialCost[sortedSeriesSum.indexOf(sum)] = [materialCost[index]];
                sortedMiscCost[sortedSeriesSum.indexOf(sum)] && sortedMiscCost[sortedSeriesSum.indexOf(sum)].length ? sortedMiscCost[sortedSeriesSum.indexOf(sum)].push(miscCost[index]) : sortedMiscCost[sortedSeriesSum.indexOf(sum)] = [miscCost[index]];
                sortedOverheadCost[sortedSeriesSum.indexOf(sum)] && sortedOverheadCost[sortedSeriesSum.indexOf(sum)].length ? sortedOverheadCost[sortedSeriesSum.indexOf(sum)].push(overheadCost[index]) : sortedOverheadCost[sortedSeriesSum.indexOf(sum)] = [overheadCost[index]];
                sortedPaymentModeDisc[sortedSeriesSum.indexOf(sum)] && sortedPaymentModeDisc[sortedSeriesSum.indexOf(sum)].length ? sortedPaymentModeDisc[sortedSeriesSum.indexOf(sum)].push(paymentModeDiscount[index]) : sortedPaymentModeDisc[sortedSeriesSum.indexOf(sum)] = [paymentModeDiscount[index]];
                sortedProfit[sortedSeriesSum.indexOf(sum)] && sortedProfit[sortedSeriesSum.indexOf(sum)].length ? sortedProfit[sortedSeriesSum.indexOf(sum)].push(profit[index]) : sortedProfit[sortedSeriesSum.indexOf(sum)] = [profit[index]];
                sortedRevenue[sortedSeriesSum.indexOf(sum)] && sortedRevenue[sortedSeriesSum.indexOf(sum)].length ? sortedRevenue[sortedSeriesSum.indexOf(sum)].push(revenue[index]) : sortedRevenue[sortedSeriesSum.indexOf(sum)] = [revenue[index]];
                sortedLabFree[sortedSeriesSum.indexOf(sum)] && sortedLabFree[sortedSeriesSum.indexOf(sum)].length ? sortedLabFree[sortedSeriesSum.indexOf(sum)].push(labFree[index]) : sortedLabFree[sortedSeriesSum.indexOf(sum)] = [labFree[index]];
            })

            sortedTreatmentNames = _.flatten(sortedTreatmentNames).filter(item => !_.isUndefined(item));
            // #272: add to flatten sorted cost arrays
            sortedDentistCommission = _.flatten(sortedDentistCommission).filter(item => !_.isUndefined(item));
            sortedMaterialCost = _.flatten(sortedMaterialCost).filter(item => !_.isUndefined(item));
            sortedMiscCost = _.flatten(sortedMiscCost).filter(item => !_.isUndefined(item));
            sortedOverheadCost = _.flatten(sortedOverheadCost).filter(item => !_.isUndefined(item));
            sortedPaymentModeDisc = _.flatten(sortedPaymentModeDisc).filter(item => !_.isUndefined(item));
            sortedProfit = _.flatten(sortedProfit).filter(item => !_.isUndefined(item));
            sortedRevenue = _.flatten(sortedRevenue).filter(item => !_.isUndefined(item));
            sortedLabFree = _.flatten(sortedLabFree).filter(item => !_.isUndefined(item));
            
            setChartOptions({...chartOptions, xaxis: { categories: sortedTreatmentNames }, tooltip: { custom: (args) => customTooltip(args, sortedTreatmentNames, sortedRevenue, sortedProfit, sortedDentistCommission, sortedMaterialCost, sortedMiscCost, sortedOverheadCost, sortedPaymentModeDisc) }});
            setChartSeries([
                {...chartSeries[0], data: sortedDentistCommission},
                {...chartSeries[1], data: sortedMaterialCost},
                {...chartSeries[2], data: sortedMiscCost},
                {...chartSeries[3], data: sortedOverheadCost},
                {...chartSeries[4], data: sortedPaymentModeDisc},
                {...chartSeries[5], data: sortedProfit}
            ]);
        }
    }, [treatmentByDiscreteTreatmentCycle]);

    return (
        <Col xs={12} lg={12} className={`${!isEmbedded ? 'px-8 ' : ''}overflow-chart`}>
            <Chart options={chartOptions} series={chartSeries} type="bar" height={isEmbedded ? 350 : 800} />
        </Col>
    )
}

export default PerInstanceOfTreatmentByDTC;
