import { startOfMonth, sub } from "date-fns";
import _ from "lodash";

const now = Date.now()
const min = 1
const max = 12
const step = 1
const type = 1

export const MONTH_SLIDER = {
    display: false,
    type,
    step,
    min,
    max,
    marks: _.range(1,13,1).map(each => ({value: each})),
    start: startOfMonth(sub(now, {months: max})),
    base: now
}
