/**
 * Main page for Patient Segmentation Dashboard
 * - if selectedComponent is null then show all graphs within the dashboard
 * - if graph (area within the pill boundary, except additional controls within it) is clicked -> only show the relevant component
 */

import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from '@reduxjs/toolkit';
import * as patientSegmentationActions from "actions/patientSegmentationActions";
import { Routing } from "routes"

import { format, parse, minTime } from "date-fns";
import { SYSTEM_DATE_DISPLAY_FORMAT2_DF, SYSTEM_DATE_FORMAT_DF } from "constants/common";

import segmentOrderConfig from "./PatientSegmentationOrderConfig"

import { Breadcrumb, Card, Row, Col } from 'react-bootstrap';

import MultiSelect from 'components/filters/MultiSelect';
import MonthSlider from 'components/filters/MonthSlider';
import { MONTH_SLIDER } from 'constants/date';

import { HomeIcon } from "@heroicons/react/solid";
import "./style.css"

import Loader from "components/Loader";
import SegmentCategoryHorizontalBarChart from './SegmentCategoryHorizontalBarChart';
import RFMBubbleChart from './RFMBubbleChart';
import RFMScatterPlot from './RFMScatterPlot';



const PatientSegmentationDashboard = (props) => {
    // 
    // ROUTES
    // 

    const history = useHistory();
    const redirectUrl = (url) => {
        history.push(url);
    };
    const goBackToDashboard = () => {
        window.history.replaceState(null, "Medtrik", "/patient-segmentation");
        setSelectedComponent(null);
      };

    // 
    // STATES
    // 

    const [selectedDateRange, setSelectedDateRange] = useState({});
    const [selectedSegments, setSelectedSegments] = useState([{ value: "all", label: "All Segments" }]);
    const [selectedComponent, setSelectedComponent] = useState(props.selectedComponent);

    // get segment filter option from PatientSegmentationOrderConfig.js
    let segmentOption = Object.keys(segmentOrderConfig).map(segment => { return { value: segment, label: segment } });
    segmentOption.unshift({ value: "all", label: "All Segments" })

    // 
    // COMPONENTS
    // 

    const componentMapping = {
        "Patient Count per Segment Category": SegmentCategoryHorizontalBarChart,
        "Visualisation of Recency, Frequency and Monetary Values of Patient Segments": RFMBubbleChart,
        "Recency, Frequency, and Monetary Correlations": RFMScatterPlot
    };

    const getSelectedComponent = (componentName, isEmbedded=false) => {
        const SelectedComponent = componentMapping[componentName];
        const childProps = Object.assign({}, props, {
            selectedDateRange,
            selectedSegments,
            isEmbedded
        });
        return <SelectedComponent {...childProps} />
    };

    // 
    // HANDLE FILTER CHANGES
    // 

    const handleDateRangeChange = (dateRange) => {
        setSelectedDateRange(dateRange);
    };

    const handleSegmentChange = (segments=[]) => {
        setSelectedSegments(segments);
    }

    // 
    // USEEFFECT RE-RENDERS
    // 

    // call API everytime either filters is changed
    useEffect(() => {
        if (selectedSegments && (selectedSegments.length>0) && selectedDateRange.startDate && selectedDateRange.endDate) {
            props.actions.PatientSegmentationActions.getSegmentCategoryHorizontalBarChart(selectedDateRange.startDate, selectedDateRange.endDate, selectedSegments.map(segment => { return segment.value }));
            props.actions.PatientSegmentationActions.getRFMBubbleChart(selectedDateRange.startDate, selectedDateRange.endDate, selectedSegments.map(segment => { return segment.value }));
        }
    }, [selectedSegments, selectedDateRange]);



    // 
    // RENDER
    // 

    return (
        <div>
            {/* IF EXPORT ACTION IS LOADING -> SHOW LOADER */}
            { props.isBarLoading && <Loader/>}
            {/* DASHBOARD TITLE AND BREADCRUMB ELEMENT */}
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                            {
                                selectedComponent
                                ?
                                <>
                                    <Breadcrumb.Item onClick={goBackToDashboard}>
                                        Patient Segmentation Dashboard
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>{selectedComponent}</Breadcrumb.Item>
                                </>
                                : <Breadcrumb.Item active>Patient Segmentation Dashboard</Breadcrumb.Item>
                            }
                    </Breadcrumb>
                    <h4>{selectedComponent ? selectedComponent : "Patient Segmentation Dashboard"}</h4>
                </div>
            </div>

            {/* DASHBOARD CONTENT */}
            <Card border="0" className="table-wrapper table-responsive shadow">
                <Card.Body>
                    <Row className="patient-segment-dashboard">
                        {/* FILTERS */}
                        <Col xs={12}>
                            <Row className="mb-3 pb-3">
                                <Col xs={12} lg={4}>
                                    <MultiSelect placeholder={"Select Segments"} onChange={handleSegmentChange} options={segmentOption} value={selectedSegments} valueForAll={"all"} labelForAll={"All Segments"} />
                                </Col>
                                <Col xs={12} lg={2}></Col>
                                <Col xs={12} lg={6}>
                                    <h6 className="m-0">Selected Latest Visit Range {selectedDateRange?.startDate && format(parse(selectedDateRange.startDate, SYSTEM_DATE_FORMAT_DF, minTime), SYSTEM_DATE_DISPLAY_FORMAT2_DF)} - {selectedDateRange?.endDate && format(parse(selectedDateRange.endDate, SYSTEM_DATE_FORMAT_DF, minTime), SYSTEM_DATE_DISPLAY_FORMAT2_DF)}</h6>
                                    {/* made the upper boundary in the slider to be static */}
                                    <MonthSlider {...Object.assign({}, MONTH_SLIDER, { display: true, type: 2, max: 24 })} handleMonthChange={handleDateRangeChange} staticThumbs={[1]} />
                                </Col>
                            </Row>
                        </Col>
                        {/* IF A COMPONENT IS SELECTED -> SHOW COMPONENT FROM ANOTHER FILE */}
                        {selectedComponent && getSelectedComponent(selectedComponent)}
                        {/* IF NO COMPONENT IS SELECTED -> SHOW ALL IN DASHBOARD */}
                        {!selectedComponent &&
                            Object.keys(componentMapping).map((componentName, index) => {
                                return (
                                    <Col xs={12} lg={12} key={index}>
                                        <div
                                            className="mb-4"
                                            onClick={() => setSelectedComponent(componentName)}
                                        >
                                            <div className="pill-container px-4 pt-3">
                                                <div className="pill-text mb-3">
                                                    {componentName}
                                                </div>
                                                { getSelectedComponent(componentName, true) }
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Card.Body>
            </Card>
        </div>
    
    );
};

// MAP GLOBAL STATE TO PROPS IN THIS COMPONENT
const mapStateToProps = (state) => {
    const { patientSegmentationReducer } = state;
    return {
        errorMessage: patientSegmentationReducer.errorMessage,
        isBarLoading: patientSegmentationReducer.isBarLoading,
        isExportLoading: patientSegmentationReducer.isExportLoading,
        segmentCategoryHorizontalBarChart: patientSegmentationReducer.segmentCategoryHorizontalBarChart,
        segmentPatientExportData: patientSegmentationReducer.segmentPatientExport,
        rfmScatterPlot: patientSegmentationReducer.rfmScatterPlot,
        rfmBubbleChart: patientSegmentationReducer.rfmBubbleChart
    };
};

// MAP ACTIONS TO PROPS IN THIS COMPONENT
const mapDispatchToProps = (dispatch) => ({
    actions: {
        PatientSegmentationActions:  bindActionCreators(patientSegmentationActions, dispatch)
    }
});

// CONNECT MAP FUNCTIONS AND EXPORT COMPONENT
export default connect(mapStateToProps, mapDispatchToProps)(PatientSegmentationDashboard);