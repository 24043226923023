import {
    GET_FINANCE_COMPONENTS,
    GET_FINANCE_CLINICS,
    GET_FINANCE_REVENUE_FIGURES,
    GET_FINANCE_REVENUE_GRAPH,
    GET_FINANCE_DAILY_REVENUE,
    GET_FINANCE_KPI_PROGRESS,
    GET_FINANCE_TREATMENT_BY_INVOICE_COUNT,
    GET_FINANCE_TREATMENT_BY_DISCRETE_TREATMENT_CYCLE,
    GET_FINANCE_PER_HOUR_TREATMENT_BY_INVOICE_COUNT,
    GET_FINANCE_REVENUE_FORECAST,
    GET_FINANCE_REVENUE_FORECAST_SHOW,
    GET_FINANCE_OPTIMAL_DOCTOR_CHAIR_RATIO
} from '../constants/actionsTypes';
import initialState from './initialState';

export default function financeReducer(state = initialState.financeReducer, action) {
    switch (action.type) {
        case GET_FINANCE_COMPONENTS:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                success_msg: null,
                dashboardData: action.data
            });

        case GET_FINANCE_CLINICS:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                success_msg: null,
                clinics: action.data
            });

        case GET_FINANCE_REVENUE_FIGURES:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                success_msg: null,
                revenueFigures: action.data
            });

        case GET_FINANCE_REVENUE_GRAPH:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                success_msg: null,
                revenueGraph: action.data
            });

        case GET_FINANCE_DAILY_REVENUE:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                success_msg: null,
                dailyRevenue: action.data
            });

        case GET_FINANCE_KPI_PROGRESS:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                success_msg: null,
                kpiProgress: action.data
            });

        case GET_FINANCE_TREATMENT_BY_INVOICE_COUNT:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                success_msg: null,
                treatmentByInvoiceCount: action.data
            });

        case GET_FINANCE_TREATMENT_BY_DISCRETE_TREATMENT_CYCLE:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                success_msg: null,
                treatmentByDiscreteTreatmentCycle: action.data
            });

        case GET_FINANCE_PER_HOUR_TREATMENT_BY_INVOICE_COUNT:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                success_msg: null,
                perHourTreatmentByInvoiceCount: action.data
            });

        case GET_FINANCE_REVENUE_FORECAST:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                success_msg: null,
                revenueForecastData: action.data
            });

        case GET_FINANCE_REVENUE_FORECAST_SHOW:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                success_msg: null,
                revenueForecastShow: action.data['filterShow']
            });

        case GET_FINANCE_OPTIMAL_DOCTOR_CHAIR_RATIO:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                success_msg: null,
                optimalDoctorChairRatio: action.data
            });

        default:
            return Object.assign({}, state, {
                loading: false
              });
    }
};
