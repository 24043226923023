import React, { useEffect, useState } from "react";
import { Form, Card, Button, Row, Col, Table } from "react-bootstrap";

import { Routing } from "routes";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as kpiPlannerAction from "actions/kpiPlannerAction";

import Box from "@mui/material/Box";
import { Slider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loader from "components/Loader";
import _ from "lodash";
import swal from "sweetalert";
import { numberWithCommas, roundToTwo } from "utils/parser.js";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    "& .Mui-active .MuiStepIcon-root": { color: "white" },
    "& .Mui-completed .MuiStepIcon-root": { color: "#2f4858" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "#dfe4ed" },
    "& .MuiStepLabel-root .MuiStepLabel-label": {
      fontFamily: "Poppins",
      fontSize: "1em",
      paddingBottom: "1em",
      fontWeight: "500",
      color: "#2f4858",
    },
  },
  cols: {
    paddingLeft: "1em",
    paddingRight: "2em",
  },
  label: {
    fontFamily: "Poppins",
    fontSize: "1em",
    fontWeight: "500",
  },
  tryial: {
    color: "green",
  },
  stepper: {
    iconColor: "yellow",
    hoveredIconColor: "grey",
    inactiveIconColor: "black",
    iconTextColor: "red",
    hoveredIconTextColor: "gold",
    inactiveIconTextColor: "pink",
  },
  textField: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    backgroundColor: "white",
  },
}));

const ChainLevel = ({
  step,
  setName,
  setStepPage,
  getReferenceChain,
  getAllocateChain,
  getChainKpiPlanningData,
  viewMode,
  chainId,
  year,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const steps = [
    "Propose Chain Target",
    "Propose Clinic Target",
    "Propose Doctor Target",
    "Confirm Doctor Target",
    "Confirm Clinic Target",
    "Confirm Chain Target",
  ];

  const initClinicData = (clinics) => {
    const clinicInputs = {};

    if (!clinics?.length) {
      return clinicInputs;
    }

    clinics.forEach((each) => {
      clinicInputs[each.clinicName] = {
        clinicId: each.clinicId,
        referenceAmount: each.referenceAmount,
        targetAmount: null,
        percentage: 0,
      };
    });

    return clinicInputs;
  };

  const reference = useSelector(
    (state) => state.kpiPlannerReducer.referencesChain
  );
  const allocate = useSelector(
    (state) => state.kpiPlannerReducer.allocatedByChain.allocateBy
  );
  const chainKPIData = useSelector(
    (state) => state.kpiPlannerReducer.chainKPIData
  );
  const [ranges, setRanges] = useState(
    chainKPIData
      ? chainKPIData.clinics.map((item) => {
          return { [item.clinicName]: 0 };
        })
      : {}
  );
  const initClinicInputData = initClinicData(chainKPIData?.clinics);
  const [clinicInputData, setClinicInputData] = useState(initClinicInputData);
  const [inputTarget, setInputTarget] = useState(
    chainKPIData?.inputTargetAmount
  );
  const [optionReference, setOptionReference] = useState();
  const [allocateBy, setAllocateBy] = useState();
  const [errors, setErrors] = useState({
    inputAmount: null,
    referenceId: null,
    allocateId: null,
  });
  const [isClickSelectReference, setIsClickSelectReference] = useState(false);
  const disable = false;

  const [loading, setLoading] = useState(true);

  const handleRangeChanges = (e) => {
    let { name, value } = e.target;

    if (!inputTarget) {
      return;
    }

    let tempClinicInputData = clinicInputData;

    if (!clinicInputData || !Object.keys(clinicInputData)?.length) {
      tempClinicInputData = initClinicInputData;
    }

    let targetAmount;

    if (value >= 0) {
      targetAmount = +((inputTarget * value) / 100).toFixed(2);
    }

    setRanges({
      ...ranges,
      [name]: value,
    });

    setClinicInputData({
      ...tempClinicInputData,
      [name]: {
        ...tempClinicInputData[name],
        percentage: value,
        targetAmount,
      },
    });

    setAllocateBy("4");
  };

  // #299: add onChange function for new form control
  const handleTargetAmountChanges = (name, value) => {
    if (!inputTarget) {
      return;
    }

    let tempClinicInputData = clinicInputData;

    if (!clinicInputData || !Object.keys(clinicInputData)?.length) {
      tempClinicInputData = initClinicInputData;
    }

    let targetAmount = Number(value) || 0;

    let percentage;
    if (targetAmount >= 0) {
      percentage = +(((targetAmount * 100) / inputTarget).toFixed())
    }

    setRanges({
      ...ranges,
      [name]: percentage,
    });

    setClinicInputData({
      ...tempClinicInputData,
      [name]: {
        ...tempClinicInputData[name],
        targetAmount: targetAmount,
        percentage: percentage,
      },
    });

    setAllocateBy("4");
  };

  const handleInputTargetChange = (e) => {
    let { value } = e.target;

    if (!clinicInputData || !Object.keys(clinicInputData)?.length) {
      setClinicInputData(initClinicInputData);
    }

    let tempClinicInputData = _.cloneDeep(clinicInputData);

    if (!clinicInputData || !Object.keys(clinicInputData)?.length) {
      tempClinicInputData = initClinicInputData;
    }

    setInputTarget(value);
    // if (!Number(value)) {
    //     setClinicInputData(initClinicInputData)
    //     setAllocateBy('4')
    //     return
    // }

    tempClinicInputData = changeDataClinicAcordingToAllocateId(
      tempClinicInputData,
      Number(value),
      Number(allocateBy)
    );
    setClinicInputData(tempClinicInputData);
  };

  const handleChangeAllocateInput = (e) => {
    setAllocateBy(e.target.value);
    if (inputTarget) {
      let tempClinicInputData = _.cloneDeep(clinicInputData);
      tempClinicInputData = changeDataClinicAcordingToAllocateId(
        tempClinicInputData,
        Number(inputTarget),
        Number(e.target.value)
      );
      setClinicInputData(tempClinicInputData);
    }
  };

  const changeDataClinicAcordingToAllocateId = (
    tempClinicInputData,
    value,
    allocateId
  ) => {
    let shallowClinicData = _.cloneDeep(tempClinicInputData);
    if (allocateId === 1) {
      Object.keys(shallowClinicData).forEach((key) => {
        shallowClinicData[key] = {
          ...shallowClinicData[key],
          targetAmount: +(value / Object.keys(clinicInputData)?.length).toFixed(2),
          percentage: roundToTwo(100 / Object.keys(clinicInputData)?.length),
        };
      });
    } else if (allocateId === 2 && chainKPIData.referenceAmount) {
      Object.keys(shallowClinicData).forEach((key) => {
        shallowClinicData[key] = {
          ...shallowClinicData[key],
          targetAmount:
            +((shallowClinicData[key].referenceAmount /
              chainKPIData.referenceAmount) *
            value).toFixed(2),
          percentage: (
            +(shallowClinicData[key].referenceAmount /
              chainKPIData.referenceAmount) *
            100
          ).toFixed(),
        };
      });
    } else if (allocateId === 4) {
      Object.keys(shallowClinicData).forEach((key) => {
        shallowClinicData[key] = {
          ...shallowClinicData[key],
          targetAmount: +((shallowClinicData[key].percentage / 100) * value).toFixed(2),
        };
      });
    }
    return shallowClinicData;
  };

  const marks = [
    {
      label: "-100%",
      value: 0,
    },

    {
      value: 100,
      label: "Max",
    },
  ];

  const datas = {
    chainId: chainKPIData?.chainId,
    referenceId: Number(optionReference),
    allocateId: Number(allocateBy),
    referenceAmount: chainKPIData?.referenceAmount,
    inputAmount: Number(inputTarget),
    clinics: [],
  };

  const validateFormData = (data) => {
    let errors = {};
    let formIsValid = true;

    // inputAmount
    if (!data?.inputAmount) {
      errors.inputAmount = "Please type input amount";
    }
    if (data?.inputAmount && typeof data?.inputAmount !== "number") {
      errors.inputAmount = "Input amount must be a number";
    }

    // referenceId
    if (!data?.referenceId) {
      errors.referenceId = "Please select reference";
    }

    // allocateId
    if (!data?.allocateId) {
      errors.allocateId = "Please select allocation";
    }

    // #299: add validation for target amount per clinic
    if (data?.clinics) {
      data.clinics.forEach(clinic => {
        if (!clinic.targetAmount) {
          errors.targetAmount = "Please fill in all clinic targets"
        }
        if (clinic.targetAmount && clinic.targetAmount > data.inputAmount) {
          errors.targetAmount = "Clinic targets must be less than chain target ($" + numberWithCommas(roundToTwo(data?.inputAmount)) + ")"
        }
        if (clinic.targetAmount && clinic.targetAmount < 0) {
          errors.targetAmount = "Clinic targets could not be negative"
        }
        if (clinic.targetAmount && typeof clinic.targetAmount !== "number") {
          errors.targetAmount = "Clinic targets must be a number"
        }
      })
    }

    if (Object.keys(errors)?.length) {
      formIsValid = false;
      setErrors(errors);
    }

    // #299: return 2 values
    return [formIsValid, errors];
  };

  const submitFormData = (e) => {
    const clinicFormData = [];

    for (const name in clinicInputData) {
      clinicFormData.push(clinicInputData[name]);
    }

    datas.clinics = clinicFormData;
    datas.year = year;

    e.preventDefault();

    // #299: modify to allow maximum limit:
    // if form is valid -> call API, if not -> show error popup
    let [isFormValid, formErrors] = validateFormData(datas)
    if (isFormValid) {
      setLoading(true);
      kpiPlannerAction
        .postChainProposeKPI(datas)
        .then((response) => {
          setLoading(false);
          if (response) {
            swal(response.message, {
              icon: "success",
            }).then(() => {
              prevStep1();
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      Object.entries(formErrors).forEach(entry => {
        const [key, value] = entry;
        swal({
          title: "Invalid Values",
          text: value,
          icon: "error"
        })
      })
    }
  }

  const prevStep1 = () => {
    history.push(Routing.KpiPlanner.path);
    // nextStep();
  };

  const handleChangeOptionsReference = (e) => {
    const optionReference = e.target.value;
    setOptionReference(optionReference);
    setLoading(true);
    setIsClickSelectReference(true);
    getChainKpiPlanningData(chainId, Number(optionReference), year);
  };

  const handleFindTotalTarget = (clinicInputData) => {
    if (!_.isEmpty(clinicInputData)) {
      let total = 0;
      for (const key in clinicInputData) {
        total = total + clinicInputData[key].targetAmount;
      }
      return numberWithCommas(roundToTwo(total));
    } else {
      return 0;
    }
  };

  useEffect(() => {
    setName(chainKPIData?.chainId);

    getAllocateChain(chainId, 0);
    getReferenceChain(chainId, 0);
  }, []);

  useEffect(() => {
    if (reference?.references?.length) {
      setOptionReference(reference.references[0].referenceId);
      setLoading(true);
      getChainKpiPlanningData(
        chainId,
        Number(reference.references[0].referenceId),
        year
      );
    }
  }, [reference]);

  useEffect(() => {
    if (allocate && allocate.length) {
      setAllocateBy(allocate[0].allocateById);
    }
  }, [allocate]);

  useEffect(() => {
    if (!_.isEmpty(chainKPIData)) {
      if (chainKPIData._id) {
        setStepPage(6);
        return;
      }
      setLoading(false);
      let shallowClinicData = initClinicData(chainKPIData?.clinics);
      if (isClickSelectReference) {
        shallowClinicData = changeDataClinicAcordingToAllocateId(
          shallowClinicData,
          Number(inputTarget),
          Number(allocateBy)
        );
        setIsClickSelectReference(false);
      }
      setClinicInputData(shallowClinicData);
    }
  }, [chainKPIData]);

  if (loading) {
    return <Loader></Loader>;
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div className="row">
          <div className="col-12 mb-3">
            <div className="wrapper-progressBar">
              <ul className="progressBar">
                {steps.map((el, index) => (
                  <li
                    key={index}
                    className={clsx({
                      "selected-progress": el === "Propose Chain Target",
                    })}
                  >
                    {el}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Box>

      <Card className="table-wrapper table-responsive shadow">
        <Card.Body>
          <Form>
            <Row>
              <Col xs={12} lg={4}>
                <div className="mt-4">
                  <h1>KPI Year: {year}</h1>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center chain-level">
              <Col xs={12} lg={4}>
                <p> Chain {chainKPIData?.chainId}</p>
                <Table className="align-items-center">
                  <tbody
                    key={indexedDB}
                    style={{ fontFamily: "Poppins", fontWeight: "500" }}
                  >
                    <tr className="table-active">
                      <td style={{ textAlign: "left" }}>Reference:</td>
                      <td>
                        $
                        {chainKPIData?.referenceAmount &&
                          numberWithCommas(
                            roundToTwo(chainKPIData?.referenceAmount)
                          )}
                      </td>
                      <td></td>
                    </tr>
                    <tr className="table-active">
                      <td style={{ textAlign: "left" }}>Input Target:</td>
                      <td>
                        <Form.Group controlId="inputTarget">
                          <Form.Control
                            type="number"
                            required={true}
                            value={inputTarget}
                            readOnly={disable}
                            onChange={handleInputTargetChange}
                          />
                        </Form.Group>
                        {errors?.inputAmount && (
                          <span style={{ color: "red", fontSize: "15px" }}>
                            {errors.inputAmount}
                          </span>
                        )}
                      </td>
                      <td>
                        {`  >>>   $${handleFindTotalTarget(clinicInputData)}`}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>

              <Col xs={0} lg={4}></Col>
              <Col xs={12} lg={4}>
                <Form.Group id="Reference" className="mb-4">
                  <Form.Label> Select reference: </Form.Label>
                  <Form.Select
                    required={true}
                    value={optionReference}
                    onChange={(e) => handleChangeOptionsReference(e)}
                    className="mb-4"
                    id="financialYears"
                    style={{ fontFamily: "Poppins", fontWeight: "500" }}
                  >
                    {disable ? (
                      <option defaultChecked>
                        {reference && reference.references[0].referenceName}
                      </option>
                    ) : (
                      <>
                        {reference &&
                          reference.references.map((item, index) => (
                            <option
                              key={index}
                              value={item.referenceId}
                            >
                              {item.referenceName}
                            </option>
                          ))}
                      </>
                    )}
                  </Form.Select>
                  {errors?.referenceId && (
                    <span style={{ color: "red" }}>{errors.referenceId}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row
              className="justify-content-left clinic-chain-level pt-5 pb-5"
              style={{ fontFamily: "Poppins", fontWeight: "500" }}
            >
              {chainKPIData &&
                _.sortBy(chainKPIData?.clinics, "clinicId").map(
                  (item, index) => (
                    <Col
                      key={index}
                      xs={12}
                      md={6}
                      lg={6}
                      xl={4}
                      className={classes.cols}
                    >
                      <Form.Label>{item.clinicName}</Form.Label>
                      <Table className="align-items-center table-responsive  ">
                        <tbody
                          style={{ fontFamily: "Poppins", fontWeight: "500" }}
                        >
                          <tr className="table-active">
                            <td style={{ textAlign: "left" }}>Reference:</td>
                            <td>
                              $
                              {numberWithCommas(
                                roundToTwo(item.referenceAmount)
                              )}
                            </td>
                          </tr>

                          <tr className="table-active">
                            <td style={{ textAlign: "left" }}>Target:</td>
                            {/* // #299: adding input field
                            <td>
                              $
                              {numberWithCommas(
                                roundToTwo(
                                  clinicInputData[item.clinicName]?.targetAmount
                                )
                              )}
                            </td> */}
                            <td>
                              <Form.Control
                                required={true}
                                type="number"
                                defaultValue={item.inputTargetAmount}
                                value={clinicInputData[item.clinicName].targetAmount}
                                onChange={(e) => handleTargetAmountChanges(item.clinicName, e.target.value)}
                                disabled={disable}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <Slider
                        defaultValue={item.inputTargetPercentage}
                        value={clinicInputData[item.clinicName].percentage}
                        name={item.clinicName}
                        onChange={handleRangeChanges}
                        marks={marks}
                        valueLabelDisplay="auto"
                        disabled={disable}
                        min={0}
                        max={100}
                        width={"10%"}
                        className={classes.cols}
                      />
                    </Col>
                  )
                )}
            </Row>

            <Row
              className="justify-content-center clinic-chain-level pt-1 pb-1"
              style={{ fontFamily: "Poppins", fontWeight: "500" }}
            >
              <Col xs={12} lg={4}>
                <Form.Group id="AllocatedBy" className="mb-4">
                  <Form.Label> Allocate By: </Form.Label>
                  <Form.Select
                    required={true}
                    value={allocateBy}
                    onChange={handleChangeAllocateInput}
                    className="mb-4"
                    id="financialYears"
                  >
                    {disable ? (
                      <option defaultChecked>
                        {allocate && allocate[0].allocateByName}
                      </option>
                    ) : (
                      <>
                        {allocate &&
                          allocate.map((item, index) => (
                            <option key={index} value={item.allocateById}>
                              {item.allocateByName}
                            </option>
                          ))}
                      </>
                    )}
                  </Form.Select>
                  {errors?.allocateId && (
                    <span style={{ color: "red" }}>{errors.allocateId}</span>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} lg={8}></Col>
            </Row>
            <div style={{ justifyContent: "space-around" }}>
              <Row
                className="justify-content-center clinic-chain-level pt-5 pb-5"
                style={{ fontFamily: "Poppins", fontWeight: "500" }}
              >
                <Col xs={12} lg={8}></Col>
                <Col xs={12} lg={2}>
                  <Table className="align-items-center">
                    <tbody>
                      <tr className="table-active">
                        <td>Total:</td>
                        <td>${handleFindTotalTarget(clinicInputData)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col xs={12} lg={2} className="button-color">
                  <Button
                    onClick={submitFormData}
                    variant="primary"
                    className="animate-up-2 btn btn-gray-800 btn-lg"
                    type="button"
                    disabled={!!viewMode || !year}
                  >
                    Propose
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReferenceChain: (chainID, clinicID) =>
      dispatch(kpiPlannerAction.getReferenceByChain(chainID, clinicID)),
    getAllocateChain: (chainID, clinicID) =>
      dispatch(kpiPlannerAction.getAllocatedByChain(chainID, clinicID)),
    getChainKpiPlanningData: (chainId, referenceId, year) =>
      dispatch(
        kpiPlannerAction.getChainKPIPlanningData(
          chainId,
          referenceId,
          null,
          year
        )
      ),
    chainPropose: (data) =>
      dispatch(kpiPlannerAction.postChainProposeKPI(data)),
  };
};

export default connect(null, mapDispatchToProps)(ChainLevel);
