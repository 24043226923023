//Admin
export const ADMIN_INPROGRESS = "ADMIN_INPROGRESS"
export const ADMIN_DONEPROGRESS = "ADMIN_DONEPROGRESS"
export const ADMIN_ERROR = "ADMIN_ERROR"
export const IS_AUTHENTICATED = "IS_AUTHENTICATED"
export const PASSWORD_RECOVERED = "PASSWORD_RECOVERED"
export const LOGOUT = "LOGOUT"
export const GET_DOCTORS_DETAIL = "GET_DOCTORS_DETAIL"
export const GET_DOCTOR_CLINIC_DETAIL = "GET_DOCTOR_CLINIC_DETAIL"
export const GET_KPI_CLINIC = "GET_KPI_CLINIC"
export const GET_CHAINS = "GET_CHAINS"
export const GET_CLINICS = "GET_CLINICS"
export const GET_USER_CLINICS_BY_ROLE = "GET_USER_CLINICS_BY_ROLE" // #298: assign clinic state based on user role -> clinic filter in dr performance report dashboard
export const GET_USERS_DETAIL = "GET_USERS_DETAIL"
export const GET_ROLES_DETAIL = "GET_ROLES_DETAIL"
export const CREATE_USERS = "CREATE_USERS"
export const UPDATE_USER_DATA = "UPDATE_USER_DATA"
export const USER_DELETED = "USER_DELETED"
export const CREATE_ROLES = "CREATE_ROLES"
export const UPDATE_ROLES = "UPDATE_ROLES"
export const ROLE_DELETED = "ROLE_DELETED"

// Dr Performance 
export const GET_DR_PR_COMPONENTS = "GET_DR_PR_COMPONENTS"
export const GET_DR_PR_MONTHLY = "GET_DR_PR_MONTHLY"
export const GET_DR_PR_YEARLY = "GET_DR_PR_YEARLY"
export const GET_DR_PR_MTD = "GET_DR_PR_MTD"
export const GET_DR_PR_MTD_CURR_YEAR = "GET_DR_PR_MTD_CURR_YEAR"
export const GET_DR_PR_MTD_CURR_VS_PREV_YEAR = "GET_DR_PR_MTD_CURR_VS_PREV_YEAR"
export const GET_DR_PR_ROLLING = "GET_DR_PR_ROLLING"
export const GET_DR_PR_TREATMENTMIX = "GET_DR_PR_TREATMENTMIX"
export const GET_DR_PR_YTD = "GET_DR_PR_YTD"
export const SET_DR_PR_DATE_PERIOD = "SET_DR_PR_DATE_PERIOD"

//Clinic
export const GET_CLINIC_COMPONENTS = "GET_CLINIC_COMPONENTS"
export const GET_CLINIC_DASHBOARD_FILTER = "GET_CLINIC_DASHBOARD_FILTER"
export const GET_CLINIC_HOURLY_AVERAGE_FILTER = "GET_CLINIC_HOURLY_AVERAGE_FILTER"
export const GET_CLINIC_TIMELINE = "GET_CLINIC_TIMELINE"
export const GET_CLINIC_USAGE = "GET_CLINIC_USAGE"
export const GET_CLINIC_DAILY_CALENDAR = "GET_CLINIC_DAILY_CALENDAR"
export const GET_CLINIC_WEEKLY_CALENDAR = "GET_CLINIC_WEEKLY_CALENDAR"
export const GET_CLINIC_HOURLY_CALENDAR = "GET_CLINIC_HOURLY_CALENDAR"
export const GET_CLINIC_TREATMENT_TIME = "GET_CLINIC_TREATMENT_TIME"
// #275: not used due to using different approach
// export const GET_CLINIC_WEEKLY_UNDERUTILIZATION_BOUNDARIES = "GET_CLINIC_WEEKLY_UNDERUTILIZATION_BOUNDARIES" // #275: add new actiontype to get color legend boundaries
// export const GET_CLINIC_DAILY_DOWNTIME_BOUNDARIES = "GET_CLINIC_DAILY_DOWNTIME_BOUNDARIES" // #275: add new actiontype to get color legend boundaries

//kpi planner
export const GET_KPI_PLANNER = "GET_KPI_PLANNER"
export const GET_USER_KPI_SUMMARY = "GET_USER_KPI_SUMMARY"
export const GET_ALLOCATED_BY_CHAIN = "GET_ALLOCATED_BY_CHAIN"
export const GET_ALLOCATED_BY_CLINIC = "GET_ALLOCATED_BY_CLINIC"
export const GET_REFERENCES_CHAIN = "GET_REFERENCES_CHAIN"
export const GET_CHAIN_KPI_PLANNING_DATA = "GET_CHAIN_KPI_PLANNING_DATA"
export const UPDATE_CHAIN_PROPOSE_KPI = "UPDATE_CHAIN_PROPOSE_KPI"
export const GET_REFERENCES_CLINIC = "GET_REFERENCES_CLINIC"
export const GET_CLINIC_KPI_PLANNING_DATA = "GET_CLINIC_KPI_PLANNING_DATA"
export const UPDATE_CLINIC_PROPOSE_KPI = "UPDATE_CLINIC_PROPOSE_KPI"
export const GET_DOCTOR_KPI_PLANNING_DATA = "GET_DOCTOR_KPI_PLANNING_DATA"
export const UPDATE_DOCTOR_CONFIRMED_KPI = "UPDATE_DOCTOR_CONFIRMED_KPI"
export const UPDATE_CLINIC_CONFIRMED_KPI = "UPDATE_CLINIC_CONFIRMED_KPI"
export const UPDATE_CHAIN_CONFIRMED_KPI = "UPDATE_CHAIN_CONFIRMED_KPI"

export const GET_ALL_DOCTORS_TOTAL_REVENUE = "GET_ALL_DOCTORS_TOTAL_REVENUE"
export const GET_DOCTOR_PERFORMANCE_SUMMARY_COMPONENTS =
  "GET_DOCTOR_PERFORMANCE_SUMMARY_COMPONENTS"
export const GET_DOCTOR_PRS_CLINICS = "GET_DOCTOR_PRS_CLINICS"
export const GET_DOCTOR_PRS_TREATMENT = "GET_DOCTOR_PRS_TREATMENT"
export const GET_TOTAL_REVENUE_BAR_GRAPH = "GET_TOTAL_REVENUE_BAR_GRAPH"
export const GET_TOTAL_REVENUE_BAR_CHART = "GET_TOTAL_REVENUE_BAR_CHART"
export const GET_TOTAL_REVENUE_PIE_CHART = "GET_TOTAL_REVENUE_PIE_GRAPH"

export const GET_TREATMENT_TARGET = "GET_TREATMENT_TARGET"
export const KPI_ERROR = "KPI_ERROR"

// Finance
export const GET_FINANCE_COMPONENTS = 'GET_FINANCE_COMPONENTS';
export const GET_FINANCE_CLINICS = 'GET_FINANCE_CLINICS';
export const GET_FINANCE_REVENUE_FIGURES = 'GET_FINANCE_REVENUE_FIGURES';
export const GET_FINANCE_REVENUE_GRAPH = 'GET_FINANCE_REVENUE_GRAPH';
export const GET_FINANCE_DAILY_REVENUE = 'GET_FINANCE_DAILY_REVENUE';
export const GET_FINANCE_KPI_PROGRESS = 'GET_FINANCE_KPI_PROGRESS';
export const GET_FINANCE_TREATMENT_BY_INVOICE_COUNT = 'GET_FINANCE_TREATMENT_BY_INVOICE_COUNT';
export const GET_FINANCE_TREATMENT_BY_DISCRETE_TREATMENT_CYCLE = 'GET_FINANCE_TREATMENT_BY_DISCRETE_TREATMENT_CYCLE';
export const GET_FINANCE_PER_HOUR_TREATMENT_BY_INVOICE_COUNT = 'GET_FINANCE_PER_HOUR_TREATMENT_BY_INVOICE_COUNT';
export const GET_FINANCE_REVENUE_FORECAST = 'GET_FINANCE_REVENUE_FORECAST';
export const GET_FINANCE_REVENUE_FORECAST_SHOW = 'GET_FINANCE_REVENUE_FORECAST_SHOW';
export const GET_FINANCE_OPTIMAL_DOCTOR_CHAIR_RATIO = 'GET_FINANCE_OPTIMAL_DOCTOR_CHAIR_RATIO';

// Patient
export const GET_PATIENT_DASHBOARD = 'GET_PATIENT_DASHBOARD';
export const GET_PATIENT_TREATMENTS = 'GET_PATIENT_TREATMENTS';
export const GET_PATIENT_DOCTORS = 'GET_PATIENT_DOCTORS';
export const GET_SEGMENT_FILTER = 'GET_SEGMENT_FILTER';
export const GET_PATIENT_GEOGRAPHIC_DISTRIBUTION = 'GET_PATIENT_GEOGRAPHIC_DISTRIBUTION';
export const GET_PATIENT_DEMOGRAPHIC = 'GET_PATIENT_DEMOGRAPHIC';
export const GET_PATIENT_FIRST_TREATMENT = 'GET_PATIENT_FIRST_TREATMENT';
export const GET_PATIENT_NATIONALITY_DISTRIBUTION = 'GET_PATIENT_NATIONALITY_DISTRIBUTION';
export const GET_PATIENT_OCCUPATION_DISTRIBUTION = 'GET_PATIENT_OCCUPATION_DISTRIBUTION';
export const GET_PATIENT_REFERRAL_SOURCE = 'GET_PATIENT_REFERRAL_SOURCE';
export const GET_PATIENT_FREQUENT_DOCTORS = 'GET_PATIENT_FREQUENT_DOCTORS';
export const GET_PATIENT_MARITAL_STATUS = 'GET_PATIENT_MARITAL_STATUS';

// KPI STATUS
export const KPI_STATUS_NOT_STARTED = 1;
export const KPI_STATUS_PROPOSED = 2;
export const KPI_STATUS_TO_CONFIRM = 3;
export const KPI_STATUS_CONFIRMED = 4;

// #406: Patient Segmentation
export const PATIENT_SEGMENTATION_ERROR = 'PATIENT_SEGMENTATION_ERROR';
export const GET_SEGMENT_CATEGORY_HORIZONTAL_BAR_CHART = 'GET_SEGMENT_CATEGORY_HORIZONTAL_BAR_CHART';
export const GET_SEGMENT_PATIENT_EXPORT = 'GET_SEGMENT_PATIENT_EXPORT';
export const GET_RFM_SCATTER_PLOT = 'GET_RFM_SCATTER_PLOT';
export const GET_RFM_BUBBLE_CHART = 'GET_RFM_BUBBLE_CHART';
export const PATIENT_EXPORT_START = 'PATIENT_EXPORT_START';
export const PATIENT_BAR_START = 'PATIENT_BAR_START';