import { useState, useEffect } from "react"
import Chart from "react-apexcharts"
import _ from "underscore"

import { formatAmount, parseAmount } from "utils/parser"

const MTD = ({mtdData, isEmbedded}) => {
  const [series, setSeries] = useState([])
  const [categories, setCategories] = useState([])

  let options = {
    chart: {
      stacked: true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          // #319: stacked bar chart total on top
          total: {
            enabled: true,
            offsetY: -25,
            style: {
              fontSize: '20px',
            },
            formatter: (val) => (!Number.isNaN(val) ? `Revenue: ${formatAmount(val)}` : "Revenue: ")
          }
        },
      },
    },
    title: {
      text: !isEmbedded && !_.isEmpty(categories) ? `${categories[0]} vs ${categories[1]}` : "",
      // text: `${Intl.DateTimeFormat("en-us", {
      //   month: "long",
      //   year: "numeric",
      // }).format(categories[0])} vs ${Intl.DateTimeFormat("en-us", {
      //   month: "long",
      //   year: "numeric",
      // }).format(categories[1])}`,
    },
    dataLabels: {
      // #319: data labels for individual stacked bar chart segment (cost and profit)
      enabled: true,
      formatter: (val) => (!Number.isNaN(val) ? `${formatAmount(val)}` : ""),
      style: {
        fontsize: '20px'
      }
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        formatter: (date) => {
          return date
          // return Intl.DateTimeFormat("en-us", {
          //   month: "long",
          //   year: "numeric",
          //   day: "2-digit",
          // }).format(date)
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    legend: {
      show: !isEmbedded,
      inverseOrder: true
    },
    tooltip: {
      y: {
        formatter: (val) => formatAmount(val)
      }
    }
  }

  useEffect(() => {
    let update = formatData(mtdData)
    setSeries(update.series)
    setCategories(update.categories)
  }, [mtdData])

  // setState: categories
  useEffect(() => {
    _.assign(options, {
      xaxis: {
        categories: categories,
      },
    })
  }, [categories])

  // setState: categories
  useEffect(() => {
    _.assign(options, {
      xaxis: {
        categories: categories,
      },
    })
  }, [categories])

  useEffect(() => {
    if(!isEmbedded) {
      window.history.replaceState(null, "Medtrik", "/dr-performance-report/ytd-current-yr-vs-previous-yr");
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <Chart options={options} series={series} type='bar' height={isEmbedded ? 424 : '100%'} />
  )
}

let formatData = (data) => {
  // return format
  if (!data?.currentYear || !data?.previousYear)
    return {
      series: [],
      categories: [],
      kpiTarget: Number.NEGATIVE_INFINITY,
    }

  return {
    series: [
      {
        name: "Cost",
        data: [
          parseAmount(data?.currentYear.cost ?? "").amount,
          parseAmount(data?.previousYear.cost ?? "").amount,
        ],
      },
      {
        name: "Profit",
        data: [
          parseAmount(data?.currentYear.profit ?? "").amount,
          parseAmount(data?.previousYear.profit ?? "").amount,
        ],
      },
    ],
    categories: [data?.currentYear?.date, data?.previousYear?.date],
    amountPrefix:
      parseAmount(data?.currentYear.cost ?? "").prefixed ??
      Number.NEGATIVE_INFINITY,
    amountSuffix:
      parseAmount(data?.currentYear.cost ?? "").suffixed ??
      Number.NEGATIVE_INFINITY,
  }
}

export default MTD
