import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Table } from 'react-bootstrap';
import { makeStyles } from '@mui/styles';
import ReactApexChart from 'react-apexcharts';
import _ from 'lodash';
import './index.css';
import Utils from 'utils';
import { formatAmount, roundToTwo } from 'utils/parser';

const useStyles = makeStyles(() => ({
  p: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    color: '#2f4858',
  },
}));

const TotalRevenuePieChart = ({ isEmbedded, revenuePieChart }) => {
  const classes = useStyles();
  const [seriesData, setSeriesData] = useState([]);
  const [optionsData, setOptionsData] = useState({});

  useEffect(() => {
    if (!isEmbedded) {
      window.history.replaceState(null, 'Medtrik', '/TotalRevenuePieChart');
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (revenuePieChart) {
      const sortedDoctors = [...revenuePieChart.doctors].sort(
        (a, b) => b.treatmentRevPercentage - a.treatmentRevPercentage
      );
      setSeriesData(
        _.flatten(
          sortedDoctors.map((doctor) => (doctor.treatmentRevPercentage ? parseFloat(doctor.treatmentRevPercentage) : 0))
        )
      );
      setOptionsData({
        chart: {
          width: '100%',
          type: 'pie',
        },
        colors: ['#555CAA', '#916E98', '#F7FC87', '#d4526e'],
        theme: {
          monochrome: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        dataLabels: {
          style: {
            fontSize: '1em',
            fontFamily: 'Poppins',
          },
          background: {
            enabled: true,
            foreColor: '#ffffff',
            borderWidth: 0,
          },
          formatter(val, opt) {
            const doctorName = sortedDoctors.map((doctor) => doctor.doctorId.toUpperCase())[opt.seriesIndex];
            return [doctorName + ': ' + roundToTwo(val) + '%'];
          },
        },
        legend: {
          show: false,
          position: 'right',
        },
        tooltip: {
          style: {
            backgroundColor: '#E9EAEB',
            color: '#2f4858',
            textAlign: 'center',
          },
          // #359 and #271: change 'Time' in tooltip to 'Billing Hours'
          custom: function ({ seriesIndex }) {
            return `<div>
                <p> Doctor: ${
                  _.flatten(sortedDoctors.map((doctor) => doctor.doctorId.toUpperCase()))[seriesIndex]
                }</p>
                <p> % of Total Amount: ${
                  _.flatten(sortedDoctors.map((doctor) => doctor.treatmentRevPercentage))[seriesIndex]
                }% </p>
                <p> Amount: ${formatAmount(
                  _.flatten(sortedDoctors.map((doctor) => doctor.treatmentRev))[seriesIndex]
                )}</p>
                <p> Billing Hours: ${
                  _.flatten(sortedDoctors.map((doctor) => doctor.treatmentTime))[seriesIndex]
                } Hrs</p>
                <p> Revenue/Hr: ${formatAmount(
                  _.flatten(sortedDoctors.map((doctor) => doctor.treatmentRevPerHour))[seriesIndex]
                )}</p>
              </div>`;
          },
        },
      });
    }
  }, [revenuePieChart]);

  return (
    <Row className="justify-content-center">
      {!isEmbedded && (
        <>
          <h4 className="mb-4">
            <b>Total Revenue: {Utils.formatAmount(revenuePieChart?.treatmentTtlRev ?? '')}</b>
          </h4>
          <Col xs={12} md={12} lg={12}>
            <Col xs={12} md={2} lg={2}>
              <Table className="align-items-center">
                <tbody>
                  <tr className="table-active" style={{ backgroundColor: '#E9EAEB' }}>
                    <td className={classes.p}>Profit:</td>
                    <td className={classes.p}>{Utils.formatAmount(revenuePieChart?.treatmentTtlProfit ?? '')}</td>
                  </tr>
                  <tr
                    className="table-active"
                    style={{
                      backgroundColor: '#CECED0',
                      fontFamily: 'sans-serif',
                      color: '#2f4858',
                    }}
                  >
                    <td className={classes.p}>Cost:</td>
                    <td className={classes.p}>{Utils.formatAmount(revenuePieChart?.treatmentTtlCost ?? '')}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Col>
        </>
      )}
      <Col xs={12} md={isEmbedded ? 10 : 5} lg={isEmbedded ? 10 : 5}>
        <ReactApexChart
          options={optionsData}
          series={seriesData}
          type="pie"
          height={514}
          width={'100%'}
          className={classes.p}
        />
      </Col>
    </Row>
  );
};

export default TotalRevenuePieChart;
