const { parseAmount, formatAmount, formatNumber, trimAmount, parseDate, parsePercentage } = require("./parser")



const Utils = {
    parseAmount,
    formatAmount,
    formatNumber,
    trimAmount,
    parseDate,
    parsePercentage
}

export default Utils