import { formatAmount, formatNumber } from "utils/parser"

const PerformanceSummary = ({
  title,
  isEmbedded,
  totalRevenue,
  targetRevenue,
  variance,
  totalVisits,
  totalNewPatients,
  totalHoursWorked,
  totalDaysWorked,
  hoursWorkedPerDay,
}) => {
  return (
    <div className='position-relative pt-1 pb-5'>
      <div className='page-tittle'>{title}</div>
      <section className={`position-relative${!isEmbedded ? ' px-5 mt-5' : ''}`}>
        <table className='table table-bordered table-condensed rounded mb-0 app-font-class-0'>
          <thead className='thead-light'>
            <tr>
              <th className='border-0 rounded-start'>Total Revenue</th>
              <th className='border-0'>Target Revenue</th>
              <th className='border-0'>Variance</th>
            </tr>
          </thead>
          <tbody className='border-0'>
            <tr>
              <td>{formatAmount(totalRevenue)}</td>
              <td>{formatAmount(targetRevenue)}</td>
              {/* #317: add toFixed() to round up variance into integer */}
              <td>{variance?.toFixed()}%</td>
            </tr>
          </tbody>
        </table>
        <table className='table table-bordered table-condensed rounded mb-0 app-font-class-0 mt-5'>
          <thead className='thead-light'>
            <tr>
              <th className='border-0 rounded-start'>Billing Hours</th>
              <th className='border-0'>Days Worked</th>
              <th className='border-0'>Billing Hours/Day</th>
            </tr>
          </thead>
          <tbody className='border-0'>
            <tr>
              {/* #267: add formatNumber to show thousand seperator */}
              <td>{formatNumber(totalHoursWorked)}</td>
              <td>{totalDaysWorked}</td>
              <td>{hoursWorkedPerDay}</td>
            </tr>
          </tbody>
        </table>
        <table className='table table-bordered table-condensed rounded mb-0 app-font-class-0 mt-5'>
          <thead className='thead-light'>
            <tr>
              <th className='border-0 rounded-start'>Total Visits</th>
              <th className='border-0 w-50'>Total New Patients</th>
            </tr>
          </thead>
          <tbody className='border-0'>
            <tr>
              {/* #267: add formatNumber to show thousand seperator */}
              <td>{formatNumber(totalVisits)}</td>
              <td>{formatNumber(totalNewPatients)}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  )
}

export default PerformanceSummary
