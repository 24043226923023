import { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import _ from 'underscore';
import moment from 'moment';

import { formatAmount, parseAmount } from 'utils/parser';

const MonthlyPerformance = (props) => {
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [kpiTarget, setKpiTarget] = useState(Number.NEGATIVE_INFINITY);
  let year = moment(props.endDate).format('YYYY');

  useEffect(() => {
    const updateData = async () => {
      const update = await formatData(props.monthlyPerformanceData);
      setSeries(update.series);
      setCategories(update.categories);
      setKpiTarget(update.kpiTarget);
    };
    updateData();
  }, [props.monthlyPerformanceData]);

  let options = {
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
          hideOverflowingLabels: true,
        },
      },
    },
    title: {
      text: !props.isEmbedded ? `Monthly Performance ${year}` : '',
    },
    dataLabels: {
      // enabledOnSeries: [1],
      formatter: (val) => (!Number.isNaN(val) ? `${formatAmount(val)}` : ''),
      offsetY: -25,
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        // formatter: (date) => {
        //   return Intl.DateTimeFormat("en-US", {
        //     month: "short",
        //   }).format(date)
        // },
      },
    },
    yaxis: {
      min: 0,
      max:
        _.max(series?.[0]?.data) < kpiTarget
          ? kpiTarget + kpiTarget * 0.1
          : _.max(series?.[0]?.data) + _.max(series?.[0]?.data) * 0.1,
      labels: {
        show: false,
      },
    },
    annotations: {
      position: 'front',
      yaxis: [
        {
          y: kpiTarget,
          strokeDashArray: 5,
          borderColor: '#DB2F2F',
          width: '80%',
          label: {
            borderColor: 'transparent',
            text: `KPI Target: ${formatAmount(kpiTarget)}`,
            textAnchor: 'end',
            position: 'right',
            // offsetX: -70,
            // offsetY: 15,
            // rotate: -45,
            style: {
              background: 'transparent',
              color: '#000000',
              fontSize: '15px',
              fontWeight: '600',
              fontFamily: 'Poppins',
              cssClass: 'apexcharts-yaxis-annotation-label',
            },
          },
        },
      ],
    },
    tooltip: {
      y: {
        formatter: (val) => formatAmount(val),
      },
    },
  };

  let optionsEmbed = {
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
          hideOverflowingLabels: true,
        },
      },
    },
    title: {
      text: !props.isEmbedded ? `Monthly Performance ${year}` : '',
    },
    dataLabels: {
      enabledOnSeries: [1],
      formatter: (val) => (!Number.isNaN(val) ? `${formatAmount(val)}` : ''),
      offsetY: -25,
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        // formatter: (date) => {
        //   return Intl.DateTimeFormat("en-US", {
        //     month: "short",
        //   }).format(date)
        // },
      },
    },
    yaxis: {
      min: 0,
      max:
        _.max(series?.[0]?.data) < kpiTarget
          ? kpiTarget + kpiTarget * 0.1
          : _.max(series?.[0]?.data) + _.max(series?.[0]?.data) * 0.1,
      labels: {
        show: false,
      },
    },
    annotations: {
      position: 'front',
      yaxis: [
        {
          y: kpiTarget,
          strokeDashArray: 5,
          borderColor: '#DB2F2F',
          width: '80%',
          label: {
            borderColor: 'transparent',
            text: `KPI Target: ${formatAmount(kpiTarget)}`,
            textAnchor: 'end',
            position: 'right',
            // offsetX: -70,
            // offsetY: 15,
            // rotate: -45,
            style: {
              background: 'transparent',
              color: '#000000',
              fontSize: '15px',
              fontWeight: '600',
              fontFamily: 'Poppins',
              cssClass: 'apexcharts-yaxis-annotation-label',
            },
          },
        },
      ],
    },
    tooltip: {
      y: {
        formatter: (val) => formatAmount(val),
      },
    },
  };
  // setState: categories
  useEffect(() => {
    _.assign(options, {
      xaxis: {
        categories: categories,
      },
    });
  }, [categories]);

  useEffect(() => {
    if (!props.isEmbedded) {
      window.history.replaceState(null, 'Medtrik', '/dr-performance-report/monthly-performance');
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      {props.isEmbedded === false ? (
        <Chart options={options} series={series} type="bar" height={props.isEmbedded ? 424 : '100%'} />
      ) : (
        <Chart options={optionsEmbed} series={series} type="bar" height={props.isEmbedded ? 424 : '100%'} />
      )}
    </>
  );
};

let formatData = async (data) => {
  // return format
  if (!data?.months.length)
    return {
      series: [],
      categories: [],
      kpiTarget: Number.NEGATIVE_INFINITY,
    };

  // let filtered = data.months.filter((m) => m.revenue !== 0);
  let cost = data.months.map((m) => parseAmount(m.revenue).amount);
  let categories = data.months.map((m) => m.months);

  cost.push('');
  cost.push('');
  categories.push('');
  categories.push(''); // kpi target overlapping fix

  return {
    series: [
      {
        name: 'Revenue',
        data: cost,
      },
    ],
    categories: categories,
    kpiTarget: data?.kpiTarget ?? Number.NEGATIVE_INFINITY,
    amountPrefix: parseAmount(_.get(data?.months[0], 'revenue')).prefixed ?? Number.NEGATIVE_INFINITY,
    amountSuffix: parseAmount(_.get(data?.months[0], 'revenue')).suffixed ?? Number.NEGATIVE_INFINITY,
  };
};

export default MonthlyPerformance;
