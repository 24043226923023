import React, { useState, useEffect } from "react";
import { HomeIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Breadcrumb } from 'react-bootstrap';
import _ from "lodash";
import { Routing } from "routes";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as financeActions from "actions/financeActions";
import ApexCharts from "apexcharts";
import MonthSlider from "components/filters/MonthSlider";
import { default as Select } from "react-select";
import { components } from "react-select";
import { Checkbox } from "@mui/material";

import './style.css';
import { MONTH_SLIDER } from "constants/date";
import { format, minTime, parse } from "date-fns";
import { SYSTEM_DATE_DISPLAY_FORMAT2_DF, SYSTEM_DATE_FORMAT_DF } from "constants/common";
import { formatAmount } from "utils/parser";
import { useChainId } from "hooks/useChainId";


const Option = (props) => {
  return (
    <div>
      <components.Option {...props} className="react-select-option">
          <Checkbox
              checked={props.isSelected}
              color="default"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          />
          <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const FinanceDoctorChairRatioScatterGraph = (props) => {

    const history = useHistory();
    const redirectUrl = (url) => {
        history.push(url);
    };

    const chainId = useChainId()
    const [ selectedDate, setSelectedDate ] = useState({});
    const [ clinics, setClinics ] = useState([]);
    const [ selectedClinics, setSelectedClinics ] = useState([]);
    const [ clinicIds, setClinicIds ] = useState([]);
    const [ chartOptions, setChartOptions ] = useState({
      series: [
        {
          name: 'Points',
          type: 'scatter',
          data: []
        }, 
        {
          name: 'Line',
          type: 'line',
          data: [{
            x: 0.0,
            y: 0.0
          }, {
            x: 0.1,
            y: 200000
          }, {
            x: 0.2,
            y: 400000
          }, {
            x: 0.3,
            y: 600000
          }, {
            x: 0.4,
            y: 800000
          }, {
            x: 0.5,
            y: 1000000
          }, {
            x: 0.6,
            y: 1200000
          }, {
            x: 0.7,
            y: 1400000
          }, {
            x: 0.8,
            y: 1600000
          }, {
            x: 0.9,
            y: 1800000
          }]
        }
      ],
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      fill: {
        type:'solid',
      },
      colors: ['#000','#98A7FE'],
      markers: {
        size: [6, 0]
      },
      tooltip: {
        shared: false,
        intersect: true,
        style:{
          textAlign:'center'
        },
        // #326: modify tooltip so most of the data points don't say 'undefined' anymore
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          return (
            `<div>
                <p> Revenue: <b>${formatAmount(data.y)}</b> </p>
                <p> Doctor/Chair Ratio: <b>${data.x}</b> </p>
            </div>`
          );
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        title: {
          text: 'Doctor / Chair Ratio',
          offsetX: 0,
          offsetY: 5,
        },
        type: 'numeric',
        min: 0,
        max: 1,
        tickAmount: 12,
        labels: {
          style: {
            fontSize: '14px'
          }
        }
      },
      yaxis: {
        title: {
          text: "Revenue",
          offsetX: -5,
          offsetY: 0
        },
        labels: {
          formatter: (value) => formatAmount(value),
          style: {
            fontSize: '14px'
          }
        },
      },
    });

    const handleMonthChange = (date) => {
      setSelectedDate(date);
    };

    const handleClinicChange = (selectedClinics) => {
      let selectedClinicIds = selectedClinics.map(selectedClinic => selectedClinic.value.toUpperCase());
      if(selectedClinicIds.includes('ALL CLINICS')) {
          selectedClinicIds = props.clinics.map(clinic => clinic.clinicId.toUpperCase());
          setSelectedClinics([{ value: 'All Clinics', label: 'All Clinics' }]);
      }
      else {
          setSelectedClinics(selectedClinics);
      }
      setClinicIds(selectedClinicIds);
    };

    useEffect(() => {
      props.actions.financeActions.getFinanceComponents(props.user._id, props.user.Role.id);
    }, []);

    useEffect(() => {
      if(props.dashboardData.chainId)
        props.actions.financeActions.getClinics(props.dashboardData.chainId);
    }, [props.dashboardData]);

    useEffect(() => {
      let clinics = [];
      if(props.user.Role.id == 3 && props.user.clinic) {
        props.clinics.forEach(clinic => {
          if(props.user.clinic.clinicId == clinic.clinicId)
            clinics.push({ value: clinic.clinicId, label: clinic.clinicId })
        });
      }
      else {
          clinics = [{ value: 'All Clinics', label: 'All Clinics' }];
          props.clinics.forEach(clinic => {
            clinics.push({ value: clinic.clinicId, label: clinic.clinicId })
          });
          setClinics(clinics);
      }
      handleClinicChange(clinics);
    }, [props.clinics]);

    useEffect(() => {
      if(clinicIds.length)
        props.actions.financeActions.getOptimalDoctorChairRatioScatterGraph(chainId, clinicIds, selectedDate.startDate, selectedDate.endDate);
    }, [chainId, clinicIds, selectedDate]);

    useEffect(() => {
      if(props.optimalDoctorChairRatio && props.optimalDoctorChairRatio.chRaScatterGraph.length) {
        const points = props.optimalDoctorChairRatio.chRaScatterGraph.map(graph => { return {x: graph.drChRa, y: graph.revenue} });
        const chartData = _.cloneDeep(chartOptions);
        chartData.series[0].data = points;
        chartData.xaxis.min = _.floor(_.min(points.map(point => point.x)));
        chartData.xaxis.max = _.ceil(_.max(points.map(point => point.x)));
        setChartOptions(chartData);
      } else {
        const chartData = _.cloneDeep(chartOptions);
        chartData.series[0].data = [];
        chartData.xaxis.min = 0;
        chartData.xaxis.max = 1;
        setChartOptions(chartData);
      }
    }, [props.optimalDoctorChairRatio]);

    useEffect(() => {
      document.querySelector("#chart").innerHTML = '';
      const chart = new ApexCharts(document.querySelector("#chart"), chartOptions);
      chart.render();
    }, [chartOptions]);

    return (
        <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                    <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                    <Breadcrumb.Item active>Optimal Doctor Chair Ratio Scatter Graph</Breadcrumb.Item>
                </Breadcrumb>
                <h4>Optimal Doctor Chair Ratio</h4>
            </div>
        </div>
        <Card border="0" className="table-wrapper table-responsive shadow">
            <Card.Body>
                <Row className="finance-dashboard">
                    <h4 className="mb-6"><b>Optimal Doctor/Chair Ratio Scatter Graph</b></h4>
                    <Col xs={12} lg={4}>
                      {props.user.Role.id != 3 &&
                        <Select
                          value={selectedClinics}
                          className="medtrik-dropdown"
                          placeholder="Select Clinic(s)..."
                          isMulti
                          components={{Option}}
                          onChange={handleClinicChange}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          options={clinics} />
                      }
                    </Col>
                    <Col xs={12} lg={2}></Col>
                    <Col xs={12} lg={6} className="justify-content-end text-end mb-6 px-4">
                      <h6 className="m-0">Selected Month Range {selectedDate?.startDate && format(parse(selectedDate.startDate, SYSTEM_DATE_FORMAT_DF, minTime), SYSTEM_DATE_DISPLAY_FORMAT2_DF)} - {selectedDate?.endDate && format(parse(selectedDate.endDate, SYSTEM_DATE_FORMAT_DF, minTime), SYSTEM_DATE_DISPLAY_FORMAT2_DF)}</h6>
                      <MonthSlider {...Object.assign({}, MONTH_SLIDER, {display: true, type: 2, max: 24})} handleMonthChange={handleMonthChange} />
                    </Col>
                    <Col xs={12} md={12} lg={10} xl={8} className="px-8">
                      <div id="chart"></div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </>
    )
}

const mapStateToProps = (state) => {
  const {adminAuthReducer, financeReducer} = state;
  return {
    user: adminAuthReducer.adminUser,
    dashboardData: financeReducer.dashboardData,
    clinics: financeReducer.clinics,
    optimalDoctorChairRatio: financeReducer.optimalDoctorChairRatio,
  }
};

const mapDispatchToProps = dispatch => ({
    actions: {
      financeActions: bindActionCreators(financeActions, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(FinanceDoctorChairRatioScatterGraph)
