import {
    GET_PATIENT_DASHBOARD,
    GET_PATIENT_TREATMENTS,
    GET_PATIENT_DOCTORS,
    GET_SEGMENT_FILTER,
    GET_PATIENT_GEOGRAPHIC_DISTRIBUTION,
    GET_PATIENT_DEMOGRAPHIC,
    GET_PATIENT_FIRST_TREATMENT,
    GET_PATIENT_NATIONALITY_DISTRIBUTION,
    GET_PATIENT_OCCUPATION_DISTRIBUTION,
    GET_PATIENT_REFERRAL_SOURCE,
    GET_PATIENT_FREQUENT_DOCTORS,
    GET_PATIENT_MARITAL_STATUS
} from '../constants/actionsTypes';
import initialState from './initialState';

export default function patientReducer(state = initialState.patientReducer, action) {
    switch (action.type) {
        case GET_PATIENT_DASHBOARD:
            return Object.assign({}, state, {
                dashboardData: action.data
            });

        case GET_PATIENT_TREATMENTS:
            return Object.assign({}, state, {
                // treatments: action.data.sort((a, b) => a.treatmentName.localeCompare(b.treatmentName))
                treatments: action.data.sort((a, b) => a.treatmentId ? b.treatmentId ? a.treatmentId.localeCompare(b.treatmentId) : -1 : 1)
            });

        case GET_PATIENT_DOCTORS:
            return Object.assign({}, state, {
                doctors: action.data
            });

        case GET_SEGMENT_FILTER:
            const arrangedSegments = {};
            action.data.forEach(segment => {
                if(segment.segmentName === 'Low-Value') {
                    arrangedSegments[2] = segment;
                }
                if(segment.segmentName === 'Mid-Value') {
                    arrangedSegments[1] = segment;
                }
                if(segment.segmentName === 'High-Value') {
                    arrangedSegments[0] = segment;
                }
            })
            return Object.assign({}, state, {
                segments: Object.values(arrangedSegments)
            });

        case GET_PATIENT_DEMOGRAPHIC:
            return Object.assign({}, state, {
                demographicData: action.data
            });

        case GET_PATIENT_GEOGRAPHIC_DISTRIBUTION:
            return Object.assign({}, state, {
                geographicDistribution: action.data
            });

        case GET_PATIENT_FIRST_TREATMENT:
            return Object.assign({}, state, {
                firstTreatmentData: action.data
            });

        case GET_PATIENT_NATIONALITY_DISTRIBUTION:
            return Object.assign({}, state, {
                nationalityDistribution: action.data
            });

        case GET_PATIENT_OCCUPATION_DISTRIBUTION:
            return Object.assign({}, state, {
                occupationDistribution: action.data
            });

        case GET_PATIENT_REFERRAL_SOURCE:
            return Object.assign({}, state, {
                referralSource: action.data
            });

        case GET_PATIENT_FREQUENT_DOCTORS:
            return Object.assign({}, state, {
                frequentDoctors: action.data
            });

        case GET_PATIENT_MARITAL_STATUS:
            return Object.assign({}, state, {
                maritalStatus: action.data
            });

        default:
            return Object.assign({}, state, {
                loading: false
              });
    }
};
