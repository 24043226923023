import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { CSSTransition } from 'react-transition-group';
import {
  AdjustmentsIcon,
  CalculatorIcon,
  CalendarIcon,
  ChartPieIcon,
  ChevronRightIcon,
  UserGroupIcon,
  UsersIcon,
  LibraryIcon,
  CurrencyDollarIcon,
  DocumentReportIcon,
} from '@heroicons/react/outline';
import { BiScatterChart } from "react-icons/bi";
import { RiPulseFill } from 'react-icons/ri';
import { Nav, Badge, Image, Button, Navbar, Collapse, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaUserCog } from 'react-icons/fa';
import { connect, useSelector } from 'react-redux';

import { Routing } from 'routes';
import Logo from 'assets/img/brand/Logo-Medtrik.png';

import './allStyle.css';

export default (props = {}) => {
  const hideStyle = { backgroundcolor: 'red' };
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const [isInside, setIsInside] = useState(false);
  const [collapsedItems, setCollapsedItems] = useState([pathname]);
  const contracted = props.contracted ? 'contracted' : '';
  const showClass = show ? 'show' : '';

  const onCollapse = () => setShow(!show);
  const onMouseEnter = () => props.onMouseEnter && props.onMouseEnter();
  const onMouseLeave = () => props.onMouseLeave && props.onMouseLeave();

  const onNavItemCollapse = (itemKey) => {
    const isCollapsed = collapsedItems.some((item) => item.includes(itemKey));
    const newCollapsedItems = isCollapsed
      ? collapsedItems.filter((item) => !item.includes(itemKey))
      : [...collapsedItems, itemKey];
    setCollapsedItems(newCollapsedItems);
  };

  const events = isMobile ? {} : { onMouseEnter, onMouseLeave };

  // #406: ADJUSTED TO ACCOMMODATE MULTI-LINE DASHBOARD TITLE
  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon: NavItemIcon, children = null, contracted } = props;
    const isOpened = collapsedItems.some((item) => item.includes(eventKey));

    if (contracted == '') {
      return (
        <Nav.Item>
          <Nav.Link
            onClick={() => onNavItemCollapse(eventKey)}
            aria-expanded={isOpened}
            aria-controls={eventKey}
            className="d-flex justify-content-between align-items-center"
          >
            <span style={{ flex: 8, flexDirection: 'row', display: 'flex' }}>
              <span style={{ flex: 5, flexDirection: 'row', display: 'flex' }}>
                <span className="sidebar-icon" style={{ flex: 1, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                  <NavItemIcon className="icon icon-xs me-2" />
                </span>
                <span style={{ flex: 0 }}/>
                <span className="sidebar-text" style={{ flex: 4.5, flexDirection: 'column', display: 'flex' }}>{title}</span>
              </span>
              <span style={{ display: 'flex', flex: 1.5, flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                <ChevronRightIcon className="icon icon-sm" style={{ right: 0, position: 'absolute', transform: isOpened ? "rotate(90deg)" : "rotate(0deg)" }} />
              </span>
            </span>
          </Nav.Link>
          <Collapse in={isOpened} className="multi-level">
            <div id={eventKey}>{children}</div>
          </Collapse>
        </Nav.Item>
      );
    } else {
      return (
        <Nav.Item>
          <Nav.Link
            onClick={() => onNavItemCollapse(eventKey)}
            aria-expanded={isOpened}
            aria-controls={eventKey}
            className="d-flex justify-content-between align-items-center"
          >
            <span style={{ flex: 8, flexDirection: 'row', display: 'flex' }}>
              <span style={{ flex: 5, flexDirection: 'row', display: 'flex' }}>
                <span className="sidebar-icon" style={{ flex: 1, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                  <NavItemIcon className="icon icon-xs me-2" />
                </span>
                <span style={{ flex:0 }}/>
                <span className="sidebar-text" style={{ flex:4.5, flexDirection: 'column', display: 'flex' }}>{title}</span>
              </span>
              <span style={{ display: 'flex', flex: 1.5, flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                <ChevronRightIcon className="icon icon-sm" style={{ right: 0, position: 'absolute', transform: isOpened ? "rotate(90deg)" : "rotate(0deg)" }} />
              </span>            
            </span>
          </Nav.Link>
          <Collapse className="multi-level">
            <div id={eventKey}>{children}</div>
          </Collapse>
        </Nav.Item>
      );
    }
  };

  const NavItem = (props) => {
    const { title, link, target, icon: NavItemIcon, image, badgeText, badgeBg, badgeColor = 'white' } = props;
    const classNames = badgeText ? 'd-flex align-items-center justify-content-between' : '';
    const navItemClassName = link === pathname ? 'active' : '';

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link as={Link} to={link} target={target} className={classNames}>
          <span>
            {NavItemIcon && (
              <span className="sidebar-icon">
                <NavItemIcon className="icon icon-xs me-2" />
              </span>
            )}

            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            {!show && contracted && !NavItemIcon && !image ? (
              <span className="sidebar-text-contracted d-none">{title[0]}</span>
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>

          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-sm notification-count">
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  const adminAuthReducer = useSelector((state) => ({
    adminUser: state.adminAuthReducer.adminUser,
  }));

  const openNewWindow = (link, title) => {
    window.open(link, title, `width=800,height=600,left=200,top=200`);
  };

  const openMenu = (link, title) => {
    window.open(link, '_self');
  };

  return (
    <>
      <Navbar
        as={Col}
        xs={12}
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-lg-none"
      >
        {/* #315: Change to redirect to /dashboard instead of /dashboard/overview */}
        {/* <Navbar.Brand as={Link} to={Routing.DashboardOverview.path} className="me-lg-5"> */}
        <Navbar.Brand as={Link} to={Routing.Dashboard.path} className="me-lg-5">
          <Image src={Logo} className="navbar-brand-dark" />
        </Navbar.Brand>
        <div className="d-flex align-items-center">
          <Navbar.Toggle as={Button} onClick={onCollapse}>
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
        </div>
      </Navbar>

      <SimpleBar
        {...events}
        className={`${contracted} ${showClass} sidebar d-lg-block bg-gray-800 text-white collapse`}
      >
        <div className={'sidebar-inner px-4 pt-3'}>
          <Nav className="flex-column pt-3 pt-md-0">
            <NavItem title="Medtrik" link="#" image={Logo} />

            <NavItem title="Overview" icon={ChartPieIcon} link={Routing.Dashboard.path} />
            {adminAuthReducer?.adminUser?.Role?.id && [2, 3].includes(adminAuthReducer.adminUser.Role.id) && (
              <>
                <CollapsableNavItem
                  contracted={contracted}
                  title="Finance"
                  eventKey="finance/"
                  icon={CurrencyDollarIcon}
                >
                  <NavItem title="Dashboard" link={Routing.FinanceDashboard.path} />
                  <NavItem title="Forecast" link={Routing.FinanceForecast.path} />
                  <NavItem
                    title="Optimal Doctor/Chair ratio Scatter Graph"
                    link={Routing.FinanceDoctorChairRatioScatterGraph.path}
                  />
                </CollapsableNavItem>
              </>
            )}

            {adminAuthReducer?.adminUser?.Role?.id && [2, 3].includes(adminAuthReducer.adminUser.Role.id) && (
              <>
                <CollapsableNavItem contracted={contracted} title="Patient" eventKey="patient/" icon={RiPulseFill}>
                  <NavItem title="Dashboard" link={Routing.PatientsDashboard.path} />
                </CollapsableNavItem>
              </>
            )}

            {adminAuthReducer?.adminUser?.Role?.id &&
              adminAuthReducer.adminUser.Role.id !== 1 &&
              adminAuthReducer.adminUser.Role.id !== 5 && (
                <>
                  <CollapsableNavItem contracted={contracted} title="Doctor" eventKey="doctor/" icon={UsersIcon}>
                    <NavItem title="KPI Planner" link={Routing.KpiPlanner.path} />
                    <NavItem title="Dr Performance Report" link={Routing.DrPerformanceReport.path} />
                    {adminAuthReducer?.adminUser?.Role?.id && [2, 3].includes(adminAuthReducer.adminUser.Role.id) && (
                      <NavItem title="Dr Performance Summary Report" link={Routing.PerformanceSummaryReport.path} />
                    )}
                  </CollapsableNavItem>
                </>
              )}

            {/* #412: REMOVE MENU FOR SUPERADMIN USERS */}
            {adminAuthReducer?.adminUser?.Role?.id && [2, 3].includes(adminAuthReducer.adminUser.Role.id) && (
              <>
                <CollapsableNavItem contracted={contracted} title="Clinic" eventKey="clinic/" icon={LibraryIcon}>
                  {/* <NavItem title="Dashboard" link={Routing.ClinicReport.path} /> */}
                  <Nav.Link onClick={() => openMenu(Routing.ClinicReport.path)}>
                    <span className="sidebar-text">Dashboard</span>
                  </Nav.Link>
                  {
                    adminAuthReducer?.adminUser?.Role?.id && [1, 2].includes(adminAuthReducer.adminUser.Role.id) &&
                      <Nav.Link
                        onClick={() => openNewWindow(Routing.ClinicScheduleRecommender.path, 'Schedule Recommender')}
                      >
                        <span className="sidebar-text">Doctor Schedule Recommender</span>
                      </Nav.Link>
                  }
                </CollapsableNavItem>
              </>
            )}

            {adminAuthReducer?.adminUser?.Role?.id && adminAuthReducer.adminUser.Role.id === 1 && (
              <>
                <CollapsableNavItem contracted={contracted} title="User Admin" eventKey="users/" icon={FaUserCog}>
                  <NavItem title="Clinic Configuration" link={Routing.KpiClinic.path} />
                  <NavItem title="Role Management" link={Routing.Roles.path} />
                  <NavItem title="User Management" link={Routing.Users.path} />
                </CollapsableNavItem>
              </>
            )}

            {/* #404: Modified terminologies */}
            {adminAuthReducer?.adminUser?.Role?.id && [1, 2, 3].includes(adminAuthReducer.adminUser.Role.id) && (
              <>
                <CollapsableNavItem contracted={contracted} title="Report" eventKey="report/" icon={DocumentReportIcon}>
                  {adminAuthReducer?.adminUser?.Role?.id && [1, 2].includes(adminAuthReducer.adminUser.Role.id) && 
                  (<NavItem title="Monthly Group Report" link={Routing.MonthlyReport.path} />)
                  }
                  <NavItem title="Monthly Clinic Report" link={Routing.MonthlyBranchReport.path} />
                </CollapsableNavItem>
              </>
            )}

            {/* #406: PATIENT SEGMENTATION DASHBOARD */}
            {adminAuthReducer?.adminUser?.Role?.id && [2].includes(adminAuthReducer.adminUser.Role.id) && (
              <>
                <CollapsableNavItem contracted={contracted} title="Patient Segmentation" eventKey="patient-segmentation/" icon={BiScatterChart}>
                  <NavItem title="Dashboard" link={Routing.PatientSegmentationDashboard.path} />
                </CollapsableNavItem>
              </>
            )}

            {/* hidden from all roles for now */}
            {false && adminAuthReducer?.adminUser?.Role?.id && adminAuthReducer.adminUser.Role.id === 1 && (
              <CollapsableNavItem contracted={contracted} title="KPI Management" eventKey="kpi/" icon={AdjustmentsIcon}>
                {/* <NavItem title="Clinic Management" link={Routing.Clinic.path} />
                  <NavItem title="Doctor Management" link={Routing.Doctors.path} /> */}
              </CollapsableNavItem>
            )}

            {/* <CollapsableNavItem eventKey="dashboard/" title="Dashboard" icon={ChartBarIcon}>
                <NavItem title="Overview" link={Routing.DashboardOverview.path} />
                <NavItem title="All Traffic" link={Routing.DashboardTraffic.path} />
                <NavItem title="Product Analysis" link={Routing.DashboardProductAnalysis.path} />
              </CollapsableNavItem>

              <NavItem title="Kanban" icon={ViewGridIcon} link={Routing.Kanban.path} />
              <NavItem title="Messages" icon={InboxIcon} badgeText="4" badgeBg="danger" link={Routing.Messages.path} />
              <NavItem title="Transactions" icon={CreditCardIcon} link={Routing.Transactions.path} />
              <NavItem title="Task List" icon={ClipboardListIcon} link={Routing.Tasks.path} />
              <NavItem title="Settings" icon={CogIcon} link={Routing.Settings.path} />
              <NavItem title="Calendar" icon={CalendarIcon} link={Routing.Calendar.path} />
              <NavItem title="Map" icon={LocationMarkerIcon} link={Routing.Map.path} />
              <NavItem title="Widgets" icon={TemplateIcon} link={Routing.Widgets.path} />

              <CollapsableNavItem eventKey="tables/" title="Tables" icon={TableIcon}>
                <NavItem title="DataTables" link={Routing.Datatables.path} />
                <NavItem title="Bootstrap Tables" link={Routing.BootstrapTables.path} />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey="examples/" title="Page Examples" icon={NewspaperIcon}>
                <NavItem title="Pricing" link={Routing.Pricing.path} />
                <NavItem title="Billing" link={Routing.Billing.path} />
                <NavItem title="Invoice" link={Routing.Invoice.path} />
                <NavItem title="Sign In" link={Routing.Signin.path} />
                <NavItem title="Sign Up" link={Routing.Signup.path} />
                <NavItem title="Forgot password" link={Routing.ForgotPassword.path} />
                <NavItem title="Reset password" link={Routing.ResetPassword.path} />
                <NavItem title="Lock" link={Routing.Lock.path} />
                <NavItem title="404 Not Found" link={Routing.NotFound.path} />
                <NavItem title="500 Server Error" link={Routing.ServerError.path} />
              </CollapsableNavItem>

              <Dropdown.Divider className="my-3 border-indigo" />

              <CollapsableNavItem eventKey="documentation/" title="Getting Started" icon={InformationCircleIcon}>
                <NavItem title="Overview" link={Routing.DocsOverview.path} />
                <NavItem title="Download" link={Routing.DocsDownload.path} />
                <NavItem title="Quick Start" link={Routing.DocsQuickStart.path} />
                <NavItem title="License" link={Routing.DocsLicense.path} />
                <NavItem title="Folder Structure" link={Routing.DocsFolderStructure.path} />
                <NavItem title="Build Tools" link={Routing.DocsBuild.path} />
                <NavItem title="Changelog" link={Routing.DocsChangelog.path} />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey="components/" title="Components" icon={ArchiveIcon}>
                <NavItem title="Accordion" link={Routing.Accordions.path} />
                <NavItem title="Alerts" link={Routing.Alerts.path} />
                <NavItem title="Badges" link={Routing.Badges.path} />
                <NavItem title="Breadcrumbs" link={Routing.Breadcrumbs.path} />
                <NavItem title="Buttons" link={Routing.Buttons.path} />
                <NavItem title="Forms" link={Routing.Forms.path} />
                <NavItem title="Modals" link={Routing.Modals.path} />
                <NavItem title="Navbars" link={Routing.Navbars.path} />
                <NavItem title="Navs" link={Routing.Navs.path} />
                <NavItem title="Pagination" link={Routing.Pagination.path} />
                <NavItem title="Popovers" link={Routing.Popovers.path} />
                <NavItem title="Progress" link={Routing.Progress.path} />
                <NavItem title="Tables" link={Routing.Tables.path} />
                <NavItem title="Tabs" link={Routing.Tabs.path} />
                <NavItem title="Toasts" link={Routing.Toasts.path} />
                <NavItem title="Tooltips" link={Routing.Tooltips.path} />
                <NavItem title="Widgets" link={Routing.WidgetsComponent.path} />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey="plugins/" title="Plugins" icon={ChartPieIcon}>
                <NavItem title="Charts" link={Routing.PluginCharts.path} />
                <NavItem title="Calendar" link={Routing.PluginCalendar.path} />
                <NavItem title="DataTable" link={Routing.PluginDatatable.path} />
                <NavItem title="Map" link={Routing.PluginMap.path} />
                <NavItem title="DropZone" link={Routing.PluginDropzone.path} />
                <NavItem title="SweetAlert" link={Routing.PluginSweetAlert.path} />
              </CollapsableNavItem> */}
          </Nav>
        </div>
      </SimpleBar>
    </>
  );
};

