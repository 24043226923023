import React, { useState, useEffect, useMemo } from 'react';
import { HomeIcon, PlusIcon, SearchIcon } from '@heroicons/react/solid';
import { EyeIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { Col, Row, Form, Button, Breadcrumb, InputGroup } from 'react-bootstrap';

import { Routing } from 'routes';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as authAction from 'actions/authActions';
import swal from 'sweetalert';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import VerticallyCenteredModal from '../Modal';
import Loader from '../Loader';

const Users = (props) => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [permission, setPermission] = useState({});

  useEffect(() => {
    props.actions.adminAction.getusers();
  }, []);

  const adminAuthReducer = useSelector((state) => ({
    userDetail: state.adminAuthReducer.userDetail,
    isLoading: state.adminAuthReducer.loading,
    loginUserID: state.adminAuthReducer.loginUserID,
    adminUser: state.adminAuthReducer.adminUser,
    userDeletedID: state.adminAuthReducer.userDeletedID,
  }));

  useEffect(() => {
    if (adminAuthReducer.userDeletedID) {
      props.actions.adminAction.getusers();
    }
  }, [adminAuthReducer.userDeletedID]);

  const onClickView = (rowType) => {
    setModalShow(true);
    setModalData(rowType);
  };

  const columns = [
    {
      dataField: '_id',
      text: 'INDEX',
      sort: false,
      formatter(cell, row, rowIndex) {
        return ++rowIndex;
      },
    },
    { dataField: 'userName', text: 'USER NAME', sort: true },
    { dataField: 'fullName', text: 'FULL NAME', sort: true },
    { dataField: 'Role.title', text: 'ROLE', sort: true },
    { dataField: 'email', text: 'EMAIL', sort: true },
    {
      dataField: 'isActive',
      text: 'STATUS',
      sort: false,
      formatter(cell, row) {
        return row.isActive ? 'Activate' : 'Deactivate';
      },
    },
    { dataField: '', text: 'COMMAND', attrs: { className: 'command-icon' }, sort: false, formatter: rankFormatter },
  ];

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <span role="button" onClick={() => onClickView(row)}>
          <EyeIcon className="text-gray-800 me-2" />
        </span>

        <Link to={{ pathname: '/user/edit/' + row._id, state: row }}>
          <PencilAltIcon className="text-gray-800 me-2" />
        </Link>
        <span role="button" onClick={() => onClickDeleteUser(row._id)}>
          <TrashIcon className="text-gray-800 me-2" />
        </span>
      </>
    );
  }

  const onClickDeleteUser = (userID) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this data!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        props.actions.adminAction.deleteUsers(userID);
      }
    });
  };

  const pagination = paginationFactory({
    sizePerPage: 10,
    showTotal: true,
    nextPageText: 'Next',
    prePageText: 'Previous',
    alwaysShowAllBtns: true,
  });

  const permissionIssue = (data) => {
    swal('Sorry', "You don't the permission " + data, 'error');
  };

  useEffect(() => {
    if (adminAuthReducer.isDeleted) {
      let user = [...users];
      users.filter((u) => u._id !== adminAuthReducer.loginUserID);
      user = user.filter((u) => u._id !== adminAuthReducer.userDeletedID[0]);
      setUsers(user);
      swal('Poof! Your record has been deleted!', {
        icon: 'success',
      });
    }
  }, [adminAuthReducer.isDeleted]);

  useEffect(() => {
    if (adminAuthReducer.userDetail) {
      setUsers(adminAuthReducer.userDetail.filter((u) => u._id != adminAuthReducer.loginUserID).map((u) => ({ ...u })));
    }
  }, [adminAuthReducer.userDetail]);

  const redirectUrl = (url) => {
    history.push(url);
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>User Management</Breadcrumb.Item>
          </Breadcrumb>
          <h4>User Management</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            variant="gray-800"
            size="sm"
            className="d-inline-flex align-items-center usertest"
            onClick={() => redirectUrl(Routing.AddUser.path)}
          >
            <PlusIcon className="icon icon-xs me-2" /> Add New
          </Button>
        </div>
      </div>
      {users && (
        <>
          <ToolkitProvider bootstrap4 keyField="_id" data={users} columns={columns} search>
            {(props) => (
              <div className="cs-data-tables">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton {...props.searchProps} />
                <BootstrapTable classes="table-striped" pagination={pagination} {...props.baseProps} />
              </div>
            )}
          </ToolkitProvider>

          <VerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} data={modalData} user={true} />
        </>
      )}

      {adminAuthReducer.isLoading && <Loader />}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(authAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(Users);
