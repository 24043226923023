import { HomeIcon } from "@heroicons/react/solid"
import { Breadcrumb } from "react-bootstrap"
import { Routing } from "routes"
import { useHistory } from "react-router-dom"


const Header = ({ title, setSelectedComponent, selectedComponent }) => {
    const history = useHistory()
    const redirectUrl = (url) => {
      history.push(url)
    }

    const goBackToDashboard = (link) => {
      window.history.replaceState(null, "Medtrik", "/dr-performance-report");
      setSelectedComponent(null);
    }
  
    return (
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            {
                selectedComponent ?
                <>
                    <Breadcrumb.Item onClick={goBackToDashboard}>Dr Performance Report</Breadcrumb.Item>
                    <Breadcrumb.Item active>{selectedComponent}</Breadcrumb.Item>
                </> :
                <Breadcrumb.Item active>Dr Performance Report</Breadcrumb.Item>
            }
          </Breadcrumb>
          {
            title
            ? <h4 className='app-font-class app-font-class-0'>{title}</h4>
            : null
          }
        </div>
      </div>
    )
}


export default Header