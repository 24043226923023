import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Chart from "react-apexcharts"
import _ from "lodash"
import SingleSelect from "components/filters/SingleSelect"
import Utils from "utils"
import { Routing } from "routes"
import { useChainId } from "hooks/useChainId";

const MIN = 0
const MAX = 6
const MEDIUM = 3

const HourlyCalendar = (props) => {
  const chainId = useChainId();
  const { actions, clinicId, startDate, endDate} = props

  const [min, setMin] = useState(MIN)
  const [max, setMax] = useState(MAX)
  const [medium, setMedium] = useState(MEDIUM)

  const [series, setSeries] = useState([])
  const [categories, setCategories] = useState([])
  const [criteriaOptions, setCriteriaOptions] = useState([])
  const [criteria, setCriteria] = useState("")

  const avgData = useSelector((state) => state.clinicReducer?.hourlyData?.hourlyAvgView)
  const criteriaFilter = useSelector((state) => state.clinicReducer.hourly_average_filter)

  // api request to fetch criteria
  useEffect(() => {
    _.isEmpty(criteriaFilter) && actions?.clinicActions?.getHourlyAverageFilter(
      0
    )
  }, [])

  // api request to fetch data
  useEffect(() => {
    !_.isEmpty(clinicId) && criteria && actions?.clinicActions?.getHourlyCalendar(
      chainId,
      clinicId,
      startDate,
      endDate,
      criteria
    )
  }, [clinicId, startDate, endDate, criteria])

  // criteria into options
  useEffect(() => {
    if(_.isEmpty(criteriaFilter)) return
    let opts = criteriaFilter?.map(({hourlyAveFilterName}) => ({label: hourlyAveFilterName, value : hourlyAveFilterName}))
    setCriteriaOptions(opts)
    if(_.isEmpty(criteria?.value) && !_.isEmpty(opts[0]?.value)) setCriteria(opts[0]?.value)
  }, [criteriaFilter])

  // handeling change
  const handleAvgCriteria = (change) => {
    setCriteria(change?.value)
  }

  // data into apex data
  useEffect(() => {
    let update = formatData(avgData)
    setSeries(update.series)
    setCategories(update.categories)
    
    if(update.max > MAX) setMax(update.max)
    else setMax(MAX)

    if(update.medium > MEDIUM) setMedium(update.medium)
    else setMedium(MEDIUM)
  }, [avgData])

  let options = {
    plotOptions: {
      heatmap: {
        colorScale: {
          enableShades: true,
          shadeIntensity: 0.3,
          ranges: [
            {
              from: 0,
              to: 0,
              color: "#FFFFFF",
              foreColor: "#FFFFFF",
              name: "none",
            },
            {
              from: min + 0.0000001,
              to: medium / 2,
              color: "#C36D50",
              name: "lower_middle",
            },
            {
              from: medium / 2 + 0.0000001,
              to: medium,
              color: "#D7CD48",
              name: "upper_middle",
            },
            {
              from: medium + 0.0000001,
              to: max,
              color: "#519985",
              name: "high",
            },
          ],
          min: min,
          max: max,
        },
      },
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: '1rem'
      }
    },
    dataLabels: {
      formatter: (val) => `${Utils.formatNumber(val)}`,
      style: {
        fontSize: "1rem",
      }
    },
    xaxis: {
      categories: categories,
      position: "top",
      labels: {
        show: true,
        formatter: (date) => {
          return date
          // return Intl.DateTimeFormat("en-us", {
          //   month: "long",
          //   year: "numeric",
          //   day: "2-digit",
          // }).format(date)
        },
        style: {
          fontSize: '1rem'
        }
      },
    },
    yaxis: {
      reversed: true,
      labels: {
        show: true,
        align: 'right',
        style: {
          fontSize: '1rem'
        }
      },
    },
    legend: {
      show: false,
    },
  }

    // setState: categories
    useEffect(() => {
      _.assign(options, {
        xaxis: {
          categories: categories,
        },
      })
    }, [categories])

    useEffect(() => {
      if (!props.isEmbedded) {
        window.history.replaceState(null, null, `/clinic-report${Routing.ClinicReport.HourlyCalendar.relativePath}`);
        window.scrollTo(0, 0);
      }
    }, []);

  return (
    <article className='postion-relative w-100'>
      {/* chart header */}
      {!props.isEmbedded &&
        <header className='app-font-class-0'>
          Hourly View of Average No. of Patients & Patient-to-Doctor Ratio
        </header>
      }
      <main className='position-relative mt-2'>
        {/* dropdown */}
        {!props.isEmbedded &&
          <section className='d-flex flex-row-reverse mt-1'>
            <SingleSelect placeholder={"Select criteria"} onChange={handleAvgCriteria} options={criteriaOptions} value={{label: criteria, value: criteria}}/>
          </section>
        }
        {/* chart */}
        <section className='mt-1'>
          <Chart
            options={options}
            series={series}
            type='heatmap'
          />
        </section>
      </main>
    </article>
  )
}

let formatData = (data) => {
  // return format
  if (!data?.length > 0)
    return {
      series: [],
      categories: [..._.range(8,13,1).map(v => v+"AM"), ..._.range(1,12,1).map(v => v+"PM")], 
    }

  let series = []
  let mergedData = []

  // find min, max, avg from mergedData
  for(let row of data){
    let res = row.data.map(datum => datum.usage)
    mergedData = _.union(mergedData, res)
    series.push({
      name: row.day,
      data: res
    })
  }

  if(series.length && series[0].name === 'Sunday') {
    series.push(series.shift());
  }

  // calculating min, max, avg
  let vmin = Number.POSITIVE_INFINITY
  let vmax = Number.NEGATIVE_INFINITY
  let vsum = 0
  let vavg = 0
  for(let i of mergedData){
    if(i < vmin) vmin = i
    if(i > vmax) vmax = i
    vsum+=i
  }
  if(vsum) vavg = vsum/ mergedData.length

  return {
    series,
    categories: data?.[0]?.data?.map(each => each.time),
    max: _.ceil(vmax),
    medium: Math.round(vavg),
    min: _.floor(vmin)
  }
}

export default HourlyCalendar
