import React, { useState, useEffect } from "react";
import { Col, Row } from 'react-bootstrap';
import _ from "lodash";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import '../style.css';

const OccupationDistribution = (props) => {
    const [order, setOrder] = React.useState('asc');
    const handleRequestSort = () => {
        const isAsc = order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setDistributionData(order === 'asc' ? _.sortBy(distributionData, 'occupation') : _.sortBy(distributionData, 'occupation').reverse());
    };

    const [ distributionData, setDistributionData ] = useState([]);
    const columns = [
        { id: 'occupation', label: 'Occupation', minWidth: 170 },
        { id: 'values', label: '', minWidth: 200 }
    ];

    const occupationDistribution = props.occupationDistribution

    useEffect(() => {
        let distributionData = [];
        if(occupationDistribution) {
            distributionData = _.sortBy(occupationDistribution, [function(n) { return parseInt(n.occupationCount); }]).reverse();
            distributionData = _.map(distributionData, (d, i) => {
                return {
                    id: i,
                    occupation: d.occupationName,
                    values: `${d.occupationPercentage}\n${d.occupationCount}`,
                    bcolor: '#2C5A84',
                    color: '#ffffff'
                };
            })
            setDistributionData(distributionData);
        }
    }, [occupationDistribution]);

    useEffect(() => {
        if (!props.isEmbedded) {
            window.history.replaceState(
                null,
                "Medtrik",
                "/patient/dashboard/occupation-distribution"
            );
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <Row className={!props.isEmbedded ? "pb-5" : "pb-4"}>
            <Col xs={12} lg={12} className={!props.isEmbedded ? "px-8 mt-4" : ""}>
                <Paper sx={{ width: '100%' }}>
                    <TableContainer sx={{ maxHeight: 480 }}>
                        <Table stickyHeader aria-label="sticky table" sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
                            {!props.isEmbedded &&
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" colSpan={2} sx={{ fontSize: '24px', backgroundColor: '#E6E6E6', color: '#656565', padding: '17px' }}>
                                            Occupation Distribution
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ top: 0, minWidth: column.minWidth, fontSize: '18px' }}
                                                sortDirection={column.id === 'occupation' ? order : false}>
                                                <TableSortLabel
                                                    active={column.id === 'occupation'}
                                                    direction={column.id === 'occupation' ? order : 'asc'}
                                                    onClick={handleRequestSort}>
                                                    {column.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                            }
                            <TableBody>
                                {distributionData.map((row) => {
                                    return (
                                        <TableRow key={row.id}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        className="occupation-values-cell"
                                                        key={column.id}
                                                        align={column.align}
                                                        sx={{
                                                            backgroundColor: column.id === 'values' ? row.bcolor : '#ffffff',
                                                            color: column.id === 'values' && row.color ? row.color : '#000000'
                                                        }}>
                                                        {value ? value.split('\n')[0] : 'Not Specified'}<br />{value ? value.split('\n')[1] : ''}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Col>
        </Row>
    )
}

export default OccupationDistribution;
