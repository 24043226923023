/**
 * Info modal data and render config
 * - Will show explanations for RFM values
 */

import { useEffect, useState, useRef } from 'react';

import moment from "moment-timezone"
import segmentOrderConfig from "./PatientSegmentationOrderConfig";

import swal from "sweetalert";

import Loader from "components/Loader";
import MultiSelect from "components/filters/MultiSelect";
import "./style.css";



const RFMInfoModal = (props) => {

    
    // 
    // RENDER UI
    // 

    return (
        <>
            <div className="info-modal" style={{ display: props.infoToggle ? 'block' : 'none', bottom: props.isEmbedded ? "45px" : "35px" }}>
                <table className="info-table" cellSpacing={0} cellPadding={0}>
                    <tbody className="info-table-tbody">
                        <tr className="info-table-tr">
                            <td className="info-table-td info-bold" key={'recency-name'}>Recency:</td>
                            <td className="info-table-td info-desc" key={'recency-desc'}>Measures the number of days from the last visit to the present date</td>
                        </tr>
                        <tr className="info-table-tr">
                            <td className="info-table-td info-bold" key={'frequency-name'}>Frequency:</td>
                            <td className="info-table-td info-desc" key={'frequency-desc'}>Measures the number of visits made over the lifetime of the patient relationship with the practice</td>
                        </tr>
                        <tr className="info-table-tr">
                            <td className="info-table-td info-bold" key={'monetary-name'}>Monetary:</td>
                            <td className="info-table-td info-desc" key={'monetary-desc'}>Measures the total revenue earned over the lifetime of the patient relationship with the practice</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
};

export default RFMInfoModal;