import axios from 'axios';
import {
    ADMIN_ERROR,
    ADMIN_INPROGRESS,
    GET_FINANCE_COMPONENTS,
    GET_FINANCE_CLINICS,
    GET_FINANCE_REVENUE_FIGURES,
    GET_FINANCE_REVENUE_GRAPH,
    GET_FINANCE_DAILY_REVENUE,
    GET_FINANCE_KPI_PROGRESS,
    GET_FINANCE_TREATMENT_BY_INVOICE_COUNT,
    GET_FINANCE_TREATMENT_BY_DISCRETE_TREATMENT_CYCLE,
    GET_FINANCE_PER_HOUR_TREATMENT_BY_INVOICE_COUNT,
    GET_FINANCE_REVENUE_FORECAST,
    GET_FINANCE_REVENUE_FORECAST_SHOW,
    GET_FINANCE_OPTIMAL_DOCTOR_CHAIR_RATIO
} from '../constants/actionsTypes';
import ENVIRONMENT_VARIABLES from '../config';

const processRequest = (api, type, objectKey) => {
    try {
        return (dispatch) => {
            dispatch({type: ADMIN_INPROGRESS});
            axios(api).then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type,
                        data: objectKey ? response.data[objectKey] : response.data.data
                    });
                }
            }).catch((error) => {
                if (error && error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
                    dispatch({type: ADMIN_ERROR, data: {error_msg: error.response.data.user_msg}});
                } else {
                    dispatch({type: ADMIN_ERROR, data: {error_msg: error.message.toString()}});
                }
            });
        }
    } catch (error) {
        alert(error.message.toString());
    }
}

export const getFinanceComponents = (userId, roleId) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Finances/getFinanceComponents",
        data: { userId, roleId }
    };
    return processRequest(api, GET_FINANCE_COMPONENTS);
};

export const getClinics = (chainId) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Clinics",
        data: { chainId }
    };
    return processRequest(api, GET_FINANCE_CLINICS);
};

export const getFinanceRevenueFigures = (chainId, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Finances/getRevFigure",
        data: { chainId, clinicId: clinicIds, filterStartDate, filterEndDate }
    };
    return processRequest(api, GET_FINANCE_REVENUE_FIGURES, 'reFiguresTbl');
};

export const getFinanceRevenueGraph = (chainId, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Finances/getRevGraph",
        data: { chainId, clinicId: clinicIds, filterStartDate, filterEndDate }
    };
    return processRequest(api, GET_FINANCE_REVENUE_GRAPH, 'resData');
};

export const getFinanceDailyRevenue = (chainId, clinicIds, filterMonth) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Finances/getDailyRevenue",
        data: { chainId, clinicId: clinicIds, filterMonth }
    };
    return processRequest(api, GET_FINANCE_DAILY_REVENUE, 'dailyRevenueMonthGraph');
};

export const getFinanceKPIProgress = (chainId, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Finances/getKPIProgressBar",
        data: {
            chainId,
            clinicId: clinicIds,
            filterStartDate,
            filterEndDate
        }
    };
    return processRequest(api, GET_FINANCE_KPI_PROGRESS, 'resData');
};

export const getFinancePerInstanceOfTreatmentByInvoiceCount = (chainId, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Finances/getPerInstanceOfTreatmentByInvoiceCount",
        data: {
            chainId,
            clinicId: clinicIds,
            filterStartDate,
            filterEndDate
        }
    };
    return processRequest(api, GET_FINANCE_TREATMENT_BY_INVOICE_COUNT, 'resData');
};

export const getFinancePerInstanceOfTreatmentByDiscreteTreatmentCycle = (chainId, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Finances/getDiscreteTreatmentCycle",
        data: {
            chainId,
            clinicId: clinicIds,
            filterStartDate,
            filterEndDate
        }
    };
    return processRequest(api, GET_FINANCE_TREATMENT_BY_DISCRETE_TREATMENT_CYCLE, 'resData');
};

export const getFinancePerHourOfTreatmentByInvoiceCount = (chainId, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Finances/getPerHourOfTreatmentByInvoiceCount",
        data: {
            chainId,
            clinicId: clinicIds,
            filterStartDate,
            filterEndDate
        }
    };
    return processRequest(api, GET_FINANCE_PER_HOUR_TREATMENT_BY_INVOICE_COUNT, 'resData');
};

export const getFinanceRevenueForecast = (chainId, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Finances/getClinicRevenueForecast",
        data: {
            chainId,
            clinicId: clinicIds,
            filterStartDate,
            filterEndDate
        }
    };
    return processRequest(api, GET_FINANCE_REVENUE_FORECAST, 'resData');
};

export const getFinanceRevenueForecastShow = (chainId, clinicIds) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Others/getShow",
        data: { chainId, clinicId: clinicIds }
    };
    return processRequest(api, GET_FINANCE_REVENUE_FORECAST_SHOW);
};

export const getOptimalDoctorChairRatioScatterGraph = (chainId, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Finances/getOptimalDoctor/chairRatioScatterGraph",
        data: {
            chainId,
            clinicId: clinicIds,
            filterStartDate,
            filterEndDate
        }
    };
    return processRequest(api, GET_FINANCE_OPTIMAL_DOCTOR_CHAIR_RATIO, 'resData');
};
