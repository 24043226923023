import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  formControlSingle: {
    minWidth: 100,
  },
  formControlTime: {
    width: 200,
    height: 50,
    // borderRadius: '10px',
    // color: blue
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

const optionsDay = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const optionsWeekday = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const optionsWeekend = ['Saturday', 'Sunday'];

const optionsPeriod = [
  { periodValue: 'today', periodLabel: 'today' },
  { periodValue: '1 week', periodLabel: '1 week' },
  { periodValue: '2 weeks', periodLabel: '2 weeks' },
  { periodValue: '3 weeks', periodLabel: '3 weeks' },
  { periodValue: '4 weeks', periodLabel: '4 weeks' },
  { periodValue: '5 weeks', periodLabel: '5 weeks' },
  { periodValue: '6 weeks', periodLabel: '6 weeks' },
  { periodValue: '7 weeks', periodLabel: '7 weeks' },
  { periodValue: '8 weeks', periodLabel: '8 weeks' },
  { periodValue: '9 weeks', periodLabel: '9 weeks' },
  { periodValue: '1 month', periodLabel: '1 month' },
  { periodValue: '2 months', periodLabel: '2 months' },
  { periodValue: '3 months', periodLabel: '3 months' },
  { periodValue: '4 months', periodLabel: '4 months' },
  { periodValue: '5 months', periodLabel: '5 months' },
  { periodValue: '6 months', periodLabel: '6 months' },
  { periodValue: '7 months', periodLabel: '7 months' },
  { periodValue: '8 months', periodLabel: '8 months' },
  { periodValue: '9 months', periodLabel: '9 months' },
  { periodValue: '1 year', periodLabel: '1 year' },
  { periodValue: '2 years', periodLabel: '2 years' },
  { periodValue: '3 years', periodLabel: '3 years' },
];

const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

const optionsTime = [
  { timeValue: '09:00-18:00', timeLabel: 'Working Hour' },
  { timeValue: '17:00-21:00', timeLabel: 'Evening' },
  { timeValue: '', timeLabel: 'Other' },
];

const optionsTimeStart = [
  { timeValue: dayjs('09:00:00', 'HH:mm:ss'), timeLabel: 'Working Hour' },
  { timeValue: dayjs('17:00:00', 'HH:mm:ss'), timeLabel: 'Evening' },
  { timeValue: 'Other', timeLabel: 'Other' },
];

const optionsTimeEnd = [
  { timeValue: dayjs('18:00:00', 'HH:mm:ss'), timeLabel: 'Working Hour' },
  { timeValue: dayjs('21:00:00', 'HH:mm:ss'), timeLabel: 'Evening' },
  { timeValue: 'Other', timeLabel: 'Other' },
];

export {
  useStyles,
  MenuProps,
  optionsDay,
  optionsPeriod,
  optionsTime,
  optionsTimeStart,
  optionsTimeEnd,
  optionsWeekend,
  optionsWeekday,
};
