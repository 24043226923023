import React, { useState, useEffect } from "react";
import { Table, Col } from 'react-bootstrap';
import Chart from "react-apexcharts";

import '../style.css';
import { formatAmount } from "utils/parser";

const RevenueGraph = ({isEmbedded, revenueGraph, revenueFigures}) => {
    const [ chartOptions, setChartOptions ] = useState({
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 0
            },
        },
        xaxis: {},
        yaxis: {
            labels: {
                formatter: (value) => formatAmount(value),
                style: {
                    fontSize: '14px'
                }
            },
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetY: 0
        },
        tooltip: {},
        fill: {
            opacity: 1,
        },
        colors: ['#C7C7C7', '#E7E7E7'],
    });

    const customTooltip = ({ series, dataPointIndex }) => {
        const profit = series[1][dataPointIndex];
        const cost = series[0][dataPointIndex];
        const revenue = revenueGraph.barGraph.map(bar => bar.revenue)[dataPointIndex];
        const prcntMargin = revenueGraph.barGraph.map(bar => bar.prcntMargin)[dataPointIndex];
        return (
            `<div>
                <p> Revenue: <b>${formatAmount(revenue)}</b> </p>
                <p> Cost: <b>${formatAmount(cost)}</b> </p>
                <p> Profit ($): <b>${formatAmount(profit)}</b> </p>
                <p> Profit (%): <b>${prcntMargin}%</b> </p>
            </div>`
        );
    }

    const [ chartSeries, setChartSeries ] = useState([
        {
            name: 'COST',
            data: []
        },
        {
            name: 'PROFIT',
            data: []
        }
    ]);

    useEffect(() => {
        if(!isEmbedded) {
            window.history.replaceState(null, "Medtrik", "/finance/dashboard/revenue-graph");
            window.scrollTo(0, 0);
        }
    }, []);

    useEffect(() => {
        if(revenueGraph.barGraph) {
            const months = revenueGraph.barGraph.map(bar => bar.monthName);
            const cost = revenueGraph.barGraph.map(bar => bar.cost);
            const profit = revenueGraph.barGraph.map(bar => bar.profit);

            setChartOptions({...chartOptions, xaxis: {
                categories: months,
                labels: {
                    style: {
                        fontSize: '14px'
                    }
                }
            }, tooltip: {
                custom: customTooltip
            }});
            setChartSeries([{...chartSeries[0], data: cost}, {...chartSeries[1], data: profit}]);
        }
    }, [revenueGraph]);

    return (
        <>
        <Col xs={12} lg={12} className={`${!isEmbedded ? 'px-8 mb-6' : 'mb-3'}`}>
            <Table bordered className="revenue-figures">
                <thead className="thead-light">
                    <tr>
                        <th className="border-0">Total Revenue</th>
                        <th className="border-0">Total Cost</th>
                        <th className="border-0">Profit</th>
                        <th className="border-0">Margin</th>
                    </tr>
                </thead>
                <tbody className="border-0">
                    <tr>
                        <td className="border-0 fw-bold">{revenueFigures.totalRev && formatAmount(parseFloat(revenueFigures.totalRev))}</td>
                        <td className="border-0 fw-bold">{revenueFigures.totalCost && formatAmount(parseFloat(revenueFigures.totalCost))}</td>
                        <td className="border-0 fw-bold">{revenueFigures.profit && formatAmount(parseFloat(revenueFigures.profit))}</td>
                        <td className="border-0 fw-bold">{revenueFigures.prcntMargin && `${revenueFigures.prcntMargin}%`}</td>
                    </tr>
                </tbody>
            </Table>
        </Col>
        <Col xs={12} lg={12} className={`${!isEmbedded ? 'px-8 ' : ''}`}>
            <Chart options={chartOptions} series={chartSeries} type="bar" height={350} />
        </Col>
        </>
    )
}

export default RevenueGraph;
