import React, { useEffect, useState, useMemo } from "react";
import { Form, Card, Button, Row, Col, Table } from "react-bootstrap";

import { Routing } from "routes";
import { connect, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as kpiPlannerAction from "actions/kpiPlannerAction";
import Box from '@mui/material/Box';
import { Slider, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getChainKpiData } from "actions/kpiPlannerAction";
import Loader from "components/Loader"
import swal from 'sweetalert';
import {numberWithCommas, roundToTwo} from "utils/parser.js"
import decode from "jwt-decode";
import _ from "lodash";
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
    root: {
        "& .Mui-active .MuiStepIcon-root": { color: "white" },
        "& .Mui-completed .MuiStepIcon-root": { color: "#2f4858" },
        "& .Mui-disabled .MuiStepIcon-root": { color: "#dfe4ed" },
        "& .MuiStepLabel-root .MuiStepLabel-label": { fontFamily: 'Poppins', fontSize: '1em', paddingBottom: '1em', fontWeight: '500', color: '#2f4858' }
    },
    cols: {
        paddingLeft: '2em',
        paddingRight: '2em'
    },
    textField: {
        marginLeft: '1em',
        marginRight: 'auto',
        paddingBottom: 0,
        backgroundColor: 'white',
        marginBottom: "20%",
        maxHeight: '10%',
        borderRadius: '10%'
    },
}));

// #299: remove clinicPropose from here
const ClinicLevel = ({ step, nextStep, setStepPage, setName, prevStep, handleFormData, values, clinics, singleProcess = false, getClinicKPIPlanningData, getClinicReference, getAllocatesByClinic, viewMode, year }) => {

    const classes = useStyles();
    const [ranges, setRanges] = useState({});
    const [chainKpiData, setChainKpiData] = useState(null)
    const allocate = useSelector((state) => state.kpiPlannerReducer.allocatedByClinic);
    const clinicReference = useSelector((state) => state.kpiPlannerReducer.referencesClinic);
    const clinicKPIData = useSelector((state) => state.kpiPlannerReducer.clinicKPIData);
    const [optionReference, setOptionReference] = useState();
    const [allocateBy, setAllocateBy] = useState();
    const [loading, setLoading] = useState(true)
    const [inputTarget, setInputTarget] = useState('');
    const [doctorsData, setDoctorsData] = useState([])
    const [isClickSelectReference, setIsClickSelectReference] = useState(false)
    const [errors, setErrors] = useState({
        inputAmount: null
    });

    const history = useHistory();

    const steps = [
        'Propose Chain Target',
        'Propose Clinic Target',
        'Propose Doctor Target',
        'Confirm Doctor Target',
        'Confirm Clinic Target',
        'Confirm Chain Target'
    ];
    const marks = [
        {
            label: '-100%',
            value: 0
        },

        {
            value: 100,
            label: 'Max',
        },
    ];

    const clinicId = useMemo(() => {
        const accessToken = localStorage.getItem("accessToken")
        if (accessToken !== "undefined" && accessToken !== null) {
            const userProfile = decode(accessToken)
            const user = userProfile.user;
            if (user.clinic) {
                return user.clinic.clinicId;
            }
        }
    }, [])

    // #299: replaced with another function
    // const submitFormData = (e, data) => {
    //     e.preventDefault();
    //     clinicPropose(data)
    //     setTimeout(() => { prevStep1() }, 4000);
    // }

    const prevStep1 = () => {
        history.push(Routing.KpiPlanner.path);
    }
    const handleChangeRanges = (e) => {
        let { name:index, value } = e.target;
        if (!inputTarget) {
            return
        }
        let shallowListDoctors = _.cloneDeep(doctorsData)
        let targetAmount = 0

        if (value >= 0) {
            targetAmount = +(inputTarget * value / 100).toFixed(2);
        }
        shallowListDoctors[index].targetAmount = targetAmount
        shallowListDoctors[index].percentage = value
        setDoctorsData(shallowListDoctors)
        setAllocateBy("4")
    }

    // #299: add onChange function for new handleChangeTargetAmount
    const handleChangeTargetAmount = (index, value) => {
        if (!inputTarget) {
            return
        }

        let shallowListDoctors = _.cloneDeep(doctorsData);

        let targetAmount = +(Number(value) || 0).toFixed(2);

        let percentage;
        if (targetAmount >= 0) {
            percentage = +(((targetAmount * 100) / inputTarget).toFixed())
        }

        shallowListDoctors[index].targetAmount = targetAmount;
        shallowListDoctors[index].percentage = percentage;
        setDoctorsData(shallowListDoctors);

        setAllocateBy("4");
    }

    const handleChangeOptionsReference = (e) => {
        const optionReference = e.target.value
        setOptionReference(optionReference)
        setLoading(true)
        setIsClickSelectReference(true)
        if (clinicId) {
            getClinicKPIPlanningData(clinicId, Number(optionReference), year);
        }
    }

    const handleInputTargetChange = (e) => {
        let { value } = e.target
        let targetValue = Number(value) || 0;
        let shallowListDoctors = _.cloneDeep(doctorsData)
        shallowListDoctors = changeDataDoctorAcordingToAllocateId(shallowListDoctors, targetValue, Number(allocateBy))
        setDoctorsData(shallowListDoctors)
        setInputTarget(targetValue)
    }

    const handleChangeAllocateInput = (e) => {
        setAllocateBy(e.target.value)
        if (inputTarget) {
            let shallowListDoctors = _.cloneDeep(doctorsData)
            shallowListDoctors = changeDataDoctorAcordingToAllocateId(shallowListDoctors, Number(inputTarget), Number(e.target.value))
            setDoctorsData(shallowListDoctors)
        }
    }

    const changeDataDoctorAcordingToAllocateId = (tempDoctorData, value, allocateId) => {
        let shallowListDoctors = _.cloneDeep(tempDoctorData)
        if (allocateId === 1) {
            // #299: old code will set the targetAmount to half the input target, not average
            return shallowListDoctors.map(el => {
                return {
                    ...el,
                    percentage: roundToTwo(100/Object.keys(doctorsData)?.length),
                    targetAmount: +(value/Object.keys(doctorsData)?.length).toFixed(2)
                }
            })
        } else if (allocateId === 2 && clinicKPIData?.referenceAmount) {
            return shallowListDoctors.map(el => {
                return {
                    ...el,
                    percentage: +(el.referenceAmount / clinicKPIData.referenceAmount * 100).toFixed(),
                    targetAmount: +(el.referenceAmount / clinicKPIData.referenceAmount * value).toFixed(2)
                }
            })
        } else if (allocateId === 4) {
            return shallowListDoctors.map(el => {
                return {
                    ...el,
                    targetAmount: +(el.percentage / 100 * value).toFixed(2)
                }
            })
        }
        return shallowListDoctors
    }

    useEffect(() => {
        getAllocatesByClinic(0);
        getClinicReference(0);
    }, []);

    useEffect(() => {
        if (clinicReference && clinicReference.references && clinicReference.references.length) {
            setOptionReference(clinicReference.references[0].referenceId)
            if (clinicId) {
                getClinicKPIPlanningData(clinicId, Number(clinicReference.references[0].referenceId), year);
            }
        }
    }, [clinicReference])

    useEffect(() => {
        if (allocate && allocate.allocateBy && allocate.allocateBy.length) {
            setAllocateBy(allocate.allocateBy[0].allocateById)
        }
    }, [allocate])

    const handleFindTotalTarget = (data) => {
        if (data && data.length) {
            const total = data.reduce((previousValue, currentValue) => {
                return previousValue + Number(currentValue.targetAmount);
            }, 0)
            return numberWithCommas(roundToTwo(total))
        } else {
            return 0
        }
    }

    const validateFormData = (data) => {
        let errors = {};
        let formIsValid = true;

        // inputAmount
        if (!data?.inputAmount) {
            errors.inputAmount = "Please type input amount"
        }
        if (data?.inputAmount && typeof data?.inputAmount !== 'number') {
            errors.inputAmount = "Input amount must be a number"
        }

        // #299: add validation for target amount per doctor
        if (data?.doctors) {
            data.doctors.forEach(doctor => {
                if (!doctor.targetAmount) {
                  errors.targetAmount = "Please fill in all doctor targets"
                }
                if (doctor.targetAmount && doctor.targetAmount > data.inputAmount) {
                  errors.targetAmount = "Doctor targets must be less than clinic target ($" + numberWithCommas(roundToTwo(data?.inputAmount)) + ")"
                }
                if (doctor.targetAmount && doctor.targetAmount < 0) {
                  errors.targetAmount = "Doctor targets could not be negative"
                }
                if (doctor.targetAmount && typeof doctor.targetAmount !== "number") {
                  errors.targetAmount = "Doctor targets must be a number"
                }
              })
        }

        if (Object.keys(errors)?.length) {
            formIsValid = false;
            setErrors(errors)
        } else {
            setErrors({})
        }

        // #299: return 2 values
        return [formIsValid, errors]
    }

    // #299: rename from sendAPiClinicPlanningData to submitFormData
    const submitFormData = (e) => {
        const dataRequest = {
            "chainId": 1,
            "clinicId": clinicKPIData.clinicId,
            "referenceId": Number(optionReference),
            "allocateId": Number(allocateBy),
            "referenceAmount": clinicKPIData?.referenceAmount,
            "inputAmount": Number(inputTarget),
            "doctors": doctorsData,
            "year": year
        }
        e.preventDefault();

        // #299: modify to allow maximum limit:
        // if form is valid -> call API, if not -> show error popup
        let [isFormValid, formErrors] = validateFormData(dataRequest);
        if (isFormValid) {
            setLoading(true);
            kpiPlannerAction.postClinicProposeKpi(dataRequest).then(response => {
                setLoading(false)
                if (response) {
                    swal("Update Clinic KPI is successfully!", {
                        icon: "success"
                    }).then(() => {
                        prevStep1()
                    });
                }
            })
        } else {
            Object.entries(formErrors).forEach(entry => {
              const [key, value] = entry;
              swal({
                title: "Invalid Values",
                text: value,
                icon: "error"
              })
            })      
        }
    }

    useEffect(() => {
        if (!_.isEmpty(clinicKPIData)) {
            setLoading(false)
            if (clinicKPIData._id) {
                setStepPage(5)
                return
            }
            let shallowDoctorsData = clinicKPIData?.doctors.map(el => {
                return {
                    doctorId: el.doctorId,
                    referenceAmount: el.referenceAmount,
                    targetAmount: null,
                    percentage: 0
                }
            })
            if (isClickSelectReference) {
                shallowDoctorsData = changeDataDoctorAcordingToAllocateId(shallowDoctorsData, Number(inputTarget), Number(allocateBy))
                setIsClickSelectReference(false)
            }
            setDoctorsData(shallowDoctorsData)
        }
    }, [clinicKPIData])

    if (loading) {
        return <Loader></Loader>
    }

    return (
        <>

            <Box sx={{ width: '100%' }}>
                <div class="row">
                    <div class="col-12 mb-3">
                        <div class="wrapper-progressBar">
                            <ul class="progressBar">
                                {steps.map((el) =>
                                    <li
                                    className={clsx({'selected-progress': el === 'Propose Clinic Target'})}>
                                        {el}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </Box>


            <Card className="table-wrapper table-responsive shadow">
                <Card.Body>
                    {/* #299: remove onSubmit */}
                    <Form>
                        <Row className="justify-content-center chain-level" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            <Col xs={12} lg={4} className={classes.cols}>
                                <Table className="align-items-center">
                                    <tbody>

                                    <tr className="table-active">
                                        <td>Reference:</td>
                                        <td>${numberWithCommas(roundToTwo(clinicKPIData?.referenceAmount))}</td>
                                        <td></td>
                                    </tr>
                                    <tr className="table-active">
                                        <td>Input Target:</td>
                                        <td>
                                            <Form.Group controlId="inputTarget">
                                                <Form.Control
                                                    type="number"
                                                    required={true}
                                                    value={inputTarget}
                                                    onChange={(e) => handleInputTargetChange(e)}
                                                />
                                            </Form.Group>
                                            {
                                                errors?.inputAmount && <span style={{ color: 'red', fontSize: '15px'}}>
                                                        {errors.inputAmount}
                                                    </span>
                                            }
                                        </td>
                                        <td> {`  >>>   $${handleFindTotalTarget(doctorsData)}`} </td>


                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={12} lg={4}></Col>
                            <Col xs={12} lg={4} className={classes.cols}>
                                <Form.Group id="Reference" className="mb-4">
                                    <Form.Label> Select reference: </Form.Label>
                                    <Form.Select
                                        className="mb-4" id="financialYears" value={optionReference} disabled={viewMode ? true : null}
                                        onChange={(e)=> handleChangeOptionsReference(e)}
                                    >
                                        {clinicReference?.references.map((item, index) => (

                                            <option key={index} value={item.referenceId}>{item.referenceName}</option>


                                        ))}


                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-center clinic-chain-level pt-5 pb-5" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            {doctorsData.map((item, index) => (

                                <Col key={index} xs={12} md={6} lg={6} xl={3} className={classes.cols}>

                                    <Form.Label>{item.doctorId}</Form.Label>
                                    <Table className="align-items-center table-responsive  ">
                                        <tbody style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                                        <tr className="table-active">
                                            <td>Reference:</td>
                                            <td>${numberWithCommas(roundToTwo(item.referenceAmount))}</td>
                                        </tr>

                                        <tr className="table-active">
                                            <td>Target:</td>
                                            {/* // #299: add input field
                                            <td>${(doctorsData && doctorsData.length) && numberWithCommas(roundToTwo(doctorsData[index].targetAmount))}</td> */}
                                            <td>
                                                <Form.Control
                                                    required={true}
                                                    type="number"
                                                    value={doctorsData && doctorsData.length ? doctorsData[index].targetAmount : 0}
                                                    onChange={(e) => handleChangeTargetAmount(index, e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>

                                    <Slider
                                        defaultValue={item.inputTargetPercentage}
                                        value={doctorsData && doctorsData.length ? doctorsData[index].percentage : 0}
                                        name={index}
                                        onChange={handleChangeRanges}
                                        marks={marks}
                                        valueLabelDisplay="auto"
                                        min = {0} max = {100}
                                        width={"15%"}
                                        className={classes.cols}
                                        // disabled={viewMode ? true : null}
                                    />


                                </Col>



                            ))}

                        </Row>
                        <Row className="justify-content-center clinic-chain-level pt-1 pb-1" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            <Col xs={12} lg={4} className={classes.cols}>
                                <Form.Group id="AllocatedBy" className="mb-4">
                                    <Form.Label> Allocate By: </Form.Label>
                                    <Form.Select value={allocateBy}
                                                 onChange={handleChangeAllocateInput}
                                                 className="mb-4" id="financialYears" disabled={viewMode ? true : null}>
                                        {allocate?.allocateBy.map((item, index) => (
                                            <option key={index} value={item.allocateById}>{item.allocateByName}</option>

                                        ))}
                                        {/* <option value="Simple-Average">Simple Average</option>
                                    <option value="Fixed-Percentage">Fixed Percentage</option>
                                    <option value="Historical-Contribution">Historical Contribution</option>
                                    <option value="Number-of-Bookings">Number of Bookings</option>
                                    <option value="Manual-Adjustment">Manual Adjustment</option> */}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={8}></Col>
                        </Row>
                        <div style={{ justifyContent: "space-around" }}>
                            <Row className="justify-content-center clinic-chain-level pt-2 pb-5" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                                <Col xs={12} lg={8}></Col>
                                <Col xs={12} lg={2}>
                                    <Table className="align-items-center">
                                        <tbody>
                                        <tr className="table-active">
                                            <td>Total:</td>
                                            <td>{`$${handleFindTotalTarget(doctorsData)}`}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs={12} lg={2} className="button-color">
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                        {/* <Button variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" onClick={prevStep1} style={{ marginLeft: "1em"}}> Back </Button> */}
                                        {/* #299: change button type from submit to button; call submitFormData on click */}
                                        <Button variant="primary" onClick={submitFormData} className="animate-up-2 btn btn-gray-800 btn-lg" type="button" style={{ marginLeft: "1em" }} disabled={viewMode ? true : null}> Propose </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        getAllocatesByClinic: (data) => dispatch(kpiPlannerAction.getAllocatedByClinic(data)),
        getClinicReference: (data) => dispatch(kpiPlannerAction.getReferenceByClinic(data)),
        getClinicKPIPlanningData: (clinicId, referenceId, year) => dispatch(kpiPlannerAction.getClinicKPIPlanningData(clinicId, referenceId), year),
        clinicPropose: (data) => dispatch(kpiPlannerAction.updateClinicProposeKPI(data)),
    }
};

export default connect(null, mapDispatchToProps)(ClinicLevel)
