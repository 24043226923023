import React, { useEffect, useState, useMemo } from "react"
import { HomeIcon } from "@heroicons/react/solid"
import { Container, Col, Row, Card, Breadcrumb } from "react-bootstrap"
import _ from "lodash"
import { useChainId } from "hooks/useChainId";
import { Routing } from "routes"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import * as authAction from "actions/authActions"
import * as clinicActions from "actions/clinicActions"

import DailyCalendar from "./DailyCalendar"
import TimeLine from "./TimeLine"
import WeeklyCalendar from "./WeeklyCalendar"
import HourlyCalendar from "./HourlyCalendar"
import Usage from "./Usage"
import TreatmentTime from "./TreatmentTime"
import useLoginDetails from "hooks/useLoginDetails"
import useClinics from "hooks/useClinics"
import { MONTH_SLIDER } from "constants/date"
import MonthSlider from "components/filters/MonthSlider"
import MultiSelect from "components/filters/MultiSelect"

import "./style.css"

const ClinicReport = ({ actions, preselectedComponent }) => {
  const user = useLoginDetails()

  const chainId = useChainId();
  const [roleId, setRoleId] = useState(undefined)
  // const [multiClinic, setMultiClinic] = useState([{ label: "All Clinics", value: "all" }])
  // #334: show clinic dashboard for clinic manager
  const [clinicId, setClinicId] = useState(user.Role.id == 3 ? [{label: user.clinic.clinicId, value: user.clinic.clinicId}] : [{label: 'All Clinics', value: 'all'}]);
  const [selectedComponent, setSelectedComponent] = useState(preselectedComponent);
  const history = useHistory();
  const redirectUrl = (url) => {
    history.push(url);
  };

  const clinics = useClinics()

  useEffect(() => {
    user?.Role?.id && setRoleId(user.Role.id)
  }, [user?.Role?.id])

  // happening: api requests
  useEffect(() => {
    if (!roleId) return

    // admin || doctor
    if (roleId === 1 || roleId === 4 || roleId === 5) return
    
    // #334: show clinic dashboard for clinic manager
    // chain manger || clinic manager
    if (roleId === 2 || roleId === 3) {
      !clinics?.length && actions.adminAction.getUserClinicsByRole(roleId, chainId, user);
    }
  }, [roleId])

  // #334: get clinicOptions for filter
  let clinicOptions = useMemo(() => {
    return clinics?.map(({ clinicId: cid }, ind) => {
      return {value: cid, label: cid}
    }) ?? []
  }, [roleId, clinics])  

  // #334: get clinicOptions for filter
  let handleClinicChange = (change=[]) => {
    setClinicId(change);
  }

  const [selectedDate, setSelectedDate] = useState({})
  const handleMonthChange = (date) => {
    setSelectedDate(date)
  }

  const componentMapping = {
    'Ave. Utilisation & Chair Usage': {
      title: 'Timeline View of Ave. Utilisation & Chair Usage',
      component: TimeLine,
    },
    'Ave. Chair Usage & Downtime per Day': {
      title: 'Ave. Chair Usage & Downtime per Day (Day of Week View)',
      component: Usage,
    },
    'Average Time of Treatment': {
      title: 'Average Time of Treatment',
      component: TreatmentTime,
    },
    'Calendar View': {
      title: 'Daily Calendar View of Chair Usage, Downtime & Revenue Booked',
      component: DailyCalendar,
    },
    'Weekly View': {
      title: 'Weekly View of Chair Usage & Underutilisation Rate (%)',
      component: WeeklyCalendar,
    },
    // #353: changed 'Hour View' to 'Hourly View'
    'Hourly View': {
      title: 'Hourly View of Average No. of Patients & Patient-to-Doctor Ratio',
      component: HourlyCalendar,
    },
  }

  const getSelectedComponent = (componentName, actions, clinicId, selectedDate={}, isEmbedded=false) => {
    if(_.size(selectedDate) && !_.isEmpty(clinicId)) {
      const SelectedComponent = componentMapping[componentName].component;
      return <SelectedComponent actions={actions} clinicId={clinicId.map(clinic => clinic.value)} isEmbedded={isEmbedded} {...selectedDate} />;
    }
  }

  const goBackToDashboard = () => {
    window.history.replaceState(null, "Medtrik", "/clinic-report");
    setSelectedComponent(null);
  }

  return (
    <Container fluid={true} className="clinic-dashboard">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            {
              selectedComponent ?
              <>
                  <Breadcrumb.Item onClick={goBackToDashboard}>Clinic Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>{selectedComponent}</Breadcrumb.Item>
              </> :
              <Breadcrumb.Item active>Clinic Dashboard</Breadcrumb.Item>
            }
          </Breadcrumb>
          <h4>Clinic Dashboard</h4>
        </div>
      </div>
      <Card border='0' className='table-wrapper table-responsive shadow w-100'>
        <Card.Body>
          <div className="mb-3">
            <Row>
              <Col className='position-relative d-flex flex-row p-2'>
                <MultiSelect placeholder={"Select Clinic"} onChange={handleClinicChange} options={clinicOptions} value={clinicId} valueForAll={user.Role.id == 3 ? null : "all"} labelForAll="All Clinics"/>
              </Col>
              <Col className="position-relative d-flex flex-row-reverse">
                <MonthSlider {...Object.assign({}, MONTH_SLIDER, {display: true, type: 2})} handleMonthChange={handleMonthChange}/>
              </Col>
            </Row>
          </div>
          {selectedComponent && getSelectedComponent(selectedComponent, actions, clinicId, selectedDate)}
          {!selectedComponent && Object.keys(componentMapping).map((clinicComponent, index) => {
            return (
              <Col xs={12} key={index}>
                <div className="mb-4" onClick={() => setSelectedComponent(clinicComponent)}>
                  <div className="pill-container px-4 pt-3">
                    <div className="pill-text mt-3 mb-3">{componentMapping[clinicComponent].title}</div>
                    {getSelectedComponent(clinicComponent, actions, clinicId, selectedDate, true)}
                  </div>
                </div>
              </Col>
            )
          })}
        </Card.Body>
      </Card>
    </Container>
  )
}

export default connect(null, (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(authAction, dispatch),
    clinicActions: bindActionCreators(clinicActions, dispatch),
  },
}))(ClinicReport)
