import React, { useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {useRoleId} from "../../hooks/useRoleId"
import Dashboard from "../MonthlyReport/Dashboard"
import ConversionBarChart from '../MonthlyReport/ConversionBarChart'
import './style.css'
import {conversionColumns} from '../MonthlyReport/columns'
import { getReportData, getConversionData } from '../../actions/reportActions'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import { HomeIcon } from "@heroicons/react/solid"
import { Breadcrumb, Card, Button } from 'react-bootstrap'
import { Routing } from "routes"
import { useHistory } from "react-router-dom";
import { getKpiClinics } from '../../actions/authActions'


function ConversionChart({type,dashboarddate,branch,doctors,isEmbedded}) {
const dispatch = useDispatch()
//   const history = useHistory();
//   const redirectUrl = (url) => {
//       history.push(url);
//   };

  //get role id from redux state to determine whether user can edit the headlines and insights or not
  const roleId = useRoleId()

  //define current month and year for the initial rendering of data to be the latest available data
  const currDate = new Date()
  let currMonth = currDate.getMonth()
  let currYear = currDate.getFullYear()
  if (currMonth === 0) {
      //meaning that the current month is Jan
      //then month of report to get is Dec of last year
      currMonth = 12
      currYear = currYear - 1
  }

  //get kpiClinic and populate the redux state
  //access kpiClinic and adminUser from redux state to initialise currBranch and branchOptions
  useMemo(() => dispatch(getKpiClinics()), [])
  const {adminUser, kpiClinic} = useSelector((state) => state.adminAuthReducer)
  let currBranch = ''
  let branchOptions = []
  if (type === 'branch') {
    if (roleId === 3) {
        //clinic manager only has access to own branch, which is in adminUser 
        currBranch = adminUser?.clinic?.clinicId
    } else {
        if (kpiClinic && kpiClinic.length > 0) {
            //superadmin and chain manager each have access to all branches, which is in kpiClinic
            // currBranch = kpiClinic[0]['clinicId']
            currBranch = 'CHAIN'
            branchOptions = kpiClinic.map((clinic) => {
                return {value: clinic['clinicId'], label: toTitleCase(clinic['clinicId'])}
            })
            branchOptions = [{ value: 'CHAIN', label: 'All Clinics' }, ...branchOptions];

        }
    }
  } else {
    currBranch = 'CHAIN'
  }

  //get report data for the first time using roleId, currMonth, currYear, and branch, then populate the redux state
  useMemo(() => {
    if (currBranch && currBranch.length > 0) {
        dispatch(getReportData({roleId: roleId, month: currMonth, year: currYear, branch: currBranch}))
    }
  }, [roleId, currMonth, currYear, currBranch, dispatch])
  
  //access reportData, success statuses, and errorMessage from the redux state
  const {reportData, updateHeadlinesInsightsSuccess, publishHeadlinesInsightsSuccess, errorMessage} = useSelector((state) => state.reportReducer)
  
  //each memoised data is in an array form of [...row objects, mergedComments]
  const patientDataMemo = useMemo(() => reportData.patientDashboard, [reportData.patientDashboard])
  const conversionDataMemo = useMemo(() => reportData.conversionDashboard, [reportData.conversionDashboard])
  const branchDoctorsMemo = useMemo(() => reportData.branchDoctors, [reportData.branchDoctors])

  //format conversion data to get the props for ConversionBarChart
  var seriesData = [[], [], []]
  var barXLabels = []
  var rowMaxWidths = []
  var maxWidth = 0
  conversionDataMemo.forEach((row) => {
    seriesData[0].push(row['firstPatients'])
    seriesData[1].push(row['returnedPatients'])
    seriesData[2].push(row['conversionRate'])
    barXLabels.push(row['dateLabel'])

    let rowMaxWidth = Math.max(row['firstPatients'], row['returnedPatients'])
    rowMaxWidths.push(rowMaxWidth)

    if (rowMaxWidth >= maxWidth) {
        maxWidth = rowMaxWidth
    }
  })

  //define month options and month state for the month select component
  const monthOptions = [
      {value: 1, label: 'Jan'}, {value: 2, label: 'Feb'}, {value: 3, label: 'Mar'}, {value: 4, label: 'Apr'},
      {value: 5, label: 'May'}, {value: 6, label: 'Jun'}, {value: 7, label: 'Jul'}, {value: 8, label: 'Aug'},
      {value: 9, label: 'Sep'}, {value: 10, label: 'Oct'}, {value: 11, label: 'Nov'}, {value: 12, label: 'Dec'}
  ]
  const [monthChoice, setMonthChoice] = useState({value: 0, label: ''})

  //define branch state for branch select component
  const [branchChoice, setBranchChoice] = useState({value: '', label: ''})

  //define ending month state for conversion ending month select component
  const [conversionMonth, setConversionMonth] = useState({value: 0, label: ''})
  //define ending year state for conversion ending year input component
  const [conversionYear, setConversionYear] = useState(0)

  //format branchDoctors data to get drOptions
  //define conversion dr state for conversion dr select component
  
  const drOptions = branchDoctorsMemo.map((dr) => {return {value: dr, label: dr}})
  drOptions.push({value: 'BRANCH', label: 'All Doctors'})


  const [drChoice, setDrChoice] = useState({value: '', label: ''})

  useEffect(() => {
    if (Object.keys(errorMessage).length > 0) {
        toast.error(errorMessage.message)
    }
}, [errorMessage])

  //function to get conversion data using the inputted month, year, branch, and dr in the form
  const handleSubmitConversion = (e) => {
    e.preventDefault()
    var formData = new FormData(e.target)
    var formJson = Object.fromEntries(formData.entries())

    if (branchChoice['value'].length > 0) {
        //in the branch-level report, superadmin/chain manager chooses a month, year, branch, and dr
        dispatch(getConversionData({month: conversionMonth['value'], year: formJson['conversionYear'], 
        branch: branchChoice['value'], dr: drChoice['value'].length > 0 ? drChoice['value'] : 'BRANCH'}))
    } else if (drChoice['value'].length > 0) {
        //in the branch-level report, clinic manager chooses a month, year, and dr
        dispatch(getConversionData({month: conversionMonth['value'], year: formJson['conversionYear'], 
        branch: currBranch, dr: drChoice['value']}))
    } else {
        if (type === 'chain') {
            //in the chain-level report, superadmin/chain manager chooses a month and year
            dispatch(getConversionData({month: conversionMonth['value'], year: formJson['conversionYear'], 
            branch: currBranch, dr: currBranch}))
        } else {
            //in the branch-level report, clinic manager cannot choose the branch and doesn't select a dr
            //OR
            //in the branch-level report, superadmin/chain manager doesn't select a branch and dr
            dispatch(getConversionData({month: conversionMonth['value'], year: formJson['conversionYear'], 
            branch: currBranch, dr: 'BRANCH'}))
        }
    }
  }

  //whenever a new report is generated, set month and year select states to be aligned with new report
  useEffect(() => {
    if (reportData.month) {
        setMonthChoice({value: reportData.month, label: getMonthName(reportData.month)})
        if (reportData.month !== conversionMonth['value']) {
            setConversionMonth({value: reportData.month, label: getMonthName(reportData.month)})
        }
    }
    if (reportData.year && reportData.year !== conversionYear) {
        setConversionYear(reportData.year)
    }
  }, [reportData.month, reportData.year])

  useEffect(() => {
    if (isEmbedded){
        window.history.replaceState(
            null,
            "Medtrik",
            "/patient/dashboard/conversion-graph"
        );
        window.scrollTo(0,0);
    }
}, []);

  return (
    <div> {!isEmbedded ?
      <div>
          {/* <div className='reportContentWrapper'> */}
              <Card style={{ border: '1px solid black' }}>
                {isEmbedded}
                <Card.Body>
                    {/* <h1>{dashboarddate.endDate} {branch} {doctors}</h1> */}
                    <h4 style={{display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}>Patient Conversion Summary</h4>
                    <p style={{display: 'flex', justifyContent: 'center'}}> *Note: The treatments filter and segments filter does not apply to this section.</p>
                    <div className="conversionInputWrapper">
                        <form className='formOrItem' onSubmit={(e) => handleSubmitConversion(e)}>
                            <div className='formOrItem'>
                                <div className='endingMonthSelectWrapper'>
                                    <Select
                                    value={conversionMonth['value'] > 0 ? conversionMonth : null}
                                    options={monthOptions}
                                    placeholder='Ending Month'
                                    onChange={(m) => setConversionMonth(m)}
                                    />
                                </div>
                            </div>
                            <div className='formOrItem'>
                                <input name='conversionYear' type='number' min='2021' 
                                placeholder='Ending Year' className='yearInput' 
                                value={conversionYear}
                                onChange={(e) => setConversionYear(e.target.value)}
                                />
                            </div>
                            {type === 'branch' && [1, 2].includes(roleId) ? 
                            <div className='formOrItem'>
                                <div className='monthSelectWrapper'>
                                    <Select
                                    value={branchChoice['value'].length > 0 ? branchChoice : {value: currBranch, label: toTitleCase('All Clinics')}}
                                    options={branchOptions}
                                    placeholder='Clinic'
                                    onChange={(m) => setBranchChoice(m)}
                                    />
                                </div>
                            </div> : null}
                            <div className='formOrItem'>
                                <div className='monthSelectWrapper'>
                                    <Select
                                    value={drChoice['value'].length > 0 ? drChoice : {value: 'BRANCH', label: 'All Doctors'}}
                                    options={drOptions}
                                    placeholder='Doctor'
                                    onChange={(m) => setDrChoice(m)}
                                    />
                                </div>
                            </div>
                            <div className="formOrItem formButton">
                                <Button type='submit' variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" id='formattedButton'>
                                    Generate Data
                                </Button>
                            </div>
                        </form>
                    </div>

                    {/* <Dashboard columns={conversionColumns} data={conversionDataMemo} 
                    type='conversion' monthYear={[reportData.month, reportData.year]}/> */}

                    <div className="chart">
                        <ConversionBarChart seriesData={seriesData} xaxisCategories={barXLabels} rowMaxWidths={rowMaxWidths} maxWidth={maxWidth}/>
                    </div>
                </Card.Body>
              </Card>
          {/* </div> */}
          <ToastContainer/>
      </div>
      :
      <div>
          {/* <div className='reportContentWrapper'> */}
              <Card style={{border:'none'}}>
                {isEmbedded}
                <Card.Body>
                    {/* <h1>{dashboarddate.endDate} {branch} {doctors}</h1> */}
                    <h4 style={{display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}>Patient Conversion Summary</h4>
                    <p style={{display: 'flex', justifyContent: 'center'}}> *Note: The treatments filter and segments filter does not apply to this section.</p>
                    <div className="conversionInputWrapper">
                        <form className='formOrItem' onSubmit={(e) => handleSubmitConversion(e)}>
                            <div className='formOrItem'>
                                <div className='endingMonthSelectWrapper'>
                                    <Select
                                    value={conversionMonth['value'] > 0 ? conversionMonth : null}
                                    options={monthOptions}
                                    placeholder='Ending Month'
                                    onChange={(m) => setConversionMonth(m)}
                                    />
                                </div>
                            </div>
                            <div className='formOrItem'>
                                <input name='conversionYear' type='number' min='2021' 
                                placeholder='Ending Year' className='yearInput' 
                                value={conversionYear}
                                onChange={(e) => setConversionYear(e.target.value)}
                                />
                            </div>
                            {type === 'branch' && [1, 2].includes(roleId) ? 
                            <div className='formOrItem'>
                                <div className='monthSelectWrapper'>
                                    <Select
                                    value={branchChoice['value'].length > 0 ? branchChoice : {value: currBranch, label: toTitleCase('All Clinics')}}
                                    options={branchOptions}
                                    placeholder='Clinic'
                                    onChange={(m) => setBranchChoice(m)}
                                    />
                                </div>
                            </div> : null}
                            <div className='formOrItem'>
                                <div className='monthSelectWrapper'>
                                    <Select
                                    value={drChoice['value'].length > 0 ? drChoice : {value: 'BRANCH', label: 'All Doctors'}}
                                    options={drOptions}
                                    placeholder='Doctor'
                                    onChange={(m) => setDrChoice(m)}
                                    />
                                </div>
                            </div>
                            <div className="formOrItem formButton">
                                <Button type='submit' variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" id='formattedButton'>
                                    Generate Data
                                </Button>
                            </div>
                        </form>
                    </div>

                    <Dashboard columns={conversionColumns} data={conversionDataMemo} 
                    type='conversion' monthYear={[reportData.month, reportData.year]}/>

                    <div className="chart">
                        <ConversionBarChart seriesData={seriesData} xaxisCategories={barXLabels} rowMaxWidths={rowMaxWidths} maxWidth={maxWidth}/>
                    </div>
                </Card.Body>
              </Card>
          {/* </div> */}
          <ToastContainer/>
      </div>
    }
    </div>
  )
}

function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
}

function getMonthName(monthNumber) {
    const date = new Date()
    date.setDate(1)
    date.setMonth(monthNumber - 1)
    return date.toLocaleString('en-US', {month: 'short'})
}

export default ConversionChart
