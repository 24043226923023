import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Table, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { makeStyles } from '@mui/styles';
import ReactApexChart from 'react-apexcharts';
import { formatAmount } from 'utils/parser';

const useStyles = makeStyles(() => ({
  p: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    color: '#2f4858',
  },
}));

const TreatmentTarget = ({ isEmbedded, revenueBarChart }) => {
  const classes = useStyles();
  const [seriesData, setSeriesData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [targetValue, setTargetValue] = useState();
  const [radioValue, setRadioValue] = useState('1');          // #381: RADIO OPTION STATE
  const [sortByDoctor, setSortByDoctor] = useState(false);    // #381: SORT BY DOCTOR (true) OR SORT BY REVENUE (false) STATE

  // #381: RADIO OPTIONS
  const radios = [
    { name: 'By Revenue', value: '2' },
    { name: 'By Doctor', value: '3' },
  ];

  const state = {
    treatmentTime: [
      {
        data: seriesData,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 480,
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          vertical: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      colors: ['#CCC05A', '#8672A5', '#546E7A', '#d4526e'],
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        style: {
          colors: ['#2f4858'],
          fontSize: '1.0em',
        },
        formatter: (value) => formatAmount(value),
        offsetX: 0,
        offsetY: -15,
        dropShadow: {
          enabled: false,
        },
      },

      legend: {
        show: false,
      },
      xaxis: {
        categories: categories,
        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          color: '#78909C',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      annotations: {
        yaxis: [
          {
            y: targetValue,
            borderColor: '#d4526e',
            strokeDashArray: 4,
            fontSize: '16px',
            label: {
              background: '#d4526e',
              offsetX: -100,
              style: {
                color: '#2f4858',
                fontSize: '1.0em',
                fontFamily: 'Poppins',
                textAlign: 'center',
              },
              text: '$' + (targetValue || 0) + ' Target',
            },
          },
        ],
      },
      yaxis: {
        tickAmount: 10,

        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          formatter: (value) => formatAmount(value),
        },
      },
      tooltip: {
        style: {
          textAlign: 'center',
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div>' +
            '<span>' +
            w.globals.labels[dataPointIndex] +
            ' : ' +
            formatAmount(series[seriesIndex][dataPointIndex]) +
            '</span>' +
            '</div>'
          );
        },
      },
    },
  };

  const stateEmbed = {
    treatmentTime: [
      {
        data: seriesData,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 480,
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          vertical: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      colors: ['#CCC05A', '#8672A5', '#546E7A', '#d4526e'],
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
        textAnchor: 'middle',
        style: {
          colors: ['#2f4858'],
          fontSize: '1.0em',
        },
        formatter: (value) => formatAmount(value),
        offsetX: 0,
        offsetY: -15,
        dropShadow: {
          enabled: false,
        },
      },

      legend: {
        show: false,
      },
      xaxis: {
        categories: categories,
        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          color: '#78909C',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      annotations: {
        yaxis: [
          {
            y: targetValue,
            borderColor: '#d4526e',
            strokeDashArray: 4,
            fontSize: '16px',
            label: {
              background: '#d4526e',
              offsetX: -100,
              style: {
                color: '#2f4858',
                fontSize: '1.0em',
                fontFamily: 'Poppins',
                textAlign: 'center',
              },
              text: '$' + (targetValue || 0) + ' Target',
            },
          },
        ],
      },
      yaxis: {
        tickAmount: 10,

        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          formatter: (value) => formatAmount(value),
        },
      },
      tooltip: {
        style: {
          textAlign: 'center',
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div>' +
            '<span>' +
            w.globals.labels[dataPointIndex] +
            ' : ' +
            formatAmount(series[seriesIndex][dataPointIndex]) +
            '</span>' +
            '</div>'
          );
        },
      },
    },
  };

  useEffect(() => {
    if (!isEmbedded) {
      window.history.replaceState(null, 'Medtrik', '/TreatmentTarget');
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    //set series data
    let data = [];
    let categories = [];

    if (revenueBarChart) {
      // #429: only show non-zero bars
      let filtered = revenueBarChart.treatmentRevPerHrData.filter((rph) => rph.treatmentRevPerHr && rph.treatmentRevPerHr!=0);
      const sortedTreatmentTarget = [...filtered].sort((a, b) => {
        if (sortByDoctor) {
          // #381: Sort alphabetically by doctor name
          return a.doctorName.localeCompare(b.doctorName);               
        } else {
          // #381: Sort by average treatment time
          return parseFloat(b.treatmentRevPerHr) - parseFloat(a.treatmentRevPerHr)
        }
      });

      sortedTreatmentTarget.map((item, index) => {
        categories.push(item.doctorName);
        data.push(item.treatmentRevPerHr.toFixed(2));
      });
    }
    setTargetValue(revenueBarChart?.targetRevPerHr);
    setSeriesData(data);
    setCategories(categories);
  }, [revenueBarChart, sortByDoctor]);  // #381: ADD sortByDoctor TRIGGER

  // #381: SET sortByDoctor STATE WHEN RADIO BUTTONS ARE CLICKED
  const handleSortByDoctorClick = (value) => {
    if (value==='3') {
      setSortByDoctor((prevSortByDoctor) => !prevSortByDoctor);
    } else if (value==='2') {
      setSortByDoctor(false);
    }
  };

  return (
    <>
      {isEmbedded === false ? (
        // <ReactApexChart
        //   options={state.options}
        //   series={state.treatmentTime}
        //   type="bar"
        //   width={'100%'}
        //   className={classes.p}
        // />
        // #381: ADD RADIO BUTTONS ON TOP OF GRAPH DISPLAY IF NOT EMBEDDED
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <ButtonGroup style={{ marginBottom: '30px' }}>
                {radios.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={'outline-success'}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                    onClick={() => handleSortByDoctorClick(radio.value)}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </div>
          <Table className="align-items-center" style={{ width: '100%', marginBottom: '0.5%' }}>
            <tbody>
              <tr className="table-active" style={{ backgroundColor: '#E9EAEB' }}>
                <td className={classes.p} style={{ textAlign: 'center', fontSize: '1.3em' }}>
                  Target Revenue per Hour:
                </td>
              </tr>
              <tr
                className="table-active"
                style={{ backgroundColor: '#CECED0', fontFamily: 'Poppins', color: '#2f4858', fontSize: '1.2em' }}
              >
                <td className={classes.p} style={{ textAlign: 'center', fontSize: '1em' }}>
                  {revenueBarChart?.targetRevPerHr.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </td>
              </tr>
            </tbody>
          </Table>
          <ReactApexChart
            options={state.options}
            series={state.treatmentTime}
            type="bar"
            height={450}
            width={'100%'}
            className={classes.fontF}
          />
        </>            
      ) : (
        // <Row className="justify-content-left kpi-planner">
        //   <Col xs={12} lg={isEmbedded ? 12 : 6} md={isEmbedded ? 12 : 6}>
        //     {!isEmbedded && (
        //       <Table className="align-items-center" style={{ width: '100%', marginBottom: '2%' }}>
        //         <tbody>
        //           <tr className="table-active" style={{ backgroundColor: '#E9EAEB' }}>
        //             <td className={classes.p} style={{ textAlign: 'center', fontSize: '1.3em' }}>
        //               Target Revenue per Hour:
        //             </td>
        //           </tr>
        //           <tr
        //             className="table-active"
        //             style={{ backgroundColor: '#CECED0', fontFamily: 'Poppins', color: '#2f4858', fontSize: '1.2em' }}
        //           >
        //             <td className={classes.p} style={{ textAlign: 'center', fontSize: '1em' }}>
        //               {revenueBarChart?.targetRevPerHr.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
        //             </td>
        //           </tr>
        //         </tbody>
        //       </Table>
        //     )}
        //     <ReactApexChart
        //       options={stateEmbed.options}
        //       series={stateEmbed.treatmentTime}
        //       type="bar"
        //       width={'100%'}
        //       className={classes.p}
        //     />
        //   </Col>
        // </Row>
        // #381: GRAPH DISPLAY WHEN EMBEDDED 
        <Row className="justify-content-left kpi-planner" style={{ marginBottom: '14px' }}>
          <Col xs={12} lg={isEmbedded ? 12 : 6} md={isEmbedded ? 12 : 6} className="mt-4">
            <ReactApexChart
              options={stateEmbed.options}
              series={stateEmbed.treatmentTime}
              type="bar"
              height={450}
              width={'100%'}
              className={classes.fontF}
            />
          </Col>
        </Row>      
      )}
    </>
  );
};

export default TreatmentTarget;
