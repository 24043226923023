import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import ENVIRONMENT_VARIABLES from '../config';

const BASE_URL = `${ENVIRONMENT_VARIABLES.Base_API_URL}/Report`

export const getReportData = createAsyncThunk('report/getReportData', async (data, thunkAPI) => {
    console.log("data REQ ERROR: ", data);
    const {roleId, month, year, branch} = data
    const token = "Bearer " + localStorage.getItem('accessToken')
    try {
        const response = await axios.post(`${BASE_URL}/getReportData`, {
            roleId: roleId,
            month: month,
            year: year,
            branch: branch
        }, {
            headers: {
                'Authorization': token
            }
        })
        return response.data
    } catch (error) {
        console.log(error)
        if (error.response.status === 400) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else if (error.response.status === 500) {
            return thunkAPI.rejectWithValue({message: 'Both month and year must be specified!'})
        }
        return thunkAPI.rejectWithValue(error)
    }
})

export const updateHeadlinesInsights = createAsyncThunk('report/updateHeadlinesInsights', async (data, thunkAPI) => {
    const {month, year, headlines, insights} = data
    const token = "Bearer " + localStorage.getItem('accessToken')
    try {
        const response = await axios.put(`${BASE_URL}/updateHeadlinesInsights`, {
            month: month,
            year: year,
            headlines: headlines,
            insights: insights
        }, {
            headers: {
                'Authorization': token
            }
        })
        return response.data
    } catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue(error)
    }
})

export const publishHeadlinesInsights = createAsyncThunk('report/publishHeadlinesInsights', async (data, thunkAPI) => {
    const {month, year, headlines, insights} = data
    const token = "Bearer " + localStorage.getItem('accessToken')
    try {
        const response = await axios.put(`${BASE_URL}/publishHeadlinesInsights`, {
            month: month,
            year: year,
            headlines: headlines,
            insights: insights
        }, {
            headers: {
                'Authorization': token
            }
        })
        return response.data
    } catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue(error)
    }
})

export const getConversionData = createAsyncThunk('report/getConversionData', async (data, thunkAPI) => {
    const {month, year, branch, dr} = data
    const token = "Bearer " + localStorage.getItem('accessToken')
    try {
        const response = await axios.post(`${BASE_URL}/getConversionData`, {
            month: month,
            year: year,
            branch: branch,
            dr: dr
        }, {
            headers: {
                'Authorization': token
            }
        })
        return response.data
    } catch (error) {
        console.log(error)
        if (error.response.status === 400) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else if (error.response.status === 500) {
            return thunkAPI.rejectWithValue({message: 'Month, year, branch, and doctor must be specified!'})
        }
        return thunkAPI.rejectWithValue(error)
    }
})
