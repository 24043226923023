/**
 * Config file containing patient segments (in order) and its corresponding color for each graph
 * [2024-04-12] Currently colors are taken from Colab Notebook graphs 
 * [2024-04-17] Added description/explanation for each category
 */

export default {
    'Champion': {
        'color': '#3b75af',
        'description': 'Patients who have last visited recently and have high frequency of visits and total lifetime revenue.'
    },
    'Loyal': {
        'color': '#ef8636',
        'description': 'Patients who have last visited recently and have high frequency of visits.'
    },
    'Potential Upsell': {
        'color':'#519e3e',
        'description': 'Patients who have last visited recently, but have low frequency of visits and lifetime revenue.'
    },
    'Recent': {
        'color': '#c53a32',
        'description': 'Patients who have last visited very recently, and have low frequency of visits.'
    },
    'Essential Engagement': {
        'color': '#8d69b8',
        'description': 'Patients who have last visited some time ago, and have moderate frequency of visits and total lifetime revenue.'
    },
    'Critical Engagement': {
        'color': '#84584e',
        'description': 'Patients who have not visited for some time, but have historically shown  moderately high frequency of visits and lifetime revenue.'
    },
    'Inactive': {
        'color': '#d57dbe',
        'description': 'Patients who have not visited for some time.'
    },
    'Disengaged': {
        'color': '#7f7f7f',
        'description': 'Patients who have not visited for a very long time.'
    },
    'Others': {
        'color': '#bcbd45',
        'description': 'Patients who do not belong to any of the 8 categories above.'
    }
}