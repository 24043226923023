import initialState from "./initialState"
import {createSlice} from '@reduxjs/toolkit'
import { getReportData, updateHeadlinesInsights, publishHeadlinesInsights, getConversionData } from "../actions/reportActions"

const reportSlice = createSlice({
    name: 'report',
    initialState: initialState.reportReducer,
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(getReportData.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(getReportData.fulfilled, (state, action) => {
            state.isLoading = false
            state.getReportDataSuccess = true
            state.updateHeadlinesInsightsSuccess = false
            state.publishHeadlinesInsightsSuccess = false
            state.errorMessage = {}
            state.reportData = action.payload
        })
        .addCase(getReportData.rejected, (state, action) => {
            state.isLoading = false
            state.getReportDataSuccess = false
            state.errorMessage = action.payload
        })
        .addCase(updateHeadlinesInsights.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(updateHeadlinesInsights.fulfilled, (state, action) => {
            state.isLoading = false
            state.updateHeadlinesInsightsSuccess = true
            state.errorMessage = {}
            state.reportData['headlines'] = action.payload.updatedData.savedHeadlines
            state.reportData['insights'] = action.payload.updatedData.savedInsights
        })
        .addCase(updateHeadlinesInsights.rejected, (state, action) => {
            state.isLoading = false
            state.updateHeadlinesInsightsSuccess = false
            state.errorMessage = action.payload
        })
        .addCase(publishHeadlinesInsights.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(publishHeadlinesInsights.fulfilled, (state, action) => {
            state.isLoading = false
            state.updateHeadlinesInsightsSuccess = false
            state.publishHeadlinesInsightsSuccess = true
            state.errorMessage = {}
            state.reportData['headlines'] = action.payload.updatedData.savedHeadlines
            state.reportData['insights'] = action.payload.updatedData.savedInsights
            state.reportData['headlinesInsightsPublished'] = action.payload.updatedData.publishedAt
        })
        .addCase(publishHeadlinesInsights.rejected, (state, action) => {
            state.isLoading = false
            state.publishHeadlinesInsightsSuccess = false
            state.errorMessage = action.payload
        })
        .addCase(getConversionData.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(getConversionData.fulfilled, (state, action) => {
            state.isLoading = false
            state.getConversionDataSuccess = true
            state.updateHeadlinesInsightsSuccess = false
            state.publishHeadlinesInsightsSuccess = false
            state.errorMessage = {}
            state.reportData['conversionDashboard'] = action.payload.data
        })
        .addCase(getConversionData.rejected, (state, action) => {
            state.isLoading = false
            state.getConversionDataSuccess = false
            state.errorMessage = action.payload
        })
    }
})

export default reportSlice.reducer
