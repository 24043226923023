import {    
    GET_USER_KPI_SUMMARY,
    GET_ALLOCATED_BY_CHAIN,
    GET_REFERENCES_CHAIN,
    GET_REFERENCES_CLINIC,
    GET_CHAIN_KPI_PLANNING_DATA,
    GET_ALLOCATED_BY_CLINIC,
    UPDATE_CHAIN_PROPOSE_KPI,
    GET_CLINIC_KPI_PLANNING_DATA,
    UPDATE_CLINIC_PROPOSE_KPI,
    GET_DOCTOR_KPI_PLANNING_DATA,
    UPDATE_DOCTOR_CONFIRMED_KPI,
    UPDATE_CLINIC_CONFIRMED_KPI,
    UPDATE_CHAIN_CONFIRMED_KPI,
    GET_DOCTOR_PERFORMANCE_SUMMARY_COMPONENTS, 
    GET_ALL_DOCTORS_TOTAL_REVENUE,
    GET_DOCTOR_PRS_CLINICS,
    GET_DOCTOR_PRS_TREATMENT,
    GET_TOTAL_REVENUE_BAR_GRAPH,
    GET_TOTAL_REVENUE_BAR_CHART,
    GET_TOTAL_REVENUE_PIE_CHART,
    GET_TREATMENT_TARGET,
} from '../constants/actionsTypes';
import initialState from './initialState'; 

export default function kpiPlannerReducer (state = initialState.kpiPlannerReducer, action) {
    switch (action.type) {
        case GET_USER_KPI_SUMMARY:
            return Object.assign({}, state, { 
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,              
                userKPISummary: action.data,
               
            });
        case GET_TREATMENT_TARGET:
            return Object.assign({}, state, { 
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,              
                treatmentTarget: action.data,
                
            });
        case GET_TOTAL_REVENUE_BAR_GRAPH:
            return Object.assign({}, state, {  
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,             
                revenueBarGraph: action.data,
                
            });
        case GET_TOTAL_REVENUE_BAR_CHART:
            return Object.assign({}, state, { 
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,              
                revenueBarChart: action.data,
                
            });
        case GET_TOTAL_REVENUE_PIE_CHART:
            return Object.assign({}, state, {  
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,             
                revenuePieChart: action.data,
                
            });
            
        case GET_ALLOCATED_BY_CHAIN:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allocatedByChain: action.data,
                allocatedByChainError: action.data.allocatedByChainError
            });
        case GET_ALLOCATED_BY_CLINIC:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                allocatedByClinic: action.data,
                allocatedByClinicError: action.data.allocatedByChainError
            });
        case GET_REFERENCES_CHAIN:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                referencesChain: action.data,
            });
        case GET_REFERENCES_CLINIC:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                referencesClinic: action.data
            });
        case GET_CHAIN_KPI_PLANNING_DATA:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                chainKPIData: action.data,
                chainKPIDataError: action.data
            });
        case GET_CLINIC_KPI_PLANNING_DATA:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                clinicKPIData: action.data,
                clinicKPIDataError: action.data.error
            });
        case GET_DOCTOR_KPI_PLANNING_DATA:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                doctorKPIData: action.data,
                clinicKPIDataError: action.data.error
            });
        case UPDATE_DOCTOR_CONFIRMED_KPI:
            if(state.id !== action.id) return state;
            // let id = action.id;
            return Object.assign({}, state, {
                doctorKPIUpdate: action.data,
                // [id]:Object.assign({}, state[id], {doctorKPIConfirm:action.data}),
                
            });
        case UPDATE_CLINIC_CONFIRMED_KPI:
            if(state.id !== action.id) return state;
            // let id = action.id;
            return Object.assign({}, state, {
                clinicKPIConfirm: action.data,
            // [id]:Object.assign({}, state[id], {doctorKPIConfirm:action.data}),

        });
        case UPDATE_CHAIN_CONFIRMED_KPI:
            if(state.id !== action.id) return state;
            // let id = action.id;
            return Object.assign({}, state, {
                chainKPIConfirm: action.data,
                // [id]:Object.assign({}, state[id], {doctorKPIConfirm:action.data}),
                
            });
        case UPDATE_CHAIN_PROPOSE_KPI:
            if(state.id !== action.id) return state;
            // let id = action.id;
            return Object.assign({}, state, {
                updateChainPropose: action.data,
                // [id]:Object.assign({}, state[id], {doctorKPIConfirm:action.data}),
                
            });
        case UPDATE_CLINIC_PROPOSE_KPI:
            if(state.id !== action.id) return state;
            // let id = action.id;
            return Object.assign({}, state, {
                updateClinicPropose: action.data,
                // [id]:Object.assign({}, state[id], {doctorKPIConfirm:action.data}),
                
            });
        case GET_DOCTOR_PERFORMANCE_SUMMARY_COMPONENTS: 
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                doctorSRComponent: action.data,
                clinicKPIDataError: action.data.error
            });           
        case GET_ALL_DOCTORS_TOTAL_REVENUE:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                doctorTotalRevenue: action.data,
                doctorTotalRevenueError: action.data.error

            });
        case GET_DOCTOR_PRS_CLINICS:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                doctorPRSclinic: action.data,
                doctorPRSclinicError: action.data.error
            });
        case GET_DOCTOR_PRS_TREATMENT:
            return Object.assign({}, state, {
                loading: false,
                error_msg: null,
                isAuthenticated: false,
                token: null,
                isAuthenticatedFail: false,
                treatment: action.data,
                treatmentError: action.data.error
            });
        default:
            return Object.assign({}, state, {
                loading: false
              });
        }
}