import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Table, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { makeStyles } from '@mui/styles';
import ReactApexChart from 'react-apexcharts';
import './index.css';

const useStyles = makeStyles(() => ({
  p: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    color: '#2f4858',
  },
}));

const TreatmentAverage = ({ isEmbedded, treatmentTarget }) => {
  const classes = useStyles();
  const [seriesData, setSeriesData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [radioValue, setRadioValue] = useState('1');          // #381: RADIO OPTION STATE
  const [sortByDoctor, setSortByDoctor] = useState(false);    // #381: SORT BY DOCTOR (true) OR SORT BY REVENUE (false) STATE

  // #381: RADIO OPTIONS
  const radios = [
    { name: 'By Revenue', value: '2' },
    { name: 'By Doctor', value: '3' },
  ];

  const state = {
    series: [
      {
        data: seriesData,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 380,
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          vertical: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      colors: ['#CCC05A', '#8672A5', '#546E7A', '#d4526e'],
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        style: {
          colors: ['#2f4858'],
          fontSize: '1.0em',
        },
        formatter: function (val, opt) {
          return ' ' + val + ' h';
        },
        offsetX: 0,
        offsetY: -15,
        dropShadow: {
          enabled: false,
        },
      },

      legend: {
        show: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        tickAmount: 10,
        style: {
          fontFamily: 'Poppins',
          color: '#2f4858',
        },

        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          formatter: (value) => ' ' + value.toFixed(2) + ' h',
        },
      },
      tooltip: {
        style: {
          textAlign: 'center',
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div>' +
            '<span>' +
            w.globals.labels[dataPointIndex] +
            ' : ' +
            series[seriesIndex][dataPointIndex] +
            'h' +
            '</span>' +
            '</div>'
          );
        },
      },
    },
  };

  const stateEmbed = {
    series: [
      {
        data: seriesData,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 380,
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          vertical: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      colors: ['#CCC05A', '#8672A5', '#546E7A', '#d4526e'],
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
        textAnchor: 'middle',
        style: {
          colors: ['#2f4858'],
          fontSize: '1.0em',
        },
        formatter: function (val, opt) {
          return ' ' + val + ' h';
        },
        offsetX: 0,
        offsetY: -15,
        dropShadow: {
          enabled: false,
        },
      },

      legend: {
        show: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        tickAmount: 10,
        style: {
          fontFamily: 'Poppins',
          color: '#2f4858',
        },

        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          formatter: (value) => ' ' + value.toFixed(2) + ' h',
        },
      },
      tooltip: {
        style: {
          textAlign: 'center',
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div>' +
            '<span>' +
            w.globals.labels[dataPointIndex] +
            ' : ' +
            series[seriesIndex][dataPointIndex] +
            'h' +
            '</span>' +
            '</div>'
          );
        },
      },
    },
  };

  useEffect(() => {
    if (!isEmbedded) {
      window.history.replaceState(null, 'Medtrik', '/TreatmentAverage');
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    //set series data
    let data = [];
    let categories = [];

    if (treatmentTarget) {
      // #429: only show non-zero bars
      let filtered = treatmentTarget.aveTreatmentTimeData.filter((tt) => tt.aveTreatmentTime && tt.aveTreatmentTime !== "0h")
      const sortedTreatmentTimeData = [...filtered].sort((a,b) => {
        if (sortByDoctor) {
          // #381: Sort alphabetically by doctor name
          return a.drName.localeCompare(b.drName);        
        } else {
          // #381: Sort by average treatment time
          return parseFloat(b.aveTreatmentTime) - parseFloat(a.aveTreatmentTime)
        }
      });

      sortedTreatmentTimeData.map((item) => {
        categories.push(item.drName);
        data.push(item.aveTreatmentTime);
      });    
    }
    setSeriesData(data);
    setCategories(categories);
  }, [treatmentTarget, sortByDoctor]); // #381: ADD sortByDoctor TRIGGER

  // #381: SET sortByDoctor STATE WHEN RADIO BUTTONS ARE CLICKED
  const handleSortByDoctorClick = (value) => {
    if (value==='3') {
      setSortByDoctor((prevSortByDoctor) => !prevSortByDoctor);
    } else if (value==='2') {
      setSortByDoctor(false);
    }
  };

  return (
    <>
      {isEmbedded === false ? (
        // <ReactApexChart options={state.options} series={state.series} type="bar" width={'100%'} />
        // #381: ADD RADIO BUTTONS ON TOP OF GRAPH DISPLAY IF NOT EMBEDDED
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <ButtonGroup style={{ marginBottom: '30px' }}>
                {radios.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={'outline-success'}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                    onClick={() => handleSortByDoctorClick(radio.value)}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </div>
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="bar"
            height={450}
            width={'100%'}
            className={classes.fontF}
          />
        </>      
      ) : (
        // <Row className="justify-content-left kpi-planner">
        //   <Col xs={12} lg={isEmbedded ? 12 : 6} md={isEmbedded ? 12 : 6} className={classes.p}>
        //     <ReactApexChart options={stateEmbed.options} series={stateEmbed.series} type="bar" width={'100%'} />
        //   </Col>
        // </Row>
        // #381: GRAPH DISPLAY WHEN EMBEDDED 
        <Row className="justify-content-left kpi-planner" style={{ marginBottom: '14px' }}>
          <Col xs={12} lg={isEmbedded ? 12 : 6} md={isEmbedded ? 12 : 6} className="mt-4">
            <ReactApexChart
              options={stateEmbed.options}
              series={stateEmbed.series}
              type="bar"
              height={450}
              width={'100%'}
              className={classes.fontF}
            />
          </Col>
        </Row>      
      )}
    </>
  );
};

export default TreatmentAverage;
