import React, { useState, useEffect } from "react";
import { HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Breadcrumb,
  InputGroup,
} from "react-bootstrap";

import { Routing } from "routes";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as authAction from "actions/authActions";

import SelectClinic from "./SelectClinic";
import ClinicLevel from "./ClinicLevel";
import DoctorLevel from "./DoctorLevel";
import DoctorConfirm from "./DoctorConfirm";

import "./style.css";
import ClinicConfirm from "./ClinicConfirm";

const SingleClinic = (props) => {
  const history = useHistory();
  const [step, setstep] = useState(1);
  const [clinic, setClinic] = useState([]);
  const [selectClinic, setSelectClinic] = useState("");
  const [selectedYear, setSelectedYear] = useState(false);

  const redirectUrl = (url) => {
    history.push(url);
  };

  useEffect(() => {
    props.actions.adminAction.getClinics();
    const queryParams = new URLSearchParams(window.location.search);
    const kpiYear = queryParams.get("year");
    setSelectedYear(kpiYear);
  }, []);

  const adminAuthReducer = useSelector((state) => ({
    isLoading: state.adminAuthReducer.loading,
    clinics: state.adminAuthReducer.clinics,
  }));

  // function for going to next step by increasing step state by 1
  const nextStep = (value) => {
    window.scrollTo(0, 0);
    if (value) {
      setSelectClinic(value);
    }
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    window.scrollTo(0, 0);
    setstep(step - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    // setFormData(prevState => ({
    //     ...prevState,
    //     [input]: value
    // }));
  };

  useEffect(() => {
    if (adminAuthReducer.clinics.length) {
      setClinic(adminAuthReducer.clinics);
    }
  }, [adminAuthReducer.clinics]);

  switch (step) {
    case 1:
      return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.Dashboard.path)}
                >
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.KpiPlanner.path)}
                >
                  KPI Planner
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Clinic: KPI</Breadcrumb.Item>
              </Breadcrumb>
              <h4>Select Clinic</h4>
            </div>
          </div>
          <SelectClinic
            nextStep={nextStep}
            handleFormData={handleInputData}
            clinicData={clinic}
          />
        </>
      );
    case 2:
      return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.Dashboard.path)}
                >
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.KpiPlanner.path)}
                >
                  KPI Planner
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Clinic: KPI</Breadcrumb.Item>
              </Breadcrumb>
              <h4>Clinic : KPI Planning Process</h4>
            </div>
          </div>
          <ClinicLevel
            nextStep={nextStep}
            handleFormData={handleInputData}
            singleProcess={true}
            clinics={adminAuthReducer.clinics.filter(
              (u) => u._id === Number(selectClinic)
            )}
            year={selectedYear}
          />
        </>
      );
    case 3:
      return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.Dashboard.path)}
                >
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.KpiPlanner.path)}
                >
                  KPI Planner
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Doctor: KPI</Breadcrumb.Item>
              </Breadcrumb>
              <h4>Doctor A : KPI Planning Process</h4>
            </div>
          </div>
          <DoctorLevel
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            singleProcess={true}
            year={selectedYear}
          />
        </>
      );
    case 4:
      return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.Dashboard.path)}
                >
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.KpiPlanner.path)}
                >
                  KPI Planner
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Doctor: KPI</Breadcrumb.Item>
              </Breadcrumb>
              <h4>Doctor A: KPI Planning Process</h4>
            </div>
          </div>
          <DoctorConfirm
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            singleProcess={true}
            year={selectedYear}
          />
        </>
      );
    case 5:
      return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.Dashboard.path)}
                >
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.KpiPlanner.path)}
                >
                  KPI Planner
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Clinic: KPI</Breadcrumb.Item>
              </Breadcrumb>
              <h4>Clinic 1: KPI Planning Process</h4>
            </div>
          </div>
          <ClinicConfirm
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            singleProcess={true}
            year={selectedYear}
          />
        </>
      );

    default:
      return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.Dashboard.path)}
                >
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.KpiPlanner.path)}
                >
                  KPI Planner
                </Breadcrumb.Item>
                {/* <Breadcrumb.Item active>{ updateClinic[0].clinicId }</Breadcrumb.Item> */}
              </Breadcrumb>
              {/* <h4>{ updateClinic[0].clinicId } : KPI Planning Process</h4> */}
            </div>
          </div>
        </>
      );
  }
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(authAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(SingleClinic);
