import {createColumnHelper} from '@tanstack/react-table'

const financeColumnHelper = createColumnHelper()
const clinicColumnHelper = createColumnHelper()
const doctorColumnHelper = createColumnHelper()
const treatmentColumnHelper = createColumnHelper()
const patientColumnHelper = createColumnHelper()
const conversionColumnHelper = createColumnHelper()

export const financeColumns = [
    financeColumnHelper.group({
        header: 'Finance Dashboard',
        columns: [
            financeColumnHelper.accessor('label', {
                header: '',
                cell: info => <div style={{textAlign: 'center', fontWeight: 'bold'}}>{info.getValue()}</div>
            }),
            financeColumnHelper.accessor('revenue', {
                header: 'Revenue ($)',
                cell: info => info.getValue() && info.getValue().split(' ').length === 2 ? 
                (info.getValue().split(' '))[1].charAt(1) === '+' ? 
                <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{color: 'green', fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                (info.getValue().split(' '))[1].charAt(1) === '-' ? 
                <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{color: 'red', fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            financeColumnHelper.accessor('varRevenue', {
                header: 'MOM Var of Revenue',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            financeColumnHelper.accessor('cost', {
                header: 'Cost ($)',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            financeColumnHelper.accessor('varCost', {
                header: 'MOM Var of Cost',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            financeColumnHelper.accessor('profit', {
                header: 'Profit ($)',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            financeColumnHelper.accessor('varProfit', {
                header: 'MOM Var of Profit',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            financeColumnHelper.accessor('profitMargin', {
                header: 'Profit Margin',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            financeColumnHelper.accessor('firstInvoice', {
                header: '1st (Per Invoice of Treatment)',
                cell: info => info.getValue()
            }),
            financeColumnHelper.accessor('secondInvoice', {
                header: '2nd (Per Invoice of Treatment)',
                cell: info => info.getValue()
            }),
            financeColumnHelper.accessor('thirdInvoice', {
                header: '3rd (Per Invoice of Treatment)',
                cell: info => info.getValue()
            }),
        ]
    })
]

export const clinicColumns = [
    clinicColumnHelper.group({
        header: 'Clinic Dashboard',
        columns: [
            clinicColumnHelper.accessor('label', {
                header: '',
                cell: info => <div style={{textAlign: 'center', fontWeight: 'bold'}}>{info.getValue()}</div>
            }),
            clinicColumnHelper.accessor('uncapturedRevenue', {
                header: 'Uncaptured Revenue ($)',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            clinicColumnHelper.accessor('avgUtilisation', {
                header: 'Average Utilisation',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            clinicColumnHelper.accessor('peakHours1', {
                header: 'Peak Hours 1',
                cell: info => info.getValue()
            }),
            clinicColumnHelper.accessor('peakHours2', {
                header: 'Peak Hours 2',
                cell: info => info.getValue()
            }),
            clinicColumnHelper.accessor('peakHours3', {
                header: 'Peak Hours 3',
                cell: info => info.getValue()
            }),
        ]
    })
]

export const doctorColumns = [
    doctorColumnHelper.group({
        header: 'Doctor Dashboard',
        columns: [
            doctorColumnHelper.accessor('label', {
                header: '',
                cell: info => <div style={{textAlign: 'center', fontWeight: 'bold'}}>{info.getValue()}</div>
            }),
            doctorColumnHelper.accessor('visits', {
                header: 'Visits',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            doctorColumnHelper.accessor('varVisits', {
                header: 'MOM Var of Visits',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            doctorColumnHelper.accessor('newPatients', {
                header: 'New Patients',
                cell: info => info.getValue() && info.getValue().split(' ').length === 2 ? 
                (info.getValue().split(' '))[1].charAt(1) === '+' ? 
                <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{color: 'green', fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                (info.getValue().split(' '))[1].charAt(1) === '-' ? 
                <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{color: 'red', fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            doctorColumnHelper.accessor('varNewPatients', {
                header: 'MOM Var of New Patients',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            doctorColumnHelper.accessor('hoursWorked', {
                header: 'Hours Worked',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            doctorColumnHelper.accessor('varHoursWorked', {
                header: 'MOM Var of Hours Worked',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            doctorColumnHelper.accessor('daysWorked', {
                header: 'Days Worked',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            doctorColumnHelper.accessor('varDaysWorked', {
                header: 'MOM Var of Days Worked',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            doctorColumnHelper.accessor('revPerHour', {
                header: 'Revenue per Hour ($)',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            doctorColumnHelper.accessor('varRevPerHour', {
                header: 'MOM Var of Revenue per Hour',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            doctorColumnHelper.accessor('firstDoctor', {
                header: 'Dr with 1st highest rev per hr',
                cell: info => info.getValue()
            }),
            doctorColumnHelper.accessor('secondDoctor', {
                header: 'Dr with 2nd highest rev per hr',
                cell: info => info.getValue()
            }),
            doctorColumnHelper.accessor('thirdDoctor', {
                header: 'Dr with 3rd highest rev per hr',
                cell: info => info.getValue()
            }),
        ]
    })
]

export const treatmentColumns = (topTreatments, year) => {
    const headers = {
        grouped: '',
        top1: '', 
        varTop1: '',
        top2: '',
        varTop2: '',
        top3: '',
        varTop3: '',
        top4: '',
        varTop4: ''
    }

    if (topTreatments && Object.values(topTreatments).length > 0) {
        headers['grouped'] = 'Treatment Dashboard (for top 4 revenue-generating treatments of ' + (year - 1) + ')'
        headers['top1'] = topTreatments['top1'].charAt(0).toUpperCase() + topTreatments['top1'].slice(1).toLowerCase(), 
        headers['varTop1'] = 'MOM Var of ' + topTreatments['top1'].charAt(0).toUpperCase() + topTreatments['top1'].slice(1).toLowerCase()
        headers['top2'] = topTreatments['top2'].charAt(0).toUpperCase() + topTreatments['top2'].slice(1).toLowerCase()
        headers['varTop2'] = 'MOM Var of ' + topTreatments['top2'].charAt(0).toUpperCase() + topTreatments['top2'].slice(1).toLowerCase()
        headers['top3'] = topTreatments['top3'].charAt(0).toUpperCase() + topTreatments['top3'].slice(1).toLowerCase()
        headers['varTop3'] = 'MOM Var of ' + topTreatments['top3'].charAt(0).toUpperCase() + topTreatments['top3'].slice(1).toLowerCase()
        headers['top4'] = topTreatments['top4'].charAt(0).toUpperCase() + topTreatments['top4'].slice(1).toLowerCase()
        headers['varTop4'] = 'MOM Var of ' + topTreatments['top4'].charAt(0).toUpperCase() + topTreatments['top4'].slice(1).toLowerCase()
    }

    return [
        treatmentColumnHelper.group({
            id: 'treatment_dashboard',
            header: headers['grouped'],
            columns: [
                treatmentColumnHelper.accessor('label', {
                    id: 'label',
                    header: '',
                    cell: info => <div style={{textAlign: 'center', fontWeight: 'bold'}}>{info.getValue()}</div>
                }),
                treatmentColumnHelper.accessor('top1', {
                    id: 'top1',
                    header: () => headers['top1'],
                    cell: info => info.getValue() && info.getValue().split(' ').length === 2 ? 
                    (info.getValue().split(' '))[1].charAt(1) === '+' ? 
                    <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{color: 'green', fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                    (info.getValue().split(' '))[1].charAt(1) === '-' ? 
                    <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{color: 'red', fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                    <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                    <div style={{textAlign: 'right'}}>{info.getValue()}</div>
                }),
                treatmentColumnHelper.accessor('varTop1', {
                    id: 'varTop1',
                    header: () => headers['varTop1'],
                    cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
                }),
                treatmentColumnHelper.accessor('top2', {
                    id: 'top2',
                    header: () => headers['top2'],
                    cell: info => info.getValue() && info.getValue().split(' ').length === 2 ? 
                    (info.getValue().split(' '))[1].charAt(1) === '+' ? 
                    <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{color: 'green', fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                    (info.getValue().split(' '))[1].charAt(1) === '-' ? 
                    <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{color: 'red', fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                    <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                    <div style={{textAlign: 'right'}}>{info.getValue()}</div>
                }),
                treatmentColumnHelper.accessor('varTop2', {
                    id: 'varTop2',
                    header: () => headers['varTop2'],
                    cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
                }),
                treatmentColumnHelper.accessor('top3', {
                    id: 'top3',
                    header: () => headers['top3'],
                    cell: info => info.getValue() && info.getValue().split(' ').length === 2 ? 
                    (info.getValue().split(' '))[1].charAt(1) === '+' ? 
                    <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{color: 'green', fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                    (info.getValue().split(' '))[1].charAt(1) === '-' ? 
                    <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{color: 'red', fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                    <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                    <div style={{textAlign: 'right'}}>{info.getValue()}</div>
                }),
                treatmentColumnHelper.accessor('varTop3', {
                    id: 'varTop3',
                    header: () => headers['varTop3'],
                    cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
                }),
                treatmentColumnHelper.accessor('top4', {
                    id: 'top4',
                    header: () => headers['top4'],
                    cell: info => info.getValue() && info.getValue().split(' ').length === 2 ? 
                    (info.getValue().split(' '))[1].charAt(1) === '+' ? 
                    <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{color: 'green', fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                    (info.getValue().split(' '))[1].charAt(1) === '-' ? 
                    <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{color: 'red', fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                    <div style={{textAlign: 'right'}}><span>{(info.getValue().split(' '))[0]}</span><span style={{fontWeight: 'bold'}}>{(info.getValue().split(' '))[1]}</span></div> : 
                    <div style={{textAlign: 'right'}}>{info.getValue()}</div>
                }),
                treatmentColumnHelper.accessor('varTop4', {
                    id: 'varTop4',
                    header: () => headers['varTop4'],
                    cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
                }),
            ]
        })
    ]
}

export const patientColumns = [
    patientColumnHelper.group({
        header: 'Patient Dashboard',
        columns: [
            patientColumnHelper.accessor('label', {
                header: '',
                cell: info => <div style={{textAlign: 'center', fontWeight: 'bold'}}>{info.getValue()}</div>
            }), 
            patientColumnHelper.accessor('allSeg1', {
                header: 'All Segments 1',
                cell: info => info.getValue()
            }), 
            patientColumnHelper.accessor('allSeg2', {
                header: 'All Segments 2',
                cell: info => info.getValue()
            }), 
            patientColumnHelper.accessor('allSeg3', {
                header: 'All Segments 3',
                cell: info => info.getValue()
            }), 
            patientColumnHelper.accessor('allSeg4', {
                header: 'All Segments 4',
                cell: info => info.getValue()
            }), 
            patientColumnHelper.accessor('firstTreat1', {
                header: 'First Treatment 1',
                cell: info => info.getValue()
            }), 
            patientColumnHelper.accessor('firstTreat2', {
                header: 'First Treatment 2',
                cell: info => info.getValue()
            }), 
            patientColumnHelper.accessor('firstTreat3', {
                header: 'First Treatment 3',
                cell: info => info.getValue()
            }), 
            patientColumnHelper.accessor('highVal1', {
                header: 'High Value Segment 1',
                cell: info => info.getValue()
            }), 
            patientColumnHelper.accessor('highVal2', {
                header: 'High Value Segment 2',
                cell: info => info.getValue()
            }), 
            patientColumnHelper.accessor('highVal3', {
                header: 'High Value Segment 3',
                cell: info => info.getValue()
            }), 
        ]
    })
]

export const conversionColumns = [
    conversionColumnHelper.group({
        header: 'Patient Conversion Dashboard',
        columns: [
            conversionColumnHelper.accessor('dateLabel', {
                header: '',
                cell: info => <div style={{textAlign: 'center', fontWeight: 'bold'}}>{info.getValue()}</div>
            }),
            conversionColumnHelper.accessor('firstPatients', {
                header: '1st-time Patient Count (Cumulative over 6-month period)',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            conversionColumnHelper.accessor('returnedPatients', {
                header: 'Returned within the 6-month period',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            }),
            conversionColumnHelper.accessor('conversionRate', {
                header: 'Conversion Rate',
                cell: info => <div style={{textAlign: 'right'}}>{info.getValue()}</div>
            })
        ]
    })
]