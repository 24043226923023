import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { HomeIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Breadcrumb } from "react-bootstrap";
import { useChainId } from "hooks/useChainId";
import { Routing } from "routes";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Checkbox } from "@mui/material";
import { MONTH_SLIDER } from "constants/date";
import MonthSlider from "components/filters/MonthSlider";
import Select, { components } from "react-select";
import { format, parse, minTime } from "date-fns";
import * as kpiPlanner from "actions/kpiPlannerAction";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  SYSTEM_DATE_DISPLAY_FORMAT2_DF,
  SYSTEM_DATE_FORMAT_DF,
} from "constants/common";

import TotalRevenueBarChart from "./TotalRevenueBarChart";
import TotalRevenuePieChart from "./TotalRevenuePieChart";
import TreatmentAverage from "./TreatmentAverage";
import TreatmentTarget from "./TreatmentTarget";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "2%",
    marginBottom: "2%",
  },
  boxroot: {
    display: "flex",
    justifyContent: "center",
  },
  box: {
    width: "100%",
    height: "100%",
    border: "1px solid",
    borderRadius: "4%",
    borderColor: "#000000",
  },
  card: {
    width: "100%",
    height: "auto",
    marginTop: "2em",
    marginBottom: "2em",
    transition: "0.3s",
  },
  row: {
    marginTop: "2em",
  },
  inbox: {
    padding: "2.2em",
  },
  txt: {
    marginTop: "2%",
  },
  icon: {
    float: "right",
    marginTop: "-10%",
    paddingRight: "10%",
  },
  textField: {
    width: "20%",
    marginLeft: "1em",
    marginRight: "auto",
    paddingBottom: 0,

    marginBottom: "20%",
    fontWeight: 500,
  },
  input: {
    backgroundColor: "white",
  },
  img: {
    marginLeft: "54.41%",
    marginRight: "44.44%",
    marginTop: "36.13%",
    marginBottom: "62.4%",
    width: "18px",
    height: "15.23px",

    backgroundColor: "#2F4858",
    borderRadius: "20px",
  },
  fontF: {
    fontFamily: "Poppins",
    fontWeight: "500",
  },
}));

const CustomOption = (props) => {
  return (
    <div>
      <components.Option {...props} className="react-select-option">
        <Checkbox
          checked={props.isSelected}
          color="default"
          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
        />
        <label style={{ whiteSpace: "nowrap" }}>{props.label}</label>
      </components.Option>
    </div>
  );
};

const PerformanceSummaryReport = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const redirectUrl = (url) => {
    history.push(url);
  };

  const chainId = useChainId();
  const drClinic = useSelector(
    (state) => state.kpiPlannerReducer.doctorPRSclinic
  );
  const drTreatment = useSelector((state) => state.kpiPlannerReducer.treatment);
  const loggedIN = useSelector((state) => state.adminAuthReducer.adminUser);

  const [selectedDate, setSelectedDate] = useState({});
  const [clinics, setClinics] = useState([]);
  const [selectedClinics, setSelectedClinics] = useState([]);
  const [clinicIds, setClinicIds] = useState([]);
  const [treatmentFilter, setTreatmentFilter] = useState([]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [treatmentIds, setTreatmentIds] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(props.selectedComponent);

  useEffect(() => {
    props.actions.kpiPlanner.getDoctorsTtlRevenue(chainId);
    props.actions.kpiPlanner.getDoctorPRSClinic(chainId);
    props.actions.kpiPlanner.getDoctorPRSTreatment();
  }, []);

  useEffect(() => {
    if (drClinic && drClinic.length) {
      let clinics = [];
      if (loggedIN.Role.id == 3 && loggedIN.clinic) {
        drClinic.forEach((clinic) => {
          if (loggedIN.clinic.clinicId == clinic.clinicId)
            clinics.push({ value: clinic.clinicId, label: clinic.clinicId });
        });
      } else {
        clinics = [{ value: "All Clinics", label: "All Clinics" }];
        drClinic.forEach((clinic) => {
          clinics.push({ value: clinic.clinicId, label: clinic.clinicId });
        });
        setClinics(clinics);
      }
      handleClinicChange(clinics);
    }
  }, [drClinic]);

  const handleMonthChange = (date) => {
    setSelectedDate(date);
  };

  const handleClinicChange = (selectedClinics) => {
    let selectedClinicIds = selectedClinics.map((selectedClinic) =>
      selectedClinic.value.toUpperCase()
    );
    if (selectedClinicIds.includes("ALL CLINICS")) {
      selectedClinicIds = drClinic.map((clinic) =>
        clinic.clinicId.toUpperCase()
      );
      setSelectedClinics([{ value: "All Clinics", label: "All Clinics" }]);
    } else {
      setSelectedClinics(selectedClinics);
    }
    setClinicIds(selectedClinicIds);
  };

  useEffect(() => {
    if (drTreatment && drTreatment.length) {
      let treatmentFilter = [
        { value: "All Treatments", label: "All Treatments" },
      ];
      drTreatment.forEach((treatment) => {
        treatmentFilter.push({
          value: treatment.treatmentId,
          label: treatment.treatmentName,
        });
      });
      setTreatmentFilter(treatmentFilter);
      handleTreatmentChange([
        { value: "All Treatments", label: "All Treatments" },
      ]);
    }
  }, [drTreatment]);

  const handleTreatmentChange = (selectedTreatments) => {
    let selectedTreatmentIds = selectedTreatments.map(
      (selectedTreatment) => selectedTreatment.value
    );
    if (selectedTreatmentIds.includes("All Treatments")) {
      selectedTreatmentIds = drTreatment.map(
        (treatment) => treatment.treatmentId
      );
      setSelectedTreatments([
        { value: "All Treatments", label: "All Treatments" },
      ]);
    } else {
      setSelectedTreatments(selectedTreatments);
    }
    setTreatmentIds(selectedTreatmentIds);
  };

  useEffect(() => {
    if (chainId !== undefined && clinicIds.length && treatmentIds.length) {
      props.actions.kpiPlanner.getTotalRevenuePieChart(chainId, clinicIds, treatmentIds, selectedDate);
      props.actions.kpiPlanner.getTotalRevenueBarGraph(chainId, clinicIds, treatmentIds, selectedDate);
      props.actions.kpiPlanner.getTreatmentTarget(chainId, clinicIds, treatmentIds, selectedDate);
      props.actions.kpiPlanner.getTotalRevenueBarChart(chainId, clinicIds, treatmentIds, selectedDate);
    }
  }, [chainId, clinicIds, treatmentIds, selectedDate]);

  // #265: Change 'for' to 'of' for revenue per hour and average time
  const componentMapping = {
    "Total Revenue Pie Chart": TotalRevenuePieChart,
    "Total Revenue Bar Graph": TotalRevenueBarChart,
    "Revenue per Hour of Treatment": TreatmentTarget,
    "Average Time of Treatment": TreatmentAverage,
  };

  const getSelectedComponent = (componentName, isEmbedded = false) => {
    const SelectedComponent = componentMapping[componentName];
    return <SelectedComponent {...props} isEmbedded={isEmbedded} />;
  };

  const goBackToDashboard = () => {
    window.history.replaceState(null, "Medtrik", "/PerformanceSummaryReport");
    setSelectedComponent(null);
}

  return (
    <>
      <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center ">
        <div className={classes.txt}>
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            {
              selectedComponent ?
              <>
                <Breadcrumb.Item onClick={goBackToDashboard}>Doctor Performance Summary Report</Breadcrumb.Item>
                <Breadcrumb.Item active className={classes.fontF}>{selectedComponent}</Breadcrumb.Item>
              </> :
              <Breadcrumb.Item active className={classes.fontF}>Doctor Performance Summary Report</Breadcrumb.Item>
            }
          </Breadcrumb>
          {
            selectedComponent ?
              <h4 className={classes.fontF}>{selectedComponent}</h4> :
              <h4 className={classes.fontF}>Doctor Performance Summary Report</h4>
          }
        </div>
      </div>
      <Card className={classes.card}>
        <Card.Body>
          <Row className={`${"justify-content-center"} ${classes.row} mb-5`}>
            <Col xs={12} lg={3} md={3}>
              {loggedIN.Role.id != 3 && loggedIN.Role.id != 4 && (
                <Select
                  value={selectedClinics}
                  className="medtrik-dropdown"
                  placeholder="Select Clinic(s)..."
                  isMulti
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      width: "max-content",
                    }),
                  }}
                  components={{
                    IndicatorSeparator: () => null,
                    Option: CustomOption,
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={handleClinicChange}
                  options={clinics}
                />
              )}
            </Col>
            <Col xs={12} lg={3} md={3}>
              <Select
                value={selectedTreatments}
                className="medtrik-dropdown"
                placeholder="Treatment Type"
                isMulti
                styles={{
                  menu: (provided, state) => ({
                    ...provided,
                    width: "max-content",
                  }),
                }}
                components={{
                  IndicatorSeparator: () => null,
                  Option: CustomOption,
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={handleTreatmentChange}
                options={treatmentFilter}
              />
            </Col>
            <Col xs={12} lg={6}>
              <h6 className="ms-5 mb-0">
                Selected Month Range{" "}
                {selectedDate?.startDate &&
                  format(
                    parse(
                      selectedDate.startDate,
                      SYSTEM_DATE_FORMAT_DF,
                      minTime
                    ),
                    SYSTEM_DATE_DISPLAY_FORMAT2_DF
                  )}{" "}
                -{" "}
                {selectedDate?.endDate &&
                  format(
                    parse(selectedDate.endDate, SYSTEM_DATE_FORMAT_DF, minTime),
                    SYSTEM_DATE_DISPLAY_FORMAT2_DF
                  )}
              </h6>
              <MonthSlider
                {...Object.assign({}, MONTH_SLIDER, {
                  display: true,
                  type: 2,
                  max: 24,
                })}
                handleMonthChange={handleMonthChange}
              />
            </Col>
          </Row>
            {selectedComponent && getSelectedComponent(selectedComponent)}
          <Row className={`performance-summary-dashboard ${"justify-content-center"} ${classes.row}`}>
            {!selectedComponent && Object.keys(componentMapping).map((doctorComponent, index) => {
              return (
                <Col xs={12} lg={6} key={index}>
                  <div className="mb-4" onClick={() => setSelectedComponent(doctorComponent)}>
                    <div className="pill-container px-4 pt-3">
                      <div className="pill-text mt-3 mb-3">{doctorComponent}</div>
                      {getSelectedComponent(doctorComponent, true)}
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
    const {kpiPlannerReducer} = state;
    return {
        treatmentTarget: kpiPlannerReducer.treatmentTarget,
        revenueBarGraph: kpiPlannerReducer.revenueBarGraph,
        revenuePieChart: kpiPlannerReducer.revenuePieChart,
        revenueBarChart: kpiPlannerReducer.revenueBarChart,
    }
};

const mapDispatchToProps = dispatch => ({
    actions: {
        kpiPlanner: bindActionCreators(kpiPlanner, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceSummaryReport);
